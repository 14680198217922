import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { requiredWhenLoaded } from '../../../utils/propTypes';
import Dot from '../Dot';
import CtaPopup from '../CtaPopup';
import './style.scss';

class NavigationItem extends Component {
    state = {
        isHoveringOverComponent: false,
    }

    resetState = () => {
        this.setState({ isHoveringOverComponent: false });
    }

    toggleHover = () => {
        this.setState(({ isHoveringOverComponent }) => ({
            isHoveringOverComponent: !isHoveringOverComponent,
        }));
    }

    render() {
        const {
            label,
            disabled,
            action,
            pathname,
            url,
            userHasUnseenTopics,
            notificationsEnabled,
            showCtaPopup,
        } = this.props;

        return action ? (
            <div className="menu_item">
                <Menu.Item
                    className="regular-spacing"
                    active={pathname === url}
                    disabled={disabled}
                    onClick={action}
                >
                    {label}
                </Menu.Item>
            </div>
        ) : (
            <div className="menu_item" onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                <Menu.Item
                    className="regular-spacing"
                    active={pathname === url}
                    disabled={disabled}
                    as={disabled ? null : Link}
                    to={url}
                >
                    {label}
                </Menu.Item>
                { notificationsEnabled && userHasUnseenTopics && <Dot /> }
                { notificationsEnabled &&
                    userHasUnseenTopics &&
                    showCtaPopup &&
                    this.state.isHoveringOverComponent &&
                    <CtaPopup onMoreClick={this.resetState} /> }
            </div>
        );
    }
}

NavigationItem.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    action: PropTypes.func,
    pathname: PropTypes.string.isRequired,
    url: requiredWhenLoaded(PropTypes.string.isRequired, 'disabled'),
    userHasUnseenTopics: PropTypes.bool.isRequired,
    // only My Feed shows CTA notifications, for other items this isn't necessary
    notificationsEnabled: PropTypes.bool,
    showCtaPopup: PropTypes.bool.isRequired,
};

NavigationItem.defaultProps = {
    action: null,
    notificationsEnabled: false,
    url: null,
};

export default NavigationItem;
