import React from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Footer from '../Footer';
import Disclaimer from '../Disclaimer';
import ContainerV2 from '../ContainerV2';
import Modal from '../Modal';
import HeaderContainer from '../../containers/header';

export default function Layout(Component) {
    const layout = ({ useContainerV2, ...props }) => (
        <>
            {<HeaderContainer />}
            {useContainerV2 ?
                (
                    <ContainerV2>
                        <Component {...props} />
                    </ContainerV2>
                ) :
                (
                    <Container className="base-container">
                        <Component {...props} />
                    </Container>
                )
            }
            <Modal />
            <Disclaimer />
            <Footer />
        </>
    );

    layout.propTypes = {
        useContainerV2: PropTypes.bool,
    };

    layout.defaultProps = {
        useContainerV2: false,
    };

    return layout;
}
