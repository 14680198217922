import React from 'react';
import { withRouter } from 'react-router';
import { parse } from 'qs';

/**
 * Same as withRouter but parses query params
 */
export default Component => withRouter((props) => {
    const { location } = props;
    const query = location.search.length ? parse(location.search.substring(1)) : {};

    return <Component {...props} location={{ ...location, query }} />;
});
