import client from '../../api';
import { users } from '../../api/endpoints';

const {
    index: endpoint,
    restore,
    singleUser,
} = users;

export const createUser = userData => client.post(endpoint, { data: userData });

export const loadUsers = query => client.get(endpoint, { params: query });

export const loadUser = id => client.get(singleUser(id));

export const updateUser = userData => client.patch(singleUser(userData.id), { data: userData });

export const restoreUser = ({ id }) => client.patch(restore(id));

export const deleteUser = id => client.delete(singleUser(id));
