import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DotsVerticalIcon, CheckIcon, Cross2Icon } from '@radix-ui/react-icons';
import { Dimmer, Loader } from 'semantic-ui-react';
import Button from '../../components/Button';
import CaseSearch from '../../components/ConsensusSummarySubmission/CaseSearch';
import ConsensusSubmissionForm from '../../components/ConsensusSummarySubmission/ConsensusSubmissionForm';
import ConsensusPreview from '../../components/ConsensusSummarySubmission/ConsensusPreview';
import { STRAPI_ID, postConsensusSummary } from '../../services/strapi/reducer';
import client from '../../api/apiSaga';
import { comments, questions } from '../../api/endpoints';
import SubmissionComplete from '../../components/ConsensusSummarySubmission/SubmissionComplete';
import './consensusSummarySubmission.scss';
import ConsensusSummary from '../../models/consensusSummary';
import { analytics } from '../../utils/analytics';

const getCaseWithDetails = async (topicId) => {
    try {
        const { data } = await client.get(questions.singleQuestionInternal(topicId));
        return { success: true, data };
    } catch (e) {
        switch (e.status) {
            case 404:
                return { success: false, message: 'Case Not Found' };
            default:
                return { success: false, message: 'An Error occurred. Please Try Again' };
        }
    }
};

const getQuotesForPatientCase = async (topicId) => {
    try {
        const { data } = await client.get(comments.index, {
            params: {
                topicId,
                ignoreDeleted: true,
            },
        });
        return { success: true, data };
    } catch (e) {
        switch (e.status) {
            case 404:
                return { success: false, message: 'Comments Not Found' };
            default:
                return { success: false, message: 'An Error occurred. Please Try Again' };
        }
    }
};

const SummarySubmission = ({
    isSubmitting,
    submitConsensusSummary,
}) => {
    const [caseToSummarize, setCaseToSummarize] = useState();
    const [caseSearchError, setCaseSearchError] = useState();
    const [submissionStep, setSubmissionStep] = useState('caseSearch');
    const [consensusData, setConsensusData] = useState();
    const [anchorLinkActive, setAnchorLinkActive] = useState({
        patient: false,
        community: false,
        conclusion: false,
        definitions: false,
        references: false,
    });
    const [eventsSet, setEventsSet] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const [quotes, setQuotes] = useState([]);

    useEffect(() => {
        // remove any previously lingering data in local storage.
        // this logic can be removed after a certain period of time
        // once all the existing users of this flow have submitted a new summary
        window.localStorage.removeItem('caseToSummarize');
        window.localStorage.removeItem('consensusSubmission');
        window.localStorage.removeItem('caseQuotes');
    }, []);

    const handleCaseSearch = async (caseId) => {
        const res = await getCaseWithDetails(caseId);

        if (res.success) {
            setCaseToSummarize(res.data);
            setCaseSearchError();
            setSubmissionStep('consensusFormEdit');
        } else {
            setCaseSearchError(res);
        }

        const quotesResponse = await getQuotesForPatientCase(caseId);
        if (quotesResponse.success) {
            const formattedData = quotesResponse?.data.comments.map((comment) => {
                const user = `${comment.createdByUser.firstName} ${comment.createdByUser.lastName}`;
                const { text } = comment;
                const label = `${user} - ${text}`;
                const specialty = comment.createdByUser.specialty?.name;
                return {
                    user,
                    text,
                    label,
                    specialty,
                };
            });
            setQuotes(formattedData);
        }
    };

    const handleSubmitConsensus = (consensusSummaryData) => {
        analytics.trackEvent('custom_submit_click');
        const consensusSummary = new ConsensusSummary();
        consensusSummary.data = consensusSummaryData;
        const validationResults = consensusSummary.validate();
        if (!validationResults.isValid) {
            throw new Error(validationResults.validationString);
        }
        consensusSummary.buildSummaryTile();
        consensusSummary.sanitize();

        submitConsensusSummary(consensusSummary, setSubmissionStep);
    };

    const handleChangeForm = (values) => {
        setConsensusData(values);
    };

    const validateConsensusSummary = () => {
        const consensusSummary = new ConsensusSummary();
        if (consensusData) {
            consensusSummary.data = consensusData.data;
        }
        return consensusSummary.validate();
    };

    const shouldDisablePreview = () => !validateConsensusSummary().isValid;

    const screenWidth = () => {
        const docElem = window.document.documentElement;
        const body = window.document.getElementsByTagName('body')[0];
        return window.innerWidth || docElem.clientWidth || body.clientWidth;
    };
    const mobileWidth = 1350;

    const toggleMobileMenu = () => {
        if (screenWidth() <= mobileWidth) {
            setShowMenu(!showMenu);
        }
    };

    const hideMobileMenu = () => {
        if (screenWidth() <= mobileWidth) {
            setShowMenu(false);
            return;
        }
        setShowMenu(true);
    };

    // add smooth scrolling to each anchor link
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener('click', function scrollToAnchor(e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth',
            });
        });
    });

    const findCurrentMenuSection = () => {
        const verticalOffset = screenWidth() < mobileWidth ? 250 : 350;
        const newAnchorLinkActive = Object.assign({}, anchorLinkActive);
        let last;
        Object.keys(newAnchorLinkActive).forEach((anchor) => {
            newAnchorLinkActive[anchor] = false;
            const targetElement = document.querySelector(`a[id^="${anchor}"]`);
            if (targetElement && (window.scrollY >= (targetElement.offsetTop + verticalOffset))) {
                last = anchor;
            }
        });
        if (last) {
            newAnchorLinkActive[last] = true;
        }
        setAnchorLinkActive(newAnchorLinkActive);
    };

    if (!eventsSet) {
        document.addEventListener('scroll', () => {
            findCurrentMenuSection();
        });

        window.addEventListener('resize', hideMobileMenu);
        hideMobileMenu();

        setEventsSet(true);
    }

    const validationData = validateConsensusSummary().sections;

    return (
        <div className="narrow consensus-summary">
            <Dimmer page active={isSubmitting}>
                <Loader />
            </Dimmer>
            {submissionStep === 'caseSearch' && <CaseSearch error={caseSearchError} onSubmit={handleCaseSearch} />}
            {submissionStep === 'consensusFormEdit' && (
                <Fragment>
                    <ConsensusSubmissionForm
                        caseToSummarize={caseToSummarize}
                        onCaseEdit={() => setSubmissionStep('caseSearch')}
                        onChangeForm={handleChangeForm}
                        onSubmit={() => setSubmissionStep('previewConsensus')}
                        quotes={quotes}
                    />
                    {submissionStep === 'consensusFormEdit' && showMenu && (
                        <div className="consensus-form-menu">
                            <div className="menu-header">
                                <DotsVerticalIcon />
                                <span className="consensus-form-menu-title">Summary outline</span>
                            </div>
                            <ul>
                                <li className={anchorLinkActive.patient ? 'active-link' : ''}><a href="#patient">Summary title {validationData.summaryTitle ? <CheckIcon /> : <Cross2Icon className="invalid" />}</a></li>
                                <li className={anchorLinkActive.community ? 'active-link' : ''}><a href="#community">Community consensus {validationData.communityConsensus ? <CheckIcon /> : <Cross2Icon className="invalid" />}</a></li>
                                <li className={anchorLinkActive.conclusion ? 'active-link' : ''}><a href="#conclusion">Conclusion {validationData.conclusion ? <CheckIcon /> : <Cross2Icon className="invalid" />}</a></li>
                                <li className={anchorLinkActive.definitions ? 'active-link' : ''}><a href="#definitions">Definitions {validationData.definitions ? <CheckIcon /> : <Cross2Icon className="invalid" />}</a></li>
                                <li className={anchorLinkActive.references ? 'active-link' : ''}><a href="#references">List of references {validationData.listOfReferences ? <CheckIcon /> : <Cross2Icon className="invalid" />}</a></li>
                            </ul>
                        </div>
                    )}
                    <div className="consensus-summary-footer">
                        <Button
                            variant="secondary"
                            className="preview-button"
                            disabled={shouldDisablePreview()}
                            onClick={() => {
                                analytics.trackEvent('custom_preview_consensus_click');
                                setSubmissionStep('previewConsensus');
                            }}
                            type="submit"
                        >
                            Preview
                        </Button>
                        <p className="errors-text">{shouldDisablePreview() ? <b>Errors: </b> : ''}{validateConsensusSummary().validationString}</p>
                        <Button variant="icon" className="menu-button" onClick={() => toggleMobileMenu()} type="button">
                            <DotsVerticalIcon />
                        </Button>
                    </div>
                </Fragment>
            )}
            {submissionStep === 'previewConsensus' && (
                <Fragment>
                    <ConsensusPreview
                        consensusData={consensusData}
                    />
                    <div className="consensus-summary-footer">
                        <Button
                            variant="secondary"
                            className="preview-button"
                            onClick={() => {
                                analytics.trackEvent('custom_go_back_and_edit_click');
                                setSubmissionStep('consensusFormEdit');
                            }}
                            type="submit"
                        >
                            Go back and edit
                        </Button>
                        <Button className="submit-button" onClick={() => handleSubmitConsensus(consensusData.data)}>
                            Submit
                        </Button>
                    </div>
                </Fragment>
            )}
            {submissionStep === 'submissionComplete' && (
                <SubmissionComplete />
            )}
        </div>
    );
};

SummarySubmission.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    submitConsensusSummary: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    [STRAPI_ID]: {
        isSubmitting,
    },
}) => ({
    isSubmitting,
});

const mapDispatchToProps = dispatch => ({
    submitConsensusSummary: (consensusSummary, setSubmissionStep) =>
        dispatch(postConsensusSummary({ consensusSummary, setSubmissionStep })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummarySubmission);
