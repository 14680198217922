import { combineReducers } from 'redux';
import { reducer as QuestionsReducer, ID as QuestionsID } from './questions';
import { reducer as AnswersReducer, ID as AnswersID } from './answers';
import { reducer as UsersReducer, ID as UsersID } from './users';
import { reducer as AppHcUsersReducer, AppHcUserID } from './appHcUsers/slice';
import { reducer as RolesReducer, ID as RolesID } from './roles';
import { reducer as SpecialtyReducer, ID as SpecialtyID } from './specialties';
import { reducer as CampaignsReducer, ID as CampaignsID } from './campaigns';
import { reducer as ProgramsReducer, ID as ProgramsID } from './programs';
import { reducer as topicProgramsReducer, ID as TopicProgramsID } from './topicPrograms';
import { reducer as globalHcmpReducer, ID as GlobalHcmpID } from './globalHcmp';
import { reducer as eventReducer, ID as EventID } from './events';
import { reducer as RewardsReducer, ID as RewardsID } from './rewards';
import { reducer as TooltipReducer, ID as TooltipID } from './tooltips';

export default combineReducers({
    [QuestionsID]: QuestionsReducer,
    [AnswersID]: AnswersReducer,
    [UsersID]: UsersReducer,
    [AppHcUserID]: AppHcUsersReducer,
    [SpecialtyID]: SpecialtyReducer,
    [RolesID]: RolesReducer,
    [CampaignsID]: CampaignsReducer,
    [ProgramsID]: ProgramsReducer,
    [TopicProgramsID]: topicProgramsReducer,
    [GlobalHcmpID]: globalHcmpReducer,
    [EventID]: eventReducer,
    [RewardsID]: RewardsReducer,
    [TooltipID]: TooltipReducer,
});
