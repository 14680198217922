export const ID = 'CAMPAIGNS';

export const DOWNLOAD_TARGET_LIST = `${ID}/DOWNLOAD_TARGET_LIST`;
export const DOWNLOAD_TARGET_LIST_COMPLETED = `${ID}/DOWNLOAD_TARGET_LIST_COMPLETED`;
export const DOWNLOAD_TARGET_LIST_FAILED = `${ID}/DOWNLOAD_TARGET_LIST_FAILED`;
export const DOWNLOAD_ANSWER_REPORT = `${ID}/DOWNLOAD_ANSWER_REPORT`;
export const DOWNLOAD_ANSWER_REPORT_COMPLETED = `${ID}/DOWNLOAD_ANSWER_REPORT_COMPLETED`;
export const DOWNLOAD_ANSWER_REPORT_FAILED = `${ID}/DOWNLOAD_ANSWER_REPORT_FAILED`;
export const EXPORT_USERS_LIST = `${ID}/EXPORT_USERS_LIST`;
export const EXPORT_USERS_LIST_COMPLETE = `${ID}/EXPORT_USERS_LIST_COMPLETE`;
export const EXPORT_USERS_LIST_FAILED = `${ID}/EXPORT_USERS_LIST_FAILED`;
export const LOAD_CAMPAIGNS = `${ID}/LOAD_CAMPAIGNS`;
export const LOAD_CAMPAIGNS_COMPLETED = `${ID}/LOAD_CAMPAIGNS_COMPLETED`;
export const LOAD_CAMPAIGNS_FAILED = `${ID}/LOAD_CAMPAIGNS_FAILED`;
export const CREATE_CAMPAIGN = `${ID}/CREATE_CAMPAIGN`;
export const CREATE_CAMPAIGN_COMPLETED = `${ID}/CREATE_CAMPAIGN_COMPLETED`;
export const CREATE_CAMPAIGN_FAILED = `${ID}/CREATE_CAMPAIGN_FAILED`;
export const DELETE_CAMPAIGN = `${ID}/DELETE_CAMPAIGN`;
export const DELETE_CAMPAIGN_COMPLETED = `${ID}/DELETE_CAMPAIGN_COMPLETED`;
export const DELETE_CAMPAIGN_FAILED = `${ID}/DELETE_CAMPAIGN_FAILED`;

export const LOAD_GENERAL_CAMPAIGNS = `${ID}/LOAD_GENERAL_CAMPAIGNS`;
export const LOAD_GENERAL_CAMPAIGNS_COMPLETED = `${ID}/LOAD_GENERAL_CAMPAIGNS_COMPLETED`;
export const LOAD_GENERAL_CAMPAIGNS_FAILED = `${ID}/LOAD_GENERAL_CAMPAIGNS_FAILED`;
export const CREATE_SPECIALTY_CAMPAIGN = `${ID}/CREATE_SPECIALTY_CAMPAIGN`;
export const CREATE_SPECIALTY_CAMPAIGN_COMPLETED = `${ID}/CREATE_SPECIALTY_CAMPAIGN_COMPLETED`;
export const CREATE_SPECIALTY_CAMPAIGN_FAILED = `${ID}/CREATE_SPECIALTY_CAMPAIGN_FAILED`;
export const CREATE_NPI_CAMPAIGN = `${ID}/CREATE_NPI_CAMPAIGN`;
export const CREATE_NPI_CAMPAIGN_COMPLETED = `${ID}/CREATE_NPI_CAMPAIGN_COMPLETED`;
export const CREATE_NPI_CAMPAIGN_FAILED = `${ID}/CREATE_NPI_CAMPAIGN_FAILED`;
export const DELETE_GENERAL_CAMPAIGN = `${ID}/DELETE_GENERAL_CAMPAIGN`;
export const DELETE_GENERAL_CAMPAIGN_COMPLETED = `${ID}/DELETE_GENERAL_CAMPAIGN_COMPLETED`;
export const DELETE_GENERAL_CAMPAIGN_FAILED = `${ID}/DELETE_GENERAL_CAMPAIGN_FAILED`;
