import { createSelector } from '@reduxjs/toolkit';
import { formValueSelector } from 'redux-form';
import { ID as DataID, SpecialtyID } from '../../../../data';
import { formName } from './constants';

const selectedSpecialtySelector = state => formValueSelector(formName)(state, 'specialtyId');
export const selectedRoleIdSelector = state => formValueSelector(formName)(state, 'roleId');
export const selectedAppHcIdSelector = state => formValueSelector(formName)(state, 'appHcUserId');

const specialtiesSelector = ({
    [DataID]: {
        [SpecialtyID]: {
            specialties,
        },
    },
}) => specialties;

const subSpecialtiesSelector = ({
    [DataID]: {
        [SpecialtyID]: {
            subSpecialties,
        },
    },
}) => subSpecialties;

export const filteredSpecsSelector = createSelector(
    [selectedSpecialtySelector, subSpecialtiesSelector],
    (selectedSpecialty, subSpecialties) => {
        if (!selectedSpecialty) {
            return [];
        }
        return subSpecialties.filter(x => x.specialtyId === selectedSpecialty)
            .map(({ id, name }) => ({ key: id, value: id, text: name }));
    },
);

export const sortedSpecialtiesSelector = createSelector(
    specialtiesSelector,
    items => items.map(({ id, name }) => ({ key: id, value: id, text: name })),
);
