import {
    all,
    put,
    takeEvery,
    take,
    select,
    takeLeading,
} from 'redux-saga/effects';
import { reset } from 'redux-form';
import { parse } from 'qs';
import { ID as DataID } from '../../data';
import { Actions as AnswerDataActions } from '../../data/answers';
import { LOAD_QUESTION_COMPLETED } from '../../data/questions/actionTypes';
import { selectors as questionSelectors, loadQuestion } from '../../data/questions';
import { answerInputFocused } from '../../data/events';
import { newAnswerFormName, newCommentFormName } from './Answers/constants';
import { actionTypes } from '../../data/programs';
import { markProgramAsRead } from '../../data/programs/api';
import { markAnswerAsNew, showNotice, showProgramFailed, hideNotice, prepareProgram } from './actions';
import { SHOW_PROGRAM, SHOW_NEXT_QUESTION, ANSWER_INPUT_FOCUSED, PREPARE_PROGRAM } from './actionTypes';
import { UPDATE_ANSWER_COMPLETED } from '../../data/answers/actions';
import { SHOW_DTI } from '../../services/sharedUIActions/actionTypes';
import { showDtiFailed } from '../../services/sharedUIActions/actions';
import { manualLog } from '../../services/actionsHandling/actions';
import { currentTopicIdSelector } from '../../data/questions/selectors';
import localization from '../../localization';
import { loadCurrentRewards } from '../../data/rewards';
import { hcmpEnabled, showAnswersInterstitialEnabled } from '../../environment';
import { push } from '../../services/navigation';
import links from '../../links';

const {
    thread: {
        programNotice: {
            answeredQuestionText,
            nextQuestionText,
        },
    },
} = localization;

const {
    GET_PROGRAM_FOR_USER_COMPLETED,
    GET_PROGRAM_FOR_USER_FAILED,
    ID: ProgramsDataID,
} = actionTypes;

function* resetFormSaga({ payload: { answer: { replyTo } } }) {
    if (replyTo) {
        yield put(reset(newCommentFormName));
    } else {
        yield put(reset(newAnswerFormName));
    }
}

function* showProgramSaga({ payload = {} }) {
    const {
        answerId = null,
        returnUrl = null,
        notice = null,
    } = payload;

    try {
        let url;
        let id = null;

        ({ url, id } = yield select(({
            [DataID]: {
                [ProgramsDataID]: { program = {} },
            },
        }) => program));

        if (!url) {
            const { type, payload: getProgramsPayload } = yield take([
                GET_PROGRAM_FOR_USER_COMPLETED,
                GET_PROGRAM_FOR_USER_FAILED,
            ]);
            if (type === GET_PROGRAM_FOR_USER_COMPLETED) {
                ({ url, id } = getProgramsPayload);
            }
        }

        if (url) {
            yield put(showNotice({ noticeText: notice }));
            yield take(SHOW_PROGRAM);
            const topicId = yield select(currentTopicIdSelector);

            try {
                if (answerId && topicId) {
                    yield markProgramAsRead(id, answerId, topicId, false);
                } else if (topicId) {
                    yield markProgramAsRead(id, null, topicId, true);
                } else {
                    yield put(manualLog({ customMessage: 'ShowProgramSagas payload has undefined topicId!' }));
                }
            } catch (error) {
                yield put(manualLog({ customMessage: 'Mark program as read failed', error }));
            }

            const {
                location: {
                    pathname,
                    origin,
                },
            } = window;

            // this is the place that error happens but rarely
            // we have url polyfill but still there is error in the process
            try {
                let useReturnUrl;

                if (!returnUrl) {
                    useReturnUrl = new URL(pathname, origin);
                    if (answerId) {
                        useReturnUrl.searchParams.append('answer_id', answerId);
                    }
                } else {
                    useReturnUrl = returnUrl;
                }

                const basePath = new URL(`https://${url}`);
                basePath.searchParams.append('return_url', useReturnUrl);
                window.location = basePath;
            } catch (error) {
                // we should define what to do if this happens
                // checking program as unseen would be to much of work for little gain
                yield put(hideNotice());
                yield put(manualLog({ customMessage: 'URL replacement failed', error }));
            }
        }
    } catch (error) {
        yield put(showProgramFailed({ error }));
    }
}

function* showNextQuestionSaga({ payload: { currentId, nextId, hasAnswered } }) {
    const nextInterstitialShow = !parseInt(sessionStorage.getItem('next_interstitial_shown'), 10);
    const shouldShowInterstitial = !parseInt(sessionStorage.getItem('sai_interstitial_shown'), 10);

    let id = null;
    ({ id } = yield select(({
        [DataID]: {
            [actionTypes.ID]: { program = {} },
        },
    }) => program));

    const showProgram = nextInterstitialShow &&
                        shouldShowInterstitial &&
                        id !== 0 &&
                        !hasAnswered &&
                        showAnswersInterstitialEnabled;

    if (showProgram) {
        const {
            location: {
                pathname,
                origin,
            },
        } = window;

        const returnUrl = new URL(pathname, origin);
        returnUrl.pathname = returnUrl.pathname.replace(currentId, nextId);

        sessionStorage.setItem('next_interstitial_shown', 1);

        yield put(prepareProgram({ returnUrl, notice: nextQuestionText }));
    } else {
        yield put(push(links.topics.view.replace(':id', nextId)));
    }
}

function* showDtiSaga() {
    try {
        const { dtiLink } = yield select(({
            [DataID]: {
                [ProgramsDataID]: data,
            },
        }) => data);
        if (dtiLink) {
            const basePath = new window.URL(`https://${dtiLink}`);
            window.location = basePath;
        }
    } catch (error) {
        yield put(showDtiFailed({ error }));
    }
}

function* highlightNewAnswerSaga() {
    try {
        yield take(AnswerDataActions.LOAD_ANSWERS_COMPLETED);
        // remove ? so they dont stack when user posts multiple answers/comments
        const lastUpdatedCommentId = parseInt(parse(window.location.search.replace('?', '')).answer_id, 10) || null;
        if (lastUpdatedCommentId) {
            yield put(markAnswerAsNew({ id: lastUpdatedCommentId }));
            const comment = document.getElementById(`comment-${lastUpdatedCommentId}`);
            // hacks
            if (comment) {
                comment.scrollIntoView();
                window.scrollBy(0, -100);
            }
        }
    } catch (e) {
        /* this fails if the comment isn't found because the id isn't set for the users answer
            as the answer is on top of the page it's unneeded to scroll to it
        */
    }
}

function* shouldDisplayIntersticialSaga({ payload: { isNew, answer: { id: answerId } } }) {
    const showInterstitial = !parseInt(sessionStorage.getItem('sai_interstitial_shown'), 10);

    if (isNew) {
        if (!showAnswersInterstitialEnabled || showInterstitial) {
            yield put(prepareProgram({ answerId, notice: answeredQuestionText }));
        }
    }
}

function* reloadRewardsAndQuestionSaga({ payload: { isNew } }) {
    if (isNew) {
        const topicId = yield select(currentTopicIdSelector);

        yield put(loadCurrentRewards());
        yield put(loadQuestion({ id: topicId }));
    }
}

function* answerFormFocusedSaga() {
    const isQuestionLoading = yield select(state => questionSelectors.questionLoading(state));
    if (isQuestionLoading) {
        yield take(LOAD_QUESTION_COMPLETED);
    }
    yield put(answerInputFocused());
}

export default function* () {
    const sagas = [
        takeEvery(AnswerDataActions.UPDATE_ANSWER_COMPLETED, resetFormSaga),
        takeEvery(UPDATE_ANSWER_COMPLETED, reloadRewardsAndQuestionSaga),
        takeLeading(ANSWER_INPUT_FOCUSED, answerFormFocusedSaga),
    ];

    if (hcmpEnabled) {
        sagas.push(
            highlightNewAnswerSaga(),
            takeEvery(UPDATE_ANSWER_COMPLETED, shouldDisplayIntersticialSaga),
            takeEvery(PREPARE_PROGRAM, showProgramSaga),
            takeEvery(SHOW_DTI, showDtiSaga),
            takeEvery(SHOW_NEXT_QUESTION, showNextQuestionSaga),
        );
    }
    yield all(sagas);
}
