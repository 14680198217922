import { required } from '../../../utils/form/validators';

const syncValidations = ({
    userEmail,
    title,
    message,
}) => ({
    userEmail: required(userEmail),
    title: required(title),
    message: required(message),
});

export default syncValidations;
