import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Accordion } from 'semantic-ui-react';
import localization from '../../localization';
import PrivacyPolicy, { policyAgreement } from '../PrivacyPolicy';
import './style.scss';

const {
    common: {
        agree,
        cancel,
    },
    disclaimer: {
        title,
        privacyPolicy,
        text,
    },
} = localization;

class Disclaimer extends Component {
    state = {
        privacyPolicyOpen: false,
    }

    togglePrivacyPolicy = () => {
        this.setState(prevState => ({ privacyPolicyOpen: !prevState.privacyPolicyOpen }));
    }

    render() {
        const {
            open,
            onDisagreeClick,
            onAgreeClick,
        } = this.props;
        const {
            privacyPolicyOpen,
        } = this.state;
        return (
            <Modal open={open} className="disclaimer">
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>{text}</p>

                        <Accordion >
                            <Accordion.Title
                                active={privacyPolicyOpen}
                                content={privacyPolicy}
                                onClick={this.togglePrivacyPolicy}
                            />
                            <Accordion.Content
                                active={privacyPolicyOpen}
                                content={<PrivacyPolicy />}
                            />
                        </Accordion>

                        <br />
                        {policyAgreement}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions className="buttons">
                    <Button secondary onClick={onDisagreeClick}>
                        {cancel}
                    </Button>
                    <Button primary onClick={onAgreeClick}>
                        {agree}
                    </Button>
                </Modal.Actions>
            </Modal>);
    }
}

Disclaimer.propTypes = {
    onDisagreeClick: PropTypes.func,
    onAgreeClick: PropTypes.func,
    open: PropTypes.bool,
};

Disclaimer.defaultProps = {
    open: false,
    onDisagreeClick: () => {},
    onAgreeClick: () => {},
};

export default Disclaimer;
