import Immutable from 'seamless-immutable';
import { handleActions } from 'redux-actions';
import {
    SEARCH_AUTHOR,
    SEARCH_AUTHOR_COMPLETED,
    SEARCH_AUTHOR_FAILED,
} from './actionTypes';

const initialState = Immutable.from({
    loading: false,
    authors: [],
});

export default handleActions({
    [SEARCH_AUTHOR]: state => state.set('loading', true),
    [SEARCH_AUTHOR_COMPLETED]: (state, { payload: { data } }) => state.merge({
        authors: data,
        loading: false,
    }),
    [SEARCH_AUTHOR_FAILED]: state => state.set('loading', false),

}, initialState);
