import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingIndicator } from '../../components';

const Login = () => {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        loginWithRedirect();
    }, [loginWithRedirect]);

    return (<LoadingIndicator active="true" />);
};

export default Login;
