import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { hideModal as hideModalAction, ID as APP_UI_ID } from '../../services/sharedUIActions';
import localization from '../../localization';

const {
    common: {
        yes,
        no,
    },
} = localization;

const BasicModal = ({
    open = true,
    loading,
    hideModal,
    header,
    icon,
    content,
    ok,
    positiveResponseAction,
    hideOnPositiveClick,
}) => (
    <Modal basic size="small" open={open} >
        <Header icon={icon} content={header} />
        <Modal.Content>
            {content}
        </Modal.Content>
        <Modal.Actions>
            {ok ? (
                <Button
                    color="green"
                    inverted
                    onClick={hideModal}
                >
                    <Icon name="checkmark" /> OK
                </Button>
            ) : (
                <div>
                    <Button
                        basic
                        color="red"
                        inverted
                        onClick={hideModal}
                    >
                        <Icon name="remove" /> {no}
                    </Button>
                    <Button
                        color="green"
                        loading={loading}
                        inverted
                        onClick={() => {
                            if (hideOnPositiveClick) {
                                hideModal();
                            }
                            positiveResponseAction();
                        }}
                    >
                        <Icon name="checkmark" /> {yes}
                    </Button>
                </div>
            )}
        </Modal.Actions>
    </Modal>
);

BasicModal.propTypes = {
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
    header: PropTypes.string,
    content: PropTypes.string,
    ok: PropTypes.bool,
    icon: PropTypes.string,
    positiveResponseAction: PropTypes.func,
    hideOnPositiveClick: PropTypes.bool,
};

BasicModal.defaultProps = {
    icon: null,
    loading: false,
    header: '',
    content: '',
    ok: false,
    hideOnPositiveClick: false,
    positiveResponseAction: () => null,
};

const mapStateToProps = ({
    [APP_UI_ID]: {
        modal = {},
    },
}) => modal;

export default connect(mapStateToProps, { hideModal: hideModalAction })(BasicModal);
