/* eslint-disable import/prefer-default-export */
import { ID as DataID } from '../';
import { ID } from '../programs/actionTypes';

export const dtiExpirationDateSelector = ({
    [DataID]: {
        [ID]: {
            expiresAt,
        },
    },
}) => expiresAt;
