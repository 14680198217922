import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid, Popup, Button, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Tooltip from '../../../components/Tooltip';
import rewardsPropTypes from './rewardsPropTypes';
import links from '../../../links';

const RewardsHeader = ({
    earned, max, npiUnrewardedTopicsCount, value,
}) => {
    if (max === earned && npiUnrewardedTopicsCount === 0) {
        return (
            <strong>All available rewards collected</strong>
        );
    }
    return (
        <>
            <strong>${((max - earned) * value) + (npiUnrewardedTopicsCount * 25)}</strong> in rewards available
        </>
    );
};

RewardsHeader.propTypes = {
    earned: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    npiUnrewardedTopicsCount: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
};

const QuestionsAnswered = ({
    earned, max, npiUnrewardedTopicsCount, npiRewardedTopicsCount, value,
}) => {
    if (max === earned && npiUnrewardedTopicsCount === 0) {
        return ` questions answered this week, $${(earned * value) + (npiRewardedTopicsCount * 25)} earned`;
    }
    return ' questions answered this week';
};

QuestionsAnswered.propTypes = {
    earned: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    npiUnrewardedTopicsCount: PropTypes.number.isRequired,
    npiRewardedTopicsCount: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
};

const RewardValuesButtons = ({
    earned, earnedRewards, max, npiUnrewardedTopics, npiRewardedTopics, value,
}) => {
    const { view } = links.topics;
    const buttons = [];
    for (let i = 0; i < (earnedRewards.length - npiRewardedTopics.length); i += 1) {
        buttons.push(<Button key={`${i}-button-earned`} className="gift-card-value active-gift-card-value">${earnedRewards[i]}</Button>);
    }
    for (let i = 0; i < npiRewardedTopics.length; i += 1) {
        buttons.push(<Button key={`${i}-button-earned`} className="gift-card-value active-gift-card-value gift-card-value-npi">$25.00</Button>);
    }
    for (let i = 1; i <= (max - earned); i += 1) {
        buttons.push(<Button key={`${i}-button-available`} as={Link} to={links.topics.myFeed} href className="gift-card-value">${value}</Button>);
    }
    npiUnrewardedTopics.forEach((topic) => {
        buttons.push(<Button key={`${topic.id}-button-npi-available`} as={Link} to={view.replace(':id', topic.id)} href className="gift-card-value gift-card-value-npi" >$25.00</Button>);
    });

    return <>{buttons}</>;
};

RewardValuesButtons.propTypes = {
    earned: PropTypes.number.isRequired,
    earnedRewards: PropTypes.arrayOf(PropTypes.string).isRequired,
    max: PropTypes.number.isRequired,
    npiUnrewardedTopics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
    })).isRequired,
    npiRewardedTopics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
    })).isRequired,
    value: PropTypes.string.isRequired,
};

const RewardsSection = ({
    rewards, rewardable,
}) => {
    const {
        max,
        value,
        npiUnrewardedTopics,
        npiRewardedTopics,
        earned,
        rewards: earnedRewards,
    } = rewards;

    const [showRewardsOnMobile, setShowRewardsOnMobile] = useState(false);
    const handleToggleShowRewardsOnMobile = useCallback(() => {
        setShowRewardsOnMobile(!showRewardsOnMobile);
    }, [showRewardsOnMobile, setShowRewardsOnMobile]);

    return (
        <div className="feed-rewards-section">
            <Grid>
                <Grid.Row only="computer" className="reward-row">
                    <Grid.Column mobile={3} tablet={2} computer={2} className="vertical-middle-content align-right">
                        <Tooltip
                            trigger={<Icon circular inverted color={!rewardable ? 'green' : 'yellow'} name={((max + npiUnrewardedTopics.length) === earnedRewards.length) ? 'check' : 'gift'} size="large" />}
                            name="questions-available-rewards-icon"
                        />

                    </Grid.Column>
                    <Grid.Column mobile={10} tablet={12} computer={6} className="vertical-middle-content">
                        <h6 className="gift-card-header">
                            <RewardsHeader earned={earned} max={max} npiUnrewardedTopicsCount={npiUnrewardedTopics.length} value={value} />
                        </h6>
                        <p className="gift-card-content">
                            <span className="gift-count-state">{earnedRewards.length} / {max + (earnedRewards.length - earned) + npiUnrewardedTopics.length} </span>
                            <QuestionsAnswered earned={earned} max={max} npiUnrewardedTopicsCount={npiUnrewardedTopics.length} npiRewardedTopicsCount={npiRewardedTopics.length} value={value} />
                        </p>
                    </Grid.Column>
                    <Grid.Column mobile={1} tablet={1} computer={7} className="vertical-middle-content">
                        <div className="horizontal-right-content">
                            <div className="rewards-right-wrapper">
                                <span className="rewards-value-wrapper">
                                    <RewardValuesButtons earned={earned} earnedRewards={earnedRewards} max={max} npiUnrewardedTopics={npiUnrewardedTopics} npiRewardedTopics={npiRewardedTopics} value={value} />
                                </span>
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column mobile={1} tablet={2} computer={1} className="vertical-middle-content">
                        <div className="horizontal-right-content">
                            <div className="rewards-right-wrapper">
                                <Popup
                                    trigger={<Icon circular name="info" className="info-icon" />}
                                    size="tiny"
                                    position="bottom center"
                                    inverted
                                    style={{ opacity: '#442A5C', padding: '0.5rem' }}
                                    hoverable
                                    wide
                                >
                                    <Popup.Content>
                                        Earn rewards for answering questions.
                                    </Popup.Content>
                                </Popup>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row only="mobile tablet" className="reward-row">
                    <Grid.Column width="2" className="vertical-middle-content align-right">
                        <Icon circular inverted color={(max === earnedRewards.length) ? 'green' : 'yellow'} name={(max === earnedRewards.length) ? 'check' : 'gift'} />
                    </Grid.Column>
                    <Grid.Column width="14" className="vertical-middle-content">
                        <Accordion>
                            <Accordion.Title
                                active={showRewardsOnMobile}
                                className="header-title"
                                index={0}
                                onClick={handleToggleShowRewardsOnMobile}
                            >
                                <h6 className="gift-card-header">
                                    <RewardsHeader earned={earned} max={max} npiUnrewardedTopicsCount={npiUnrewardedTopics.length} value={value} />
                                </h6>
                                <p className="gift-card-content">
                                    <span className="gift-count-state">{earnedRewards.length} / {max} </span>
                                    <QuestionsAnswered earned={earned} max={max} npiUnrewardedTopicsCount={npiUnrewardedTopics.length} npiRewardedTopicsCount={npiRewardedTopics.length} value={value} />
                                </p>
                                <Icon name="dropdown" className="dropdown-icon" />
                            </Accordion.Title>
                            <Accordion.Content active={showRewardsOnMobile}>
                                <div className="horizontal-right-content">
                                    <div className="rewards-right-wrapper">
                                        <span className="rewards-value-wrapper">
                                            <RewardValuesButtons earned={earned} earnedRewards={earnedRewards} max={max} npiUnrewardedTopics={npiUnrewardedTopics} npiRewardedTopics={npiRewardedTopics} value={value} />
                                        </span>
                                    </div>
                                </div>
                            </Accordion.Content>
                        </Accordion>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

RewardsSection.propTypes = {
    rewards: PropTypes.shape(rewardsPropTypes).isRequired,
    rewardable: PropTypes.bool.isRequired,
};

export default RewardsSection;
