import { logEvents } from '../../api/endpoints';
import client from '../../api';

const {
    logAnswerFormFocus,
} = logEvents;

// eslint-disable-next-line
export const logAnswerFormFocusEvent = topicViewId => client.post(
    logAnswerFormFocus,
    {
        data: {
            topicViewId,
        },
    },
);
