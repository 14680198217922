export const ID = 'SESSION';

export const SET_POST_AUTH_REDIRECT_URL = `${ID}/SET_POST_AUTH_REDIRECT_URL`;

export const FETCH_USER = `${ID}/FETCH_USER`;

export const FETCH_USER_COMPLETED = `${ID}/FETCH_USER_COMPLETED`;

export const FETCH_USER_FAILED = `${ID}/FETCH_USER_FAILED`;

export const LOGOUT = `${ID}/LOGOUT`;

export const MARK_DISCLAIMER_READ = `${ID}/DISCLAIMER_READ`;
export const MARK_DISCLAIMER = `${ID}/MARK_DISCLAIMER`;
export const MARK_DISCLAIMER_NOT_READ = `${ID}/DISCLAIMER_NOT_READ`;
export const MARK_DISCLAIMER_READ_FAILED = `${ID}/MARK_DISCLAIMER_READ_FAILED`;

export const REDIRECT_TO_UNSEEN_QUESTIONS = `${ID}/REDIRECT_TO_UNSEEN_QUESTIONS`;

export const DISABLE_CTA_POPUP = `${ID}/DISABLE_CTA_POPUP`;
export const DISABLE_CTA_POPUP_COMPLETED = `${ID}/DISABLE_CTA_POPUP_COMPLETED`;
export const DISABLE_CTA_POPUP_FAILED = `${ID}/DISABLE_CTA_POPUP_FAILED`;

export const ALLOW_TOOLTIPS = `${ID}/ALLOW_TOOLTIPS`;
export const ALLOW_TOOLTIPS_COMPLETED = `${ID}/ALLOW_TOOLTIPS_COMPLETED`;
export const ALLOW_TOOLTIPS_FAILED = `${ID}/ALLOW_TOOLTIPS_FAILED`;

export const DISABLE_TOOLTIPS = `${ID}/DISABLE_TOOLTIPS`;
export const DISABLE_TOOLTIPS_COMPLETED = `${ID}/DISABLE_TOOLTIPS_COMPLETED`;
export const DISABLE_TOOLTIPS_FAILED = `${ID}/DISABLE_TOOLTIPS_FAILED`;
