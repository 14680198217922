import React, { useEffect, useRef, useState } from 'react';
import { CaretDownIcon } from '@radix-ui/react-icons';
import PropTypes from 'prop-types';
import Avatar from './avatar';
import Dropdown from './dropdown';
import DropdownItem from './dropdownItem';
import { history } from '../../store';

const UserDropdown = ({ initials, dropdownItems }) => {
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleUserDropdownBlur = (e) => {
        if (!dropdownRef.current?.contains(e.target)) {
            setIsUserDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener('click', handleUserDropdownBlur);
        return () => {
            document.body.removeEventListener('click', handleUserDropdownBlur);
        };
    }, []);


    const handleUserDropdownItemClick = ({ url }) => {
        setIsUserDropdownOpen(false);
        history.push(url);
    };

    return (

        <div className="relative">
            <div
                role="menu"
                tabIndex={0}
                className="inline-flex p-2 items-center gap-2 hover:rounded hover:bg-purple-gray-800 hover:bg-opacity-25 focus:rounded focus:bg-purple-gray-800 focus:bg-opacity-25"
                aria-label="User dropdown menu"
                onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                        setIsUserDropdownOpen(!isUserDropdownOpen);
                    } else if (event.key === 'Escape') {
                        setIsUserDropdownOpen(false);
                    }
                }}
            >
                <Avatar initials={initials} />
                <CaretDownIcon className="text-white" />
            </div>
            {isUserDropdownOpen && (
                <Dropdown
                    className="mt-2 min-w-36 right-0"
                    ref={dropdownRef}
                >
                    {
                        dropdownItems.map(({ key, ...rest }) => (
                            <DropdownItem
                                key={key}
                                onItemClick={e => handleUserDropdownItemClick(e)}
                                onEscape={() => setIsUserDropdownOpen(false)}
                            >
                                {rest}
                            </DropdownItem>
                        ))
                    }
                </Dropdown>
            )}
        </div>
    );
};

UserDropdown.propTypes = {
    initials: PropTypes.string.isRequired,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        label: PropTypes.string,
        url: PropTypes.string,
    })).isRequired,
};

UserDropdown.defaultProps = {
};


export default UserDropdown;
