import { connect } from 'react-redux';
import Tooltip from './component';
import { selectors as tooltipSelectors, markTooltip } from '../../data/tooltips';
import { selectors as sessionSelectors } from '../../services/session';

const {
    tooltipsSelector,
} = tooltipSelectors;

const {
    showTooltipsSelector,
} = sessionSelectors;

const mapStateToProps = (state) => {
    const result = {
        tooltips: tooltipsSelector(state),
        showTooltips: showTooltipsSelector(state),
    };

    return result;
};

export default connect(mapStateToProps, { markTooltip })(Tooltip);
