import React from 'react';
import { fieldPropTypes } from 'redux-form';
import { Form } from 'semantic-ui-react';
import './style.scss';

const Checkbox = ({
    input,
    checked,
    defaultChecked,
    onClick,
    onChange,
    ...props
}) => (
    <div className="checkbox">
        <Form.Checkbox
            checked={Boolean(input.value)}
            onClick={(param, data) => {
                input.onChange(!data.checked);

                if (onClick && typeof onClick === 'function') {
                    onClick(param, data);
                }
            }}
            onChange={(param, data) => {
                input.onChange(data.checked);
                if (onChange && typeof onChange === 'function') {
                    onChange(param, data);
                }
            }}
            {...props}
        />
    </div>
);

Checkbox.propTypes = {
    ...fieldPropTypes,
};

export default Checkbox;
