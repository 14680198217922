import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './style.scss';
import localization from '../../localization';
import Tooltip from '../Tooltip';
import { rewardsEnabled } from '../../environment';

const {
    questionRewardBadge: {
        rewardEarned,
        rewardAvailable,
        rewardBonus,
    },
} = localization;

const QuestionRewardBadge = ({
    hasExpired,
    rewarded,
    isQuestionDetail,
    rewardable,
    firstRewardable,
    useNpiMap,
}) => {
    if (!hasExpired && rewardsEnabled) {
        if (rewarded) {
            return (
                <Fragment>
                    <span className="question-reward-earned">
                        <Tooltip
                            trigger={<Icon inverted name="gift" className="earned-color" />}
                            name="question-after-user-earned"
                            display={isQuestionDetail}
                        />
                        <span className="question-reward-earned-text">{rewardEarned}</span>
                    </span>
                    <span className="question-earned-expires-spacer">•</span>
                </Fragment>
            );
        } else if (rewardable && useNpiMap) {
            return (
                <Fragment>
                    <span className="question-reward-available">
                        <Tooltip
                            trigger={<Icon inverted name="star" className="bonus-color" />}
                            name={isQuestionDetail ? 'question-gift-card-icon' : 'questions-gift-card-icon'}
                            display={firstRewardable}
                        />
                        <span className="question-reward-bonus-text">{rewardBonus}</span>
                        <span className="question-earned-expires-spacer bonus-spacer">•</span>
                        <span className="question-reward-earned-text">{rewardAvailable}</span>
                    </span>
                    <span className="question-earned-expires-spacer">•</span>
                </Fragment>
            );
        } else if (rewardable) {
            return (
                <Fragment>
                    <span className="question-reward-available">
                        <Tooltip
                            trigger={<Icon inverted name="gift" className="available-color" />}
                            name={isQuestionDetail ? 'question-gift-card-icon' : 'questions-gift-card-icon'}
                            display={firstRewardable}
                        />
                        <span className="question-reward-earned-text">{rewardAvailable}</span>
                    </span>
                    <span className="question-earned-expires-spacer">•</span>
                </Fragment>
            );
        }
    }

    return <Fragment />;
};

QuestionRewardBadge.propTypes = {
    hasExpired: PropTypes.bool,
    rewarded: PropTypes.bool,
    isQuestionDetail: PropTypes.bool,
    rewardable: PropTypes.bool,
    firstRewardable: PropTypes.bool,
    useNpiMap: PropTypes.bool,
};

QuestionRewardBadge.defaultProps = {
    hasExpired: false,
    rewarded: false,
    isQuestionDetail: false,
    rewardable: false,
    firstRewardable: true,
    useNpiMap: false,
};

export default QuestionRewardBadge;
