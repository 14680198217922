import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import localization from '../../../localization';
import { hcmpEnabled } from '../../../environment';
import './style.scss';

const {
    thread: {
        programNotice: {
            thank,
            button,
        },
    },
} = localization;

const NoticeModal = ({
    open,
    showProgram,
    noticeText,
}) => (
    <Modal basic open={open && hcmpEnabled} className="notice-modal" size="small">
        <Modal.Content>
            <p>{thank}</p>
            <p>{noticeText}</p>
        </Modal.Content>
        <Modal.Actions>
            <Button
                color="green"
                content={button}
                onClick={() => showProgram()}
            />
        </Modal.Actions>
    </Modal>
);

NoticeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    showProgram: PropTypes.func.isRequired,
    noticeText: PropTypes.string.isRequired,
};

export default NoticeModal;
