import { call, put, takeEvery, all } from 'redux-saga/effects';

import {
    loadTooltip,
    markTooltip,
} from './api';

import {
    loadTooltipCompleted,
    loadTooltipFailed,
    markTooltipCompleted,
    markTooltipFailed,
} from './actions';

import { LOAD_TOOLTIP, MARK_TOOLTIP } from './actionTypes';

function* loadSaga({ payload: { name } }) {
    try {
        const data = yield call(loadTooltip, name);
        yield put(loadTooltipCompleted({ name, ...data }));
    } catch (error) {
        yield put(loadTooltipFailed({ name, ...error }));
    }
}

function* markSaga({ payload: { name } }) {
    try {
        yield call(markTooltip, name);
        yield put(markTooltipCompleted({ name }));
    } catch (error) {
        yield put(markTooltipFailed({ name, ...error }));
    }
}

function* tooltipsSaga() {
    yield all([
        takeEvery(LOAD_TOOLTIP, loadSaga),
        takeEvery(MARK_TOOLTIP, markSaga),
    ]);
}

export default tooltipsSaga;
