import { ID } from '../../services/session';

export { FetchState } from '../../services/session/reducer';

export const userIdSelector = ({
    [ID]: {
        id,
    },
}) => id;

export const roleNameSelector = ({
    [ID]: {
        roleName,
    },
}) => roleName || null;

export const permissionsSelector = ({
    [ID]: {
        permissions,
    },
}) => permissions || [];

export const postAuthRedirectUrlSelector = ({
    [ID]: {
        postAuthRedirectUrl,
    },
}) => postAuthRedirectUrl;

export const disclaimerSelector = ({ [ID]: { disclaimerRead } }) =>
    disclaimerRead;

export const shouldHideDisclaimerSelector = ({ [ID]: { disclaimerRead, disclaimerBEChecked } }) =>
    disclaimerRead || !disclaimerBEChecked;

export const showTooltipsSelector = ({ [ID]: { showTooltips } }) => showTooltips;

export const fetchStateSelector = ({ [ID]: { fetchState } }) =>
    fetchState;
