import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import localization from '../../../../../localization';
import AssignedProgramRow from './AssignedProgramRow';
import './style.scss';

const {
    admin: {
        globalHcmpList: {
            tableHeaders: {
                id,
                name,
                enabled,
                priority,
                actions,
            },
        },
    },
} = localization;

const AssignedPrograms = ({
    assignedPrograms,
    changeGlobalHcmpStatus,
    changeGlobalHcmpPriority,
    loading,
}) => {
    const priorityOptions = assignedPrograms
        .filter(x => x.globalPriority)
        .map(x => ({
            text: `${x.globalPriority}`,
            value: x.globalPriority,
        }));
    const programs = assignedPrograms && assignedPrograms.map(x => (
        <AssignedProgramRow
            key={`${x.id}-${x.globalPriority}`}
            hcmpPrgId={x.hcmpPrgId}
            name={x.name}
            globalPriority={x.globalPriority}
            priorityOptions={priorityOptions}
            changeGlobalHcmpStatus={changeGlobalHcmpStatus}
            changeGlobalHcmpPriority={changeGlobalHcmpPriority}
            loading={loading}
        />
    ));

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{id}</Table.HeaderCell>
                    <Table.HeaderCell width={10}>{name}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{enabled}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{priority}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">{actions}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {programs}
            </Table.Body>
        </Table>
    );
};

AssignedPrograms.propTypes = {
    assignedPrograms: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeGlobalHcmpStatus: PropTypes.func.isRequired,
    changeGlobalHcmpPriority: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default AssignedPrograms;
