import client from '../../api';
import apiClient from '../../api/apiSaga';
import { questions } from '../../api/endpoints';

const {
    index: endpoint,
    internal: internalEndpoint,
    singleQuestionInternal,
    singleQuestion,
    nextQuestion,
    relatedUsersCount,
    eligibleUsersCount,
    npi,
} = questions;

export const loadQuestions = queryOptions => apiClient.get(endpoint, {
    params: queryOptions,
});

export const loadQuestionsInternal = queryOptions => client.get(internalEndpoint, {
    params: queryOptions,
});

export const loadQuestionInternal = id => client.get(singleQuestionInternal(id));

export const loadQuestion = id => client.get(singleQuestion(id));

export const loadNextQuestion = id => client.get(nextQuestion(id));

export const createQuestion = question => client.post(internalEndpoint, { data: question });

export const deleteQuestion = questionId => client.delete(singleQuestionInternal(questionId));

export const updateQuestion = (questionId, question) =>
    client.patch(singleQuestionInternal(questionId), { data: question });

export const flagQuestion = (id, data) => client.post(questions.flagQuestion(id), { data });

export const getRelatedUsersCount = questionId => client.get(relatedUsersCount(questionId));

export const getEligibleUsersCount = questionId => client.get(eligibleUsersCount(questionId));

export const getQuestionNpis = () => client.get(npi);

export const updateQuestionsNpis = data => client.post(npi, { data });
