import {
    minLength,
    maxLength,
    minNullLength,
    maxNullLength,
    required,
    dateBefore,
} from '../../../../utils/form/validators';
import localization from '../../../../localization';

const {
    publishDateValidation,
    specsValidation,
    datesValidation,
} = localization.admin.questions.questionForm;

const checkPublishDate = (publishDate, expirationDate) => {
    if (publishDate >= expirationDate) {
        return publishDateValidation;
    }
    return null;
};

const titleLength = { min: 5, max: 200 };
const descriptionLength = { min: 20, max: 2000 };
const extraFieldsLength = { min: 2, max: 20 };

export const syncValidations = ({
    author,
    expirationDate,
    publishDate,
    specialties,
    subspecialties,
    npiMap,
    title = '',
    description = '',
    useNpiMap = false,
    therapyArea = '',
    primaryIndication = '',
    secondaryIndication = '',
    stageProgression = '',
    initiatingTeam = '',
}) => ({
    specialties: !useNpiMap && required(specialties) && required(subspecialties)
        && specsValidation,
    subspecialties: !useNpiMap && required(specialties) && required(subspecialties)
        && specsValidation,
    npiMap: useNpiMap && required(npiMap),
    author: required(author),
    expirationDate: required(expirationDate),
    publishDate: required(publishDate) || checkPublishDate(publishDate, expirationDate),
    title: minLength(title, titleLength.min) || maxLength(title, titleLength.max),
    description: minLength(description, descriptionLength.min)
        || maxLength(description, descriptionLength.max),
    therapyArea: required(therapyArea) || minNullLength(therapyArea, extraFieldsLength.min) || maxNullLength(therapyArea, extraFieldsLength.max),
    primaryIndication: required(primaryIndication) || minNullLength(primaryIndication, extraFieldsLength.min) || maxNullLength(primaryIndication, extraFieldsLength.max),
    secondaryIndication: required(secondaryIndication) || minNullLength(secondaryIndication, extraFieldsLength.min) || maxNullLength(secondaryIndication, extraFieldsLength.max),
    stageProgression: required(stageProgression) || minNullLength(stageProgression, extraFieldsLength.min) || maxNullLength(stageProgression, extraFieldsLength.max),
    initiatingTeam: required(initiatingTeam) || minNullLength(initiatingTeam, extraFieldsLength.min) || maxNullLength(initiatingTeam, extraFieldsLength.max),
});

export const syncWarnings = ({
    expirationDate,
    publishDate,
}) => ({
    expirationDate: dateBefore(expirationDate) ? null : datesValidation,
    publishDate: dateBefore(publishDate) ? null : datesValidation,
});
