import { call, put, all, takeEvery } from 'redux-saga/effects';

import { LOAD_HISTORY_REWARDS, LOAD_CURRENT_REWARDS } from './actionTypes';

import {
    loadCurrentRewardsCompleted,
    loadCurrentRewardsFailed,
    loadHistoryRewardsCompleted,
    loadHistoryRewardsFailed,
} from './actions';

import { loadHistoryRewards, loadCurrentRewards } from './api';

function* loadCurrentRewardsSaga() {
    try {
        const response = yield call(loadCurrentRewards);
        yield put(loadCurrentRewardsCompleted(response));
    } catch (error) {
        yield put(loadCurrentRewardsFailed({ error }));
    }
}

function* loadHistoryRewardsSaga() {
    try {
        const response = yield call(loadHistoryRewards);
        yield put(loadHistoryRewardsCompleted(response));
    } catch (error) {
        yield put(loadHistoryRewardsFailed({ error }));
    }
}

function* rewardsSaga() {
    yield all([
        takeEvery(LOAD_CURRENT_REWARDS, loadCurrentRewardsSaga),
        takeEvery(LOAD_HISTORY_REWARDS, loadHistoryRewardsSaga),
    ]);
}

export default rewardsSaga;
