const ConsensusSummary = class {
    constructor() {
        this.data = {
            ConsensusTitle: '',
            ConsensusOpinionStatement: '',
            Author: {
                Name: '',
            },
            PatientCases: [],
            ClinicalAnalysis: {
                AnalysisText: '',
                Quotes: [],
            },
            Conclusion: {
                ConclusionTitle: '',
                ConclusionText: '',
                Quotes: [],
            },
            Sections: [],
            Definitions: [],
            References: [],
            publishedAt: null,
            Program: {
                ProgramId: '',
                Name: '',
            },
        };
    }

    setConsensusTitle(str) {
        this.data.ConsensusTitle = str;
    }
    setConsensusOpinionStatement(str) {
        this.data.ConsensusOpinionStatement = str;
    }
    setAuthor(str) {
        this.data.Author.Name = str;
    }
    setAnalysisText(str) {
        this.data.ClinicalAnalysis.AnalysisText = str;
    }
    setConclusionTitle(str) {
        this.data.Conclusion.ConclusionTitle = str;
    }
    setConclusionText(str) {
        this.data.Conclusion.ConclusionText = str;
    }

    // currently we only set one case per summary when using the submission flow
    // in the future we will be able to add more than one case to this collection.
    setPatientCases(caseCollection) {
        this.data.PatientCases = caseCollection.map(({
            id,
            description,
            publishDate,
            totalResponseCount,
        }) => ({
            CaseNumber: id,
            CaseText: description,
            View: 'Clinical',
            PublishDate: publishDate,
            ResponseCount: totalResponseCount,
        }));
    }

    addSection(title, text) {
        this.data.Sections.push({
            Title: title,
            Text: text,
            Quotes: [{
                Quote: '',
                Author: '',
                KeyOpinionLeader: false,
            }],
        });
    }
    removeSection(index) {
        this.data.Sections.splice(index, 1);
    }
    addDefinition(term, definition) {
        this.data.Definitions.push({
            Term: term,
            Definition: definition,
        });
    }
    removeDefinition(index) {
        this.data.Definitions.splice(index, 1);
    }
    addReference(text, url) {
        this.data.References.push({
            Text: text,
            Url: url,
        });
    }
    removeReference(index) {
        this.data.References.splice(index, 1);
    }

    addClinicalAnalysisQuote(quote, author, keyOpinionLeader) {
        this.data.ClinicalAnalysis.Quotes.push({
            Quote: quote,
            Author: author,
            KeyOpinionLeader: keyOpinionLeader === null ? false : keyOpinionLeader,
        });
    }
    removeClinicalAnalysisQuote(index) {
        this.data.ClinicalAnalysis.Quotes.splice(index, 1);
    }
    addConclusionQuote(quote, author, keyOpinionLeader) {
        this.data.Conclusion.Quotes.push({
            Quote: quote,
            Author: author,
            KeyOpinionLeader: keyOpinionLeader === null ? false : keyOpinionLeader,
        });
    }
    removeConclusionQuote(index) {
        this.data.Conclusion.Quotes.splice(index, 1);
    }
    addConsultQuote(sectionIndex, quote, author, keyOpinionLeader) {
        this.data.Sections[sectionIndex].Quotes.push({
            Quote: quote,
            Author: author,
            KeyOpinionLeader: keyOpinionLeader === null ? false : keyOpinionLeader,
        });
    }
    removeSectionQuote(sectionIndex, quoteIndex) {
        this.data.Sections[sectionIndex].Quotes.splice(quoteIndex, 1);
    }

    static isEmptyValue(item) {
        return typeof item === 'undefined' || item === null || (typeof item === 'string' && item.trim() === '');
    }

    static isTooShort(item, length) {
        return item.split(' ').length < length;
    }

    static isTooLong(item, length) {
        return item.split(' ').length > length;
    }

    validate() {
        const validation = {
            isValid: true,
            validationString: '',
            sections: {
                summaryTitle: true,
                patientCase: true,
                communityConsensus: true,
                conclusion: true,
                definitions: true,
                listOfReferences: true,
            },
        };

        if (ConsensusSummary.isEmptyValue(this.data.ConsensusTitle)) {
            validation['Summary Title'] = 'must not be empty';
            validation.sections.summaryTitle = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isTooLong(this.data.ConsensusTitle, 40)) {
            validation['Summary Title'] = 'is too long';
            validation.sections.summaryTitle = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isEmptyValue(this.data.ConsensusOpinionStatement)) {
            validation['High-Level Summary Statement'] = 'must not be empty';
            validation.sections.communityConsensus = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isTooLong(this.data.ConsensusOpinionStatement, 40)) {
            validation['High-Level Summary Statement'] = 'is too long';
            validation.sections.communityConsensus = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isEmptyValue(this.data.ClinicalAnalysis.AnalysisText)) {
            validation['Clinical Analysis of HCP Consults'] = 'must not be empty';
            validation.sections.communityConsensus = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isTooLong(this.data.ClinicalAnalysis.AnalysisText, 150)) {
            validation['Clinical Analysis of HCP Consults'] = 'is too long';
            validation.sections.communityConsensus = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isEmptyValue(this.data.Conclusion.ConclusionTitle) &&
            !ConsensusSummary.isEmptyValue(this.data.Conclusion.ConclusionText)) {
            validation['Conclusion Title'] = 'must not be empty';
            validation.sections.conclusion = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isTooLong(this.data.Conclusion.ConclusionTitle, 40)) {
            validation['Conclusion Title'] = 'is too long';
            validation.sections.conclusion = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isEmptyValue(this.data.Conclusion.ConclusionText) &&
            !ConsensusSummary.isEmptyValue(this.data.Conclusion.ConclusionTitle)) {
            validation['Conclusion Paragraph'] = 'must not be empty';
            validation.sections.conclusion = false;
            validation.isValid = false;
        }
        if (ConsensusSummary.isTooLong(this.data.Conclusion.ConclusionText, 150)) {
            validation['Conclusion Paragraph'] = 'is too long';
            validation.sections.conclusion = false;
            validation.isValid = false;
        }

        this.data.PatientCases.forEach((patientCase, i) => {
            if (ConsensusSummary.isEmptyValue(patientCase.CaseNumber)) {
                validation[`Patient Case ${i + 1} Case Number`] = 'must not be empty';
                validation.sections.patientCase = false;
                validation.isValid = false;
            }
            if (ConsensusSummary.isEmptyValue(patientCase.CaseText)) {
                validation[`Patient Case ${i + 1} Case Text`] = 'must not be empty';
                validation.sections.patientCase = false;
                validation.isValid = false;
            }
            if (ConsensusSummary.isEmptyValue(patientCase.View)) {
                validation[`Patient Case ${i + 1} View`] = 'must not be empty';
                validation.sections.patientCase = false;
                validation.isValid = false;
            }
        });

        this.data.Definitions.forEach((definition, i) => {
            if (ConsensusSummary.isEmptyValue(definition.Term) && ConsensusSummary.isEmptyValue(definition.Definition)) {
                return;
            }
            if (ConsensusSummary.isEmptyValue(definition.Term)) {
                validation[`Definition ${i + 1} Term`] = 'must not be empty';
                validation.sections.definitions = false;
                validation.isValid = false;
            }
            if (ConsensusSummary.isEmptyValue(definition.Definition)) {
                validation[`Definition ${i + 1} Definition`] = 'must not be empty';
                validation.sections.definitions = false;
                validation.isValid = false;
            }
        });

        this.data.References.forEach((reference, i) => {
            if (!ConsensusSummary.isEmptyValue(reference.Url) && ConsensusSummary.isEmptyValue(reference.Text)) {
                validation[`Reference ${i + 1} Text`] = 'must not be empty';
                validation.sections.listOfReferences = false;
                validation.isValid = false;
            }
        });

        this.data.ClinicalAnalysis.Quotes.forEach((quote, i) => {
            if (ConsensusSummary.isEmptyValue(quote.Quote) && ConsensusSummary.isEmptyValue(quote.Author)) {
                return;
            }
            if (ConsensusSummary.isEmptyValue(quote.Quote)) {
                validation[`Clinical Analysis Quote ${i + 1} Quote`] = 'must not be empty';
                validation.isValid = false;
            }
            if (ConsensusSummary.isEmptyValue(quote.Author)) {
                validation[`Clinical Analysis Quote ${i + 1} Author`] = 'must not be empty';
                validation.isValid = false;
            }
        });

        this.data.Conclusion.Quotes.forEach((quote, i) => {
            if (ConsensusSummary.isEmptyValue(quote.Quote) && ConsensusSummary.isEmptyValue(quote.Author)) {
                return;
            }
            if (ConsensusSummary.isEmptyValue(quote.Quote)) {
                validation[`Summary Quote ${i + 1} Quote`] = 'must not be empty';
                validation.isValid = false;
            }
            if (ConsensusSummary.isEmptyValue(quote.Author)) {
                validation[`Summary Quote ${i + 1} Author`] = 'must not be empty';
                validation.isValid = false;
            }
        });

        this.data.Sections.forEach((section, i) => {
            if (ConsensusSummary.isEmptyValue(section.Title) && ConsensusSummary.isEmptyValue(section.Text) &&
                section.Quotes.length === 1 && ConsensusSummary.isEmptyValue(section.Quotes[0].Quote) && ConsensusSummary.isEmptyValue(section.Quotes[0].Author)) {
                return;
            }
            if (ConsensusSummary.isEmptyValue(section.Title)) {
                validation[`Consult ${i + 1} Title`] = 'must not be empty';
                validation.isValid = false;
            }
            if (ConsensusSummary.isTooLong(section.Title, 40)) {
                validation[`Consult ${i + 1} Title`] = 'is too long';
                validation.isValid = false;
            }
            if (ConsensusSummary.isEmptyValue(section.Text)) {
                validation[`Consult ${i + 1} Text`] = 'must not be empty';
                validation.isValid = false;
            }
            if (ConsensusSummary.isTooLong(section.Text, 150)) {
                validation[`Consult ${i + 1} Text`] = 'is too long';
                validation.isValid = false;
            }
            section.Quotes.forEach((quote, j) => {
                if (ConsensusSummary.isEmptyValue(quote.Quote) && ConsensusSummary.isEmptyValue(quote.Author)) {
                    return;
                }
                if (ConsensusSummary.isEmptyValue(quote.Quote)) {
                    validation[`Consult ${i + 1} Quote ${j + 1} Quote`] = 'must not be empty';
                    validation.isValid = false;
                }
                if (ConsensusSummary.isEmptyValue(quote.Author)) {
                    validation[`Consult ${i + 1} Quote ${j + 1} Author`] = 'must not be empty';
                    validation.isValid = false;
                }
            });
        });

        if (!validation.isValid) {
            const validationArray = [];
            Object.keys(validation).forEach((key) => {
                if (key !== 'isValid' && key !== 'validationString' && key !== 'sections') {
                    validationArray.push(`${key} ${validation[key]}`);
                }
            });
            validation.validationString = validationArray.join(', ');
        }

        return validation;
    }

    sanitize() {
        this.data.Definitions = this.data.Definitions.filter(x => !ConsensusSummary.isEmptyValue(x.Term) && !ConsensusSummary.isEmptyValue(x.Definition));
        this.data.References = this.data.References.filter(x => !ConsensusSummary.isEmptyValue(x.Text));
        this.data.ClinicalAnalysis.Quotes = this.data.ClinicalAnalysis.Quotes.filter(x => !ConsensusSummary.isEmptyValue(x.Quote) && !ConsensusSummary.isEmptyValue(x.Author));
        this.data.Conclusion.Quotes = this.data.Conclusion.Quotes.filter(x => !ConsensusSummary.isEmptyValue(x.Quote) && !ConsensusSummary.isEmptyValue(x.Author));
        this.data.Sections = this.data.Sections.filter(x => !ConsensusSummary.isEmptyValue(x.Title) && !ConsensusSummary.isEmptyValue(x.Text));
        this.data.Sections.forEach((section, i) => {
            this.data.Sections[i].Quotes = section.Quotes.filter(x => !ConsensusSummary.isEmptyValue(x.Quote) && !ConsensusSummary.isEmptyValue(x.Author));
        });
    }

    buildSummaryTile() {
        this.data.SummaryTile = {
            Title: this.data.ConsensusTitle,
        };
        if (this.data.Author !== null && this.data.Author.Name !== null && this.data.Author.Name !== '') {
            this.data.SummaryTile.AuthorName = this.data.Author.Name;
        }
    }
};

export default ConsensusSummary;
