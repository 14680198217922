import React from 'react';
import PropTypes from 'prop-types';
import { Comment } from 'semantic-ui-react';
import { relativePastTime } from '../../../../utils/dates';
import localization from '../../../../localization';

export const answerTypes = { answer: 1, comment: 2 };

const {
    topic: {
        answered,
        edited,
        commented,
    },
} = localization;

const AnswerCommentHeader = ({
    author,
    createdAt,
    updatedAt,
    type,
}) => {
    const nonEditedLabel = (type === answerTypes.comment) ? commented : answered;
    const lastChangeTime = createdAt === updatedAt ?
        `${nonEditedLabel} ${relativePastTime(createdAt)}` :
        `${edited} ${relativePastTime(updatedAt)}`;

    return (
        <div className="answer-header">
            <Comment.Author>{author}</Comment.Author>
            <Comment.Metadata className="comment-date">
                {lastChangeTime}
            </Comment.Metadata>
        </div>);
};

AnswerCommentHeader.propTypes = {
    author: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(answerTypes)).isRequired,
};

export default AnswerCommentHeader;
