import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CrossCircledIcon } from '@radix-ui/react-icons';
import { Dimmer, Loader } from 'semantic-ui-react';
import client from '../../../../api';
import Table from '../../../../components/Table';
import Button from '../../../../components/Button';
import links from '../../../../links';

import './userRewards.scss';

const formatEligibilityPeriod = (from, to) => {
    const formattedFrom = moment(from).format('M/D/YYYY');
    const formattedTo = moment(to).format('M/D/YYYY');
    return `${formattedFrom} - ${formattedTo}`;
};

const isValidateDate = (str) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(str);
};

const Rewards = ({ computedMatch }) => {
    const { id } = computedMatch?.params;
    const [filterDate, setFilterDate] = useState('');
    const [user, setUser] = useState(null);
    const [rewards, setRewards] = useState([]);
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingUserRewards, setLoadingUserRewards] = useState(true);

    const formatRewards = data => data.map(item => ({
        id: item.id,
        eligibilityPeriod: formatEligibilityPeriod(item.created_at, item.expire_at),
        dateEarned: item?.earned_at ? moment(item.earned_at).format('M/D/YYYY') : ' - ',
        patientCaseNum: item.question_id,
        patientCaseTitle: item.question_title,
    }));

    useEffect(() => {
        const fetchRewardsUser = async () => {
            setLoadingUser(true);
            try {
                const response = await client.get(`/users/${id}`, {});
                setUser(response.data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching data:', error);
            }
            setLoadingUser(false);
        };
        fetchRewardsUser();
    }, [id]);

    useEffect(() => {
        const fetchRewards = async () => {
            setLoadingUserRewards(true);
            // early return if filterDate is not a valid date
            if (filterDate && !isValidateDate(filterDate)) {
                return;
            }
            const qs = filterDate ? `?searchDate=${filterDate}` : '';
            try {
                const response = await client.get(`/users/${id}/rewards${qs}`, {});
                setRewards(formatRewards(response.data.rewards));
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching data:', error);
            }
            setLoadingUserRewards(false);
        };
        fetchRewards();
    }, [id, filterDate]);

    const handleFilterDateChange = (event) => {
        setFilterDate(event.target.value);
    };

    const tableColumns = [
        { key: 'id', heading: 'Reward #', className: 'reward-number' },
        { key: 'eligibilityPeriod', heading: 'Eligibility period', className: 'eligibility-period' },
        { key: 'dateEarned', heading: 'Date earned', className: 'date-earned' },
        { key: 'patientCaseNum', heading: 'Patient Case #', className: 'patient-case-number' },
        { key: 'patientCaseTitle', heading: 'Patient Case Title', className: 'patient-case-title' },
    ];

    const clearFilter = () => {
        setFilterDate('');
    };

    return (
        <div className="user-rewards-container">
            <Dimmer page active={loadingUser || loadingUserRewards}>
                <Loader />
            </Dimmer>
            {!loadingUser &&
                <div className="user-info">
                    <h1>Rewards for &ldquo;{user?.firstName} {user?.lastName}&rdquo;</h1>
                    <Link to={links.moderator.users.index}>
                        <Button type="button" variant="secondary">Back to all users</Button>
                    </Link>
                </div>
            }
            <div className="user-details">
                <span>{user?.email}</span>
                {user?.npi ? (<span>{user.npi}</span>) : null}
            </div>
            <div className="filter-by">
                Filter by <input value={filterDate} type="date" onChange={handleFilterDateChange} />
                <Button onClick={clearFilter} type="button" variant="text" icon={<CrossCircledIcon />} iconPosition="right">Clear filter</Button>
            </div>

            <Table columns={tableColumns} data={rewards} className="rewards-table" />
        </div>
    );
};

export default Rewards;

Rewards.propTypes = {
    computedMatch: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }).isRequired,
    }).isRequired,
};
