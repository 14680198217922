import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { ID as DataID, QuestionsID } from '../../../data';
import { ID as SessionID } from '../../../services/session';
import { WithRouterHOC } from '../../../components/Router';
import FlagQuestion from './container';
import { flagQuestion } from '../../../data/questions';
import validations from './validations';
import localization from '../../../localization';

const {
    topic: {
        flagQuestion: {
            report,
            suggestMessage,
        },
    },
} = localization;

const mapStateToProps = (state, {
    match: {
        params: {
            id: questionId,
        },
    },
}) => {
    const {
        [DataID]: {
            [QuestionsID]: {
                topics,
                flagging,
                flaggingFailed,
                flaggingSuccess,
            },
        },
        [SessionID]: {
            email,
        },
    } = state;
    const question = topics.find(x => x.id === parseInt(questionId, 10)) || { title: '' };
    return {
        loading: flagging,
        loadingError: flaggingFailed,
        loadingSuccess: flaggingSuccess,
        id: questionId,
        initialValues: {
            userEmail: email,
            title: `${report} ${question.title.substring(0, 100)}`,
            message: `${suggestMessage}`,
        },
    };
};


export default compose(
    WithRouterHOC,
    connect(mapStateToProps),
    reduxForm({
        form: 'flag-comment',
        onSubmit: (values, dispatch, props) => {
            const {
                answerId,
                commentId,
                content,
                id,
            } = props;

            dispatch(flagQuestion({
                ...values,
                id,
                answerId,
                commentId,
                content,
            }));
        },
        validate: validations,
    }),
)(FlagQuestion);
