import { connect } from 'react-redux';
import UserEngagementComponent from './component';
import { downloadAnswerReport, selectors } from '../../../../../data/campaigns';
import { ownStateSelector, dataStateSelector } from '../selectors';
import { clearAnswerReportError } from '../actions';

const mapStateToProps = (state, { id }) => {
    const emails = dataStateSelector(state).campaigns[id];
    return ({
        emailsCount: emails ? emails.length : 0,
        ...ownStateSelector(state),
        ...selectors.selectDownloadStatus(state),
    });
};

export default connect(mapStateToProps, {
    downloadAnswerReport,
    clearAnswerReportError,
})(UserEngagementComponent);
