import { connect } from 'react-redux';
import Search from './Component';
import { ID, roleNameSelector } from '../../services/session';
import { isAdminOrModerator } from '../../utils/roleHelper';

const mapStateToProps = (state) => {
    const {
        [ID]: {
            npi,
            firstName,
            lastName,
            specialty,
        },
    } = state;

    return {
        npi,
        firstName,
        lastName,
        specialty,
        allowCsvDownload: isAdminOrModerator(roleNameSelector(state)),
    };
};

export default connect(mapStateToProps)(Search);
