import { createAction } from 'redux-actions';

import {
    LOAD_SPECIALTIES,
    LOAD_SPECIALTIES_COMPLETED,
    LOAD_SPECIALTIES_FAILED,
    LOAD_SUBSPECIALTIES,
    LOAD_SUBSPECIALTIES_COMPLETED,
    LOAD_SUBSPECIALTIES_FAILED,
} from './actionTypes';

export const loadSpecialties = createAction(LOAD_SPECIALTIES);
export const loadSpecialtiesCompleted = createAction(LOAD_SPECIALTIES_COMPLETED);
export const loadSpecialtiesFailed = createAction(LOAD_SPECIALTIES_FAILED);

export const loadSubSpecialties = createAction(LOAD_SUBSPECIALTIES);
export const loadSubSpecialtiesCompleted = createAction(LOAD_SUBSPECIALTIES_COMPLETED);
export const loadSubSpecialtiesFailed = createAction(LOAD_SUBSPECIALTIES_FAILED);
