import { createSelector } from '@reduxjs/toolkit';
import { getGlobalHcmpSelector } from '../../../../data/globalHcmp';

const priorityCompareFunction = (x, y) => (x.globalPriority - y.globalPriority);
const hcmpIdCompareFunction = (x, y) => (x.hcmpPrgId - y.hcmpPrgId);

export const assignedHcmpSelector = createSelector(
    getGlobalHcmpSelector,
    (globalHcmpList) => {
        const confirmedHcmp = globalHcmpList.filter(x => x.confirmedAt).asMutable();
        const groupedHcmp = confirmedHcmp
            .reduce((acc, val) => {
                acc[val.globalPriority ? 0 : 1].push(val);
                return acc;
            }, [[], []]);

        groupedHcmp[0].sort(priorityCompareFunction);
        groupedHcmp[1].sort(hcmpIdCompareFunction);

        return [...groupedHcmp[0], ...groupedHcmp[1]];
    },
);

export const unassignedHcmpSelector = createSelector(
    getGlobalHcmpSelector,
    globalHcmpList => globalHcmpList
        .filter(x => !x.confirmedAt)
        .asMutable()
        .sort(hcmpIdCompareFunction), // - is used to have ascending sort
);

export const numberOfAssignedHcmpSelector = createSelector(
    [assignedHcmpSelector, getGlobalHcmpSelector],
    assignedHcmpList => (assignedHcmpList ?
        assignedHcmpList.filter(x => x.globalPriority).length : 0),
);
