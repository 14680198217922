import { call, put, all, takeEvery } from 'redux-saga/effects';
import {
    LOAD_SPECIALTIES,
    LOAD_SUBSPECIALTIES,
} from './actionTypes';
import {
    loadSpecialtiesCompleted,
    loadSpecialtiesFailed,
    loadSubSpecialtiesCompleted,
    loadSubSpecialtiesFailed,
} from './actions';
import {
    loadSpecialties,
    loadSubSpecialties,
} from './api';

function* loadSpecialtiesSaga() {
    try {
        const response = yield call(loadSpecialties);
        yield put(loadSpecialtiesCompleted(response));
    } catch (error) {
        yield put(loadSpecialtiesFailed({ error }));
    }
}

function* loadSubSpecialtiesSaga() {
    try {
        const response = yield call(loadSubSpecialties);
        yield put(loadSubSpecialtiesCompleted(response));
    } catch (error) {
        yield put(loadSubSpecialtiesFailed({ error }));
    }
}

function* specialtiesSaga() {
    yield all([
        takeEvery(LOAD_SPECIALTIES, loadSpecialtiesSaga),
        takeEvery(LOAD_SUBSPECIALTIES, loadSubSpecialtiesSaga),
    ]);
}

export default specialtiesSaga;
