import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Comment, Button, Icon } from 'semantic-ui-react';
import FlagModal from '../../../Flag';
import localization from '../../../../../localization';
import './style.scss';

const {
    topic: strings,
    common: commonStrings,
} = localization;

const AnswerView = ({
    agreeCount,
    canEditAll,
    deleteAnswer,
    deleted,
    disagreeCount,
    id,
    openAnswerEditForm,
    openReplyForm,
    othersHaveCommented,
    ownAnswer,
    rateAnswer,
    replyFormOpen,
    showModal,
    specialty,
    text,
    userVote,
    // meta
    ratingInProgress,
    pressedRating,
    deleting,
}) => {
    const agreeButton = !(ownAnswer) ? (
        <Comment.Action title={strings.anonymityClaim}>
            <Button
                label={{ basic: true, content: agreeCount }}
                positive={userVote === 1}
                labelPosition="left"
                content={strings.agree}
                loading={ratingInProgress && pressedRating === 1}
                onClick={() => {
                    if (!ratingInProgress) {
                        rateAnswer({ rating: 1, id });
                    }
                }}
            />
        </Comment.Action>
    ) : (
        <span className="count">{agreeCount} <span className="agree-disagree state">{strings.agree}</span></span>
    );

    const disagreeButton = !(ownAnswer) ? (
        <Comment.Action title={strings.anonymityClaim}>
            <Button
                positive={userVote === -1}
                label={{ basic: true, content: disagreeCount }}
                labelPosition="left"
                content={strings.disagree}
                loading={ratingInProgress && pressedRating === -1}
                onClick={() => {
                    if (!ratingInProgress) {
                        rateAnswer({ rating: -1, id });
                    }
                }}
            />
        </Comment.Action>
    ) : (
        <span className="count">{disagreeCount} <span className="agree-disagree state">{strings.disagree}</span></span>
    );

    const ownActions = !deleted && (
        <Fragment>
            <span>
                {!ownAnswer &&
                    <Comment.Action className="action-right">
                        <FlagModal answerId={id} content={text} />
                    </Comment.Action>
                }
                {((ownAnswer) || canEditAll) && (
                    <Fragment>
                        <Button
                            icon
                            loading={deleting}
                            onClick={() => {
                                if (!deleting) {
                                    showModal({
                                        hideOnPositiveClick: true,
                                        content: strings.deleteAnswer,
                                        header: strings.deleteConfirmation,
                                        positiveResponseAction: () => deleteAnswer({ id }),
                                    });
                                }
                            }}
                        >
                            {commonStrings.delete}
                            <Icon name="trash" />
                        </Button>
                        <Button
                            icon
                            onClick={() => openAnswerEditForm(id)}
                            className="edit-button"
                            disabled={deleting}
                        >
                            {commonStrings.edit}
                            <Icon name="edit" />
                        </Button>
                    </Fragment>)
                }
            </span>
        </Fragment>
    );

    return (
        <Fragment>
            <span className="comment-actions">
                <span className="metadata">{specialty}</span>
                {ownActions}
            </span>
            <Comment.Text>{text}</Comment.Text>
            <Comment.Actions>
                {agreeButton}
                {disagreeButton}
                {(othersHaveCommented) &&
                    <Fragment>
                        <Comment.Action className="action-right">
                            <Button
                                className="comment-button"
                                onClick={() => openReplyForm(id)}
                                active={replyFormOpen}
                            >{strings.comment}
                            </Button>
                        </Comment.Action>
                    </Fragment>
                }
            </Comment.Actions>
        </Fragment>);
};

AnswerView.propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    specialty: PropTypes.string,
    agreeCount: PropTypes.number,
    disagreeCount: PropTypes.number,
    deleted: PropTypes.bool,
    ownAnswer: PropTypes.bool.isRequired,
    userVote: PropTypes.number,
    replyFormOpen: PropTypes.bool.isRequired,
    openReplyForm: PropTypes.func.isRequired,
    rateAnswer: PropTypes.func.isRequired,
    openAnswerEditForm: PropTypes.func.isRequired,
    canEditAll: PropTypes.bool.isRequired,
    othersHaveCommented: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
    // meta
    ratingInProgress: PropTypes.bool.isRequired,
    pressedRating: PropTypes.oneOf([null, -1, 1]),
    deleting: PropTypes.bool.isRequired,
};

AnswerView.defaultProps = {
    agreeCount: 0,
    disagreeCount: 0,
    userVote: null,
    specialty: '',
    deleted: false,
    pressedRating: null,
};

export default AnswerView;
