import { throttle, put, all, call } from 'redux-saga/effects';
import { SEARCH_AUTHOR } from './actionTypes';
import {
    searchAuthorCompleted,
    searchAuthorFailed,
} from './actions';
import { loadUsers } from '../../../../data/users/api';

function* userSearchSaga({
    payload: term,
}) {
    try {
        const { data } = yield call(loadUsers, { term });
        yield put(searchAuthorCompleted({ data }));
    } catch (error) {
        yield put(searchAuthorFailed({ error }));
    }
}

export default function* UserListSaga() {
    yield all([
        throttle(2000, SEARCH_AUTHOR, userSearchSaga),
    ]);
}
