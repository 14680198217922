import React from 'react';
import PropTypes from 'prop-types';
import Chip from './chip';
import Link from './link';
import { history } from '../../store';

const ConsensusTile = ({
    assetType,
    title,
    authorName,
    authorTitle,
    imageUrl,
    href,
}) => (
    <div
        role="link"
        tabIndex={0}
        aria-label="Consensus Tile"
        onClick={() => history.push(href)}
        onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                history.push(href);
            }
        }}
        className="cursor-pointer flex flex-col gap-2 bg-white p-4 self-stretch border border-solid border-purple-gray-400 rounded-lg hover:shadow focus:ring-4 focus:ring-blue-200"
    >
        <div className="relative mb-2">
            <Chip variant="light-blue" className="absolute top-2 left-2 z-[2]">{`${assetType} Consensus`}</Chip>
            <img className="w-full h-[200px] aspect-[3/2] object-cover" src={imageUrl} alt="Consensus Tile" />
        </div>
        <h4 className="title-h4 m-0">{title}</h4>
        <p className="body-s m-0">{!!authorName && authorName} {!!authorTitle && authorTitle}</p>
        <div>
            <Link href={href} >View consensus</Link>
        </div>
    </div>
);

ConsensusTile.propTypes = {
    assetType: PropTypes.oneOf(['Clinical', 'Community']).isRequired,
    title: PropTypes.string,
    authorName: PropTypes.string,
    authorTitle: PropTypes.string,
    imageUrl: PropTypes.string,
    href: PropTypes.string.isRequired,
};

ConsensusTile.defaultProps = {
    title: null,
    authorName: null,
    authorTitle: null,
    imageUrl: null,
};

export default ConsensusTile;
