import { RoleNames } from './utils/roleHelper';
import links from './links';
import { Permissions } from './permissions';

const findRedirectPathForModerator = (permissions) => {
    if (permissions.includes(Permissions.ALL_PATIENT_CASES_MANAGEMENT)) {
        return links.moderator.questions.index;
    } else if (permissions.includes(Permissions.SUMMARY_SUBMISSION)) {
        return links.moderator.consensus.submit;
    } else if (permissions.includes(Permissions.SEARCH)) {
        return links.moderator.search.index;
    }
    return links.moderator.questions.index;
};

const findRedirectPathForSalesDemo = (permissions) => {
    if (permissions.includes(Permissions.PATIENT_CASES_MY_SPECIALTY)) {
        return links.topics.myFeed;
    } else if (permissions.includes(Permissions.PATIENT_CASES_OTHER_SPECIALTIES)) {
        return links.topics.otherFeed;
    }
    return links.topics.myFeed;
};

export const chooseRedirectPath = (roleName, permissions = [], redirectUrl = '') => {
    switch (roleName) {
        case RoleNames.HCP:
        case RoleNames.TEST_HCP:
            return redirectUrl || links.consensusMd;

        case RoleNames.SALES_DEMO:
            return findRedirectPathForSalesDemo(permissions);

        case RoleNames.ADMINISTRATOR:
            return links.moderator.questions.index;
        case RoleNames.MODERATOR:
            return findRedirectPathForModerator(permissions);
        default:
            return links.login;
    }
};
