import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { CaretLeftIcon, CaretRightIcon, DotsHorizontalIcon, ExclamationTriangleIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import SearchInput from '../../../../components/SearchInput';
import Table from '../../../../components/Table';
import Button from '../../../../components/Button';
import links from '../../../../links';

import './list.scss';

const UsersContainer = ({
    searchValue,
    users,
    searchUsers,
    currentlyExporting,
    total,
    pageNumber,
    pageSize,
    showModal,
    deleteUser,
    ownId,
    deleteInProgress,
    userToDelete,
    exportUsersList,
    loadUsers,
    history,
    loading,
}) => {
    useEffect(() => {
        loadUsers({ pageNumber, searchValue, pageSize });
    }, [pageNumber, searchValue, pageSize]);

    const numPages = Math.ceil((total / pageSize));

    const tableColumns = [
        { key: 'name', heading: 'Name', className: 'user-list-name' },
        { key: 'email', heading: 'Email', className: 'user-list-email' },
        { key: 'role', heading: 'Role', className: 'user-list-role' },
        { key: 'npi', heading: 'NPI / ID', className: 'user-list-npi' },
        { key: 'actions', heading: 'Actions', className: 'user-list-actions' },
    ];

    const shouldDisableDelete = id => ownId === id || deleteInProgress || userToDelete === id;

    const userListRecords = users.map(({
        name,
        email,
        id,
        auth0RoleName,
        userProfile,
    }) => {
        const actions = (
            <span className="action-wrapper">
                <Link title="View Rewards" className="rewards-link" href to={links.moderator.users.rewards.replace(':id', id)}>Rewards</Link>
                <Link title="Edit User" className="edit-user-link" href to={links.moderator.users.edit.replace(':id', id)} >
                    Edit
                </Link>
                <Button
                    className="delete-user"
                    variant="text"
                    disabled={shouldDisableDelete(id)}
                    title="Delete User"
                    onClick={() => showModal({
                        hideOnPositiveClick: true,
                        content: `Are you sure you want to delete the user ${name}?`,
                        header: 'Confirmation Dialog',
                        positiveResponseAction: () => deleteUser({ id }),
                    })}
                >
                    Delete
                </Button>
            </span>
        );

        const nameWithErrorIndicator = (
            <>
                <ExclamationTriangleIcon
                    alt="Error creating user."
                    width="16"
                    height="14"
                    style={
                        {
                            color: '#BE4335', marginRight: '4px', position: 'relative', top: '3px',
                        }
                    }
                />
                {name}
            </>
        );

        return ({
            name: userProfile?.userCreateFailed ? nameWithErrorIndicator : name,
            email,
            npi: userProfile?.npi || 'Not assigned',
            role: auth0RoleName,
            actions,
        });
    });

    return (
        <div className="user-list-container">
            <div className="header">
                <h1>Users</h1>
                <div className="controls">
                    <Button
                        variant="text"
                        className="text"
                        disabled={currentlyExporting}
                        onClick={() => {
                            if (!currentlyExporting) {
                                exportUsersList();
                            }
                        }}
                    >
                        Export Users
                    </Button>
                    <Button
                        variant="secondary"
                        className="secondary"
                        onClick={() => history.push(links.moderator.users.new)}
                    >
                        Create User
                    </Button>
                    <Button
                        onClick={() => history.push(links.admin.registration)}
                    >
                        Register HCPs
                    </Button>
                </div>
            </div>
            <SearchInput
                className="search-input"
                icon={<MagnifyingGlassIcon />}
                loading={loading && searchValue.length > 0}
                placeholder="Search..."
                onChange={({ target }) => {
                    searchUsers({ searchValue: target.value, count: pageSize });
                }}
                value={searchValue}
            />
            <Table columns={tableColumns} data={userListRecords} className="user-list-table" />
            <div className="pagination">
                <button className="page-back" disabled={pageNumber === 1} onClick={() => searchUsers({ pageNumber: pageNumber - 1 })}><CaretLeftIcon height={24} width={24} /></button>
                {pageNumber > 2 && <DotsHorizontalIcon className="dots" height={17} width={17} />}
                {Array.from({ length: numPages }, (_, i) => i + 1).map((page) => {
                    const className = clsx('page-number', { active: page === pageNumber });

                    if (page > pageNumber + 1 || page < pageNumber - 1) {
                        if (pageNumber < 3 && page === 3) {
                            return (
                                <button
                                    key={page}
                                    className={className}
                                    disabled={page === pageNumber}
                                    onClick={() => searchUsers({ pageNumber: page })}
                                >
                                    {page}
                                </button>
                            );
                        }
                        return null;
                    }

                    return (
                        <button
                            key={page}
                            className={className}
                            disabled={page === pageNumber}
                            onClick={() => searchUsers({ pageNumber: page })}
                        >
                            {page}
                        </button>
                    );
                })}
                {numPages - pageNumber > 1 && <DotsHorizontalIcon className="dots" height={17} width={17} />}
                <button className="page-forward" disabled={pageNumber === numPages} onClick={() => searchUsers({ pageNumber: pageNumber + 1 })}><CaretRightIcon height={24} width={24} /></button>
            </div>
        </div>
    );
};

UsersContainer.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape()),
    searchUsers: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    exportUsersList: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    ownId: PropTypes.number.isRequired,
    deleteInProgress: PropTypes.bool.isRequired,
    loadUsers: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    total: PropTypes.number,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    userToDelete: PropTypes.number,
    currentlyExporting: PropTypes.bool.isRequired,
    searchValue: PropTypes.string,
    loading: PropTypes.bool,
};

UsersContainer.defaultProps = {
    users: [],
    total: 0,
    pageNumber: 1,
    pageSize: 10,
    userToDelete: null,
    loading: false,
    searchValue: '',
};

export default UsersContainer;
