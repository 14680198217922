import moment from 'moment';
import localization from '../../localization';
import { questionStatus, targetAudienceShort } from '../../data/enums';

/* eslint-disable import/prefer-default-export */

const {
    general: {
        closePastMoment,
    },
    common: {
        topicStatuses: {
            active,
            expired,
            scheduled,
        },
    },
    thread: {
        expires,
        begins,
    },
} = localization;

const {
    scheduled: scheduledEnum,
    active: activeEnum,
    expired: expiredEnum,
} = questionStatus;

export const addToCurrentTime = (expiration, currentDate) => currentDate + expiration;

export const differenceTime = (futureDate, currentDate) => futureDate - currentDate;

export const formatDateForEmail = (topicId, targetedAudience) => {
    const target = targetAudienceShort[targetedAudience] || targetAudienceShort.all;

    return `${moment(Date.now()).format('YYYY-MM-DD')}-q${topicId}-${target}`;
};

export const formatDateForSpecialtySpecificEmail = (targetedAudience) => {
    const target = targetAudienceShort[targetedAudience] || targetAudienceShort.all;
    return `${moment(Date.now()).format('YYYY-MM-DD')}-specialty-selection-${target}`;
};

export const formatDateForNPISpecificEmail = () => `${moment(Date.now()).format('YYYY-MM-DD')}-npi-csv-input`;

export const convertStatusToString = status =>
    (typeof status === 'number' ? [scheduled, active, expired][status] : active);

/**
 * returns string representing relative time between the passed date and present
 * @param {string|Date} date
 */
export const relativePastTime = (date) => {
    if (new Date(date) > new Date()) {
        return closePastMoment;
    }
    return moment(date).fromNow();
};

export const relativeLeftTime = date => moment(new Date(date)).fromNow();

export const relativeCloseTime = date => moment(new Date(date)).fromNow() < 60 * 1000;

export const calculateQuestionStatus = (status, createdAt, publishDate, expirationDate) => {
    let timedStatus;

    if (relativeCloseTime(createdAt)) {
        timedStatus = closePastMoment;
    } else if (status === scheduledEnum) {
        timedStatus = `${begins} ${relativeLeftTime(publishDate)}`;
    } else if (status === activeEnum) {
        timedStatus = `${expires} ${relativeLeftTime(expirationDate)}`;
    } else if (status === expiredEnum) {
        timedStatus = `${expired} ${relativePastTime(expirationDate)}`;
    }

    return timedStatus;
};
