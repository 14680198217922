import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import links from '../../links';

const RewardsToolTip = () => (
    <div className="absolute top-4 right-4" style={{ zIndex: 1 }}>
        <Popup
            trigger={<Icon circular name="info" className="info-icon" />}
            size="tiny"
            position="bottom center"
            inverted
            style={{ opacity: '#442A5C', padding: '0.5rem' }}
            hoverable
            wide
        >
            <Popup.Content>
                Find your honorarium eligibility under <Link to={links.my.rewards} href={links.my.rewards}>Rewards</Link>
            </Popup.Content>
        </Popup>
    </div>
);


export default RewardsToolTip;
