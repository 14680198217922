import React from 'react';
import PropTypes from 'prop-types';
import JoinAConversation from '../JoinAConversation/joinAConversation.svg';
import './actionLinkTile.scss';

const ActionLinkTile = ({
    title,
    description,
    cta,
    ctaLink,
}) => (
    <div className="action-link-tile">
        <div>
            <JoinAConversation />
        </div>
        <div className="cta-text">
            <h2>{title}</h2>
            <p>{description}</p>
            <a href={ctaLink} target="_blank" rel="noopener noreferrer">{cta}</a>
        </div>
    </div>
);

ActionLinkTile.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    ctaLink: PropTypes.string.isRequired,
};

ActionLinkTile.defaultProps = {
};

export default ActionLinkTile;
