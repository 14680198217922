import { lifecycle } from 'recompose';

export default lifecycle({
    componentDidMount() {
        const shouldScrollTop = window.pageXOffset !== 0 || window.pageYOffset !== 0;
        if (shouldScrollTop) {
            window.scrollTo(0, 0);
        }
    },
});
