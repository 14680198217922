import { RoleNames } from '../../../../utils/roleHelper';
import { Permissions } from '../../../../permissions';

const activePatientCaseManagement = {
    value: Permissions.ACTIVE_PATIENT_CASE_MANAGEMENT,
    preSelected: false,
    forAppHc: false,
    label: 'Active Patient Case Management',
};
const allPatientCasesManagement = {
    value: Permissions.ALL_PATIENT_CASES_MANAGEMENT,
    preSelected: false,
    forAppHc: false,
    label: 'All Patient Cases Management',
};
const consensusMdBeta = {
    value: Permissions.CONSENSUS_MD_BETA,
    preSelected: true,
    forAppHc: false,
    label: 'ConsensusMD Beta',
};
const demoPatientCasesMySpecialty = {
    value: Permissions.DEMO_PATIENT_CASES_MY_SPECIALTY,
    preSelected: false,
    forAppHc: true,
    label: 'Demo - Patient Cases - My Specialty',
};
const demoPatientCasesOtherSpecialties = {
    value: Permissions.DEMO_PATIENT_CASES_OTHER_SPECIALTIES,
    preSelected: false,
    forAppHc: true,
    label: 'Demo - Patient Cases - Other Specialties',
};
const diseasesAndTreatmentInformation = {
    value: Permissions.DISEASES_AND_TREATMENT_INFORMATION,
    preSelected: false,
    forAppHc: true,
    label: 'Diseases & Treatment Information',
};
const patientCasesMySpecialty = {
    value: Permissions.PATIENT_CASES_MY_SPECIALTY,
    preSelected: false,
    forAppHc: false,
    label: 'Patient Cases - My Specialty',
};
const patientCasesOtherSpecialties = {
    value: Permissions.PATIENT_CASES_OTHER_SPECIALTIES,
    preSelected: false,
    forAppHc: false,
    label: 'Patient Cases - Other Specialties',
};
const patientCaseSubmission = {
    value: Permissions.PATIENT_CASE_SUBMISSION,
    preSelected: false,
    forAppHc: true,
    label: 'Patient Case Submission',
};
const programs = {
    value: Permissions.PROGRAMS,
    preSelected: false,
    forAppHc: false,
    label: 'Programs',
};
const search = {
    value: Permissions.SEARCH,
    preSelected: false,
    forAppHc: false,
    label: 'Search',
};
const summarySubmission = {
    value: Permissions.SUMMARY_SUBMISSION,
    preSelected: false,
    forAppHc: false,
    label: 'Summary Submission',
};
const userContent = {
    value: Permissions.USER_CONTENT,
    preSelected: false,
    forAppHc: false,
    label: 'User Content',
};
const userManagement = {
    value: Permissions.USER_MANAGEMENT,
    preSelected: false,
    forAppHc: false,
    label: 'User Management',
};

const preSelect = permission => ({
    ...permission,
    preSelected: true,
});

const hcpPermissionOptions = [
    { ...preSelect(diseasesAndTreatmentInformation) },
    { ...preSelect(patientCasesMySpecialty) },
    { ...preSelect(patientCasesOtherSpecialties) },
    { ...preSelect(patientCaseSubmission) },
    { ...preSelect(userContent) },
    { ...consensusMdBeta },
];

const testHcpPermissionOptions = [...hcpPermissionOptions];

const moderatorPermissionOptions = [
    { ...allPatientCasesManagement },
    { ...diseasesAndTreatmentInformation },
    { ...summarySubmission },
    { ...search },
];

const adminPermissionOptions = [
    { ...preSelect(activePatientCaseManagement) },
    { ...preSelect(allPatientCasesManagement) },
    { ...demoPatientCasesMySpecialty },
    { ...demoPatientCasesOtherSpecialties },
    { ...diseasesAndTreatmentInformation },
    { ...patientCaseSubmission },
    { ...preSelect(programs) },
    { ...preSelect(search) },
    { ...preSelect(summarySubmission) },
    { ...preSelect(userManagement) },
];

const salesDemoPermissionOptions = [
    { ...patientCasesMySpecialty },
    { ...patientCasesOtherSpecialties },
    { ...demoPatientCasesMySpecialty },
    { ...demoPatientCasesOtherSpecialties },
    { ...diseasesAndTreatmentInformation },
];

export const getPermissionOptionsFromRole = (roleName) => {
    switch (roleName) {
        case RoleNames.HCP:
            return hcpPermissionOptions;
        case RoleNames.TEST_HCP:
            return testHcpPermissionOptions;
        case RoleNames.MODERATOR:
            return moderatorPermissionOptions;
        case RoleNames.ADMINISTRATOR:
            return adminPermissionOptions;
        case RoleNames.SALES_DEMO:
            return salesDemoPermissionOptions;
        default:
            return [];
    }
};

export const isPreSelected = (permission, roleName) => {
    const permissionOptions = getPermissionOptionsFromRole(roleName);
    const permissionOption = permissionOptions.find(po => po.value === permission);
    return permissionOption && permissionOption.preSelected;
};

export const forAppHc = (permission, roleName) => {
    const permissionOptions = getPermissionOptionsFromRole(roleName);
    const permissionOption = permissionOptions.find(po => po.value === permission);
    return permissionOption && permissionOption.forAppHc;
};
