import client from '../../api';
import { rewards } from '../../api/endpoints';

const {
    current,
    history,
} = rewards;

export const loadCurrentRewards = () => client.get(current);

export const loadHistoryRewards = () => client.get(history);
