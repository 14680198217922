import { reduxForm, change } from 'redux-form';
import Component from './component';

const formName = 'newNPIEmail';

const form = reduxForm({
    form: formName,
    onSubmit: (values, dispatch, { createNpiCampaign }) => {
        createNpiCampaign({ ...values });
    },
    onChange: (values, dispatch, pValues, oldValues) => {
        if (values.name !== oldValues.name) {
            dispatch(change(formName, 'name', values.name));
        }
    },
});

export default form(Component);
