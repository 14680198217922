import { reduxForm, getFormSyncErrors } from 'redux-form';

import { updateQuestionsNpis } from '../../../../data/questions/actions';

import { syncValidations } from './validations';

const formName = 'questionsNpisForm';

export const Form = reduxForm({
    form: formName,
    validate: syncValidations,
    enableReinitialize: true,
    onSubmit: ({ questionsNpisText }, dispatch) => {
        const questionsNpis = questionsNpisText.split('\n').map((row) => {
            const [topicId, npi] = row.split(',');
            return [parseInt(topicId, 10), npi];
        });

        const action = updateQuestionsNpis(questionsNpis);
        dispatch(action);
    },
});

export const errorSelector = getFormSyncErrors(formName);
