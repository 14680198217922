import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import {
    LOAD_SPECIALTIES,
    LOAD_SPECIALTIES_COMPLETED,
    LOAD_SPECIALTIES_FAILED,
    LOAD_SUBSPECIALTIES,
    LOAD_SUBSPECIALTIES_COMPLETED,
    LOAD_SUBSPECIALTIES_FAILED,
} from './actionTypes';

const initialState = Immutable.from({
    specialties: [],
    subSpecialties: [],
    loadingSpecialtiesError: '',
    loadingSpecialtiesFailed: false,
    loadingSpecialties: false,
    loadedSpecialties: false,
    loadingSubSpecialtiesError: '',
    loadingSubSpecialtiesFailed: false,
    loadingSubSpecialties: false,
    loadedSubSpecialties: false,
});

const reducer = handleActions({
    [LOAD_SPECIALTIES]: state =>
        state.merge({
            loadingSpecialties: true,
            loadingSpecialtiesFailed: false,
            loadingSpecialtiesError: null,
        }),
    [LOAD_SPECIALTIES_COMPLETED]: (state, { payload: { data: specialties } }) => {
        const sortedSpecialties = specialties.sort((x, y) => x.name.localeCompare(y.name));
        return state.merge({
            specialties: sortedSpecialties,
            loadingSpecialties: false,
            loadingSpecialtiesFailed: false,
            loadingSpecialtiesError: null,
            loadedSpecialties: true,
        });
    },
    [LOAD_SPECIALTIES_FAILED]: (state, { payload: error }) =>
        state.merge({
            specialty: [],
            loadingSpecialties: false,
            loadingSpecialtiesFailed: true,
            loadingSpecialtiesError: error,
        }),
    [LOAD_SUBSPECIALTIES]: state =>
        state.merge({
            loadingSubSpecialties: true,
            loadingSubSpecialtiesFailed: false,
            loadingSubSpecialtiesError: null,
        }),
    [LOAD_SUBSPECIALTIES_COMPLETED]: (state, { payload: { data: subSpecialties } }) => {
        const sortedSubSpecialties = subSpecialties.sort((x, y) => x.name.localeCompare(y.name));
        return state.merge({
            subSpecialties: sortedSubSpecialties,
            loadingSubSpecialties: false,
            loadingSubSpecialtiesFailed: false,
            loadingSubSpecialtiesError: null,
            loadedSubSpecialties: true,
        });
    },
    [LOAD_SUBSPECIALTIES_FAILED]: (state, { payload: error }) =>
        state.merge({
            subSpecialty: [],
            loadingSubSpecialties: false,
            loadingSubSpecialtiesFailed: true,
            loadingSubSpecialtiesError: error,
        }),
}, initialState);

export default reducer;
