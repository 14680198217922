import React, { Component } from 'react';
import { Header, Divider, Segment, Grid, Image, Label, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import banner from './banner.png';
import localization from '../../../localization';

import Navigation from '../../../components/My/Navigation';
import Reward from '../../../components/Reward';
import Tooltip from '../../../components/Tooltip';
import { rewardsEnabled } from '../../../environment';
import NoRewardsSection from './NoRewardsSection';
import RewardsSection from './RewardsSection';
import rewardsPropTypes from './rewardsPropTypes';

import './style.scss';

const {
    my: {
        rewards: {
            myRewardsHeading,
            welcomeRewardsHeading,
            welcomeRewardsContent,
            rewardsHistory,
        },
    },
} = localization;

class MyRewards extends Component {
    state = {
        isWelcomeOpened: true,
    };
    render() {
        const {
            historyRewards,
            rewards: {
                max,
                npi,
                earned,
                period,
            },
        } = this.props;
        const {
            isWelcomeOpened,
        } = this.state;
        const toggleWelcomeWindow = () => {
            this.setState({
                isWelcomeOpened: false,
            });
        };
        const rewards = historyRewards.map(historyReward => (
            <Reward
                key={`${historyReward.question_id}-history-reward`}
                questionId={historyReward.question_id}
                questionTitle={historyReward.question_title}
                earnedAt={historyReward.earned_at}
                giftCardValue={historyReward.value}
            />
        ));
        const welcomeWindow = (
            (isWelcomeOpened)
                ? (
                    <Segment>
                        <Grid doubling columns={2}>
                            <Grid.Row>
                                <Grid.Column width="6" className="vertical-middle-content">
                                    <Image src={banner} className="banner" />
                                </Grid.Column>
                                <Grid.Column width="10" className="vertical-middle-content">
                                    <h5>{welcomeRewardsHeading}</h5>
                                    <div className="content">
                                        {welcomeRewardsContent.main}
                                        <a href="mailto:name@email.com:member.services@healthcasts.com">{welcomeRewardsContent.contactMemberService}</a>.
                                    </div>
                                </Grid.Column>
                                <Icon name="close" className="close-button" onClick={toggleWelcomeWindow} />
                            </Grid.Row>
                        </Grid>
                    </Segment>
                ) : null
        );

        const rewardable = period && ((earned < max) || (npi > 0));
        const rewardsSection = period && (max || npi) ? (
            <RewardsSection rewards={this.props.rewards} rewardable={rewardable} />
        ) : (
            <NoRewardsSection />
        );
        return (
            <div className="my-rewards profile">
                <div className="page-container">
                    <div className="inline-align">
                        <Header>{myRewardsHeading}</Header>
                        <Navigation active="rewards" />
                    </div>
                    <Divider className="expanded" />
                    {welcomeWindow}
                    {rewardsEnabled && rewardsSection}
                    <Tooltip
                        trigger={
                            <Header as="h5" className="section-header">
                                {rewardsHistory} <Label circular className="label-number">{historyRewards.length}</Label>
                            </Header>
                        }
                        name="rewards-history"
                    />
                    {rewards}
                </div>
            </div>
        );
    }
}

MyRewards.defaultProps = {
    historyRewards: [],
    rewards: {
        max: 0,
        earned: 0,
        npiRewarded: 0,
        value: '0',
        rewards: [],
    },
};

MyRewards.propTypes = {
    historyRewards: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        question_title: PropTypes.string.isRequired,
        question_id: PropTypes.number.isRequired,
        earned_at: PropTypes.string,
    })),
    rewards: PropTypes.shape(rewardsPropTypes),
};

export default MyRewards;
