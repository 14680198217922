import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import {
    SET_POST_AUTH_REDIRECT_URL,
    FETCH_USER,
    FETCH_USER_FAILED,
    FETCH_USER_COMPLETED,
    LOGOUT,
    MARK_DISCLAIMER_NOT_READ,
    MARK_DISCLAIMER_READ,
    REDIRECT_TO_UNSEEN_QUESTIONS,
    DISABLE_CTA_POPUP_COMPLETED,
    ALLOW_TOOLTIPS,
    ALLOW_TOOLTIPS_COMPLETED,
    ALLOW_TOOLTIPS_FAILED,
    DISABLE_TOOLTIPS,
    DISABLE_TOOLTIPS_COMPLETED,
    DISABLE_TOOLTIPS_FAILED,
} from './actionTypes';

import { UPDATE_ANSWER_COMPLETED } from '../../data/answers/actions';

export const FetchState = {
    not_asked: 'session.fetch_state.not_asked',
    loading: 'session.fetch_state.loading',
    success: 'session.fetch_state.success',
    failure: 'session.fetch_state.failure',
};

const initialState = Immutable.from({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    disclaimerRead: false,
    disclaimerBEChecked: false,
    userHasUnseenTopics: false,
    ctaPopupDisabled: false,
    npi: null,
    roleName: null,
    postAuthRedirectUrl: null,
    fetchState: FetchState.not_asked,
});

export default handleActions(
    {
        [SET_POST_AUTH_REDIRECT_URL]: (
            state,
            {
                payload: {
                    postAuthRedirectUrl,
                },
            },
        ) => state.set('postAuthRedirectUrl', postAuthRedirectUrl),
        [FETCH_USER]: state => state.set('fetchState', FetchState.loading),
        [FETCH_USER_COMPLETED]: (state, {
            payload: {
                id,
                firstName,
                lastName,
                email,
                specialty,
                npi,
                showTooltips,
                ctaPopupDisabled,
                roleName,
                permissions,
            },
        }) => state.merge({
            fetchState: FetchState.success,
            id,
            firstName,
            lastName,
            email,
            specialty,
            npi,
            showTooltips,
            ctaPopupDisabled,
            roleName,
            permissions,
        }),
        [FETCH_USER_FAILED]: state => state.set('fetchState', FetchState.failure),
        [LOGOUT]: () => initialState,
        [MARK_DISCLAIMER_READ]: state => state.merge({
            disclaimerRead: true,
            disclaimerBEChecked: true,
        }),
        [MARK_DISCLAIMER_NOT_READ]: state => state.merge({
            disclaimerRead: false,
            disclaimerBEChecked: true,
        }),
        [UPDATE_ANSWER_COMPLETED]: (
            state,
            {
                payload: {
                    answer: {
                        hasUnseenTopics,
                    },
                },
            },
        ) => state.merge({ userHasUnseenTopics: hasUnseenTopics }),
        [REDIRECT_TO_UNSEEN_QUESTIONS]: state => state.merge({
            userHasUnseenTopics: false,
        }),
        [DISABLE_CTA_POPUP_COMPLETED]: state => state.merge({
            ctaPopupDisabled: true,
        }),
        [ALLOW_TOOLTIPS]: state => state.merge({
            updatingShowTooltips: true,
        }),
        [ALLOW_TOOLTIPS_COMPLETED]: state => state.merge({
            showTooltips: true,
            updatingShowTooltips: false,
        }),
        [ALLOW_TOOLTIPS_FAILED]: state => state.merge({
            updatingShowTooltips: false,
        }),
        [DISABLE_TOOLTIPS]: state => state.merge({
            updatingShowTooltips: true,
        }),
        [DISABLE_TOOLTIPS_COMPLETED]: state => state.merge({
            showTooltips: false,
            updatingShowTooltips: false,
        }),
        [DISABLE_TOOLTIPS_FAILED]: state => state.merge({
            updatingShowTooltips: false,
        }),
    },
    initialState,
);
