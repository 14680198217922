import { createAction } from 'redux-actions';
import {
    ALLOW_TOOLTIPS,
    ALLOW_TOOLTIPS_COMPLETED,
    ALLOW_TOOLTIPS_FAILED,
    DISABLE_TOOLTIPS,
    DISABLE_TOOLTIPS_COMPLETED,
    DISABLE_TOOLTIPS_FAILED,
} from './actionTypes';

export const allowTooltips = createAction(ALLOW_TOOLTIPS);
export const allowTooltipsCompleted = createAction(ALLOW_TOOLTIPS_COMPLETED);
export const allowTooltipsFailed = createAction(ALLOW_TOOLTIPS_FAILED);

export const disableTooltips = createAction(DISABLE_TOOLTIPS);
export const disableTooltipsCompleted = createAction(DISABLE_TOOLTIPS_COMPLETED);
export const disableTooltipsFailed = createAction(DISABLE_TOOLTIPS_FAILED);
