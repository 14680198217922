/* eslint-disable import/prefer-default-export */

export const ID = 'PROGRAM_EMAILS';

export const OPEN_NEW_EMAIL_MODAL = `${ID}/OPEN_NEW_EMAIL_MODAL`;
export const CLOSE_NEW_EMAIL_MODAL = `${ID}/CLOSE_NEW_EMAIL_MODAL`;
export const CLOSE_NEW_EMAIL_MODAL_FAILED = `${ID}/CLOSE_NEW_EMAIL_MODAL_FAILED`;

export const OPEN_NEW_NPI_EMAIL_MODAL = `${ID}/OPEN_NEW_NPI_EMAIL_MODAL`;
export const CLOSE_NEW_NPI_EMAIL_MODAL = `${ID}/CLOSE_NEW_NPI_EMAIL_MODAL`;
export const CLOSE_NEW_NPI_EMAIL_MODAL_FAILED = `${ID}/CLOSE_NEW_NPI_EMAIL_MODAL_FAILED`;
