import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import './styles/tailwind.css';
import './styles/semantic.min.css';
import AppInitializer from './containers/AppInitializer';
import App from './App';
import { store, persistor } from './store';
// this is commented due to Error during service worker registration DOMException, don't delete
/* import registerServiceWorker from './utils/registerServiceWorker'; */
import * as sentryConfiguration from './utils/sentryConfiguration';
import AuthProvider from './containers/AuthProvider';
import { mockApiEnabled } from './environment';
import { ViewportWidthProvider } from './hooks/ViewportWidthContext';

sentryConfiguration.configureSentry(process.env.NODE_ENV && process.env.NODE_ENV !== 'development');

if (process.env.NODE_ENV === 'development' && mockApiEnabled) {
    // eslint-disable-next-line global-require
    const { worker } = require('./mocks/browser');
    worker.start();
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AuthProvider>
                <AppInitializer>
                    <ViewportWidthProvider>
                        <App />
                    </ViewportWidthProvider>
                </AppInitializer>
            </AuthProvider>
        </PersistGate>
    </Provider>
    ,
    document.getElementById('root'),
);

// this is commented due to Error during service worker registration DOMException, don't delete
/* registerServiceWorker(); */
