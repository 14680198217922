import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withRouter } from 'react-router';
import NewNPIEmailModal from './form';
import { closeNewNPIEmailModal } from '../actions';
import { createNpiCampaign } from '../../../../../data/campaigns';
import { dataStateSelector, ownStateSelector } from '../selectors';
import { formatDateForNPISpecificEmail } from '../../../../../utils/dates';
import { targetAudience as targetAudienceEnum } from '../../../../../data/enums';

const mapStateToProps = (state, { npiRows }) => ({
    initialValues: {
        name: formatDateForNPISpecificEmail(),
        targetAudience: targetAudienceEnum.npi,
        npiRows,
    },
    open: ownStateSelector(state).newNPIEmailModalOpened,
    creatingCampaign: dataStateSelector(state).creatingCampaign,
});

export default compose(
    withRouter,
    connect(mapStateToProps, { closeNewNPIEmailModal, createNpiCampaign }),
)(NewNPIEmailModal);
