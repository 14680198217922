import { createAction } from 'redux-actions';
import {
    UPDATE_QUESTION,
    UPDATE_QUESTION_COMPLETED,
    UPDATE_QUESTION_FAILED,
    CREATE_QUESTION_COMPLETED,
    CREATE_QUESTION_FAILED,
    LOAD_QUESTIONS_SEARCH,
    LOAD_SEARCH_COMPLETED,
    LOAD_QUESTIONS,
    LOAD_QUESTIONS_COMPLETED,
    LOAD_QUESTIONS_FAILED,
    LOAD_QUESTION,
    LOAD_QUESTION_COMPLETED,
    LOAD_QUESTION_FAILED,
    LOAD_NEXT_QUESTION,
    LOAD_NEXT_QUESTION_COMPLETED,
    LOAD_NEXT_QUESTION_FAILED,
    DELETE_QUESTION,
    DELETE_QUESTION_COMPLETED,
    DELETE_QUESTION_FAILED,
    FLAG_QUESTION,
    FLAG_QUESTION_COMPLETED,
    FLAG_QUESTION_FAILED,
    RESET_FLAGGED_STATE,
    LOAD_RELATED_USERS_COUNT,
    LOAD_RELATED_USERS_COUNT_COMPLETED,
    LOAD_RELATED_USERS_COUNT_FAILED,
    CLEAR_QUESTIONS,
    LOAD_ELIGIBLE_USERS_COUNT,
    LOAD_ELIGIBLE_USERS_COUNT_COMPLETED,
    LOAD_ELIGIBLE_USERS_COUNT_FAILED,
    LOAD_QUESTIONS_NPIS,
    LOAD_QUESTIONS_NPIS_COMPLETED,
    LOAD_QUESTIONS_NPIS_FAILED,
    UPDATE_QUESTIONS_NPIS,
    UPDATE_QUESTIONS_NPIS_COMPLETED,
    UPDATE_QUESTIONS_NPIS_FAILED,
} from './actionTypes';

export const updateQuestion = createAction(UPDATE_QUESTION);
export const updateQuestionCompleted = createAction(UPDATE_QUESTION_COMPLETED);
export const updateQuestionFailed = createAction(UPDATE_QUESTION_FAILED);

export const createQuestionCompleted = createAction(CREATE_QUESTION_COMPLETED);
export const createQuestionFailed = createAction(CREATE_QUESTION_FAILED);

export const loadQuestionsSearch = createAction(LOAD_QUESTIONS_SEARCH);
export const loadSearchCompleted = createAction(LOAD_SEARCH_COMPLETED);
export const loadQuestions = createAction(LOAD_QUESTIONS);
export const loadQuestionsCompleted = createAction(LOAD_QUESTIONS_COMPLETED);
export const loadQuestionsFailed = createAction(LOAD_QUESTIONS_FAILED);

export const deleteQuestion = createAction(DELETE_QUESTION);
export const deleteQuestionCompleted = createAction(DELETE_QUESTION_COMPLETED);
export const deleteQuestionFailed = createAction(DELETE_QUESTION_FAILED);

export const loadQuestion = createAction(LOAD_QUESTION);
export const loadQuestionCompleted = createAction(LOAD_QUESTION_COMPLETED);
export const loadQuestionFailed = createAction(LOAD_QUESTION_FAILED);

export const loadNextQuestion = createAction(LOAD_NEXT_QUESTION);
export const loadNextQuestionCompleted = createAction(LOAD_NEXT_QUESTION_COMPLETED);
export const loadNextQuestionFailed = createAction(LOAD_NEXT_QUESTION_FAILED);

export const flagQuestion = createAction(FLAG_QUESTION);
export const flagQuestionCompleted = createAction(FLAG_QUESTION_COMPLETED);
export const flagQuestionFailed = createAction(FLAG_QUESTION_FAILED);
export const resetFlaggedState = createAction(RESET_FLAGGED_STATE);

export const loadRelatedUsersCount = createAction(LOAD_RELATED_USERS_COUNT);
export const loadRelatedUsersCountCompleted =
    createAction(LOAD_RELATED_USERS_COUNT_COMPLETED);
export const loadRelatedUsersCountFailed =
    createAction(LOAD_RELATED_USERS_COUNT_FAILED);

export const loadEligibleUsersCount = createAction(LOAD_ELIGIBLE_USERS_COUNT);
export const loadEligibleUsersCountCompleted =
    createAction(LOAD_ELIGIBLE_USERS_COUNT_COMPLETED);
export const loadEligibleUsersCountFailed =
    createAction(LOAD_ELIGIBLE_USERS_COUNT_FAILED);

export const clearQuestions = createAction(CLEAR_QUESTIONS);

export const loadQuestionsNpis = createAction(LOAD_QUESTIONS_NPIS);
export const loadQuestionsNpisCompleted = createAction(LOAD_QUESTIONS_NPIS_COMPLETED);
export const loadQuestionsNpisFailed = createAction(LOAD_QUESTIONS_NPIS_FAILED);

export const updateQuestionsNpis = createAction(UPDATE_QUESTIONS_NPIS);
export const updateQuestionsNpisCompleted = createAction(UPDATE_QUESTIONS_NPIS_COMPLETED);
export const updateQuestionsNpisFailed = createAction(UPDATE_QUESTIONS_NPIS_FAILED);
