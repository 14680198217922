import localization from '../../../../localization';

const {
    invalidRow,
} = localization.admin.questions.npis.validations;

export const syncValidations = ({
    questionsNpisText,
}) => {
    const rows = questionsNpisText.split('\n');
    const invalidRows = [];

    for (let i = 0; i < rows.length; i += 1) {
        if (rows[i] && !rows[i].match(/^[0-9]+,[0-9]+$/)) {
            invalidRows.push({ id: i, content: invalidRow(i) });
        }
    }

    return {
        questionsNpisErrors: invalidRows.length ? invalidRows : null,
        questionsNpisText: invalidRows.langth ? invalidRows.map(e => e.content) : null,
    };
};
