/* eslint-disable import/prefer-default-export */

export const ID = 'QUESTIONS_DETAILS';

export const SET_ANSWER_REPORT_FAILED = `${ID}/SET_ANSWER_REPORT_FAILED`;
export const CLEAR_ANSWER_REPORT_FAILED = `${ID}/CLEAR_ANSWER_REPORT_FAILED`;

export const OPEN_NEW_EMAIL_MODAL = `${ID}/OPEN_NEW_EMAIL_MODAL`;
export const CLOSE_NEW_EMAIL_MODAL = `${ID}/CLOSE_NEW_EMAIL_MODAL`;
export const CLOSE_EMAIL_FAILED = `${ID}/CLOSE_EMAIL_FAILED`;

// question_programs
export const OPEN_ATTACH_MODAL = `${ID}/OPEN_ATTACH_MODAL`;
export const CLOSE_ATTACH_MODAL = `${ID}/CLOSE_ATTACH_MODAL`;

export const OPEN_DETACH_MODAL = `${ID}/OPEN_DETACH_MODAL`;
export const CLOSE_DETACH_MODAL = `${ID}/CLOSE_DETACH_MODAL`;
