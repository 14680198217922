import React from 'react';
import { Comment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import localization from '../../../../localization';
import { AnswerCommentForm, AnswerCommentHeader, answerTypes } from '../components';
import AnswerEdit from './AnswerEdit';
import AnswerView from './AnswerView';
import { syncValidations, warn, commentLength } from '../validations';
import { newCommentFormName } from '../constants';
import { FlashIndicator } from '../.././../../components';
import Comments from './Comments';
import './style.scss';

const {
    max: maxCommentLength,
} = commentLength;

const NewCommentForm = reduxForm({
    form: newCommentFormName,
    validate: syncValidations(maxCommentLength),
    warn: warn(maxCommentLength),
})(AnswerCommentForm);

const {
    topic: strings,
} = localization;

const Answer = ({
    answer: answerProp = {},
    topicExpired,
    replyFormOpen,
    updateAnswer,
    editMode,
    lastUpdatedCommentId,
    clearUpdatedComment,
    updating,
    ...props
}) => {
    const {
        id,
        text,
        comments,
        createdAt,
        updatedAt,
        createdByUser: {
            firstName,
            lastName,
            specialty,
        } = {},
        deletedAt,
        rating: {
            '-1': disagreeCount = 0,
            1: agreeCount = 0,
        } = {},
        topicId,
        ownAnswer,
    } = answerProp;
    const answer = {
        agreeCount,
        disagreeCount,
        ...answerProp,
    };
    let content;
    const specialtyName = (specialty && specialty.name) || undefined;
    if (deletedAt) {
        const deleteMsg = ownAnswer ? strings.deletedAnswerOwn : strings.deletedAnswer;
        content = <Comment.Text className="deleted" >{deleteMsg}</Comment.Text>;
    } else if (editMode) {
        content = (<AnswerEdit
            id={id}
            text={text}
            topicId={topicId}
        />);
    } else {
        content = (<AnswerView
            replyFormOpen={replyFormOpen}
            topicExpired={topicExpired}
            specialty={specialtyName}
            {...props}
            {...answer}
        />);
    }

    const author = `${firstName} ${lastName}`;

    const commentForm = replyFormOpen && <NewCommentForm
        onSave={ans => updateAnswer({ ...ans, replyTo: id, topicId })}
        placeholder={strings.commentPlaceholder}
        submitButtonProps={{
            label: strings.postComment,
            icon: 'reply',
            loading: updating,
        }}
    />;

    return (
        <Comment>
            <FlashIndicator
                active={id === lastUpdatedCommentId}
                makeInactive={clearUpdatedComment}
                activeClass="highlighted"
                inactiveClass="inactive"
            >
                <Comment.Content>
                    <AnswerCommentHeader
                        updatedAt={updatedAt}
                        createdAt={createdAt}
                        author={author}
                        type={answerTypes.answer}
                    />
                    <Comment.Text className="content-full">{content}</Comment.Text>
                </Comment.Content>
            </FlashIndicator>
            <Comments
                comments={comments}
                lastUpdatedCommentId={lastUpdatedCommentId}
                topicExpired={topicExpired}
            >
                {commentForm}
            </Comments>
        </Comment>
    );
};

Answer.propTypes = {
    answer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        topicId: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        comments: PropTypes.arrayOf(PropTypes.shape()),
        createdAt: PropTypes.string.isRequired,
        updatedAt: PropTypes.string.isRequired,
        createdByUser: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }).isRequired,
        rating: PropTypes.shape({
            '-1': PropTypes.number,
            1: PropTypes.number,
        }),
        deletedAt: PropTypes.string,
        ownAnswer: PropTypes.bool.isRequired,
        userVote: PropTypes.number,
    }),
    comments: PropTypes.arrayOf(PropTypes.shape()),
    replyFormOpen: PropTypes.bool.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    topicExpired: PropTypes.bool,
    editMode: PropTypes.bool.isRequired,
    openAnswerEditForm: PropTypes.func.isRequired,
    clearUpdatedComment: PropTypes.func.isRequired,
    // meta
    lastUpdatedCommentId: PropTypes.number,
    updating: PropTypes.bool,
};

Answer.defaultProps = {
    comments: [],
    topicExpired: false,
    lastUpdatedCommentId: null,
    answer: null,
    updating: false,
};

export default Answer;
