import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import * as localForage from 'localforage';
import { reducer as formReducer } from 'redux-form';
import { reducer as sessionReducer, ID as SessionID, Actions } from '../services/session';
import { ID as ProgramsId } from '../data/programs/index';
import { reducer as navigationReducer, ID as NavigationID } from '../services/navigation';
import { reducer as strapiReducer, STRAPI_ID } from '../services/strapi/reducer';
import DataReducer, { ID as DataID } from '../data';
import { reducer as ThreadContainerReducer, ID as ThreadCID } from '../containers/Thread';
import { ID as APP_UI_ID, reducer as AppUIReducer } from '../services/sharedUIActions';
import { immutableTransform, immutableReconciliator, whiteListFilterTransform } from '../utils/immutable-persist';
import { reducer as ModeratorReducer, ID as ModeratorID } from '../containers/Moderator';
import { reducer as toastsReducer } from '../containers/Toast';

export const ID = 'ROOT';

const config = {
    key: ID,
    storage: localForage,
    transforms: [
        immutableTransform,
        whiteListFilterTransform({
            whitelists: {
                [SessionID]: [
                    'id',
                    'firstName',
                    'lastName',
                    'email',
                    'specialty',
                    'disclaimerRead',
                    'showTooltips',
                    'userHasUnseenTopics',
                    'ctaPopupDisabled',
                    'npi',
                ],
                [DataID]: [ProgramsId],
            },
        }),
    ],
    stateReconciler: immutableReconciliator,
    whitelist: [SessionID, DataID],
};

const reducers = combineReducers({
    [SessionID]: sessionReducer,
    [DataID]: DataReducer,
    [NavigationID]: navigationReducer,
    [ThreadCID]: ThreadContainerReducer,
    [APP_UI_ID]: AppUIReducer,
    [ModeratorID]: ModeratorReducer,
    [STRAPI_ID]: strapiReducer,
    form: formReducer,
    toast: toastsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === Actions.LOGOUT) {
        const { router } = state;
        return {
            router,
            ...reducers(undefined, action),
        };
    }
    return reducers(state, action);
};

export default persistReducer(config, rootReducer);
