import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'react-router-redux';

export { ID } from './actionTypes';

export const initialState = {
    modal: {
        open: false,
        // marked as debatable
        // modal children should not be stored in a redux store, functions in general
    },
    mobileNavMenu: {
        open: false,
    },
};

const slice = createSlice({
    name: 'APP_GLOBAL',
    initialState,
    reducers: {
        showModal: (state, { payload }) => {
            state.modal = {
                ...payload,
                open: true,
            };
        },
        showModalLoading: (state) => {
            state.modal.loading = true;
        },
        hideModal: (state) => {
            state.modal.open = false;
            state.modal.loading = false;
        },
        openPhoneMenu: (state) => {
            state.mobileNavMenu.open = true;
        },
        closePhoneMenu: (state) => {
            state.mobileNavMenu.open = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOCATION_CHANGE, (state) => {
            state.modal = {
                open: false,
            };
        });
    },
});

export const {
    showModal, showModalLoading, hideModal, openPhoneMenu, closePhoneMenu,
} = slice.actions;

export const { reducer } = slice;
