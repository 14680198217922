import Immutable from 'seamless-immutable';
import { handleActions } from 'redux-actions';
import {
    CREATE_USER,
    CREATE_USER_COMPLETED,
    CREATE_USER_FAILED,
    LOAD_USER,
    LOAD_USER_COMPLETED,
    LOAD_USER_FAILED,
    UPDATE_USER,
    UPDATE_USER_COMPLETED,
    UPDATE_USER_FAILED,
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_COMPLETED,
    RESTORE_USER,
    RESTORE_USER_COMPLETED,
    RESTORE_USER_FAILED,
} from './actionTypes';
import { mergeArraysByKey } from '../../utils/data';

const initialState = Immutable.from({
    users: [],
    loading: false,
    deletingInProgress: false,
    updating: false,
});

export default handleActions({
    [CREATE_USER]: state => state.merge({
        loading: true,
    }),
    [CREATE_USER_COMPLETED]: state => state.merge({
        loading: false,
        loadingSuccess: true,
    }),
    [CREATE_USER_FAILED]: (state, { payload: { errorCode } }) => state.merge({
        loading: false,
        loadingError: errorCode,
    }),
    [LOAD_USER]: state => state.merge({
        loading: true,
    }),
    [LOAD_USER_COMPLETED]: (state, { payload: user }) => {
        const users = mergeArraysByKey(state.users, [user]);
        return state.merge({
            users,
            loading: false,

            loadingSuccess: true,
        });
    },
    [LOAD_USER_FAILED]: (state, { payload: error }) => state.merge({
        loading: false,
        loadingError: error,

    }),
    [UPDATE_USER]: state => state.merge({
        updating: true,
    }),
    [UPDATE_USER_COMPLETED]: state => state.merge({
        updating: false,
    }),
    [UPDATE_USER_FAILED]: state => state.merge({
        updating: false,
    }),
    [DELETE_USER]: state => state.merge({
        deletingInProgress: true,
    }),
    [DELETE_USER_COMPLETED]: state =>
        state.merge({
            deletingInProgress: false,
        }),
    [DELETE_USER_FAILED]: state => state.merge({
        deletingInProgress: false,
    }),
    [RESTORE_USER]: state => state.set('updating', true),
    [RESTORE_USER_COMPLETED]: state => state.set('updating', false),
    [RESTORE_USER_FAILED]: state => state.set('updating', false),
}, initialState);
