import React, { Fragment } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ConnectedRouter as Router } from 'react-router-redux';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import Feed from './containers/Feed';
import QuestionThread from './containers/Thread';
import Autoscroll from './components/Autoscroll';
import Login from './components/Login';
import Logout from './components/Logout';
import NoPermissions from './components/NoPermissions/index';
import Profile from './containers/My/Profile';
import Rewards from './containers/My/Rewards';
import UserRewards from './containers/Moderator/Users/UserRewards';

import { Auth0ProtectedRouteHOC } from './components/Router';
import {
    UsersList,
    UserForm,
    QuestionForm,
    QuestionList,
    QuestionDetails,
    GlobalPrograms,
    CreateEmails,
    FeedNpi,
} from './containers/Moderator';
import SummarySubmission from './containers/ConsensusSummarySubmission';
import NotFoundPage from './containers/NotFoundPage';
import { About, Disclaimer as DisclaimerPage, FAQ } from './containers/InfoPages';
import links from './links';
import './styles/App.scss';

import { history } from './store';

import Layout from './components/Layout';
import Search from './containers/Search/index';
import SummaryContainer from './containers/SummaryPreview/container';
import SummaryPreview from './containers/SummaryPreview/index';
import SummaryPreviewAdmin from './containers/SummaryPreview/admin';
import { Permissions } from './permissions';
import LoginMagic from './containers/LoginMagic';
import Toast from './containers/Toast';
import DesignComponents from './ui/containers/designComponents';
import CaseConsensuses from './ui/containers/caseConsensuses';
import Registration from './ui/containers/registration';

const REGISTRATION_ENABLED = process.env.REACT_APP_USER_REGISTRATION === 'enabled';

const App = () => {
    const { isAuthenticated } = useAuth0();
    const authClasses = 'bg-purple-gray-200';
    const unauthClasses = 'bg-unauth bg-cover bg-center bg-no-repeat';
    const classNames = isAuthenticated ? authClasses : unauthClasses;

    return (
        <div className={clsx('min-h-screen', classNames)}>
            <Router history={history}>
                <Fragment>
                    <Switch>
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.admin.registration}
                            component={() => {
                                const additionalPayload = { manual: true };
                                const RegistrationWithLayout = Layout(() => <Registration additionalPayload={additionalPayload} />);
                                return <RegistrationWithLayout useContainerV2 />;
                            }}
                            expectedPermissions={[
                                Permissions.USER_MANAGEMENT,
                            ]}
                        />
                        {REGISTRATION_ENABLED && (<Route
                            exact
                            path={links.registration}
                            component={() => {
                                if (isAuthenticated) {
                                    return <Redirect to={links.topics.myFeed} />;
                                }
                                const RegistrationWithLayout = Layout(Registration);
                                return <RegistrationWithLayout useContainerV2 />;
                            }}
                        />)}
                        <Route
                            exact
                            path={links.login}
                            component={Login}
                        />
                        <Route
                            exact
                            path={links.logout}
                            component={Logout}
                        />
                        <Route
                            exact
                            path={links.noPermissions}
                            component={NoPermissions}
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.redirecting}
                            component={() => <></>}
                            initialAuth0Redirect
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.topics.myFeed}
                            component={Autoscroll(Layout(Feed))}
                            isMyFeed
                            expectedPermissions={[
                                Permissions.PATIENT_CASES_MY_SPECIALTY,
                                Permissions.ACTIVE_PATIENT_CASE_MANAGEMENT,
                            ]}
                        />
                        <Route
                            strict
                            exact
                            path="/login_magic"
                            component={LoginMagic}
                        />
                        <Route
                            strict
                            exact
                            path={links.my.profile}
                            component={Autoscroll(Layout(Profile))}
                        />
                        <Route
                            strict
                            exact
                            path={links.my.rewards}
                            component={Autoscroll(Layout(Rewards))}
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.topics.otherFeed}
                            component={Autoscroll(Layout(Feed))}
                            expectedPermissions={[
                                Permissions.PATIENT_CASES_OTHER_SPECIALTIES,
                            ]}
                        />
                        <Redirect
                            strict
                            exact
                            from={`${links.topics.myFeed}/`}
                            to={links.topics.myFeed}
                        />
                        <Redirect
                            strict
                            exact
                            from={`${links.topics.otherFeed}/`}
                            to={links.topics.otherFeed}
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.topics.view}
                            component={Autoscroll(Layout(QuestionThread))}
                            expectedPermissions={[
                                Permissions.PATIENT_CASES_MY_SPECIALTY,
                                Permissions.PATIENT_CASES_OTHER_SPECIALTIES,
                                Permissions.ACTIVE_PATIENT_CASE_MANAGEMENT,
                                Permissions.ALL_PATIENT_CASES_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.moderator.users.index}
                            component={Autoscroll(Layout(UsersList))}
                            expectedPermissions={[
                                Permissions.USER_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.moderator.search.index}
                            component={Autoscroll(Layout(Search))}
                            expectedPermissions={[
                                Permissions.SEARCH,
                            ]}
                        />
                        <Redirect
                            strict
                            exact
                            from={`${links.moderator.search.index}/`}
                            to={links.moderator.search.index}
                        />
                        <Redirect
                            strict
                            exact
                            from={`${links.moderator.users.index}/`}
                            to={links.moderator.users.index}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.users.new}
                            component={Autoscroll(Layout(UserForm))}
                            expectedPermissions={[
                                Permissions.USER_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.users.edit}
                            component={Autoscroll(Layout(UserForm))}
                            expectedPermissions={[
                                Permissions.USER_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.users.rewards}
                            component={Autoscroll(Layout(UserRewards))}
                            expectedPermissions={[
                                Permissions.USER_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.consensus.all}
                            component={Autoscroll(Layout(CaseConsensuses))}
                            useContainerV2
                            expectedPermissions={[
                                Permissions.USER_CONTENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.consensus.preview}
                            component={Autoscroll(Layout(SummaryContainer(SummaryPreviewAdmin)))}
                            useContainerV2
                            expectedPermissions={[
                                Permissions.SUMMARY_SUBMISSION,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.consensus.view}
                            component={Autoscroll(Layout(SummaryContainer(SummaryPreview)))}
                            useContainerV2
                            expectedPermissions={[
                                Permissions.USER_CONTENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.questions.npis}
                            component={Autoscroll(Layout(FeedNpi))}
                            expectedPermissions={[
                                Permissions.ALL_PATIENT_CASES_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.questions.index}
                            component={Autoscroll(Layout(QuestionList))}
                            expectedPermissions={[
                                Permissions.ALL_PATIENT_CASES_MANAGEMENT,
                            ]}
                        />
                        <Redirect
                            strict
                            exact
                            from={`${links.moderator.questions.index}/`}
                            to={links.moderator.questions.index}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.questions.new}
                            component={Autoscroll(Layout(QuestionForm))}
                            expectedPermissions={[
                                Permissions.ALL_PATIENT_CASES_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.questions.details}
                            component={Autoscroll(Layout(QuestionDetails))}
                            expectedPermissions={[
                                Permissions.ALL_PATIENT_CASES_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.questions.edit}
                            component={Autoscroll(Layout(QuestionForm))}
                            expectedPermissions={[
                                Permissions.ALL_PATIENT_CASES_MANAGEMENT,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.consensus.submit}
                            component={Autoscroll(Layout(SummarySubmission))}
                            useContainerV2
                            expectedPermissions={[
                                Permissions.SUMMARY_SUBMISSION,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.moderator.globalPrograms.index}
                            component={Autoscroll(Layout(GlobalPrograms))}
                            expectedPermissions={[
                                Permissions.PROGRAMS,
                            ]}
                        />
                        <Auth0ProtectedRouteHOC
                            strict
                            exact
                            path={links.moderator.globalPrograms.createEmails}
                            component={Autoscroll(Layout(CreateEmails))}
                            expectedPermissions={[
                                Permissions.PROGRAMS,
                            ]}
                        />
                        <Route exact path={links.infoPages.about} component={Autoscroll(About)} />
                        <Route
                            exact
                            path={links.infoPages.disclaimer}
                            component={Autoscroll(Layout(DisclaimerPage))}
                        />
                        <Route exact path={links.infoPages.faq} component={Autoscroll(FAQ)} />
                        <Auth0ProtectedRouteHOC
                            exact
                            path={links.moderator.design.components}
                            component={Autoscroll(Layout(DesignComponents))}
                            expectedPermissions={[
                                Permissions.USER_MANAGEMENT,
                            ]}
                            useContainerV2
                        />
                        <Redirect
                            exact
                            from="/"
                            to={links.login}
                        />
                        <Route component={Autoscroll(Layout(NotFoundPage))} />
                    </Switch>
                </Fragment>
            </Router>
            <Toast />
        </div>
    );
};

export default App;
