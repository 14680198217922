import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ViewportWidthContext = createContext(window.innerWidth);

export const ViewportWidthProvider = ({ children }) => {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        let timeoutId = null;

        const handleResize = () => {
            if (typeof timeoutId === 'number') clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setViewportWidth(window.innerWidth);
            }, 300);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [setViewportWidth]);

    return (
        <ViewportWidthContext.Provider value={viewportWidth}>
            {children}
        </ViewportWidthContext.Provider>
    );
};

ViewportWidthProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export const useViewportWidth = () =>
    useContext(ViewportWidthContext);
