import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Header, Message, Dropdown, Popup } from 'semantic-ui-react';
import moment from 'moment';
import localization from '../../../../../localization';
import { ExpandableRow, SpecialtyTag } from '../../../../../components';
import { SpecsProps } from '../../../../../utils/propTypes';
import { questionStatus } from '../../../../../data/enums';
import TableEmptyState from '../../../../../components/TableEmptyState';
import './style.scss';

const {
    admin: {
        questions: {
            details: {
                tableHeaders,
                targetAudience: locTargetAudience,
                targetSpecialties,
                userCount: locUserCount,
                emailListTitle,
                newEmail,
                deleteEmail,
                waitCompletion,
            },
            newCampaignForm: {
                createEmailFailed,
                ...strings
            },
            campaignDeleteModal: {
                header,
                content,
            },
            emptyEmailsState,
        },
    },
} = localization;

const {
    expired,
} = questionStatus;

const EmailList = ({
    topicId,
    emails,
    status,
    loading,
    errorCreatingCampaign,
    specialties,
    subspecialties,
    downloadingTargetList,
    campaignIdAction,
    deletingCampaign,
    loadingEmailList,
    // functions
    deleteCampaign,
    downloadTargetList,
    closeEmailError,
    openNewEmailModal,
    showModal,
}) => {
    const downloadButton = (id, targetListCreatedAt) => (
        <Button
            loading={campaignIdAction === id ?
                downloadingTargetList || !targetListCreatedAt : false}
            basic
            disabled={downloadingTargetList || !targetListCreatedAt}
            icon="download"
            className="action-button"
            onClick={() => downloadTargetList({ campaignId: id })}
        />
    );

    const rows = emails.map(({
        id,
        name,
        createdAt,
        segmentSize,
        targetAudience,
        targetListCreatedAt,
    }) => (
        <ExpandableRow
            key={id}
            renderHeader={() => (
                <>
                    <Table.Cell width={2}>{moment(createdAt).format('L')}</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell width={2} textAlign="right">{id}</Table.Cell>
                    <Table.Cell width={2} textAlign="center" className="actions">
                        {
                            loading ? <Button loading basic /> : (
                                <div className="actions">
                                    {
                                        targetListCreatedAt ? downloadButton(id, targetListCreatedAt) : (/* eslint-disable-line max-len */
                                            <Popup
                                                trigger={
                                                    <span>
                                                        {downloadButton(id, targetListCreatedAt) /* eslint-disable-line max-len */}
                                                    </span>
                                                }
                                                content={<div dangerouslySetInnerHTML={{ __html: waitCompletion }} />} // eslint-disable-line react/no-danger, max-len
                                                position="bottom center"
                                                basic
                                                wide
                                                className="tooltip"
                                            />
                                        )
                                    }

                                    <Dropdown
                                        icon={<Button
                                            icon="ellipsis vertical"
                                            className="action-button"
                                        />}
                                        disabled={downloadingTargetList}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                disabled={campaignIdAction === id ?
                                                    deletingCampaign : false}
                                                onClick={() => showModal({
                                                    content,
                                                    header,
                                                    positiveResponseAction: () =>
                                                        deleteCampaign({
                                                            campaignId: id,
                                                            topicId,
                                                        }),
                                                })}
                                                content={deleteEmail}
                                                icon="trash"
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>)
                        }
                    </Table.Cell>
                </>)
            }
            renderBody={() => (
                <Fragment>
                    <Table.Cell />
                    <Table.Cell>
                        <div className="title">{targetSpecialties}</div>
                        <div className="tags">
                            {
                                specialties.map(spec => <SpecialtyTag {...spec} key={spec.id} type="specialty" />)
                            }
                            {
                                subspecialties.map(spec => <SpecialtyTag {...spec} key={spec.id} type="subspecialty" />)
                            }
                        </div>
                        <div className="card-bottom">
                            <div>
                                <div className="title">{locTargetAudience}</div>
                                <div>{strings[targetAudience]}</div>
                            </div>
                            <div>
                                <div className="title">{locUserCount}</div>
                                <div>{segmentSize}</div>
                            </div>
                        </div>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell collapsing />
                </Fragment>)}
        />));
    return (
        <div className="email-list">
            <div className="top-half">
                <Header>{emailListTitle}</Header>
                {typeof status === 'number' && status !== expired &&
                    <Button positive content={newEmail} onClick={() => openNewEmailModal()} />
                }
            </div>
            <Message
                hidden={!errorCreatingCampaign}
                header={createEmailFailed}
                onDismiss={closeEmailError}
                negative
            />
            <Table className={ExpandableRow.tableClassName}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{tableHeaders.createdAt}</Table.HeaderCell>
                        <Table.HeaderCell>{tableHeaders.emailName}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">{tableHeaders.emailId}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{tableHeaders.actions}</Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        rows.length ? rows : <TableEmptyState
                            loading={loadingEmailList}
                            text={emptyEmailsState}
                        />
                    }
                </Table.Body>
            </Table>
        </div>);
};

EmailList.propTypes = {
    ...SpecsProps,
    emails: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        createdAt: PropTypes.string,
    })),
    downloadingTargetList: PropTypes.bool.isRequired,
    campaignIdAction: PropTypes.number,
    openNewEmailModal: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errorCreatingCampaign: PropTypes.bool.isRequired,
    closeEmailError: PropTypes.func.isRequired,
    downloadTargetList: PropTypes.func.isRequired,
    deletingCampaign: PropTypes.bool.isRequired,
    topicId: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
    loadingEmailList: PropTypes.bool.isRequired,
    status: PropTypes.number,
};

EmailList.defaultProps = {
    loading: false,
    campaignIdAction: null,
    status: null,
    emails: [],
};

export default EmailList;
