import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { RadioGroup } from '../../../../components';

const AppHcUserLookup = ({ appHcUserIds }) => {
    if (!appHcUserIds.length) return null;

    const message = appHcUserIds.length > 1 ? `There are ${appHcUserIds.length} accounts in app.hc associated with this email or NPI.` : 'There is 1 account in app.hc associated with this email or NPI.';
    const subMessage = appHcUserIds.length > 1 ? 'Would you like to connect one of these accounts to the new user?' : 'Would you like to connect this account to the new user?';

    const options = appHcUserIds.map(appHcUserId => ({
        value: appHcUserId,
        label: `Connect to user ID ${appHcUserId}`,
    })).concat({
        value: 0,
        label: 'Do not connect',
    });

    return (
        <div className="flex flex-row p-4 items-center gap-4 self-stretch rounded-lg border border-solid border-[#CECCD6] bg-[#fff]">
            <div className="flex self-stretch p-1">
                <ExclamationTriangleIcon aria-hidden={true} alt="" width="20" height="20" style={{ color: '#BE4335' }} />
            </div>
            <div className="flex flex-col items-start grow">
                <div>
                    <h3 className="font-body text-lg font-normal leading-7 tracking-[-0.09px] m-0">
                        {message}
                    </h3>
                    <p className="font-body text-sm font-normal leading-5">
                        {subMessage}
                    </p>
                </div>
                <Field
                    className="w-full"
                    component={Form.Field}
                    control={RadioGroup}
                    name="appHcUserId"
                    options={options}
                />
            </div>
        </div>
    );
};

AppHcUserLookup.propTypes = {
    appHcUserIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

AppHcUserLookup.defaultProps = {};

export default AppHcUserLookup;
