export const ID = 'PROGRAMS';

export const GET_PROGRAM_FOR_USER = `${ID}/GET_PROGRAMS_FOR_USER`;
export const GET_PROGRAM_FOR_USER_COMPLETED = `${ID}/GET_PROGRAMS_FOR_USER_COMPLETED`;
export const GET_PROGRAM_FOR_USER_FAILED = `${ID}/GET_PROGRAMS_FOR_USER_FAILED`;

export const GET_DTI_LINK = `${ID}/GET_DTI_LINK`;
export const GET_DTI_LINK_COMPLETED = `${ID}/GET_DTI_LINK_COMPLETED`;
export const GET_DTI_LINK_FAILED = `${ID}/GET_DTI_LINK_FAILED`;

export const VALIDATE_DTI_CACHE_TIMEOUT = `${ID}/VALIDATE_DTI_CACHE_TIMEOUT`;
export const VALIDATE_DTI_CACHE_TIMEOUT_COMPLETED = `${ID}/VALIDATE_DTI_CACHE_TIMEOUT_COMPLETED`;
export const VALIDATE_DTI_CACHE_TIMEOUT_FAILED = `${ID}/VALIDATE_DTI_CACHE_TIMEOUT_FAILED`;
