import React from 'react';
import PropTypes from 'prop-types';

const variants = {
    filled: 'text-white border-none bg-purple-500 hover:bg-purple-600 focus:bg-purple-600 active:bg-purple-700 disabled:bg-gray-300 disabled:text-gray-600',
    outlined: 'bg-white border border-purple-500 border-solid text-purple-500 hover:border-purple-600 hover:text-purple-600 active:border active:bg-purple-100 active:border-purple-700 active:text-purple-700 disabled:text-gray-400 disabled:border-gray-400',
    text: 'text-purple-500 border-none bg-white hover:text-purple-600 focus:text-purple-600 active:text-purple-700 active:bg-purple-100 disabled:text-gray-400',
    icon: 'bg-white border border-purple-500 border-solid text-purple-500 hover:border-purple-600 hover:text-purple-600 active:border active:bg-purple-100 active:border-purple-700 active:text-purple-700 disabled:text-gray-400 disabled:border-gray-400',
    warning: 'text-white border-none bg-red-600 hover:bg-red-700 focus:bg-red-700 active:bg-red-800 disabled:bg-gray-300 disabled:text-gray-400',
};

const Button = ({
    children,
    className,
    variant,
    disabled,
    icon,
    ...buttonProps
}) => (
    <button
        className={`inline-flex items-center transition ease-in-out gap-2 px-4 py-2 rounded-full body-s focus:ring-4 focus:ring-blue-200 cursor-pointer disabled:cursor-not-allowed ${variants[variant]} ${className}`}
        disabled={disabled}
        {...buttonProps}
    >
        {icon}
        {variant === 'icon' ? null : children}
    </button>
);

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['filled', 'outlined', 'text', 'icon', 'warning']),
    disabled: PropTypes.bool,
    icon: PropTypes.element,
};

Button.defaultProps = {
    children: null,
    className: '',
    variant: 'filled',
    disabled: false,
    icon: null,
};

export default Button;
