import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AD_BUTLER_ACCOUNT_ID = process.env.REACT_APP_AD_BUTLER;

const AdButlerAd = ({
    zone, width, height, npi, keywords, resourceId,
}) => {
    const adDivRef = useRef(null);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        // Just a sanity-check; this should also prevent non-HCP users from seeing an ad
        const validNPI = String(npi).length === 10 ? String(npi) : null;
        if (!validNPI) {
            return () => null;
        }
        window.AdButler ||= {};
        const { AdButler } = window;
        AdButler.ads = AdButler.ads || [];
        AdButler.ads.push({
            handler(opt) {
                AdButler.register(AD_BUTLER_ACCOUNT_ID, zone, [width, height], `placement_${zone}_0`, opt);
            },
            opt: {
                place: 0,
                keywords,
                eventLogData: { npi: validNPI },
                dataKeys: { npi: validNPI, consensuses_id: resourceId },
                domain: 'servedbyadbutler.com',
                click: 'CLICK_MACRO_PLACEHOLDER',
            },
        });

        const resizeObserver = new window.ResizeObserver((entries) => {
            const rect = entries[0].contentRect;
            setShowText(rect.width > 0 && rect.height > 0);
        });
        if (adDivRef.current) {
            resizeObserver.observe(adDivRef.current);
        }
        // A previous implmentation had this in the cleanup function:
        //     if (adDivRef.current) resizeObserver.unobserve(adDivRef.current);
        // However, es-lint raised this warning:
        // > The ref value 'adDivRef.current' will likely have changed by the time this effect cleanup function runs.
        // > If this ref points to a node rendered by React, copy 'adDivRef.current' to a variable inside the effect,
        // > and use that variable in the cleanup function
        const currentAdDivRef = adDivRef.current;
        return () => {
            if (currentAdDivRef) {
                resizeObserver.unobserve(currentAdDivRef);
            }
        };
    }, [AD_BUTLER_ACCOUNT_ID, zone, width, height, npi, setShowText]);
    return (
        <div className="flex flex-col">
            <div id={`placement_${zone}_0`} ref={adDivRef} />
            {showText && <div className="self-center body-s">Advertisement</div>}
        </div>
    );
};

AdButlerAd.propTypes = {
    zone: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    npi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resourceId: PropTypes.string,
    keywords: PropTypes.string,
};

// default npi to null so that we can check for it in the useEffect
// not all users have an NPI number and we don't want to show ads to them
AdButlerAd.defaultProps = {
    resourceId: null,
    npi: null,
    keywords: '',
};

const mapStateToProps = state => ({
    npi: state.SESSION.npi,
});

export default connect(mapStateToProps)(AdButlerAd);
