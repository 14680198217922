import { createAction } from 'redux-actions';

import {
    LOAD_CURRENT_REWARDS,
    LOAD_CURRENT_REWARDS_COMPLETED,
    LOAD_CURRENT_REWARDS_FAILED,
    LOAD_HISTORY_REWARDS,
    LOAD_HISTORY_REWARDS_COMPLETED,
    LOAD_HISTORY_REWARDS_FAILED,
} from './actionTypes';

export const loadCurrentRewards = createAction(LOAD_CURRENT_REWARDS);
export const loadCurrentRewardsCompleted = createAction(LOAD_CURRENT_REWARDS_COMPLETED);
export const loadCurrentRewardsFailed = createAction(LOAD_CURRENT_REWARDS_FAILED);

export const loadHistoryRewards = createAction(LOAD_HISTORY_REWARDS);
export const loadHistoryRewardsCompleted = createAction(LOAD_HISTORY_REWARDS_COMPLETED);
export const loadHistoryRewardsFailed = createAction(LOAD_HISTORY_REWARDS_FAILED);
