import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';

const CheckboxGroup = ({ options, input }) => (
    <>
        {options.map(option => (
            <div key={option.value}>
                <input
                    type="checkbox"
                    value={option.value}
                    checked={input.value.includes(option.value) || option.preSelected}
                    disabled={option.preSelected}
                    onChange={(event) => {
                        const newValue = event.target.checked
                            ? [...input.value, option.value]
                            : input.value.filter(v => v !== option.value);

                        return input.onChange(newValue);
                    }}
                />
                <label>{option.label}</label>
            </div>
        ))}
    </>
);

CheckboxGroup.propTypes = {
    ...fieldPropTypes,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        preSelected: PropTypes.bool,
    })).isRequired,
};

export default CheckboxGroup;
