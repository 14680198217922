import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Icon from '../Navigation/menu.svg';

import './style.scss';

import links from '../../../links';
import localization from '../../../localization';

const {
    navigationLinks,
} = localization;

const {
    moderator: {
        globalPrograms: {
            index,
            createEmails,
        },
    },
} = links;

const Navigation = ({ active }) => (
    <div className="my-navigation">
        <div className="tab-navigation">
            <Link to={index} href={index} className={(active === 'index') ? 'active' : ''}>
                {navigationLinks.programOverview}
            </Link>
            <Link to={createEmails} href={createEmails} className={(active === 'create_emails') ? 'active' : ''}>
                {navigationLinks.createEmails}
            </Link>
        </div>

        <Dropdown item icon={<Icon />} className="mobile-navigation">
            <Dropdown.Menu>
                <Dropdown.Item
                    as={Link}
                    to={index}
                    active={active === 'index'}
                    text={navigationLinks.programOverview}
                />
                <Dropdown.Item
                    as={Link}
                    to={createEmails}
                    active={active === 'emails'}
                    text={navigationLinks.createEmails}
                />
            </Dropdown.Menu>
        </Dropdown>
    </div>
);

Navigation.propTypes = {
    active: PropTypes.string,
};

Navigation.defaultProps = {
    active: '',
};

export default Navigation;
