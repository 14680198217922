import client from '../../api/apiSaga';
import { tooltips } from '../../api/endpoints';

const tpEndpoints = tooltips;

const disclaimerEndpoint = 'disclaimer';

const ctaPopupEndpoint = `${disclaimerEndpoint}/disable_cta_popup`;

export const getDisclaimerState = () => client.get(disclaimerEndpoint);

export const markDisclaimerAsRead = () => client.post(disclaimerEndpoint);

export const setCtaPopupAsDisabled = () => client.post(ctaPopupEndpoint);

export const allowTooltips = () => client.post(tpEndpoints.allow, {});

export const disableTooltips = () => client.post(tpEndpoints.disable, {});
