import { put, all, takeEvery } from 'redux-saga/effects';
import { SET_SHOW_TOOLTIPS } from './actionTypes';
import { allowTooltips, disableTooltips } from '../../../services/session/actions';

function* setShowTooltipsSaga({ payload: { value } }) {
    if (value) {
        yield put(disableTooltips());
    } else {
        yield put(allowTooltips());
    }
}

export default function* () {
    yield all([
        takeEvery(SET_SHOW_TOOLTIPS, setShowTooltipsSaga),
    ]);
}
