export const ID = 'ANSWERS';

export const UPDATE_ANSWER = `${ID}/UPDATE_ANSWER`;

export const UPDATE_ANSWER_COMPLETED = `${ID}/UPDATE_ANSWER_COMPLETED`;

export const UPDATE_ANSWER_FAILED = `${ID}/UPDATE_ANSWER_FAILED`;

export const LOAD_ANSWERS = `${ID}/LOAD_ANSWERS`;

export const LOAD_ANSWERS_COMPLETED = `${ID}/LOAD_ANSWERS_COMPLETED`;

export const LOAD_ANSWERS_FAILED = `${ID}/LOAD_ANSWERS_FAILED`;


export const DELETE_ANSWER = `${ID}/DELETE_ANSWER`;

export const DELETE_ANSWER_COMPLETED = `${ID}/DELETE_ANSWER_COMPLETED`;

export const DELETE_ANSWER_FAILED = `${ID}/DELETE_ANSWER_FAILED`;

export const RATE_ANSWER = `${ID}/RATE_ANSWER`;

export const RATE_ANSWER_COMPLETED = `${ID}/RATE_ANSWER_COMPLETED`;

export const RATE_ANSWER_FAILED = `${ID}/RATE_ANSWER_FAILED`;


export const SHOW_ANSWERS = `${ID}/SHOW_ANSWERS`;

export const SHOW_ANSWERS_COMPLETED = `${ID}/SHOW_ANSWERS_COMPLETED`;

export const SHOW_ANSWERS_FAILED = `${ID}/SHOW_ANSWERS_FAILED`;
