
import { createSelector } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { compose } from 'redux';
import { buildTree } from '../../utils/data';
import { ID as DataID } from '../../data';
import { userIdSelector } from '../../services/session/selectors';
import { ID as AnswersID } from '../../data/answers';
import { correctedCommentRating } from '../../utils/function';

export const defaultSortDirection = 'rating';

// store selectors
const allAnswersSelector = ({ [DataID]: { [AnswersID]: { answers } } }) => answers;
const parameterSelector = (state, parameter) => parameter;

// normal functions
const markOwnAnswer = userId => ans => ({
    ...ans,
    ownAnswer: userId === ans.createdByUser.id,
});

// Removes deleted answers without comments
const removeDeletedAndEmptyAnswers = ({
    deletedAt,
    comments = [],
}) => !(deletedAt && comments.length === 0);

const calculateAdjustedRating = ({
    rating: { 1: up = 0, '-1': down = 0 } = {},
    ...rest
}) => ({
    rating: { 1: up, '-1': down },
    adjustedRating: correctedCommentRating(up, up + down),
    ...rest,
});

const findOwnAnswer = answers => answers.find(({
    deletedAt,
    ownAnswer,
    replyTo,
}) => (!replyTo && ownAnswer && !deletedAt));

export const hasOwnAnswer = createSelector(
    [allAnswersSelector, userIdSelector, parameterSelector],
    (answers, userId, selectedTopicId) => answers.find(({
        createdByUser,
        topicId,
        replyTo,
        deletedAt,
    }) => (
        topicId === selectedTopicId &&
        !replyTo &&
        createdByUser &&
        createdByUser.id === userId &&
        !deletedAt)) !== undefined,
);

// parameterized selectors
const answersPerTopicCache = {};
const topicAnswersSelector = (topId) => {
    if (answersPerTopicCache[topId]) {
        return answersPerTopicCache[topId];
    }
    const topicSelector = createSelector(
        [allAnswersSelector, userIdSelector],
        (answers, userId) =>
            answers
                .filter(({ topicId: _topicId }) => topId === _topicId)
                .map(calculateAdjustedRating)
                .map(markOwnAnswer(userId)),
    );
    answersPerTopicCache[topId] = topicSelector;
    return topicSelector;
};

export const ownAnswerWithComments = topicId => createSelector(
    [topicAnswersSelector(topicId)],
    (answers, userId) => {
        const answer = findOwnAnswer(answers, userId);
        const ownAnswer = answer ? { ...answer, ownAnswer: true } : answer;

        if (ownAnswer) {
            const { id } = ownAnswer;
            const comments = answers.filter(({ replyTo }) => id === replyTo);
            return {
                ...ownAnswer,
                comments,
            };
        }
        return ownAnswer;
    },
);
const answersTreeCache = {};

export const topicCommentsTree = (topicId, filter) => {
    const key = `${topicId} - ${filter}`;
    const organizeComments = compose(
        answers => answers.filter(removeDeletedAndEmptyAnswers),
        (answers) => {
            const tree = buildTree(answers.asMutable(), 'id', 'replyTo', 'comments');
            const treeWithSortedComments = tree.map(({ comments, ...other }) =>
                ({ ...other, comments: orderBy(comments, ['createdAt'], ['desc']) }));
            return treeWithSortedComments;
        },
        (answers) => {
            const { id } = findOwnAnswer(answers) || {};
            if (id) {
                return answers.filter(({
                    id: ansId,
                    replyTo,
                }) => (id !== ansId && id !== replyTo));
            }
            return answers;
        },
    );
    if (!answersTreeCache[key]) {
        const topicAnswersTreeSelector = filter === defaultSortDirection ?
            (...args) => orderBy(organizeComments(...args), ['adjustedRating', 'createdAt'], ['desc', 'desc']) :
            (...args) => orderBy(organizeComments(...args), ['createdAt'], ['desc']);
        answersTreeCache[key] = createSelector(
            [topicAnswersSelector(topicId)],
            topicAnswersTreeSelector,
        );
        return answersTreeCache[key];
    }
    return answersTreeCache[key];
};
