import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form, Modal, Button } from 'semantic-ui-react';
import { TextInput } from '../../../../../components';
import './style.scss';
import localization from '../../../../../localization';

const {
    common: {
        cancel,
    },
    admin: {
        globalHcmpList: {
            programs: {
                createEmails: {
                    newNpiCampaignForm: {
                        title,
                        emailName,
                        selectTemplatePlaceholder,
                        createEmail,
                    },
                },
            },
        },
    },
} = localization;

const NewNPIEmailComponent = ({
    open,
    handleSubmit,
    creatingCampaign,
    closeNewNPIEmailModal,
}) => (
    <Modal open={open} size="tiny" className="new-npi-email">
        <Modal.Content>
            <div className="title">{title}</div>
            <Form
                loading={creatingCampaign}
            >
                <div className="form-row align">
                    <div className="field-label">
                        {emailName}
                    </div>
                    <Field
                        component={Form.Field}
                        control={TextInput}
                        name="name"
                        placeholder={selectTemplatePlaceholder}
                        width={8}
                    />
                </div>
            </Form>
        </Modal.Content>
        <Modal.Actions className="actions">
            <Button
                content={cancel}
                type="button"
                onClick={() => closeNewNPIEmailModal()}
            />
            <Button
                content={createEmail}
                disabled={creatingCampaign}
                positive
                onClick={() => handleSubmit()}
            />
        </Modal.Actions>
    </Modal>
);

NewNPIEmailComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    closeNewNPIEmailModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    creatingCampaign: PropTypes.bool.isRequired,
};


export default NewNPIEmailComponent;
