import React from 'react';
import { Field } from 'redux-form';
import { Form, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import TextArea from '../../../../components/TextArea';
import localization from '../../../../localization';

const {
    common: {
        save,
    },
    admin: {
        questions: {
            npis: {
                heading,
                description,
            },
        },
    },
} = localization;

const FeedNpi = ({
    errors,
    loading,
    updating,
    handleSubmit,
}) => {
    const renderErrors = () => {
        if (errors === null) return null;

        return errors.map(({ id, content }) => (
            <Message
                key={id}
                warning
                visible
                content={content}
            />
        ));
    };

    return (
        <div className="page-container">
            <h1>{heading}</h1>
            <p>{description}</p>
            <Form loading={loading || updating}>
                {renderErrors()}
                <Field
                    component={TextArea}
                    name="questionsNpisText"
                    style={{ minHeight: '425px' }}
                />
                <Form.Button
                    positive
                    icon
                    onClick={handleSubmit}
                >{save}
                </Form.Button>
            </Form>
        </div>
    );
};

FeedNpi.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        content: PropTypes.string.isRequired,
    })),
};

FeedNpi.defaultProps = {
    errors: [],
};

export default FeedNpi;
