import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Search } from 'semantic-ui-react';
import FieldError from '../FieldError';

class Autocomplete extends Component {
    state = {
        searchText: this.props.input.value ? `${this.props.input.value.title}, ${this.props.input.value.description}` : '',
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps({ input: { value } }) {
        if (value && value !== this.props.input.value) {
            this.setState({
                searchText: `${value.title}, ${value.description}`,
            });
        }
    }

    handleSearchChange = (_, { value }) => {
        const {
            onSearch,
            minCharacters,
        } = this.props;

        if (value.length === 0) {
            const { input: { onChange, value: formValue } } = this.props;
            if (formValue) {
                this.setState({
                    searchText: '',
                }, () => onChange(null));
            } else {
                this.setState({
                    searchText: '',
                });
            }
        } else if (value.length >= minCharacters &&
            (value.trim() !== this.state.searchText.trim())
        ) {
            this.setState({
                searchText: value,
            }, () => onSearch(value));
        } else {
            this.setState({
                searchText: value,
            });
        }
    }

    handleResultSelect = (e, { result }) => {
        const { input: { onChange } } = this.props;
        this.setState({
            searchText: `${result.title}, ${result.description}`,
        }, () => onChange(result));
    }

    render() {
        const {
            meta: {
                error,
                touched,
                warning,
                pristine,
            },
            input: {
                onBlur,
                onFocus,
                value: formValue,
            },
            minCharacters,
            disabled,
            loading,
            results = [],
        } = this.props;

        const {
            searchText,
        } = this.state;

        let infoArea = null;
        infoArea = <FieldError error={error} touched={touched} />;
        if (!infoArea && warning && !pristine) {
            infoArea = (<div className="form-warning" >{warning}</div>);
        }

        return (
            <Fragment>
                <Search
                    loading={loading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={this.handleSearchChange}
                    value={searchText}
                    results={results}
                    onBlur={() => onBlur(formValue)}
                    onFocus={onFocus}
                    disabled={disabled}
                    minCharacters={minCharacters}
                />
                {infoArea}
            </Fragment>);
    }
}

Autocomplete.propTypes = {
    ...fieldPropTypes,
    onSearch: propTypes.func.isRequired,
    disabled: propTypes.bool,
    minCharacters: propTypes.number,
    loading: propTypes.bool,
};

Autocomplete.defaultProps = {
    disabled: false,
    minCharacters: 3,
    loading: false,
};

export default Autocomplete;
