import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './style.scss';

const QuestionEmptyState = ({
    content,
    link,
    buttonContent,
}) => (
    <div className="page-container no-questions">
        <img src="/images/no_active_questions.png" className="no_questions_image" alt="no questions" />
        <div className="message">{content}</div>
        {
            link && buttonContent && <Button
                as={Link}
                to={link}
                href={link}
                content={buttonContent}
                primary
            />
        }
    </div>
);

QuestionEmptyState.propTypes = {
    content: PropTypes.string.isRequired,
    link: PropTypes.string,
    buttonContent: PropTypes.string,
};

QuestionEmptyState.defaultProps = {
    buttonContent: '',
    link: '',
};

export default QuestionEmptyState;
