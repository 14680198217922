import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Events, scrollSpy } from 'react-scroll';
import { ID as SessionID } from '../../../services/session';
import Honoraria from './honoraria.png';
import './style.scss';

class FAQ extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
    }

    componentDidMount = () => {
        Events.scrollEvent.register('begin');
        Events.scrollEvent.register('end');
        scrollSpy.update();
    }

    componentWillUnmount = () => {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div className="page-container faq">
                <div className="faq-links-fixed">
                    <div className="faq-links">
                        <div className="faq-links-side" />
                        <div className="faq-links-content">
                            <div className="faq-links-ribbon">
                                <div className="ribbon-title">
                                    <h2>HEALTHCASTS FAQ</h2>
                                </div>
                                <div className="ribbon-triangle" />
                            </div>
                            <Link activeClass="active" to="WHAT-IS-HEALTHCASTS" spy={true} smooth={true} duration={500}>
                                <h3>WHAT IS HEALTHCASTS?</h3>
                            </Link>
                            <Link activeClass="active" to="WHO-CAN-JOIN-HEALTHCASTS?" spy={true} smooth={true} duration={500}>
                                <h4>WHO CAN JOIN HEALTHCASTS?</h4>
                            </Link>
                            <Link activeClass="active" to="IS-THERE-A-COST-FOR-HEALTHCASTS-MEMBERSHIP?" spy={true} smooth={true} duration={500}>
                                <h4>IS THERE A COST FOR HEALTHCASTS MEMBERSHIP?</h4>
                            </Link>
                            <Link activeClass="active" to="WHAT-CAN-I-DO-ON-HEALTHCASTS?" spy={true} smooth={true} duration={500}>
                                <h4>WHAT CAN I DO ON HEALTHCASTS?</h4>
                            </Link>
                            <Link activeClass="active" to="CAN-I-LOG-ONTO-HEALTHCASTS-DIRECTLY?" spy={true} smooth={true} duration={500}>
                                <h4>CAN I LOG ONTO HEALTHCASTS DIRECTLY?</h4>
                            </Link>
                            <Link activeClass="active" to="CAN-I-CHANGE-MY-EMAIL-ADDRESS-OR-SPECIALTY?" spy={true} smooth={true} duration={500}>
                                <h4>CAN I CHANGE MY EMAIL ADDRESS OR SPECIALTY?</h4>
                            </Link>
                            <Link activeClass="active" to="WHAT-IF-I-DON'T-RECEIVE-EMAILS-FROM-HEALTHCASTS?" spy={true} smooth={true} duration={500}>
                                <h4>WHAT IF I DON&apos;T RECEIVE EMAILS FROM HEALTHCASTS?</h4>
                            </Link>
                            <Link activeClass="active" to="HOW-DO-I-REFER-A-COLLEAGUE?" spy={true} smooth={true} duration={500}>
                                <h4>HOW DO I REFER A COLLEAGUE?</h4>
                            </Link>
                            <Link activeClass="active" to="PATIENT-CASES-AND-CONSULTS" spy={true} smooth={true} duration={500}>
                                <h3>PATIENT CASES AND CONSULTS</h3>
                            </Link>
                            <Link activeClass="active" to="HOW-OFTEN-ARE-NEW-PATIENT-CASE-QUESTIONS-POSTED?" spy={true} smooth={true} duration={500}>
                                <h4>HOW OFTEN ARE NEW PATIENT CASE QUESTIONS POSTED?</h4>
                            </Link>
                            <Link activeClass="active" to="CAN-I-SUBMIT-A-PATIENT-CASE?" spy={true} smooth={true} duration={500}>
                                <h4>CAN I SUBMIT A PATIENT CASE?</h4>
                            </Link>
                            <Link activeClass="active" to="ARE-MY-ANSWERS-PUBLIC?" spy={true} smooth={true} duration={500}>
                                <h4>ARE MY ANSWERS PUBLIC?</h4>
                            </Link>
                            <Link activeClass="active" to="CAN-SOMEONE-SEE-IF-I-AGREE-OR-DISAGREE-WITH-THEIR-RESPONSE?" spy={true} smooth={true} duration={500}>
                                <h4>CAN SOMEONE SEE IF I &quot;AGREE&quot; OR &quot;DISAGREE&quot; WITH THEIR RESPONSE?</h4>
                            </Link>
                            {this.props.isAuthenticated ? (
                                <div>
                                    <Link activeClass="active" to="REWARDS-AND-HONORARIA" spy={true} smooth={true} duration={500}>
                                        <h3>REWARDS AND HONORARIA</h3>
                                    </Link>
                                    <Link activeClass="active" to="HOW-DO-I-EARN-AN-HONORARIUM?" spy={true} smooth={true} duration={500}>
                                        <h4>HOW DO I EARN AN HONORARIUM?</h4>
                                    </Link>
                                    <Link activeClass="active" to="HOW-DO-I-KNOW-WHEN-I-EARNED-AN-HONORARIUM?" spy={true} smooth={true} duration={500}>
                                        <h4>HOW DO I KNOW WHEN I EARNED AN HONORARIUM?</h4>
                                    </Link>
                                    <Link activeClass="active" to="HOW-LONG-IS-AN-ELIGIBILITY-PERIOD?" spy={true} smooth={true} duration={500}>
                                        <h4>HOW LONG IS AN ELIGIBILITY PERIOD?</h4>
                                    </Link>
                                    <Link activeClass="active" to="HOW-MANY-HONORARIA-CAN-I-EARN-PER-ELIGIBLITY-PERIOD?" spy={true} smooth={true} duration={500}>
                                        <h4>HOW MANY HONORARIA CAN I EARN PER ELIGIBLITY PERIOD?</h4>
                                    </Link>
                                    <Link activeClass="active" to="IS-THERE-ANY-OTHER-WAY-TO-EARN-HONORARIA?" spy={true} smooth={true} duration={500}>
                                        <h4>IS THERE ANY OTHER WAY TO EARN HONORARIA?</h4>
                                    </Link>
                                    <Link activeClass="active" to="HOW-AND-WHEN-WILL-I-GET-MY-HONORARIUM?" spy={true} smooth={true} duration={500}>
                                        <h4>HOW AND WHEN WILL I GET MY HONORARIUM?</h4>
                                    </Link>
                                    <Link activeClass="active" to="CAN-I-GET-MY-HONORARIUM-WITHOUT-LINKING-MY-AMAZON-ACCOUNT?" spy={true} smooth={true} duration={500}>
                                        <h4>CAN I GET MY HONORARIUM WITHOUT LINKING MY AMAZON ACCOUNT?</h4>
                                    </Link>
                                    <Link activeClass="active" to="CAN-I-REQUEST-MY-HONORARIUM-IN-ANOTHER-WAY?" spy={true} smooth={true} duration={500}>
                                        <h4>CAN I REQUEST MY HONORARIUM IN ANOTHER WAY (E.G., GIFT CARDS OR CASH)?</h4>
                                    </Link>
                                    <Link activeClass="active" to="WHAT-DO-I-DO-WITH-MY-AMAZON-GIFT-CARD-CODES?" spy={true} smooth={true} duration={500}>
                                        <h4>WHAT DO I DO WITH MY AMAZON GIFT CARD CODES?</h4>
                                    </Link>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>
                <div className="faq-contents">
                    <div className="what-is-healthcasts">
                        <div name="WHAT-IS-HEALTHCASTS" className="element faq-section">
                            <h4>WHAT IS HEALTHCASTS</h4>
                            <p>Healthcasts is a peer-to-peer knowledge sharing network that helps healthcare professionals improve clinical decisions and patient outcomes.</p>
                        </div>
                        <div name="WHO-CAN-JOIN-HEALTHCASTS?" className="element faq-section">
                            <h4>WHO CAN JOIN HEALTHCASTS?</h4>
                            <p>All Healthcasts members are verified healthcare professionals practicing in the U.S.</p>
                        </div>
                        <div name="IS-THERE-A-COST-FOR-HEALTHCASTS-MEMBERSHIP?" className="element faq-section">
                            <h4>IS THERE A COST FOR HEALTHCASTS MEMBERSHIP?</h4>
                            <p>No. Healthcasts is a free service dedicated to improving diagnostic outcomes. From time to time you may be asked to view relevant clinical content provided by our sponsors for your educational benefit.</p>
                        </div>
                        <div name="WHAT-CAN-I-DO-ON-HEALTHCASTS?" className="element faq-section">
                            <h4>WHAT CAN I DO ON HEALTHCASTS?</h4>
                            <p><strong>My Specialty</strong> shows you all the patient cases currently posted in your specialty of record. Review and share responses (Consults) with your peers.</p>
                            <p><strong>Other Specialties</strong> contains patient cases related to topics outside your specialty of record.</p>
                            <p><strong>Diseases and Treatment</strong> showcases the latest clinical and educational programs relevant to your specialty or interests. This includes exclusive members-only content such as <strong>Community Consensus Reports</strong> summarizing responses to recent cases posted on the platform, <strong>Experts On Call</strong> interviews and video clips featuring updates contributed by your colleagues about clinical breakthroughs impacting their practice, and more. From this area you can also <strong>submit your patient case</strong> for the Healthcasts team to review.</p>
                        </div>
                        <div name="CAN-I-LOG-ONTO-HEALTHCASTS-DIRECTLY?" className="element faq-section">
                            <h4>CAN I LOG ONTO HEALTHCASTS DIRECTLY?</h4>
                            <p>For your convenience we program our emails to automatically log you onto the platform. You may also access the platform at any time by <a href="/login">visiting this page</a> to request a magic link for one-time access or set a password to use with your email address on record.</p>
                        </div>
                        <div name="CAN-I-CHANGE-MY-EMAIL-ADDRESS-OR-SPECIALTY?" className="element faq-section">
                            <h4>CAN I CHANGE MY EMAIL ADDRESS OR SPECIALTY?</h4>
                            <p>It is very important to keep your email address current. Please contact <a href="https://marketing.healthcasts.co/acton/media/33748/member-services-inquiries" target="_blank" rel="noopener noreferrer">Member Services</a> to request any changes to your account.</p>
                        </div>
                        <div name="WHAT-IF-I-DON'T-RECEIVE-EMAILS-FROM-HEALTHCASTS?" className="element faq-section">
                            <h4>WHAT IF I DON&apos;T RECEIVE EMAILS FROM HEALTHCASTS?</h4>
                            <p>We are aware that email service providers may filter our communications. There are a few steps you can take to help improve email deliverability by teaching your email provider’s algorithm that these are messages you do want to receive:</p>
                            <ul>
                                <li>Please check your <strong>junk/spam</strong> folder regularly. If you’re missing an email from us, it’s usually found there.</li>
                                <li><strong>IMPORTANT:</strong> Mark each of our communications you find in spam/junk as <strong>Not Junk</strong> to <strong>restore</strong> them to your inbox.</li>
                                <li>Designate our emails as Priority, if that option is available from your email provider.</li>
                                <li>Add <a href="mailto:memberservices@healthcasts.co">memberservices@healthcasts.co</a> to your address book/safe senders list.</li>
                                <li>If you are using your work email address and have access to your <strong>quarantined</strong> messages, please be sure to <strong>Release</strong> or <strong>Permit</strong> our emails. You may need to speak with your IT department for assistance, and to be sure that <strong>Healthcasts is whitelisted</strong> for your organization. This helps all of your colleagues to receive our messages as well.</li>
                            </ul>
                            <p>Unsubscribing from or logging a spam complaint about ANY of our communications will unsubscribe you from ALL of them, including any honorarium notifications. If you think you unsubscribed or would like to opt back in, contact <a href="https://marketing.healthcasts.co/acton/media/33748/member-services-inquiries" target="_blank" rel="noopener noreferrer">Member Services</a> for assistance.</p>
                        </div>
                        <div name="HOW-DO-I-REFER-A-COLLEAGUE?" className="element faq-section">
                            <h4>HOW DO I REFER A COLLEAGUE?</h4>
                            <p>Give them our web address: <a href="https://hcp.healthcasts.com/" target="_blank" rel="noopener noreferrer">hcp.healthcasts.com</a>, and ask them to include your name as a reference when they fill out the registration form.</p>
                        </div>
                    </div>
                    <div className="patient-cases-and-consults">
                        <div name="PATIENT-CASES-AND-CONSULTS" className="element">
                            <h2>PATIENT CASES AND CONSULTS</h2>
                        </div>
                        <div name="HOW-OFTEN-ARE-NEW-PATIENT-CASE-QUESTIONS-POSTED?" className="element faq-section">
                            <h4>HOW OFTEN ARE NEW PATIENT CASE QUESTIONS POSTED?</h4>
                            <p>On average, 10 to 15 new patient cases are posted per week, or at least one per week in most specialties. Cases can be posted throughout the week, so we encourage you to check back regularly on both <strong>My Specialty</strong> and <strong>Other Specialties</strong> tabs.</p>
                        </div>
                        <div name="CAN-I-SUBMIT-A-PATIENT-CASE?" className="element faq-section">
                            <h4>CAN I SUBMIT A PATIENT CASE?</h4>
                            <p>Yes. Healthcasts members may submit their own original patient case questions. Please login to view our <a href="https://app.healthcasts.com/member/index?request_tab=submit_patient_case" target="_blank" rel="noopener noreferrer">guidelines</a>.</p>
                        </div>
                        <div name="ARE-MY-ANSWERS-PUBLIC?" className="element faq-section">
                            <h4>ARE MY ANSWERS PUBLIC?</h4>
                            <p>Yes. All Consults submitted on Healthcasts can be viewed by other logged-in members using the portal. Logged-out members, non-members and the general public, are not able to access this information.</p>
                        </div>
                        <div name="CAN-SOMEONE-SEE-IF-I-AGREE-OR-DISAGREE-WITH-THEIR-RESPONSE?" className="element faq-section">
                            <h4>CAN SOMEONE SEE IF I &quot;AGREE&quot; OR &quot;DISAGREE&quot; WITH THEIR RESPONSE?</h4>
                            <p>Yes. All Consults submitted on Healthcasts can be viewed by other logged-in members using the portal. Logged-out members, non-members and the general public, are not able to access this information.</p>
                        </div>
                    </div>
                    {this.props.isAuthenticated ? (
                        <div className="rewards-and-honoraria">
                            <div name="REWARDS-AND-HONORARIA" className="element">
                                <h2>REWARDS AND HONORARIA</h2>
                            </div>
                            <div name="HOW-DO-I-EARN-AN-HONORARIUM?" className="element faq-section">
                                <h4>HOW DO I EARN AN HONORARIUM?</h4>
                                <p>A proprietary algorithm updates reward eligibility for each user at the start of each eligibility period, which spans weekly from Sunday to Saturday. Rewards are not always available for every member, though you are always encouraged to do as many activities as you like!</p>
                                <p>If you are eligible to earn rewards during the current period you will see that indicated in the banner at the top of the page on your <strong>My Specialty</strong> or <strong>Other Specialties</strong> tabs. To earn a reward, you must submit your substantive response to a patient case on the platform prior to the expire date of the case.</p>
                            </div>
                            <div name="HOW-DO-I-KNOW-WHEN-I-EARNED-AN-HONORARIUM?" className="element faq-section">
                                <h4>HOW DO I KNOW WHEN I EARNED AN HONORARIUM?</h4>
                                <p>Your My Rewards page will indicate which activities that you logged on the platform are counted toward your current reward eligibility. Note that if you participated in a Healthcasts activity outside our platform, such as an online market research survey, you will not see that activity listed on My Rewards.</p>
                            </div>
                            <div name="HOW-LONG-IS-AN-ELIGIBILITY-PERIOD?" className="element faq-section">
                                <h4>HOW LONG IS AN ELIGIBILITY PERIOD?</h4>
                                <p>Eligibility periods span one week, starting after midnight (eastern time) on Sunday and ending at midnight (eastern time) on Saturday.</p>
                            </div>
                            <div name="HOW-MANY-HONORARIA-CAN-I-EARN-PER-ELIGIBLITY-PERIOD?" className="element faq-section">
                                <h4>HOW MANY HONORARIA CAN I EARN PER ELIGIBLITY PERIOD?</h4>
                                <p>Your current eligibility is indicated in the banner at the top of the page on your <strong>My Specialty</strong> or <strong>Other Specialties</strong> tabs. This is updated weekly for each member at the start of each eligibility period based on a proprietary algorithm.</p>
                            </div>
                            <div name="IS-THERE-ANY-OTHER-WAY-TO-EARN-HONORARIA?" className="element faq-section">
                                <h4>IS THERE ANY OTHER WAY TO EARN HONORARIA?</h4>
                                <p>From time to time, members may be selected to participate in honorarium-eligible surveys. Every survey is different, and not all surveys have rewards. The ask, reward amount and due date are all stated in the invitation. It&apos;s your choice whether you wish to participate in market research surveys and special studies.</p>
                            </div>
                            <div name="HOW-AND-WHEN-WILL-I-GET-MY-HONORARIUM?" className="element faq-section">
                                <h4>HOW AND WHEN WILL I GET MY HONORARIUM?</h4>
                                <p>Each eligibility period ends at midnight (Eastern time) on Saturday. All honoraria for eligible activities that took place during that period are processed on Monday (unless otherwise noted). Processing can take up to business 3 days.</p>
                                <p>You will be notified via email by Healthcasts when your honorarium is ready to be transferred directly into your Amazon account using the <strong>Login With Amazon</strong> system. These notifications contain a customized link for all honoraria earned during the eligibility period. Each link is unique to you and may not be redeemed more than once. Notifications are typically sent via email by midweek, though we appreciate your patience and understanding that delays do sometimes occur, especially around holidays.</p>
                                <p><strong>IMPORTANT:</strong> Depending on when the activity was logged or the close date of a survey, there may be more than a week&apos;s time between completing the activity and receiving the honorarium.</p>
                                <div className="faq-image">
                                    <img src={Honoraria} alt="Honoraria Activity" />
                                    <p><strong>Example:</strong> You log an eligible activity on Sunday the 1st. The eligibility period ends on Saturday the 7th. Honoraria are processed on Monday the 9th. Honoraria are distributed on Wednesday the 11th.</p>
                                </div>
                            </div>
                            <div name="CAN-I-GET-MY-HONORARIUM-WITHOUT-LINKING-MY-AMAZON-ACCOUNT?" className="element faq-section">
                                <h4>CAN I GET MY HONORARIUM WITHOUT LINKING MY AMAZON ACCOUNT?</h4>
                                <p>We have worked closely with Amazon to ensure your data is handled with the utmost respect. We provide a way for you to begin the process, but the actual account linking takes place on Amazon&apos;s side. We let them know what amount is meant to be sent, you let them know what account it is meant to be sent to, they send the confirmation to you when the funds are transferred. They have NO access to your Healthcasts account. We have NO access to anything in your Amazon account.</p>
                            </div>
                            <div name="CAN-I-REQUEST-MY-HONORARIUM-IN-ANOTHER-WAY?" className="element faq-section">
                                <h4>CAN I REQUEST MY HONORARIUM IN ANOTHER WAY (E.G., GIFT CARDS OR CASH)?</h4>
                                <p>No, there is not a way to get your honoraria without linking your Healthcasts and Amazon accounts.</p>
                            </div>
                            <div name="WHAT-DO-I-DO-WITH-MY-AMAZON-GIFT-CARD-CODES?" className="element faq-section">
                                <h4>WHAT DO I DO WITH MY AMAZON GIFT CARD CODES?</h4>
                                <p>Gift card codes never expire but you may only redeem them once, by using the <a href="https://www.amazon.com/gc/redeem" target="_blank" rel="noopener noreferrer">Amazon Redemption Tool</a>, or by pasting each code directly into your Amazon shopping cart at checkout.</p>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                    <div className="contact">
                        <p>Have a question which isn&apos;t answered above? Contact <a href="https://marketing.healthcasts.co/acton/media/33748/member-services-inquiries" target="_blank" rel="noopener noreferrer">Member Services</a>.</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        [SessionID]: {
            id,
        },
    } = state;

    return {
        isAuthenticated: id !== null,
    };
};

export default connect(mapStateToProps)(FAQ);
