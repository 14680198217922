import React from 'react';
import PropTypes from 'prop-types';
import { MagnifyingGlassIcon, ReloadIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';

import './search.scss';

const SearchInput = ({
    value, onChange, icon, loading, className, ...props
}) => (
    <div className="search-input-container">
        <input {...props} className={clsx('search-input', className)} value={value} onChange={onChange} />
        {loading ? <ReloadIcon /> : icon}
    </div>
);
SearchInput.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.element,
    onChange: PropTypes.func,
};

SearchInput.defaultProps = {
    className: '',
    loading: false,
    value: null,
    placeholder: 'Search...',
    icon: <MagnifyingGlassIcon />,
    onChange: () => { },
};

export default SearchInput;
