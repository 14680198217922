import { put, select, all, takeEvery } from 'redux-saga/effects';
import { stringify, parse } from 'qs';
import { SEARCH_USERS } from './actionTypes';
import {
    DELETE_USER_COMPLETED,
    DELETE_USER_FAILED,
} from '../../../../data/users';
import { EXPORT_USERS_LIST_FAILED } from '../../../../data/campaigns/actionTypes';
import { replace } from '../../../../services/navigation';
import links, { emptySearchValue } from '../../../../links';
import { showToast, showErrorToast, PersonIcon } from '../../../Toast';

const selectLocationSearch = state => state.router.location.search;

function* userSearchSaga({
    payload: {
        searchValue,
        count,
        pageNumber = 1,
    },
}) {
    const previousSearch = yield select(selectLocationSearch);
    const {
        searchValue: pSearchValue,
        count: pCount,
        pageNumber: pPageNumber,
    } = parse(previousSearch.substring(1));
    const searchOptions = {
        searchValue: searchValue === undefined ? pSearchValue : searchValue,
        count: count || pCount,
        pageNumber: pageNumber || pPageNumber,
    };
    yield put(replace({ search: `?${stringify(searchOptions)}` }));
}

function* setDeleteMsg({ type }) {
    if (type === DELETE_USER_COMPLETED) {
        yield put(showToast({ header: 'User was successfully deleted.', icon: PersonIcon, isPersistent: false }));
    } else if (type === DELETE_USER_FAILED) {
        yield put(showErrorToast({ header: 'There was an issue deleting this user.' }));
    }
    yield put(replace({
        pathname: links.moderator.users.index,
        search: emptySearchValue,
    }));
}

function* setExportErrorMessage() {
    yield put(showErrorToast({ header: 'There was an issue exporting these users.' }));
    window.scrollTo(0, 0);
    yield put(replace({
        pathname: links.moderator.users.index,
        state: {},
    }));
}

export default function* UserListSaga() {
    yield all([
        takeEvery([SEARCH_USERS], userSearchSaga),
        takeEvery([DELETE_USER_COMPLETED, DELETE_USER_FAILED], setDeleteMsg),
        takeEvery([EXPORT_USERS_LIST_FAILED], setExportErrorMessage),
    ]);
}
