import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import links from '../../../links';
import localization from '../../../localization';
import './style.scss';

const {
    topics: {
        myFeed,
    },
} = links;

const {
    navigationLinks: {
        popup: {
            moreQuestionsLabel,
            moreQuestionsButton,
            dontShowPopupAgain,
        },
    },
} = localization;

const CtaPopup = ({
    hidePopup,
    onMoreClick,
}) => (
    <div className="dialog__container dialog__triangle">
        <img src="/images/answer_more_questions.png" className="dialog__image" alt="more questions" />
        <div className="dialog__text">
            {moreQuestionsLabel}
        </div>
        <Link href={myFeed} to={myFeed}>
            <button className="dialog__button" onClick={onMoreClick}>{moreQuestionsButton}</button>
        </Link>
        <button onClick={hidePopup} className="dialog__button dialog__button__secondary">{dontShowPopupAgain}</button>
    </div>
);


CtaPopup.propTypes = {
    hidePopup: PropTypes.func.isRequired,
    onMoreClick: PropTypes.func.isRequired,
};

export default CtaPopup;
