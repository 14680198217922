import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectors } from '../../../../data/questions';
import { ID } from './actionTypes';
import { ID as ModeratorID } from '../../actionTypes';

const questionIdSelector = (_state, questionId) => parseInt(questionId, 10);

const authorsSelector = ({
    [ModeratorID]: {
        [ID]: {
            authors,
        },
    },
}) => authors;

const loadingSelector = ({
    [ModeratorID]: {
        [ID]: {
            loading,
        },
    },
}) => loading;

export const questionSelector = createSelector(
    [selectors.questionsSelector, questionIdSelector],
    (questions, selectedQuestionId) => {
        const question = questions.find(({
            id,
        }) => selectedQuestionId === id);
        if (question) {
            const {
                expirationDate,
                publishDate,
                author: {
                    firstName,
                    lastName,
                    id,
                    email = '',
                },
                specialties = [],
                subspecialties = [],
                ...other
            } = question;
            return {
                ...other,
                author: {
                    id,
                    childKey: id,
                    title: `${firstName} ${lastName}`,
                    description: email,
                },
                specialties: specialties.map(x => x.id),
                subspecialties: subspecialties.map(x => x.id),
                expirationDate: moment(expirationDate),
                publishDate: moment(publishDate),
            };
        }
        return null;
    },
);

const structureResults = createSelector(
    authorsSelector,
    authors => authors.map(({
        firstName,
        lastName,
        id,
        email,
    }) => ({
        id,
        childKey: id,
        title: `${firstName} ${lastName}`,
        description: email,
    })),
);

export const resultsSelector = createSelector(
    structureResults,
    loadingSelector,
    (results, searchLoading) => ({
        results,
        searchLoading,
    }),
);
