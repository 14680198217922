import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import PropTypes from 'prop-types';
import Avatar from './avatar';

const HamburgerLine = ({ className }) => (
    <span className={`right-6 relative top-1 block w-8 h-[2px] mb-[6px] bg-white rounded-s z-[1] origin-[4px_0px] transition duration-500 ease-in-out ${className}`} />
);

HamburgerLine.propTypes = {
    className: PropTypes.string.isRequired,
};

const NavItems = ({ navItems }) => navItems.map(({
    key, label, url, isExternal, newTab,
}) => (
    <li key={key}>
        {
            isExternal ? (
                <a
                    href={url}
                    target={newTab ? '_blank' : undefined}
                    key={key}
                    className="body-m text-purple-500 hover:text-purple-600 flex items-center whitespace-nowrap"
                    rel="noopener noreferrer"
                >
                    {label}
                    {newTab && <ExternalLinkIcon className="ml-[2px]" />}
                </a>
            ) : (
                <Link
                    to={url}
                    className="body-m text-purple-500 hover:text-purple-600"
                >
                    {label}
                </Link>
            )
        }
    </li>
));

const HamburgerMenu = ({
    navItems, dropdownItems, initials, fullName,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setIsOpen(false);
        } else if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen);
        }
    };

    return (
        <div className="visible lg:hidden self-center fixed right-0">
            <input
                role="button"
                aria-expanded={isOpen}
                tabIndex={0}
                type="checkbox"
                className="peer block absolute right-5 -top-1.5 w-10 h-8 cursor-pointer z-[2] opacity-0 focus:ring focus:ring-purple-500 focus:opacity-[0.001]"
                checked={isOpen}
                onChange={toggleMenu}
                onKeyDown={handleKeyDown}
            />
            <HamburgerLine className="origin-top-left peer-checked:transform peer-checked:rotate-45 peer-checked:translate-y-[-3px]" />
            <HamburgerLine className="peer-checked:opacity-0 peer-checked:rotate-0 peer-checked:scale-0" />
            <HamburgerLine className="origin-bottom-left peer-checked:transform peer-checked:-rotate-45 peer-checked:translate-y-[3px]" />
            <ul
                role="menu"
                className="fixed bg-white m-0 w-screen h-[calc(100vh-72px)] top-[72px] right-0 p-12 list-none origin-top-left transition duration-500 translate-x-full peer-checked:transform-none"
                onKeyDown={handleKeyDown}
            >
                <div className="h-full flex flex-col justify-between">
                    <div className="flex flex-col gap-12">
                        <div className="flex flex-col gap-4">
                            <NavItems navItems={navItems} />
                        </div>
                        <div className="flex flex-col gap-4">
                            <label className="body-xs font-bold text-purple-gray-600">My Account</label>
                            {
                                dropdownItems.map(({ key, label, url }) => (
                                    <li key={key}>
                                        <Link
                                            to={url}
                                            className="body-s text-purple-gray-600 hover:text-purple-gray-700"
                                        >
                                            {label}
                                        </Link>
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                    <div className="flex p-2 items-center gap-2">
                        <Avatar initials={initials} />
                        <span className="body-s text-purple-gray-800">{fullName}</span>
                    </div>
                </div>
            </ul>
        </div>
    );
};

HamburgerMenu.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        label: PropTypes.string,
        url: PropTypes.string,
    })).isRequired,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        label: PropTypes.string,
        url: PropTypes.string,
    })).isRequired,
    initials: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
};

HamburgerMenu.defaultProps = {
};

export default HamburgerMenu;
