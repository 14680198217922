import { createAction } from 'redux-actions';

import * as Actions from './actions';
import * as selectors from './selectors';

export { default as reducer } from './reducer';

export const updateAnswer = createAction(Actions.UPDATE_ANSWER);

export const updateAnswerCompleted = createAction(Actions.UPDATE_ANSWER_COMPLETED);

export const updateAnswerFailed = createAction(Actions.UPDATE_ANSWER_FAILED);

export const loadAnswers = createAction(Actions.LOAD_ANSWERS);

export const loadAnswersCompleted = createAction(Actions.LOAD_ANSWERS_COMPLETED);

export const loadAnswersFailed = createAction(Actions.LOAD_ANSWERS_FAILED);

export const deleteAnswer = createAction(Actions.DELETE_ANSWER);

export const deleteAnswerCompleted = createAction(Actions.DELETE_ANSWER_COMPLETED);

export const deleteAnswerFailed = createAction(Actions.DELETE_ANSWER_FAILED);

export const rateAnswer = createAction(Actions.RATE_ANSWER);

export const rateAnswerCompleted = createAction(Actions.RATE_ANSWER_COMPLETED);

export const rateAnswerFailed = createAction(Actions.RATE_ANSWER_FAILED);

export const showAnswers = createAction(Actions.SHOW_ANSWERS);

export const showAnswersCompleted = createAction(Actions.SHOW_ANSWERS_COMPLETED);

export const showAnswersFailed = createAction(Actions.SHOW_ANSWERS_FAILED);

export {
    Actions,
    selectors,
};

export { ID } from './actions';
