import React from 'react';
import { Header, Divider, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import localization from '../../../localization';
import Navigation from '../../../components/My/Navigation';

import './style.scss';

const {
    my: {
        profile: {
            myProfileHeading,
            aboutMe: {
                caption: aboutMeCaption,
                labels: {
                    specialty: specialtyAboutMeLabel,
                    emailAddress: emailAddressAboutMeLabel,
                    npiNumber: npiNumberAboutMeLabel,
                },
            },
            settings: {
                caption: settingsCaption,
                labels: {
                    showTooltips: showTooltipsSettingsLabel,
                },
            },
        },
    },
} = localization;

const MyProfile = ({
    firstName,
    lastName,
    email,
    specialty,
    showTooltips,
    updatingShowTooltips,
    setShowTooltips,
    npi,
}) => {
    const updateShowtTooltips = () => {
        setShowTooltips({ value: showTooltips });
    };

    return (
        <div className="my-profile profile">
            <div className="page-container">
                <div className="inline-align">
                    <Header>{myProfileHeading}</Header>

                    <Navigation active="profile" />
                </div>

                <Divider className="expanded" />

                <div className="left-line-heading">{aboutMeCaption}</div>

                <div className="ui stackable two column grid about-me">
                    <div className="column">
                        <h4 className="user-name">{firstName} {lastName}</h4>
                        <div className="attribute-container left">
                            <div className="attribute-name specialty">
                                {specialtyAboutMeLabel}:
                            </div>
                            <div className="attribute-value">
                                {specialty}
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="user-data-table">
                            <div className="attribute-container">
                                <div className="attribute-name">
                                    {emailAddressAboutMeLabel}:
                                </div>
                                <div className="attribute-value right">
                                    {email}
                                </div>
                            </div>

                            <div className="attribute-container">
                                <div className="attribute-name">
                                    {npiNumberAboutMeLabel}:
                                </div>
                                <div className="attribute-value right">
                                    {npi}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="left-line-heading">{settingsCaption}</div>

                <Form loading={updatingShowTooltips}>
                    <Form.Checkbox
                        label={showTooltipsSettingsLabel}
                        checked={showTooltips}
                        onChange={updateShowtTooltips}
                    />
                </Form>
            </div>
        </div>
    );
};

MyProfile.prototype.defaultProps = {
    firstName: '',
    lastName: '',
    email: '',
    specialty: '',
    showTooltips: false,
    updatingShowTooltips: false,
    npi: '',
};

MyProfile.prototype.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    specialty: PropTypes.string,
    showTooltips: PropTypes.bool,
    updatingShowTooltips: PropTypes.bool,
    npi: PropTypes.string,
    setShowTooltips: PropTypes.func.isRequired,
};

export default MyProfile;
