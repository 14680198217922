import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import {
    FETCH_USER,
    MARK_DISCLAIMER,
    LOGOUT,
    DISABLE_CTA_POPUP,
    ALLOW_TOOLTIPS,
    DISABLE_TOOLTIPS,
} from './actionTypes';
import {
    fetchUserCompleted,
    fetchUserFailed,
    markDisclaimerNotRead,
    markDisclaimerRead,
    markDisclaimerReadFailed,
    disableCtaPopupCompleted,
    disableCtaPopupFailed,
} from './';
import {
    allowTooltipsCompleted,
    allowTooltipsFailed,
    disableTooltipsCompleted,
    disableTooltipsFailed,
} from './actions';
import {
    getDisclaimerState,
    markDisclaimerAsRead,
    setCtaPopupAsDisabled,
    allowTooltips,
    disableTooltips,
} from './api';
import api from '../../api';
import links from '../../links';
import { push, replace } from '../navigation';
import { disclaimerSelector, postAuthRedirectUrlSelector, roleNameSelector } from './selectors';
import { analytics } from '../../utils/analytics';
import { isInternalUser } from '../../utils/roleHelper';
import { showGlobalErrorToast } from '../../containers/Toast';

function* checkDisclaimerState() {
    let disclaimerRead = yield select(disclaimerSelector);
    const roleName = yield select(roleNameSelector);
    if (isInternalUser(roleName)) {
        yield put(markDisclaimerRead());
    } else if (!disclaimerRead) {
        const { data } = yield call(getDisclaimerState);
        disclaimerRead = data && data.disclaimerRead;
        if (disclaimerRead) {
            yield put(markDisclaimerRead());
        } else {
            yield put(markDisclaimerNotRead());
        }
    }
}

function* fetchUserSaga() {
    let userTrackingData = null;
    try {
        const currentUser = yield call(() => api.get('/current_user'));
        const {
            id,
            firstName,
            lastName,
            email,
            specialty,
            ctaPopupDisabled,
            npi,
            showTooltips = false,
            roleName,
            permissions,
        } = currentUser.data;
        yield put(fetchUserCompleted({
            id,
            firstName,
            lastName,
            email,
            specialty,
            ctaPopupDisabled,
            showTooltips,
            npi,
            roleName,
            permissions,
        }));
        userTrackingData = {
            id, npi, specialty, firstName,
        };
    } catch (error) {
        yield put(fetchUserFailed());
        const status = error?.status;
        if (status === 401 || status === 403) {
            yield put(replace({ pathname: links.login }));
        } else if (status !== 500) { // 500s are already handled by apiSaga
            yield put(showGlobalErrorToast());
        }
        return;
    }
    yield checkDisclaimerState();
    const { id, npi } = userTrackingData;
    analytics.trackEvent('set_docdx_data', id, npi);
    const redirectUrl = yield select(postAuthRedirectUrlSelector);
    if (redirectUrl) {
        yield put(push(redirectUrl));
    }
}

function* markDisclaimerSaga() {
    try {
        yield call(markDisclaimerAsRead);
        yield put(markDisclaimerRead());
    } catch (error) {
        yield put(markDisclaimerReadFailed({ error }));
    }
}

function* disableCtaPopupSaga() {
    try {
        yield call(setCtaPopupAsDisabled);
        yield put(disableCtaPopupCompleted());
    } catch (error) {
        yield put(disableCtaPopupFailed({ error }));
    }
}

function* allowTooltipsSaga() {
    try {
        yield call(allowTooltips);
        yield put(allowTooltipsCompleted());
    } catch (error) {
        yield put(allowTooltipsFailed(error));
    }
}

function* disableTooltipsSaga() {
    try {
        yield call(disableTooltips);
        yield put(disableTooltipsCompleted());
    } catch (error) {
        yield put(disableTooltipsFailed(error));
    }
}

const createLogoutSaga = persistor => () =>
    persistor.purge();

export default function* (args) {
    yield all([
        takeLatest(FETCH_USER, fetchUserSaga),
        takeLatest(MARK_DISCLAIMER, markDisclaimerSaga),
        takeLatest(LOGOUT, createLogoutSaga(args)),
        takeLatest(DISABLE_CTA_POPUP, disableCtaPopupSaga),
        takeLatest(ALLOW_TOOLTIPS, allowTooltipsSaga),
        takeLatest(DISABLE_TOOLTIPS, disableTooltipsSaga),
    ]);
}
