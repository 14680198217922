import React from 'react';
import { TableRow, TableCell, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './style.scss';

const TableEmptyState = ({
    text,
    loading,
}) => (
    <TableRow>
        <TableCell
            as="td"
            colSpan={5}
            className="empty_state"
            textAlign="center"
        >
            {loading ? (<Loader active inline />) : text}
        </TableCell>
    </TableRow>
);

TableEmptyState.propTypes = {
    text: PropTypes.string.isRequired,
    loading: PropTypes.bool,
};

TableEmptyState.defaultProps = {
    loading: false,
};

export default TableEmptyState;
