import { matchPath } from 'react-router';
import { reset } from 'redux-form';
import {
    loadQuestions,
    loadQuestion,
    loadNextQuestion,
    loadRelatedUsersCount,
    loadEligibleUsersCount,
    loadQuestionsNpis,
} from './questions';
import links, { publicRoutes } from '../links';
import { loadAnswers, ID as AnswersID } from './answers';
import { loadUser } from './users';
import { loadRoles } from './roles';
import { loadCampaigns, loadGeneralCampaigns } from './campaigns';
import { getGlobalHcmpList } from './globalHcmp';
import { ID as NavigationID, handle404, LOCATION_CHANGE, urlParser, selectors } from '../services/navigation';
import { ID as DataID, RolesID, SpecialtyID } from '.';
import { loadSpecialties, loadSubSpecialties } from './specialties';
import { getAttachedPrograms } from './topicPrograms';
import { loadCurrentRewards, loadHistoryRewards } from './rewards';
import { loadTooltip } from './tooltips';
import { tooltipNames } from './enums';

const prevPathnameSelector = ({
    [NavigationID]: {
        location,
    },
}) => location && location.previousPathname;

const answersLoadedSelector = ({
    [DataID]: {
        [AnswersID]: {
            loaded,
        },
    },
}) => loaded;

const userPageRequirementsSelector = ({
    [DataID]: {
        [RolesID]: {
            loaded: loadedRoles,
        },
        [SpecialtyID]: {
            loadedSubSpecialties,
            loadedSpecialties,
        },
    },
}) => ({
    loadedSubSpecialties,
    loadedSpecialties,
    loadedRoles,
});

const questionPageRequirementsSelector = ({
    [DataID]: {
        [SpecialtyID]: {
            loadedSubSpecialties,
            loadedSpecialties,
        },
    },
}) => ({
    loadedSubSpecialties,
    loadedSpecialties,
});

const newQuestionRequirementsSelector = ({
    [DataID]: {
        [SpecialtyID]: {
            loadedSubSpecialties,
            loadedSpecialties,
        },
    },
}) => ({
    loadedSubSpecialties,
    loadedSpecialties,
});

const handleQuestionsLoad = (store, dispatch, { pathname, match } = {}) => {
    const state = store.getState();
    const answersLoaded = answersLoadedSelector(state);
    const previousPathname = prevPathnameSelector(state);

    const topicId = parseInt(match.params.id, 10);
    if (Number.isNaN(topicId)) {
        dispatch(handle404());
    } else {
        dispatch(loadQuestion({ id: topicId }));
        dispatch(loadTooltip({
            name: tooltipNames.questionGiftCardIcon,
        }));
        dispatch(loadTooltip({
            name: tooltipNames.questionAfterUserEarned,
        }));
        dispatch(loadNextQuestion({ id: topicId }));
        if (!answersLoaded || previousPathname !== pathname) {
            dispatch(loadAnswers({ topicId }));
        }
    }
};

const handleUsersLoad = (state, dispatch, { pathname } = {}) => {
    const match = matchPath(pathname, {
        path: links.moderator.users.edit,
        exact: true,
        strict: true,
    });
    if (match) {
        const userId = parseInt(match.params.id, 10);
        if (match.url !== links.moderator.users.new) {
            if (Number.isNaN(userId)) {
                dispatch(handle404());
            } else {
                dispatch(loadUser(userId));
            }
        }

        const {
            loadedSubSpecialties,
            loadedSpecialties,
            loadedRoles,
        } = userPageRequirementsSelector(state);

        if (!loadedRoles) {
            dispatch(loadRoles());
        }
        if (!loadedSpecialties) {
            dispatch(loadSpecialties());
        }
        if (!loadedSubSpecialties) {
            dispatch(loadSubSpecialties());
        }
    }
};

const handleQuestionLoad = (state, dispatch, { pathname } = {}) => {
    const questionEditMatch = matchPath(pathname, {
        path: links.moderator.questions.edit,
        exact: true,
        strict: true,
    });

    if (questionEditMatch && questionEditMatch.url !== links.moderator.questions.new) {
        const questionId = parseInt(questionEditMatch.params.id, 10);
        if (Number.isNaN(questionId)) {
            dispatch(handle404());
        } else {
            const {
                loadedSubSpecialties,
                loadedSpecialties,
            } = questionPageRequirementsSelector(state);

            if (!loadedSpecialties) {
                dispatch(loadSpecialties());
            }
            if (!loadedSubSpecialties) {
                dispatch(loadSubSpecialties());
            }
            dispatch(loadQuestion({ id: questionId, internal: true }));
        }
    }

    const matchIndex = matchPath(pathname, {
        path: links.moderator.questions.index,
        exact: true,
        strict: true,
    });

    if (matchIndex) {
        const {
            loadedSpecialties,
        } = questionPageRequirementsSelector(state);

        if (!loadedSpecialties) {
            dispatch(loadSpecialties());
        }
    }

    const questionDetailsMatch = matchPath(pathname, {
        path: links.moderator.questions.details,
        exact: true,
        strict: true,
    });

    if (questionDetailsMatch) {
        const questionId = parseInt(questionDetailsMatch.params.id, 10);
        if (Number.isNaN(questionId)) {
            dispatch(handle404());
        } else {
            dispatch(loadRelatedUsersCount({ questionId }));
            dispatch(loadEligibleUsersCount({ questionId }));
            dispatch(loadQuestion({ id: questionId, internal: true }));
            dispatch(loadCampaigns({ topicId: questionId }));
            dispatch(getAttachedPrograms({ topicId: questionId }));
        }
    }
};

const handleNewQuestionLoad = (state, dispatch, { pathname }) => {
    const match = matchPath(pathname, {
        path: links.moderator.questions.new,
        exact: true,
        strict: false,
    });
    if (match) {
        const {
            loadedSubSpecialties,
            loadedSpecialties,
        } = newQuestionRequirementsSelector;

        if (!loadedSpecialties) {
            dispatch(loadSpecialties());
        }

        if (!loadedSubSpecialties) {
            dispatch(loadSubSpecialties());
        }
    }
};

const loadData = (store, { payload }) => {
    const { pathname = '' } = payload;
    const query = urlParser(payload.search);
    const { dispatch } = store;
    const state = store.getState();
    const previousPathname = selectors.previousPathnameSelector(state);

    dispatch(loadTooltip({
        name: tooltipNames.finishRewards,
    }));
    dispatch(loadTooltip({
        name: tooltipNames.pointToMyRewards,
    }));

    if (pathname.startsWith('/moderator')) {
        handleUsersLoad(state, dispatch, payload);
        handleNewQuestionLoad(state, dispatch, payload);
        handleQuestionLoad(state, dispatch, payload);

        switch (pathname) {
            case links.moderator.questions.npis:
                dispatch(loadQuestionsNpis());
                break;
            case links.moderator.questions.index: {
                const {
                    pageNumber,
                    term,
                    count,
                    specs,
                    status,
                } = query;

                if (status === undefined && previousPathname === links.moderator.questions.index) {
                    dispatch(reset('question_search'));
                }

                dispatch(loadQuestions({
                    pageNumber,
                    term,
                    count,
                    specs,
                    status,
                    internal: true,
                }));
                break;
            }
            case links.moderator.globalPrograms.index:
                dispatch(getGlobalHcmpList());
                break;
            case links.moderator.globalPrograms.createEmails:
                dispatch(loadSpecialties());
                dispatch(loadGeneralCampaigns({ pageNumber: 1 }));
                break;
            default:
                break;
        }
    } else {
        switch (pathname) {
            case links.topics.myFeed:
                dispatch(loadQuestions({ personalized: true }));
                dispatch(loadTooltip({
                    name: tooltipNames.questionsGiftCardIcon,
                }));
                dispatch(loadTooltip({
                    name: tooltipNames.questionsAvailableRewardsIcon,
                }));
                break;
            case links.topics.otherFeed:
                dispatch(loadQuestions({ personalized: false }));
                break;
            case links.my.rewards:
                dispatch(loadTooltip({
                    name: tooltipNames.rewardsHistory,
                }));
                dispatch(loadHistoryRewards());
                dispatch(loadCurrentRewards());
                break;
            default:
                break;
        }

        const match = matchPath(pathname, {
            path: links.topics.view,
            exact: true,
            strict: false,
        });

        if (match) {
            dispatch(loadCurrentRewards());
            handleQuestionsLoad(store, dispatch, { match, ...payload });
        }
    }
};

const dataLoadMiddleWare = store => next => (action) => {
    // TODO: this was originally written as:
    //    const userAuthedAndFetched = sessionSelectors.fetchStateSelector(store.getState()) === sessionSelectors.FetchState.success;
    //    if (action.type === LOCATION_CHANGE && userAuthedAndFetched) ...
    // That ensured that `loadData` wouldn't be called until after the user was fully authed and loaded.
    // However, the `LOCATION_CHANGE` event fires _before_ the `/current_user` resolves, so `loadData`
    // wasn't being called when the user first logged in, or if they refreshed the page.
    // The best solution would be to remove this middleware entirely (HEA-745).
    // For now, this will allow calls to be made to those endpoints even if the user hasn't finished
    // auth, which will result in lots of 401s but they won't break the app due to how `apiSaga`
    // debounces auth errors (see that module's `handleAuthError` function).
    const isPublicRoute = [links.redirecting, ...publicRoutes].includes(action?.payload?.pathname);
    if (action.type === LOCATION_CHANGE && !isPublicRoute) {
        loadData(store, action);
    }

    next(action);
};

export default dataLoadMiddleWare;
