import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import { Field } from 'redux-form';
import Button from '../../../../components/Button';
import CheckboxGroup from '../../../../components/CheckboxGroup';
import Dropdown from '../../../../components/Dropdown';
import TextInput from '../../../../components/TextInput';
import { required } from '../../../../utils/form/validators';
import './style.scss';
import AppHcUserLookup from './appHcUserLookup';

const UserFormContainer = ({
    specialties,
    handleSubmit,
    loading,
    subSpecialties,
    roles,
    isFormValid,
    isExternalUser,
    isSpecialtyRequired,
    restoreUser,
    restoreMessageVisible: restoreMessageVisibleProp,
    replace,
    isUpdate,
    disabled,
    permissionOptions,
    shouldGenerateInternalIdNumber,
    changeInternalNpiField,
    isRoleSelected,
    appHcUserIds,
}) => {
    const [restoreMessageVisible, setRestoreMessageVisible] = useState(false);

    useEffect(() => {
        if (restoreMessageVisibleProp && !restoreMessageVisible) {
            setRestoreMessageVisible(true);
        }
    }, [restoreMessageVisibleProp, restoreMessageVisible]);

    // auto generate internal id number on initial load
    useEffect(() => changeInternalNpiField(), []);

    // auto generate internal id number on npi conflict and reset location state
    useEffect(() => {
        if (shouldGenerateInternalIdNumber) {
            setTimeout(() => {
                changeInternalNpiField();
            }, 5000);
            replace({
                state: {
                    npiUniqueError: false,
                },
            });
        }
    }, [shouldGenerateInternalIdNumber]);

    return (
        <>
            <div className="page-container create-or-edit-user-form">
                <h1>{isUpdate ? 'Edit user' : 'Create a new user'}</h1>
                <p className="description">
                    {isUpdate ?
                        'Edit the user details below and save to update. If you change a user’s role make sure to update the required fields below.' :
                        'Create an account for a new user so they can start exploring all the features and benefits of our platform.'
                    }
                </p>
                <p className="instruction">Required field</p>
                <Message
                    hidden={!disabled}
                    info
                    content="This user is deactivated!"
                />
                <Message
                    hidden={!restoreMessageVisible}
                    header="User exists"
                    content={
                        <div>
                            A user with that email address was deleted. Do you want to restore the user?
                            <br />
                            <Button onClick={restoreUser}>Restore</Button>
                        </div>}
                />
                <Form loading={loading}>
                    <div className="two fields">
                        <div className="field">
                            <Field
                                className="first-name"
                                label="First name"
                                placeholder="Name"
                                component={TextInput}
                                name="firstName"
                                required
                            />
                        </div>
                        <div className="field">
                            <Field
                                className="last-name"
                                label="Last name"
                                placeholder="Name"
                                component={TextInput}
                                name="lastName"
                                required
                            />
                        </div>
                    </div>
                    <Field
                        className={`${!isUpdate && appHcUserIds.length > 0 ? 'highlight' : ''}`}
                        label="Email"
                        name="email"
                        placeholder="Email"
                        component={TextInput}
                        required
                    />
                    <Field
                        name="disableAccountUpdateEmail"
                        component={({ input, label }) => (
                            <div className="py-4">
                                <input
                                    className="w-5 h-5 cursor-pointer checked:bg-blue-500"
                                    {...input}
                                    checked={input.value}
                                    type="checkbox"
                                />
                                <label className="body-s ml-3">{label}</label>
                            </div>
                        )}
                        label="Disable account update email."
                    />
                    <div>
                        {isUpdate && (
                            <>
                                {appHcUserIds?.length === 0 ? (
                                    <>
                                        <div className="field-label">App.hc CMS user id</div>
                                        <p>No app.hc accounts associated with this email and npi were found.</p>
                                        <p>We will create a new app.hc user account for you when you hit Save and update</p>
                                    </>
                                ) : (
                                    <Field
                                        name="appHcUserId"
                                        label="App.hc CMS user id"
                                        component={Dropdown}
                                        placeholder="Select"
                                        options={appHcUserIds.map(id => ({
                                            key: id,
                                            text: id,
                                            value: id,
                                        }))}
                                        required
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <Field
                        label="Select user role"
                        component={Dropdown}
                        placeholder="Select"
                        name="roleId"
                        options={roles}
                        required
                    />
                    {isRoleSelected && (
                        <>
                            <hr />
                            <div className="section-label">
                                {isExternalUser ? (
                                    <>
                                        <h2>NPI number</h2>
                                        <p className="sub-title">Healthcare provider accounts require a 10 digit NPI number which is unique for all covered healthcare providers.</p>
                                        <Field
                                            className={`${!isUpdate && appHcUserIds.length > 0 ? 'highlight' : ''}`}
                                            label="NPI number"
                                            name="npi"
                                            component={TextInput}
                                            required
                                        />
                                    </>
                                ) : (
                                    <>
                                        <h2>Internal ID Number</h2>
                                        <p className="sub-title">This is a 9 digit identifying number that will serve as a placeholder for an official NPI number for this user&apos;s role.</p>
                                        <Field
                                            name="internalNpi"
                                            component={TextInput}
                                            required
                                            disabled
                                        />
                                    </>
                                )}
                            </div>
                            {
                                !isUpdate && (
                                    <div className="app-hc-lookup">
                                        <AppHcUserLookup
                                            appHcUserIds={appHcUserIds}
                                        />
                                    </div>
                                )
                            }
                            <hr />
                            <div className="section-label">
                                <h2>Specialty</h2>
                            </div>
                            <Field
                                label="Select specialty"
                                component={Dropdown}
                                name="specialtyId"
                                options={specialties}
                                validate={isSpecialtyRequired ? required : null}
                                required={isSpecialtyRequired}
                                hasEmptyValue
                            />
                            <Field
                                label="Select sub specialty"
                                component={Dropdown}
                                name="subspecialtyId"
                                options={subSpecialties}
                                hasEmptyValue
                            />
                            {isExternalUser && (
                                <>
                                    <div className="field-label">Expert Status</div>
                                    <Field
                                        name="isExpert"
                                        component={({ input, label }) => (
                                            <div className="expert-container">
                                                <input {...input} checked={input.value} type="checkbox" />
                                                <label>{label}</label>
                                            </div>
                                        )}
                                        label="This user is considered an expert"
                                    />
                                </>
                            )}
                            <hr />
                            <div className="section-label">
                                <h2>User permissions</h2>
                            </div>
                            <div className="checkbox-container">
                                <Field
                                    name="permissions"
                                    component={CheckboxGroup}
                                    options={permissionOptions}
                                />
                            </div>
                        </>
                    )}
                    {
                        !isRoleSelected && (
                            <>
                                <hr />
                                <p className="warning">
                                    You must fill or select the required fields above before you can continue to creating new user.
                                </p>
                            </>
                        )
                    }
                </Form>
            </div>
            <div className="create-or-edit-user-form-footer">
                <Button
                    className="submit-button"
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                >
                    {isUpdate ? 'Save and update' : 'Create user'}
                </Button>
            </div>
        </>
    );
};

UserFormContainer.propTypes = {
    specialties: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        text: PropTypes.string,
        value: PropTypes.number,
    })).isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    subSpecialties: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        text: PropTypes.string,
        value: PropTypes.number,
    })).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isExternalUser: PropTypes.bool.isRequired,
    isSpecialtyRequired: PropTypes.bool.isRequired,
    isFormValid: PropTypes.bool.isRequired,
    restoreUser: PropTypes.func.isRequired,
    restoreMessageVisible: PropTypes.bool,
    isUpdate: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    permissionOptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        forAppHc: PropTypes.bool,
        preSelected: PropTypes.bool,
    })).isRequired,
    shouldGenerateInternalIdNumber: PropTypes.bool,
    changeInternalNpiField: PropTypes.func.isRequired,
    isRoleSelected: PropTypes.bool.isRequired,
    appHcUserIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

UserFormContainer.defaultProps = {
    restoreMessageVisible: false,
    shouldGenerateInternalIdNumber: false,
    appHcUserIds: null,
};

export default UserFormContainer;
