import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Button } from 'semantic-ui-react';
import localization from '../../localization';
import { namesOfTooltips } from '../../data/enums';
import { rewardsEnabled } from '../../environment';

const {
    tooltips: texts,
} = localization;

const Tooltip = ({
    name,
    trigger,
    position,
    showTooltips = true,
    tooltips = {},
    display = true,
    markTooltip,
}) => {
    const {
        [name]: tooltip = {},
    } = tooltips;

    const {
        alreadyViewed = true,
    } = tooltip;

    const message = texts[namesOfTooltips[name]];

    const viewed = (e) => {
        e.stopPropagation();
        markTooltip({ name });
    };

    return (
        <Popup
            trigger={trigger}
            position={position}
            open={display && showTooltips && !alreadyViewed && rewardsEnabled}
        >
            <Popup.Content>
                <p>{message}</p>
                <Button className="primary" onClick={e => viewed(e)}>Ok</Button>
            </Popup.Content>
        </Popup>
    );
};


Tooltip.prototype.defaultProps = {
    showTooltips: true,
    position: 'top left',
    tooltips: {},
    display: true,
};

Tooltip.prototype.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string,
    showTooltips: PropTypes.bool,
    tooltips: PropTypes.shape({
        alreadyViewed: PropTypes.bool,
    }),
    markTooltip: PropTypes.func.isRequired,
    trigger: PropTypes.node.isRequired,
    display: PropTypes.bool,
};

export default Tooltip;
