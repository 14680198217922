import { ID as DataID } from '../';
import { ID } from './actionTypes';

// eslint-disable-next-line
export const answerFocusedExecutedSelector = ({
    [DataID]: {
        [ID]: {
            answerFocusedExecuted,
        },
    },
}) => answerFocusedExecuted;
