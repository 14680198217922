import { createAction } from 'redux-actions';
import {
    ANSWER_INPUT_FOCUSED,
    ANSWER_INPUT_FOCUSED_COMPLETED,
    ANSWER_INPUT_FOCUSED_FAILED,
} from './actionTypes';

export const answerInputFocused = createAction(ANSWER_INPUT_FOCUSED);
export const answerInputFocusedCompleted = createAction(ANSWER_INPUT_FOCUSED_COMPLETED);
export const answerInputFocusedFailed = createAction(ANSWER_INPUT_FOCUSED_FAILED);
