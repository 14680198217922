import { createAction } from 'redux-actions';
import {
    OPEN_ANSWER_EDIT_FORM,
    OPEN_REPLY_FORM,
    CLEAR_LAST_UPDATED_COMMENT,
    MARK_ANSWER_AS_NEW,
    SHOW_PROGRAM,
    SHOW_NEXT_QUESTION,
    SHOW_NOTICE,
    HIDE_NOTICE,
    SHOW_PROGRAM_FAILED,
    ANSWER_INPUT_FOCUSED,
    PREPARE_PROGRAM,
} from './actionTypes';

export const openAnswerEditForm = createAction(OPEN_ANSWER_EDIT_FORM);

export const openReplyForm = createAction(OPEN_REPLY_FORM);

export const clearUpdatedComment = createAction(CLEAR_LAST_UPDATED_COMMENT);

export const markAnswerAsNew = createAction(MARK_ANSWER_AS_NEW);

export const showProgram = createAction(SHOW_PROGRAM);

export const showNotice = createAction(SHOW_NOTICE);

export const hideNotice = createAction(HIDE_NOTICE);

export const showProgramFailed = createAction(SHOW_PROGRAM_FAILED);

export const answerInputFocused = createAction(ANSWER_INPUT_FOCUSED);

export const prepareProgram = createAction(PREPARE_PROGRAM);

export const showNextQuestion = createAction(SHOW_NEXT_QUESTION);
