import { call, put, takeEvery, all, takeLatest } from 'redux-saga/effects';
import {
    UPDATE_QUESTION,
    LOAD_QUESTIONS,
    DELETE_QUESTION,
    LOAD_QUESTION,
    FLAG_QUESTION,
    LOAD_RELATED_USERS_COUNT,
    LOAD_ELIGIBLE_USERS_COUNT,
    LOAD_NEXT_QUESTION,
    LOAD_QUESTIONS_NPIS,
    UPDATE_QUESTIONS_NPIS,
} from './actionTypes';

import {
    updateQuestionCompleted,
    updateQuestionFailed,
    loadQuestionsCompleted,
    loadQuestionsFailed,
    loadSearchCompleted,
    deleteQuestionCompleted,
    deleteQuestionFailed,
    loadQuestionCompleted,
    loadQuestionFailed,
    loadNextQuestionCompleted,
    loadNextQuestionFailed,
    flagQuestionCompleted,
    flagQuestionFailed,
    createQuestionCompleted,
    createQuestionFailed,
    loadRelatedUsersCountCompleted,
    loadRelatedUsersCountFailed,
    loadEligibleUsersCountCompleted,
    loadEligibleUsersCountFailed,
    loadQuestionsNpisCompleted,
    loadQuestionsNpisFailed,
    updateQuestionsNpisCompleted,
    updateQuestionsNpisFailed,
} from './actions';
import {
    loadQuestions,
    loadQuestionsInternal,
    loadQuestionInternal,
    createQuestion,
    deleteQuestion,
    updateQuestion,
    loadQuestion,
    loadNextQuestion,
    flagQuestion,
    getRelatedUsersCount,
    getEligibleUsersCount,
    getQuestionNpis,
    updateQuestionsNpis,
} from './api';
import errorCodes from '../../api/errorCodes';
import { handle404 } from '../../services/navigation';
import { actions as programActions } from '../programs';

function* loadSaga({ payload: { internal, append, ...other } = {} }) {
    try {
        const apiCall = internal ? loadQuestionsInternal : loadQuestions;
        const options = internal ? { ...other } : {
            sort: 'expirationDate DESC',
            ...other,
        };
        const { data: topics, meta: { total } = {} } = yield call(apiCall, options);
        if (internal && !append) {
            yield put(loadSearchCompleted({ topics, total }));
        } else {
            yield put(loadQuestionsCompleted({ topics, total }));
        }
    } catch (error) {
        yield put(loadQuestionsFailed({ error }));
    }
}

function* loadSingleSaga({ payload: { id, internal } }) {
    try {
        const previousTopicId = sessionStorage.getItem('topic_id');
        if (!previousTopicId || parseInt(previousTopicId, 10) !== id) {
            sessionStorage.setItem('sai_interstitial_shown', 0);
            sessionStorage.setItem('topic_id', id);
        }

        const { data: topic } = yield call(internal ? loadQuestionInternal : loadQuestion, id);
        yield put(programActions.getProgramForUser({ topicId: id }));
        yield put(loadQuestionCompleted({ topic }));
    } catch (error) {
        if (error.status === 404 ||
            (error.data && error.data.errorCode === errorCodes.wrongArguments)) {
            yield put(handle404());
        }
        yield put(loadQuestionFailed({ error }));
    }
}

function* loadNextQuestionSaga({ payload: { id } }) {
    try {
        const { data: topic } = yield call(loadNextQuestion, id);
        yield put(loadNextQuestionCompleted({ topic }));
    } catch (error) {
        yield put(loadNextQuestionFailed({ error }));
    }
}

function* updateQuestionSaga({ payload: { id, ...Question } }) {
    try {
        let updatedQuestion;
        if (id) {
            ({ data: updatedQuestion } = yield call(updateQuestion, id, Question));
            yield put(updateQuestionCompleted({ question: updatedQuestion }));
        } else {
            ({ data: updatedQuestion } = yield call(createQuestion, Question));
            yield put(createQuestionCompleted({ question: updatedQuestion }));
        }
    } catch (error) {
        if (id) {
            yield put(updateQuestionFailed({ error }));
        } else {
            yield put(createQuestionFailed({ error }));
        }
    }
}

function* deleteQuestionSaga({ payload: { id } }) {
    try {
        yield call(deleteQuestion, id);
        yield put(deleteQuestionCompleted({ id }));
    } catch (error) {
        yield put(deleteQuestionFailed({ error }));
    }
}

function* flagQuestionSaga({ payload: { id, ...data } }) {
    try {
        yield call(flagQuestion, id, data);
        yield put(flagQuestionCompleted());
    } catch (error) {
        yield put(flagQuestionFailed({ error }));
    }
}

function* loadRelatedUsersSaga({ payload: { questionId } }) {
    try {
        const { data: { userCount } } = yield call(getRelatedUsersCount, questionId);
        yield put(loadRelatedUsersCountCompleted({ questionId, userCount }));
    } catch (error) {
        yield put(loadRelatedUsersCountFailed({ error }));
    }
}

function* loadEligibleUsersSaga({ payload: { questionId } }) {
    try {
        const { data: { userCount } } = yield call(getEligibleUsersCount, questionId);
        yield put(loadEligibleUsersCountCompleted({ questionId, userCount }));
    } catch (error) {
        yield put(loadEligibleUsersCountFailed({ error }));
    }
}

function* loadQuestionNpisSaga() {
    try {
        const { data: topicNpi } = yield call(getQuestionNpis);
        yield put(loadQuestionsNpisCompleted({ questionsNpis: topicNpi }));
    } catch (error) {
        yield put(loadQuestionsNpisFailed({ error }));
    }
}

function* updateQuestionsNpisSaga({ payload }) {
    try {
        yield call(updateQuestionsNpis, payload);
        yield put(updateQuestionsNpisCompleted());
    } catch (error) {
        yield put(updateQuestionsNpisFailed({ error }));
    }
}

function* setQuestionsSaga() {
    yield all([
        takeEvery(LOAD_QUESTIONS, loadSaga),
        takeEvery(UPDATE_QUESTION, updateQuestionSaga),
        takeEvery(DELETE_QUESTION, deleteQuestionSaga),
        takeLatest(LOAD_QUESTION, loadSingleSaga),
        takeLatest(LOAD_NEXT_QUESTION, loadNextQuestionSaga),
        takeLatest(FLAG_QUESTION, flagQuestionSaga),
        takeLatest(LOAD_RELATED_USERS_COUNT, loadRelatedUsersSaga),
        takeLatest(LOAD_ELIGIBLE_USERS_COUNT, loadEligibleUsersSaga),
        takeLatest(LOAD_QUESTIONS_NPIS, loadQuestionNpisSaga),
        takeLatest(UPDATE_QUESTIONS_NPIS, updateQuestionsNpisSaga),
    ]);
}

export default setQuestionsSaga;
