import * as React from 'react';
import { Field } from 'redux-form';
import { Form, Message, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
    TextInput,
    TextArea,
    Autocomplete,
    DatePicker,
    FormCheckbox,
    Dropdown,
} from '../../../../components';
import localization from '../../../../localization';
import './style.scss';

const {
    common: {
        fieldNames,
        cancel,
        initiatingTeamOptions,
    },
    admin: {
        questions: {
            questionForm: strings,
        },
    },
} = localization;

const audienceFields = (useNpiMap, isUpdate, specialties, subSpecialties) => {
    if (useNpiMap) {
        if (isUpdate) return null;

        return (
            <Field
                component={Form.Field}
                control={TextArea}
                label={fieldNames.npiMap}
                name="npiMap"
            />
        );
    }

    return (
        <React.Fragment>
            <Field
                component={Form.Field}
                control={Dropdown}
                label={fieldNames.specialty}
                options={specialties}
                name="specialties"
                multiple
            />
            <Field
                component={Form.Field}
                control={Dropdown}
                label={fieldNames.subSpecialty}
                options={subSpecialties}
                name="subspecialties"
                multiple
            />
        </React.Fragment>
    );
};

const QuestionForm = ({
    isFormValid,
    handleSubmit,
    handleCancel,
    specialties,
    subSpecialties,
    loading,
    error,
    isUpdate,
    searchAuthor,
    results,
    searchLoading,
    backAction,
    formValues,
}) => (
    <div className="questions-form">
        <div className="header">
            <Button icon="arrow left" basic onClick={() => backAction()} />
            <div className="header-text">{isUpdate ? strings.editTitle : strings.createTitle}</div>
        </div>
        <div className="page-container">
            <Form loading={loading}>
                <Message
                    error
                    visible={!!error}
                    content={error}
                />
                <Field
                    component={Form.Field}
                    control={Autocomplete}
                    label={strings.author}
                    name="author"
                    onSearch={searchAuthor}
                    results={results}
                    loading={searchLoading}
                    required
                />
                <Field
                    component={Form.Field}
                    control={TextInput}
                    label={fieldNames.title}
                    name="title"
                    required
                />
                <Field
                    component={Form.Field}
                    control={TextArea}
                    label={fieldNames.question}
                    name="description"
                    required
                />
                <Field
                    component={Form.Field}
                    control={TextInput}
                    label={fieldNames.therapyArea}
                    name="therapyArea"
                    required
                />
                <Field
                    component={Form.Field}
                    control={TextInput}
                    label={fieldNames.primaryIndication}
                    name="primaryIndication"
                    required
                />
                <Field
                    component={Form.Field}
                    control={TextInput}
                    label={fieldNames.secondaryIndication}
                    name="secondaryIndication"
                    required
                />
                <Field
                    component={Form.Field}
                    control={TextInput}
                    label={fieldNames.stageProgression}
                    name="stageProgression"
                    required
                />
                <Field
                    component={Form.Field}
                    control={Dropdown}
                    label={fieldNames.initiatingTeam}
                    options={initiatingTeamOptions}
                    name="initiatingTeam"
                    required
                />
                <Field
                    component={Form.Field}
                    control={FormCheckbox}
                    options={[true, false]}
                    label={fieldNames.useNpiMap}
                    name="useNpiMap"
                />
                {audienceFields(
                    formValues && formValues.useNpiMap,
                    isUpdate,
                    specialties,
                    subSpecialties,
                )}
                <Field
                    component={DatePicker}
                    label={strings.expirationDate}
                    name="expirationDate"
                    required
                />
                <Field
                    component={DatePicker}
                    label={strings.publishDate}
                    name="publishDate"
                    required
                />
                <div className="form-buttons">
                    <Form.Field>
                        <Button
                            type="button"
                            onClick={handleCancel}
                            secondary
                        >{cancel}
                        </Button>
                    </Form.Field>
                    <Form.Button
                        onClick={handleSubmit}
                        primary
                        disabled={!isFormValid}
                    >{isUpdate ? strings.updateQuestion : strings.createQuestion}
                    </Form.Button>
                </div>
            </Form>
        </div>
    </div>);

QuestionForm.propTypes = {
    isFormValid: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    specialties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    subSpecialties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    results: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    searchAuthor: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    searchLoading: PropTypes.bool.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    backAction: PropTypes.func.isRequired,
    error: PropTypes.string,
    formValues: PropTypes.shape().isRequired,
};

QuestionForm.defaultProps = {
    error: null,
};

export default QuestionForm;
