import {
    required,
    isEmail,
    isNumber,
    isNpiValid,
    isNpiLengthValid,
} from '../../../../utils/form/validators';

export const isEmailAndNpiValid = (npi, email) => {
    const npiValidationError = isNumber(npi) || isNpiLengthValid(npi) || isNpiValid(npi);
    const emailValidationError = isEmail(email);
    return !npiValidationError && !emailValidationError;
};

const syncValidations = ({
    firstName,
    lastName,
    email,
    roleId,
    npi,
    appHcUserId,
}, { appHcUserIds }) => ({
    firstName: required(firstName && firstName.trim()),
    lastName: required(lastName && lastName.trim()),
    email: required(email) || isEmail(email),
    roleId: required(roleId),
    npi: isNumber(npi) || isNpiLengthValid(npi) || isNpiValid(npi),
    appHcUserId: appHcUserIds?.length > 0 ? required(appHcUserId) : undefined,
});

export default syncValidations;
