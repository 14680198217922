// we won't have any sort of unique ID to operate on
// so overriding this lint rule is ok.
/* eslint-disable react/no-array-index-key */
import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './consensusPreview.scss';
import { consensusShape } from './shared/types';
import Chip from '../Chip';
import { markdownObjToSafeHtml } from '../../utils/markdownObjToSafeHtml';
import Ad from '../../ui/components/Ad';

const QuotesBlock = ({ quotes }) => (
    <div className="quotes-container">
        {quotes.map(({
            Quote,
            Author,
            AvatarImage,
            Specialty,
        }, i) => {
            // if either quote or author happen to be blank
            // do not render the quote-block
            if (!Quote || !Author) return null;

            /* eslint-disable react/no-danger */
            return (
                <div key={`quote-${i}`} className="quote">
                    <div className="quote-text" dangerouslySetInnerHTML={{ __html: Quote }} />
                    {AvatarImage && AvatarImage.data ? (
                        <div className="quote-info-avatar">
                            <img className="quote-avatar" src={AvatarImage.data.attributes.url} alt="author avatar" />
                            <span className="quote-author">{Author}</span>
                            {Boolean(Specialty) && <span className="quote-author">, {Specialty}</span>}
                        </div>
                    ) : (
                        <div className="quote-info-empty">
                            <span className="quote-author">&mdash; {Author}</span>
                            {Boolean(Specialty) && <span className="quote-author">, {Specialty}</span>}
                        </div>
                    )}
                </div>
            );
        })}
    </div>
);

QuotesBlock.propTypes = {
    quotes: PropTypes.arrayOf(PropTypes.shape({
        Quote: PropTypes.string,
        Author: PropTypes.string,
    })).isRequired,
};

const SectionsHeader = ({ sectionLabel, sections }) => {
    const sectionTopics = [];

    if (sections.length) {
        sections.forEach((section) => {
            if (section.SectionTopic) {
                sectionTopics.push(<a href={`#cs-section-${section.id}`} key={section.id}><Chip variant="blue">{section.SectionTopic}</Chip></a>);
            }
        });
    }

    return (
        <div id="section-topic-container">
            {sectionTopics.length ? sectionTopics : <Chip variant="blue">{sectionLabel || 'Differing consults'}</Chip>}
        </div>
    );
};

SectionsHeader.propTypes = {
    sectionLabel: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object),
};

SectionsHeader.defaultProps = {
    sectionLabel: null,
    sections: [],
};

const SourcesContent = ({ sourcesData, patientCasesData }) => {
    let sources = [];


    if (sourcesData.length) {
        sources = sourcesData.map((source) => {
            const obj = source;
            const parsedSource = markdownObjToSafeHtml({ obj, parseList: ['sourceType', 'sourceContent'], inline: true });
            const {
                id,
                sourceType,
                sourceContent,
                sourceDate,
            } = parsedSource;

            return (
                <p className="source-information" key={`source-${id}`}>
                    <span dangerouslySetInnerHTML={{ __html: sourceType }} /> <span dangerouslySetInnerHTML={{ __html: sourceContent }} /> {sourceDate}
                </p>
            );
        });
    } else {
        sources = patientCasesData.map(({
            CaseNumber,
            PublishDate,
            ResponseCount,
        }) => {
            const casePublishDate = moment(PublishDate).format('MMMM YYYY');
            return (
                <p className="source-information" key={`source-${CaseNumber}`}>
                    Healthcasts HCP Community, Patient Case: {CaseNumber},
                    n = {ResponseCount} respondents, {casePublishDate}
                </p>
            );
        });
    }

    let sourcesContainer = null;

    if (sources.length) {
        sourcesContainer = (
            <div id="sources-container">
                <div id="sources-label">
                    <b>Source:</b>
                </div>
                <div id="source-content">
                    {sources}
                </div>
            </div>);
    }

    return sourcesContainer;
};

SourcesContent.propTypes = {
    sourcesData: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        sourceType: PropTypes.string,
        sourceContent: PropTypes.string,
        sourceDate: PropTypes.string,
    })),
    patientCasesData: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        CaseNumber: PropTypes.number,
        CaseText: PropTypes.string,
        PublishDate: PropTypes.string,
        ResponseCount: PropTypes.number,
    })),
};

export const ConsensusContent = ({ resourceId, consensusData, shouldRenderAd }) => {
    const obj = JSON.parse(JSON.stringify(consensusData));
    const parsedSummaryData = markdownObjToSafeHtml({
        obj,
        parseList: [
            'AnalysisText',
            'CaseText',
            'ConclusionText',
            'ConsensusOpinionStatement',
            'Quote',
            'Text',
        ],
    });
    const {
        ConsensusTitle,
        ConsensusOpinionStatement,
        PatientCases,
        ClinicalAnalysis,
        Conclusion,
        SectionLabel,
        Sections,
        Definitions,
        References,
        Sources,
        Author,
    } = parsedSummaryData;

    /* eslint-disable react/no-danger */
    return (
        <div className="consensus-container">
            <div id="consensus-top-header" className="consensus-header">
                {shouldRenderAd && <Ad page="CASECONSENSUS" placement="TOP" resourceId={resourceId} />}
                <h1>{ConsensusTitle}</h1>
                {Author &&
                    <div className="author">
                        {Author.AvatarImage && Author.AvatarImage.data &&
                            <img className="author-avatar" src={Author.AvatarImage.data.attributes.url} alt="author avatar" />
                        }
                        <div className="author-info">
                            <div className="author-name">{Author.Name}</div>
                            <div className="author-titles">{Author.Titles}</div>
                        </div>
                    </div>
                }
                {parsedSummaryData.PatientCases.map(({
                    CaseText,
                    CaseNumber,
                    PublishDate,
                    ResponseCount,
                }) => {
                    const casePublishDate = moment(PublishDate).format('MMMM YYYY');
                    return (
                        <div key={CaseNumber} className="case">
                            <div className="case-title">
                                <Chip variant="purple">Patient Case: {CaseNumber}</Chip>
                                <p className="case-data">{ResponseCount} respondents - {casePublishDate}</p>
                            </div>
                            {parsedSummaryData && <div className="case-text" dangerouslySetInnerHTML={{ __html: CaseText }} />}
                        </div>
                    );
                })}
            </div>
            <div className="consensus-body">
                <Chip variant="blue">Summary Statement</Chip>
                <div className="title" dangerouslySetInnerHTML={{ __html: ConsensusOpinionStatement }} />
                {parsedSummaryData && <div dangerouslySetInnerHTML={{ __html: parsedSummaryData.ClinicalAnalysis?.AnalysisText }} />}
                {!!ClinicalAnalysis?.Quotes.length && (
                    <QuotesBlock quotes={ClinicalAnalysis.Quotes} />
                )}
                {!!Sections.length && !Sections.filter(s => (!s.Title || !s.Text)).length && <SectionsHeader sectionLabel={SectionLabel} sections={Sections} />}
                {!!Sections.length && Sections.map(({
                    id, Title, Text, Quotes,
                }, i) => {
                    if (!Title || !Text) return null;

                    return (
                        <div key={`section-${i}`} id={`cs-section-${id}`} className="section">
                            <div className="title">{Title}</div>
                            <div dangerouslySetInnerHTML={{ __html: Text }} />
                            {Quotes.length >= 1 && (
                                <QuotesBlock quotes={Quotes} />
                            )}
                            <div className="back-to-top-btn-box">
                                <a className="back-to-top-btn" href="#consensus-top-header">^Top</a>
                            </div>
                        </div>
                    );
                })}
                {Conclusion && Conclusion.ConclusionTitle && (
                    <Fragment>
                        <h2>Conclusion</h2>
                        <div className="title">{Conclusion.ConclusionTitle}</div>
                        {parsedSummaryData && <div dangerouslySetInnerHTML={{ __html: parsedSummaryData.Conclusion.ConclusionText }} />}
                        {!!Conclusion.Quotes.length && (
                            <QuotesBlock quotes={Conclusion.Quotes} />
                        )}
                    </Fragment>
                )}
                {!!Definitions.length && !!Definitions.filter(d => (!!d.Term || !!d.Definition)).length && (
                    <div className="definitions">
                        <h2>Definitions</h2>
                        <ul>
                            {Definitions.map(({ Term, Definition }, i) => {
                                if (!Term || !Definition) return null;
                                return (
                                    <li key={`definition-${i}`} >
                                        <p>{Term} = {Definition}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {!!References.length && !!References.filter(r => (!!r.Text)).length && (
                    <div className="references">
                        <h2>References</h2>
                        <ol>
                            {References.map(({ Text, Url, LinkText }, i) => {
                                if (!Text) return null;
                                const doc = new window.DOMParser().parseFromString(Text, 'text/html');
                                const referenceText = doc.firstChild.textContent;
                                return (
                                    <li key={`reference-${i}`}>
                                        <p>
                                            {referenceText} {!!Url && <a href={Url} target="_blank" rel="noopener noreferrer">{LinkText || Url}</a>}
                                        </p>
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                )}
                <SourcesContent sourcesData={Sources} patientCasesData={PatientCases} />
            </div>
        </div>
    );
};

ConsensusContent.propTypes = {
    consensusData: consensusShape.isRequired,
    resourceId: PropTypes.string,
    shouldRenderAd: PropTypes.bool,
};

ConsensusContent.defaultProps = {
    resourceId: null,
    shouldRenderAd: true,
};

const ConsensusPreview = ({
    consensusData,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="preview-container">
            <h1>Preview your consensus.</h1>
            <p>Review your summarization of the patient case.</p>
            <ConsensusContent consensusData={consensusData.data} />
        </div>
    );
};

ConsensusPreview.propTypes = {
    consensusData: PropTypes.shape({ data: consensusShape }).isRequired,
};

export default ConsensusPreview;
