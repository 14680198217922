import { all } from 'redux-saga/effects';
import UserListSaga from './Users/List/sagas';
import EditUsersSaga from './Users/CreateOrEdit/sagas';
import { saga as QuestionsSaga } from './Questions';

export default function* moderatorSagas() {
    yield all([
        UserListSaga(),
        EditUsersSaga(),
        QuestionsSaga(),
    ]);
}
