import { connect } from 'react-redux';
import { compose } from 'redux';
import { WithRouterHOC } from '../../../../components/Router';
import { searchUsers as searchUsersAction } from './actions';
import Container from './container';
import usersSelector from './selectors';
import { ID as ModeratorID } from '../../';
import { ID } from './actionTypes';
import { showModal as showModalAction } from '../../../../services/sharedUIActions';
import {
    deleteUser as deleteUserAction,
    deleteStatusSelector,
    loadUsers,
} from '../../../../data/users';
import { userIdSelector } from '../../../../services/session';
import { exportUsersList } from '../../../../data/campaigns';
import { ID as DataID, CampaignsID } from '../../../../data';

export { ID };
export { default as reducer } from './reducer';
export { default as saga } from './sagas';

const mapStateToProps = (state, {
    location: {
        query: {
            pageNumber,
            count,
            searchValue,
        },
    },
}) => {
    const {
        [ModeratorID]: {
            [ID]: {
                total,
                userToDelete,
                loading,
            },
        },
        [DataID]: {
            [CampaignsID]: {
                currentlyExporting,
                expiresAt,
            },
        },
    } = state;

    return {
        users: usersSelector(state),
        total,
        pageNumber: pageNumber && parseInt(pageNumber, 10),
        pageSize: count && parseInt(count, 10),
        searchValue,
        ownId: userIdSelector(state),
        deleteInProgress: deleteStatusSelector(state),
        userToDelete,
        currentlyExporting,
        isExpired: !(expiresAt && new Date(expiresAt) < new Date()),
        loading,
    };
};

export default compose(
    WithRouterHOC,
    connect(mapStateToProps, {
        loadUsers,
        searchUsers: searchUsersAction,
        showModal: showModalAction,
        deleteUser: deleteUserAction,
        exportUsersList,
    }),
)(Container);
