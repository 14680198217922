import { reduxForm, reset, formValueSelector, change } from 'redux-form';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import head from 'lodash/head';
import { ID as DataID, SpecialtyID } from '../../../../../data';
import { WithRouterHOC } from '../../../../../components/Router';
import { loadQuestionsSearch as loadAction } from '../../../../../data/questions';
import { questionStatus } from '../../../../../data/enums';

import Container from './component';

const formName = 'question_search';

const selector = formValueSelector(formName);

const {
    scheduled: scheduledEnum,
    active: activeEnum,
    expired: expiredEnum,
} = questionStatus;

const searchFilter = (values) => {
    const {
        status: {
            scheduled,
            active,
            expired,
        },
        ...others
    } = values;
    const status = [];
    if (scheduled) status.push(scheduledEnum);

    if (active) status.push(activeEnum);

    if (expired) status.push(expiredEnum);

    return { ...others, status };
};

const mapStateToProps = (state) => {
    const {
        [DataID]: {
            [SpecialtyID]: {
                specialties,
                loadingSpecialties,
            },
        },
    } = state;

    let scheduledReadOnly = false;
    let activeReadOnly = false;
    let expiredReadOnly = false;
    let statusArray = [];

    const statusValue = selector(state, 'status');
    if (statusValue) {
        statusArray = searchFilter({ status: statusValue }).status;
        if (statusArray.length === 1) {
            const status = head(statusArray);
            if (status === scheduledEnum) {
                scheduledReadOnly = true;
            } else if (status === activeEnum) {
                activeReadOnly = true;
            } else if (status === expiredEnum) {
                expiredReadOnly = true;
            }
        }
    }

    return {
        specialties: specialties.map(({
            id,
            name,
        }) => ({
            key: id,
            value: id,
            text: name,
        })),
        loading: loadingSpecialties,
        scheduledReadOnly,
        activeReadOnly,
        expiredReadOnly,
    };
};

const mapDispatchToProps = {
    resetSearchValues: () => (reset(formName)),
    loadQuestionsSearch: loadAction,
    changeSearchValues: (scheduled, active, expired, cancel) => change('question_search', 'status', {
        scheduled,
        active,
        expired,
        cancel,
    }),
};

export default compose(
    WithRouterHOC,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: formName,
        initialValues: {
            status: {
                active: true,
                scheduled: true,
                expired: false,
            },
            term: '',
            specs: [],
        },
        onChange: (values, dispatch, { loadQuestionsSearch }) => {
            const {
                cancel,
                ...changeValues
            } = values;

            if (!cancel) {
                loadQuestionsSearch({ values: searchFilter(changeValues) });
            }
        },
    }),
)(Container);
