import { all, takeEvery, select } from 'redux-saga/effects';
import Raven from 'raven-js';
import { failedActionRegex, error4xxRegex } from './config';
import { userIdSelector } from '../session/selectors';
import { THROW_FAILED } from './actionTypes';

// list of actions we do not want to be logged to sentry
const failedActionTypesBlacklist = [
    '@@redux-form/SET_SUBMIT_FAILED',
];

function* errorHandleSaga({
    payload: { error, customMessage } = { error: null, customMessage: null },
    type = null,
}) {
    try {
        if (error && error.message === 'Logout') {
            yield Raven.captureMessage('Warning: session expired', {
                extra: {
                    error,
                },
                level: 'warning',
                tags: {
                    action: type,
                },
            });

            return;
        }

        const userId = yield select(userIdSelector);

        let message = null;
        if (type === THROW_FAILED) {
            message = customMessage;
        } else {
            message = type.toLowerCase();
        }

        if ((error || customMessage) && type) {
            const errorStatus = error && error.status ? error.status : 500;
            const level = error4xxRegex.test(errorStatus) ? 'warning' : 'error';
            const label = error4xxRegex.test(errorStatus) ? 'Warning' : 'Error';
            yield Raven.captureMessage(`${label}: ${message}`, {
                extra: {
                    error,
                },
                level,
                tags: {
                    userId,
                },
            });
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

export default function* actionHandleSaga() {
    yield all([
        takeEvery(action => failedActionRegex.test(action.type) &&
            !failedActionTypesBlacklist.includes(action.type), errorHandleSaga),
    ]);
}
