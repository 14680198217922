import axios from 'axios';
import { showErrorToast, showGlobalErrorToast } from '../containers/Toast';
import { store } from '../store';
import { API_URL } from '../environment';
import { logoutUser } from '../services/session';

const audience = process.env.AUTH0_AUDIENCE;

// Cache-Control and Pragma headers are necessary for IE11 cache
const api = axios.create({
    headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
    },
    baseURL: API_URL,
});

// This functionality comes from auth0. Because we're using the react library,
// we need to pass in the `getAccessTokenSilently` method from the `useAuth0` hook
// in `react/src/index.jsx`.
let _getAccessToken; // eslint-disable-line no-underscore-dangle
let _logout; // eslint-disable-line no-underscore-dangle
const init = (getAccessToken, logout) => {
    _getAccessToken = getAccessToken;
    _logout = logout;
};

let authError = null;
const handleAuthError = (error) => {
    // If additional API calls are made after the user's session has expired,
    // early return to prevent multiple global error messages from being shown.
    if (authError) return;
    authError = error;
    store.dispatch(showErrorToast({
        header: 'Looks like your session has expired.',
        body: 'You will be automatically logged out in 5 seconds.',
    }));

    setTimeout(() => {
        store.dispatch(logoutUser());
        _logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
    }, 5000);
};

async function apiCall(url, {
    method,
    headers = {},
    ...options
}, requiresAuthorization = true) {
    if (authError) throw authError;
    try {
        const requestOptions = {};

        if (requiresAuthorization) {
            try {
                const accessToken = await _getAccessToken({ audience });
                requestOptions.headers = {
                    Authorization: `Bearer ${accessToken}`,
                };
            } catch (error) {
                handleAuthError(error);
                throw error;
            }
        }

        const request = {
            ...requestOptions,
            ...options,
            headers: {
                ...requestOptions.headers,
                ...headers,
            },
            url,
            method,
        };

        try {
            const response = await api(request);
            return response && response.data;
        } catch (e) {
            const error = (e && e.response) || e;
            throw error;
        }
    } catch (e) {
        // `getAccessTokenSilently` might have _seemed_ to succeed
        if (e.status === 401 || e.status === 403) {
            handleAuthError(e);
        } else if (e.status === 500) {
            store.dispatch(showGlobalErrorToast());
            throw e;
        }
        throw e;
    }
}

export const makeRequest = apiCall;

export function getRequest(url, options, requiresAuthorization = true) {
    return makeRequest(url, { ...options, method: 'get' }, requiresAuthorization);
}

export function postRequest(url, options, requiresAuthorization = true) {
    return makeRequest(url, { ...options, method: 'post' }, requiresAuthorization);
}

export function patchRequest(url, options) {
    return makeRequest(url, {
        ...options,
        method: 'patch',
    });
}

export function deleteRequest(url, options) {
    return makeRequest(url, {
        ...options,
        method: 'delete',
    });
}

export default {
    get: getRequest,
    post: postRequest,
    patch: patchRequest,
    delete: deleteRequest,
    init,
};
