import React, { Component } from 'react';
import { Header, Accordion } from 'semantic-ui-react';
import localization from '../../../localization';
import { PrivacyPolicy, policyAgreement } from '../../../components';

const {
    disclaimer: {
        title,
        text,
        privacyPolicy,
    },
} = localization;

class Disclaimer extends Component {
    state = {
        privacyPolicyOpen: false,
    }

    togglePrivacyPolicy = () => {
        this.setState(prevState => ({ privacyPolicyOpen: !prevState.privacyPolicyOpen }));
    }

    render() {
        const {
            privacyPolicyOpen,
        } = this.state;

        return (
            <div className="page-container">
                <Header content={title} />
                <p>{text}</p>
                <br />
                <Accordion >
                    <Accordion.Title
                        active={privacyPolicyOpen}
                        content={privacyPolicy}
                        onClick={this.togglePrivacyPolicy}
                    />
                    <Accordion.Content
                        active={privacyPolicyOpen}
                        content={<PrivacyPolicy />}
                    />
                </Accordion>
                <br />
                {policyAgreement}
            </div>
        );
    }
}

export default Disclaimer;
