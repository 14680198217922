import { programs } from '../../api/endpoints';
import client from '../../api';

const {
    index,
    programTest,
    dtiLink,
} = programs;

export const getProgramForUser =
    topicId => client.get(index, { params: topicId ? { topic_id: topicId } : {} });

export const getDtiForUser = () => client.get(dtiLink);

export const markProgramAsRead = (
    programId,
    commentId,
    topicId,
    fromCommentsView,
) => client.post(index, {
    data: {
        programId,
        commentId,
        topicId,
        fromCommentsView,
    },
});

export const getProgramPage = (programId, npi) => client.get(programTest, {
    params: {
        program_id: programId,
        npi,
    },
});
