import React, { useEffect, useState, Fragment } from 'react';
import HTMLtoDOCX from 'html-to-docx';
import PropTypes from 'prop-types';
import { html_beautify as htmlBeautify } from 'js-beautify';
import Button from '../../components/Button';
import { ConsensusContent } from '../../components/ConsensusSummarySubmission/ConsensusPreview';
import DemoUserActionLinkTiles from '../../components/ActionLinkTile/demoUserActionLinkTiles';
import HcpUserActionLinkTiles from '../../components/ActionLinkTile/hcpUserActionLinkTiles';
import { footerContent, wrapAssetMarkup, wrapTileMarkup } from './util';
import { renderToStaticMarkup } from '../../utils/renderToStaticMarkup';
import { consensusShape } from '../../components/ConsensusSummarySubmission/shared/types';
import Tile from './tile';
import SummaryContent from './content';

// function for creating file in memory
function download(filename, href) {
    const element = document.createElement('a');
    // eslint-disable-next-line prefer-template
    element.setAttribute('href', href);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

const SummaryPreviewAdmin = ({ fetchConsensusSummary, resourceId, resource }) => {
    const [errorText, setErrorText] = useState();

    useEffect(() => {
        if (resourceId) {
            fetchConsensusSummary(resourceId, setErrorText);
        }
    }, [resourceId]);

    const handleGenerateSummary = () => {
        const html = renderToStaticMarkup(<ConsensusContent consensusData={resource} shouldRenderAd={false} />);

        const footer = footerContent(
            renderToStaticMarkup(<DemoUserActionLinkTiles />),
            renderToStaticMarkup(<HcpUserActionLinkTiles />),
        );

        const assetMarkup = wrapAssetMarkup(html, footer);

        const beautified = htmlBeautify(assetMarkup);
        const href = `data:text/plain;charset=utf-8,${encodeURIComponent(beautified)}`;

        // TODO: we should generate a name based on the assigned ProgramID;
        download('Test_Asset.php', href);
    };

    const handleGenerateSummaryDOCX = async () => {
        const html = htmlBeautify(renderToStaticMarkup(<ConsensusContent consensusData={resource} shouldRenderAd={false} />));

        const fileBuffer = await HTMLtoDOCX(html, null, {
            table: { row: { cantSplit: true } },
            footer: false,
            pageNumber: false,
        });

        // TODO: we should generate a name based on the assigned ProgramID;
        download('Test_Asset.docx', URL.createObjectURL(fileBuffer));
    };

    const handleGenerateTile = () => {
        const { ConsensusType, SummaryTile } = resource;

        const html = renderToStaticMarkup(<Tile
            assetType={ConsensusType}
            title={SummaryTile.Title}
            ctaText={SummaryTile.CtaText}
            authorName={SummaryTile.AuthorName}
            authorTitle={SummaryTile.AuthorTitle}
            image={SummaryTile.Image}
            docdxUrl={`${window.location.origin}/consensuses/${resourceId}`}
        />);

        const tileMarkup = wrapTileMarkup(html);
        const beautified = htmlBeautify(tileMarkup);
        const href = `data:text/plain;charset=utf-8,${encodeURIComponent(beautified)}`;

        // TODO: we should generate a name based on the assigned ProgramID;
        download('Test_Tile.php', href);
    };

    if (!resource && !errorText) {
        return null;
    }

    if (errorText) {
        return (
            <div>
                {errorText}
            </div>
        );
    }

    const { ConsensusType, SummaryTile } = resource;

    return (
        <div className="summary-preview-container">
            {SummaryTile && ConsensusType &&
                <Fragment>
                    <Button onClick={handleGenerateTile}>Export Tile</Button>
                    <Tile
                        assetType={ConsensusType}
                        title={SummaryTile.Title}
                        ctaText={SummaryTile.CtaText}
                        authorName={SummaryTile.AuthorName}
                        authorTitle={SummaryTile.AuthorTitle}
                        image={SummaryTile.Image}
                        docdxUrl={`${window.location.origin}/consensuses/${resourceId}`}
                    />
                </Fragment>
            }
            <div className="export-summary-container">
                <Button className="export-summary" onClick={handleGenerateSummary}>Export Summary</Button>
                <Button className="export-summary" onClick={handleGenerateSummaryDOCX}>Export DOCX</Button>
            </div>
            <SummaryContent resource={resource} />;
        </div>
    );
};

SummaryPreviewAdmin.propTypes = {
    resourceId: PropTypes.string,
    resource: PropTypes.shape({ data: consensusShape }),
    fetchConsensusSummary: PropTypes.func.isRequired,
};

SummaryPreviewAdmin.defaultProps = {
    resourceId: null,
    resource: null,
};

export default SummaryPreviewAdmin;
