import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import Raven from 'raven-js';
import { ID as SessionID } from '../../services/session';
import Button from '../components/button';
import ConsensusTile from '../components/consensusTile';
import Pagination from '../components/pagination';
import Dropdown from '../components/dropdown';
import DropdownItem from '../components/dropdownItem';
import api from '../../api';
import useAdButler from '../../hooks/useAdButler';

const CaseConsensuses = ({ userSpecialty }) => {
    const totalPages = 12;
    const pageSize = 100; // intentionally keeping this high to avoid pagination for now
    const [specialty, setSpecialty] = useState(userSpecialty);
    const [specialties, setSpecialties] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSpecialtyDropdownOpen, setIsSpecialtyDropdownOpen] = useState(false);
    const [consensuses, setConsensuses] = useState([]);
    const ad = useAdButler('CASE_CONSENSUSES', 'TOP');

    useEffect(() => {
        async function getAllSpecialties() {
            try {
                const allSpecialties = await api.get('/specialties');
                const sortedSpecialties = allSpecialties.data.sort((x, y) => x.name.localeCompare(y.name));
                setSpecialties(sortedSpecialties);
            } catch (error) {
                Raven.captureException(error);
            }
        }
        getAllSpecialties();
    }, []);

    useEffect(() => {
        async function getConsensuses() {
            try {
                const newConsensuses = await api.get(`/strapi/consensuses?specialty=${specialty}`);
                setConsensuses(newConsensuses.data);
            } catch (error) {
                Raven.captureException(error);
            }
        }
        getConsensuses();
    }, [specialty]);

    const toggleSpecialtyDropdown = () => {
        setIsSpecialtyDropdownOpen(!isSpecialtyDropdownOpen);
    };

    const closeSpecialtyDropdown = () => {
        setIsSpecialtyDropdownOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSpecialtyChange = (newSpecialty) => {
        setSpecialty(newSpecialty);
        closeSpecialtyDropdown();
    };

    return (
        <>
            <div className="px-6 py-8 md:px-12 md:py-10 lg:px-[120px] lg:py-20 max-w-screen-xl m-auto">
                <h1 className="title-h1 mb-3">Case Consensuses</h1>
                <p className="body-m text-purple-gray-600">Read and respond to Consensuses of the other expert consults provided on a patient case within your, or other, specialties.</p>
            </div>
            <div className="bg-white px-6 py-8 md:px-12 md:py-10 lg:px-[120px] max-w-screen-xl m-auto">
                <div className="flex justify-center">{ad}</div>
                <h2 className="title-h2 mb-6">All Case Consensuses for &quot;{specialty}&quot;</h2>
                <div className="relative inline-block text-left">
                    <Button
                        variant="outlined"
                        className="mb-6"
                        onClick={toggleSpecialtyDropdown}
                    >
                        Filter: {specialty}
                        <ChevronDownIcon />
                    </Button>
                    {isSpecialtyDropdownOpen && (
                        <Dropdown className="-mt-4">
                            {
                                specialties.map(({ id, name }) => (
                                    <DropdownItem
                                        key={id}
                                        selectedItem={specialty}
                                        onItemClick={handleSpecialtyChange}
                                        onEscape={closeSpecialtyDropdown}
                                    >
                                        {name}
                                    </DropdownItem>
                                ))
                            }
                        </Dropdown>
                    )}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    {
                        consensuses.map(({ id, attributes }) => (
                            <ConsensusTile
                                key={id}
                                assetType={attributes.ConsensusType}
                                title={attributes.SummaryTile.Title}
                                authorName={attributes.SummaryTile.AuthorName}
                                authorTitle={attributes.SummaryTile.AuthorTitle}
                                imageUrl={attributes.SummaryTile.Image.data.attributes.url}
                                href={`/consensuses/${id}`}
                            />
                        ))
                    }
                </div>
                {
                    consensuses.length > pageSize && (
                        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                    )
                }
            </div>
        </>
    );
};

CaseConsensuses.propTypes = {
    userSpecialty: PropTypes.string,
};

CaseConsensuses.defaultProps = {
    userSpecialty: null,
};

const mapStateToProps = (state) => {
    const {
        [SessionID]: {
            specialty,
        },
    } = state;

    return {
        userSpecialty: specialty || 'Allergy / Immunology',
    };
};

export default connect(mapStateToProps, null)(CaseConsensuses);
