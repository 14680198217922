import { ID } from './actions';
import DataId from '../config';

/* eslint-disable import/prefer-default-export */

export const allAnswersSelector = ({
    [DataId]: {
        [ID]: {
            answers,
        },
    },
}) => answers;
