import { createAction } from 'redux-actions';
import {
    GET_GLOBAL_HCMP_LIST,
    GET_GLOBAL_HCMP_LIST_COMPLETED,
    GET_GLOBAL_HCMP_LIST_FAILED,
    CHANGE_HCMP_GLOBAL_PRIORITY,
    CHANGE_HCMP_GLOBAL_PRIORITY_COMPLETED,
    CHANGE_HCMP_GLOBAL_PRIORITY_FAILED,
    CHANGE_HCMP_GLOBAL_STATUS_COMPLETED,
    CHANGE_HCMP_GLOBAL_STATUS_FAILED,
    CHANGE_HCMP_GLOBAL_STATUS,
    CONFIRM_HCMP_GLOBAL,
    CONFIRM_HCMP_GLOBAL_COMPLETED,
    CONFIRM_HCMP_GLOBAL_FAILED,
} from './actionTypes';

export const getGlobalHcmpList = createAction(GET_GLOBAL_HCMP_LIST);
export const getGlobalHcmpListCompleted = createAction(GET_GLOBAL_HCMP_LIST_COMPLETED);
export const getGlobalHcmpListFailed = createAction(GET_GLOBAL_HCMP_LIST_FAILED);

export const changeHcmpGlobalPriority = createAction(CHANGE_HCMP_GLOBAL_PRIORITY);
export const changeHcmpGlobalPriorityCompleted =
    createAction(CHANGE_HCMP_GLOBAL_PRIORITY_COMPLETED);
export const changeHcmpGlobalPriorityFailed = createAction(CHANGE_HCMP_GLOBAL_PRIORITY_FAILED);

export const changeHcmpGlobalStatus = createAction(CHANGE_HCMP_GLOBAL_STATUS);
export const changeHcmpGlobalStatusCompleted = createAction(CHANGE_HCMP_GLOBAL_STATUS_COMPLETED);
export const changeHcmpGlobalStatusFailed = createAction(CHANGE_HCMP_GLOBAL_STATUS_FAILED);

export const confirmHcmpGlobalStatus = createAction(CONFIRM_HCMP_GLOBAL);
export const confirmHcmpGlobalStatusCompleted = createAction(CONFIRM_HCMP_GLOBAL_COMPLETED);
export const confirmHcmpGlobalStatusFailed = createAction(CONFIRM_HCMP_GLOBAL_FAILED);
