import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import {
    LOAD_ROLES,
    LOAD_ROLES_COMPLETED,
    LOAD_ROLES_FAILED,
} from './actionTypes';

const initialState = Immutable.from({
    roles: [],
    loading: false,
    loaded: false,
});

const reducer = handleActions({
    [LOAD_ROLES]: state =>
        state.merge({
            loading: true,
        }),
    [LOAD_ROLES_COMPLETED]: (state, { payload }) =>
        state.merge({
            roles: payload.data,
            loading: false,
            loaded: true,
        }),
    [LOAD_ROLES_FAILED]: state =>
        state.merge({
            loading: false,
        }),
}, initialState);

export default reducer;
