/* eslint-disable import/extensions */
import Marked from 'marked';
import DOMPurify from 'dompurify';

// add a class to paragraphs that contain a <strong> child
// this is used as an alternative to the has: selector in CSS, which has
// limited browser support
const renderer = new Marked.Renderer();
renderer.paragraph = (text) => {
    const hasStrongChild = /<strong>.*<\/strong>/.test(text);
    if (hasStrongChild) {
        return `<p class="has-strong">${text}</p>\n`;
    }
    return `<p>${text}</p>\n`;
};
Marked.use({ renderer });

// find and parse the Markdown text for every string within an Object
export const markdownObjToSafeHtml = ({ obj, parseList, inline = false }) => {
    if (typeof obj === 'undefined' || obj === null || typeof obj !== 'object') {
        return obj;
    }

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object') {
            markdownObjToSafeHtml({ obj: obj[key], parseList, inline });
        } else if (typeof obj[key] === 'string') {
            let html = null;
            if (parseList.includes(key)) {
                html = inline ? Marked.parseInline(obj[key]) : Marked.parse(obj[key]);
            } else {
                html = obj[key];
            }
            obj[key] = DOMPurify.sanitize(html);
        }
    });

    return obj;
};
