import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MagnifyingGlassIcon, ReaderIcon, ChatBubbleIcon } from '@radix-ui/react-icons';

const Benefit = ({ children }) => (
    <React.Fragment>
        {children[0]}
        <h3 className="font-bold text-base mt-0 mb-2 leading-tight">{children[1]}</h3>
        <p className="text-base mt-0 mb-2 leading-tight mb-6">{children[2]}</p>
    </React.Fragment>
);

Benefit.propTypes = {
    children: PropTypes.node.isRequired,
};

const BenefitSlideIndicator = ({ isActive }) => <div className={`w-2 h-2 rounded-full mr-[6px] ${isActive ? 'bg-purple-500' : 'bg-purple-300'}`} />;
BenefitSlideIndicator.propTypes = {
    isActive: PropTypes.bool.isRequired,
};

const BenefitsCarousel = ({ children }) => {
    const [index, setIndex] = useState(0);
    const [maxSlideHeight, setMaxSlideHeight] = useState(0);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const slideRef1 = useRef(null);
    const slideRef2 = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => prevIndex + 1);
        }, 3500);
        return () => clearInterval(interval);
    }, []);

    // Resizing the window causes the layout to change, which impacts the max height of the
    // slides, which impacts the positioning of the slide indicator widget.
    useEffect(() => {
        const handleResize = () => setViewportWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [setViewportWidth]);

    useEffect(() => {
        if (slideRef1.current && slideRef2.current) {
            const maxHeight = Math.max(slideRef1.current.offsetHeight, slideRef2.current.offsetHeight);
            if (maxHeight !== 0) {
                setMaxSlideHeight(maxHeight);
            }
        }
    }, [index, viewportWidth, setMaxSlideHeight]);

    return (
        <React.Fragment>
            <div className="relative md:hidden">
                <div ref={slideRef1} className={`absolute top-0 left-0 z-[1] transition-opacity duration-300 ease-out ${(index % 2 === 0) ? 'opacity-0' : 'opacity-100'}`}>
                    { children[((index % 2 === 0) ? index : index + 1) % children.length]}
                </div>
                <div ref={slideRef2} className={`absolute top-0 left-0 z-[2] transition-opacity duration-300 ease-out ${(index % 2 === 0) ? 'opacity-100' : 'opacity-0'}`}>
                    { children[((index % 2 === 0) ? index + 1 : index) % children.length]}
                </div>
                <div className="relative" style={{ height: `${maxSlideHeight}px` }} />
                <div className="flex mt-4">
                    <BenefitSlideIndicator isActive={index % children.length === 0} />
                    <BenefitSlideIndicator isActive={index % children.length === 1} />
                    <BenefitSlideIndicator isActive={index % children.length === 2} />
                </div>
            </div>
            <div className="hidden md:block">{children}</div>
        </React.Fragment>
    );
};

BenefitsCarousel.propTypes = {
    children: PropTypes.node.isRequired,
};

const ProductBenefits = () => (
    <div className="md:p-10 px-4 py-6 text-purple-600">
        <h2 className="font-title text-lg mb-6">Members of Healthcasts can...</h2>
        <BenefitsCarousel>
            <Benefit>
                <MagnifyingGlassIcon width={24} height={24} className="mb-3" />
                <span>Explore our consult database through search.</span>
                <span>Search treatments, diseases, and more to find patient cases and consults
    most relevant to your clinical questions.
                </span>
            </Benefit>
            <Benefit>
                <ReaderIcon width={24} height={24} className="mb-3" />
                <span>Discover second opinions to validate your treatment decision.</span>
                <span>Consult summaries provide you with a treatment consensus across cases relevant to your search.</span>
            </Benefit>
            <Benefit>
                <ChatBubbleIcon width={24} height={24} className="mb-3" />
                <span>Respond to cases to improve diagnostic outcomes.</span>
                <span>Provide your opinion while creating unique peer-to-peer connections across regions and disciplines.</span>
            </Benefit>
        </BenefitsCarousel>
    </div>
);

export default ProductBenefits;
