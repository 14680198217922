import React from 'react';
import PropTypes from 'prop-types';
import { CaretLeftIcon, CaretRightIcon, DotsHorizontalIcon } from '@radix-ui/react-icons';
import { getPaginationRange } from '../../utils/pagination';

const Dots = () => (
    <div className="flex w-8 h-8 p-1 justify-center items-center cursor-pointer">
        <DotsHorizontalIcon className="text-purple-500" height={16} width={16} />
    </div>
);

const Pagination = ({
    currentPage,
    totalPages,
    siblingCount,
    onPageChange,
}) => {
    const pages = getPaginationRange(currentPage, totalPages, siblingCount);

    return (
        <div className="inline-flex items-start">
            <button
                className="flex py-1 px-0 items-center rounded-md border-none bg-white cursor-pointer text-purple-500 disabled:text-gray-400 disabled:border-none"
                disabled={currentPage === 1}
                onClick={() => onPageChange(currentPage - 1)}
            >
                <CaretLeftIcon height={24} width={24} />
            </button>

            {pages.map((page) => {
                if (page.type === 'ellipsis') {
                    return <Dots key={page.value} />;
                }

                return (
                    <button
                        key={page.value}
                        className={`body-s flex w-8 h-8 p-1 justify-center items-center rounded-md hover:border hover:border-solid hover:border-purple-gray-400 text-purple-500 border-none cursor-pointer ${page.value === currentPage ? 'bg-purple-gray-200' : 'bg-white '}`}
                        disabled={page.value === currentPage}
                        onClick={() => onPageChange(page.value)}
                    >
                        {page.value}
                    </button>
                );
            })}

            <button
                className="flex py-1 px-0 items-center rounded-md border-none bg-white cursor-pointer text-purple-500 disabled:text-gray-400 disabled:border-none"
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(currentPage + 1)}
            >
                <CaretRightIcon height={24} width={24} />
            </button>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    siblingCount: PropTypes.number,
    onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
    currentPage: 1,
    totalPages: 1,
    siblingCount: 1,
    onPageChange: () => {},
};

export default Pagination;
