
import { all } from 'redux-saga/effects';
import sessionSaga from '../services/session/sagas';
import profileSaga from '../containers/My/Profile/sagas';
import navigationSaga from '../services/navigation/sagas';
import questionContainerSaga from '../containers/Thread/saga';
import actionsSaga from '../services/actionsHandling/saga';
import moderatorSagas from '../containers/Moderator/sagas';
import { saga as dataSaga } from '../data';
import strapiSaga from '../services/strapi/sagas';
import appSaga from './AppSaga';

export default function* rootSaga(args) {
    yield all([
        sessionSaga(args),
        navigationSaga(),
        questionContainerSaga(),
        moderatorSagas(),
        appSaga(),
        dataSaga(),
        actionsSaga(),
        profileSaga(),
        strapiSaga(),
    ]);
}
