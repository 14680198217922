import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withRouter } from 'react-router';
import NewEmailModal from './form';
import { closeNewEmailModal } from '../actions';
import { createSpecialtyCampaign } from '../../../../../data/campaigns';
import { ownStateSelector, dataStateSelector } from '../selectors';
import { targetAudience as targetAudienceEnum } from '../../../../../data/enums';
import { formatDateForSpecialtySpecificEmail } from '../../../../../utils/dates';

const mapStateToProps = (state, { checkedSpecialties }) => ({
    initialValues: {
        name: formatDateForSpecialtySpecificEmail(targetAudienceEnum.specialtyAll),
        targetAudience: targetAudienceEnum.specialtyAll,
        checkedSpecialties,
    },
    open: ownStateSelector(state).newEmailModalOpened,
    creatingCampaign: dataStateSelector(state).creatingCampaign,
});


export default compose(
    withRouter,
    connect(mapStateToProps, { closeNewEmailModal, createSpecialtyCampaign }),
)(NewEmailModal);
