import React from 'react';
import propTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Form, Message } from 'semantic-ui-react';
import localization from '../../localization';

const {
    admin: {
        questions: {
            questionForm: {
                unspecifiedSelection,
            },
        },
    },
} = localization;

const emptyValue = { text: unspecifiedSelection, key: -1, value: null };

const Dropdown = ({
    label,
    meta: {
        error,
        touched,
        warning,
        pristine,
    },
    required,
    disabled,
    options,
    input,
    multiple,
    hasEmptyValue,
    ...props
}) => {
    const hasError = touched && error && error.length > 0;
    let infoArea = null;

    if (hasError) {
        infoArea = (<Message
            visible
            error={hasError}
            content={error}
        />);
    } else if (warning && !pristine) {
        infoArea = (<div className="form-warning" >{warning}</div>);
    }
    const { onBlur, value, ...inputParams } = input;
    const dropdownValue = value || (multiple ? [] : value);

    let newOptions = options || [];
    if (hasEmptyValue && options && options.length) {
        newOptions = [emptyValue, ...options];
    }

    return (
        <div>
            <Form.Dropdown
                label={label}
                value={dropdownValue}
                fluid
                search
                selection
                multiple={multiple}
                options={newOptions}
                {...inputParams}
                onChange={(param, data) => input.onChange(data.value)}
                onBlur={() => onBlur(value)}
                required={required}
                disabled={disabled}
                className={hasEmptyValue ? 'dropdown-extra' : ''}
                {...props}
            />
            {infoArea}
        </div>
    );
};

Dropdown.propTypes = {
    ...fieldPropTypes,
    required: propTypes.bool,
    disabled: propTypes.bool,
};

Dropdown.defaultProps = {
    required: false,
    disabled: false,
};

export default Dropdown;
