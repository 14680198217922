import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { TextArea, CharactersLeftWarning } from '../../../../components';
import localization from '../../../../localization';
import { submitValidations, answerLength } from '../validations';
import { preSubmitValidation } from '../../../../utils/form/validators';
import { handleCtrlEnter } from '../../../../utils/form/keyHandlers';


const strings = localization.topic;

const warningProps = {
    infoStep: 3600,
    warningStep: 3800,
};

const {
    min: minAnswerLength,
} = answerLength;

const AnswerForm = ({
    handleSubmit,
    onSave,
    children: actions = [],
    submitButtonProps: {
        label = strings.postAnswer,
        icon = 'reply',
        loading,
    },
    fieldLabel = '',
    rows,
    placeholder,
    onFocus,
}) => {
    const submitFormValidations = submitValidations(minAnswerLength);
    const submitForm = handleSubmit(preSubmitValidation(submitFormValidations, onSave));
    return (
        <Form reply className="delay">
            <Field
                name="text"
                className="answer-input"
                component={TextArea}

                placeholder={placeholder}
                label={fieldLabel}
                warningProps={warningProps}
                WarningRenderer={CharactersLeftWarning}
                rows={rows}
                onKeyPress={handleCtrlEnter(submitForm)}
                onFocus={onFocus}
            />
            <div className="form-buttons">
                {actions}
                <Form.Button
                    primary
                    content={label}
                    icon={icon}
                    onClick={(...a) => {
                        if (!loading) {
                            submitForm(...a);
                        }
                    }}
                    labelPosition="left"
                    floated="right"
                    loading={loading}
                />
            </div>
        </Form>);
};

AnswerForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    submitButtonProps: PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        loading: PropTypes.bool,
    }),
    rows: PropTypes.number,
    fieldLabel: PropTypes.string,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
};

AnswerForm.defaultProps = {
    children: [],
    submitButtonProps: {
        label: strings.postAnswer,
        icon: 'reply',
        loading: false,
    },
    fieldLabel: '',
    placeholder: strings.replyPlaceholder,
    rows: 3,
    onFocus: null,
};


export default AnswerForm;
