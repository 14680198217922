import client from '../../api';
import { tooltips } from './../../api/endpoints';

const {
    load: loadEndpoint,
    mark: markEndpoint,
    allow: allowEndpoint,
    disable: disableEndpoint,
} = tooltips;

export const loadTooltip = name => client.get(loadEndpoint(name));

export const markTooltip = name => client.post(markEndpoint, { data: { name } });

export const allowTooltips = () => client.post(allowEndpoint, {});

export const disableTooltips = () => client.post(disableEndpoint, {});
