/* eslint-disable import/prefer-default-export */

/**
 * Adjusts up vote ratio to lower bound of confidence
 * interval with Wilson score interval formula for z
 * @param {number} up - up votes
 * @param {number} total - total votes
 * @param {number} z - z score
 */
export const correctedCommentRating = (up, total, z = 1.96) => {
    // https://en.wikipedia.org/wiki/Standard_score
    // 95% = 1.96
    // 99% = 2.326348

    if (total <= 0 || total < up) return 0;

    const phat = up / total;
    const z2 = z * z;
    return ((phat + (z2 / (2 * total)))
        - (z * Math.sqrt(((phat * (1 - phat)) + (z2 / (4 * total))) / total)))
        / (1 + (z2 / total));
};

/**
 * Check if check digit or the last number in npi is valid
 * More: https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
 * @param {number} npiWithCheckDigit - NPI
 * @param {number} prefixConstant - the prefix for calculating check digit
 */
export const npiDigitCheck = (npiWithCheckDigit = 0, prefixConstant = 24) => {
    const npiWithCheckDigitString = npiWithCheckDigit ? npiWithCheckDigit.toString() : '';
    const doubled = [];
    const notDoubled = [];
    const npiDigitsR = [...npiWithCheckDigitString.substr(0, 9)].reverse();
    const checkDigit = [...npiWithCheckDigitString][npiWithCheckDigitString.length - 1];

    npiDigitsR.forEach((digit, i) => {
        if (i % 2 !== 0) {
            notDoubled.push(digit.toString());
            return;
        }
        doubled.push(...(digit * 2).toString());
    });

    let npiDigitsSum = 0;
    [...doubled, ...notDoubled].forEach((digit) => {
        npiDigitsSum += Number.parseInt(digit, 10);
    });

    const checkDigitHigh = prefixConstant + npiDigitsSum;
    const checkDigitReduced = Math.ceil(checkDigitHigh / 10) * 10;
    const checkDigitLow = (checkDigitReduced - checkDigitHigh).toString();

    return checkDigit === checkDigitLow;
};
