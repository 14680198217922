import { all, takeLatest, put } from 'redux-saga/effects';
import { DOWNLOAD_ANSWER_REPORT_FAILED } from '../../../../data/campaigns/actionTypes';
import { setAnswerReportError } from './actions';
import { DELETE_QUESTION_COMPLETED } from '../../../../data/questions/actionTypes';
import { push } from '../../../../services/navigation';
import links from '../../../../links';

function* handleQuestionDelete() {
    yield put(push({
        state: {
            deleteSuccess: true,
        },
        pathname: links.moderator.questions.index,
    }));
}

function* setDownloadAnswerReportErrorMessage() {
    window.scrollTo(0, 0);
    yield put(setAnswerReportError());
}
export default function* UserListSaga() {
    yield all([
        takeLatest(DOWNLOAD_ANSWER_REPORT_FAILED, setDownloadAnswerReportErrorMessage),
        takeLatest(DELETE_QUESTION_COMPLETED, handleQuestionDelete),
    ]);
}
