import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withRouter } from 'react-router';
import NewCampaignModal from './form';
import { closeNewEmailModal } from '../actions';
import { createCampaign } from '../../../../../data/campaigns';
import { ownStateSelector, dataStateSelector } from '../selectors';
import { targetAudience as targetAudienceEnum } from '../../../../../data/enums';
import { formatDateForEmail } from '../../../../../utils/dates';
import { currentTopicUseNpiSelector } from '../../../../../data/questions/selectors';

const mapStateToProps = (state, props) => {
    const {
        match: {
            params: {
                id: topicId,
            },
        },
    } = props;

    const useNpi = currentTopicUseNpiSelector(state);
    const defaultTarget = useNpi ? targetAudienceEnum.npi : targetAudienceEnum.all;

    return {
        topicId,
        initialValues: {
            name: formatDateForEmail(topicId, defaultTarget),
            targetAudience: defaultTarget,
        },
        useNpi,
        open: ownStateSelector(state).newEmailModalOpened,
        creatingCampaign: dataStateSelector(state).creatingCampaign,
    };
};


export default compose(
    withRouter,
    connect(mapStateToProps, { closeNewEmailModal, createCampaign }),
)(NewCampaignModal);
