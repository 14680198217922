import { createAction } from 'redux-actions';
import {
    SEARCH_AUTHOR,
    SEARCH_AUTHOR_COMPLETED,
    SEARCH_AUTHOR_FAILED,
} from './actionTypes';

export const searchAuthor = createAction(SEARCH_AUTHOR);
export const searchAuthorCompleted = createAction(SEARCH_AUTHOR_COMPLETED);
export const searchAuthorFailed = createAction(SEARCH_AUTHOR_FAILED);
