import { call, put, takeEvery, all } from 'redux-saga/effects';
import {
    getConsensusSummary,
    getConsensusSummaryCompleted,
    postConsensusSummary,
    postConsensusSummaryCompleted,
    postConsensusSummaryFailed,
} from './reducer';
import { getConsensusSummaryApi, postConsensusSummaryApi } from './api';
import { showErrorToast } from '../../containers/Toast';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* getConsensusSummarySaga({
    payload: {
        id,
        setErrorText,
    },
}) {
    try {
        const response = yield getConsensusSummaryApi(id);
        yield put(getConsensusSummaryCompleted(response.data.attributes));
    } catch (error) {
        setErrorText('Something went wrong. Please try again later.');
    }
}

function* postConsensusSummarySaga({
    payload: {
        consensusSummary,
        setSubmissionStep,
    },
}) {
    try {
        yield postConsensusSummaryApi(consensusSummary);
        yield call(delay, 2000);
        yield put(postConsensusSummaryCompleted(setSubmissionStep));
    } catch (error) {
        if (error?.status !== 500) { // apiSaga handles 500s automatically
            yield put(showErrorToast({ body: 'There was an error submitting, please refresh and try again' }));
        }
        yield put(postConsensusSummaryFailed());
    }
}

export default function* () {
    yield all([
        takeEvery(getConsensusSummary, getConsensusSummarySaga),
        takeEvery(postConsensusSummary, postConsensusSummarySaga),
    ]);
}
