import React from 'react';
import PropTypes from 'prop-types';
import { CheckIcon } from '@radix-ui/react-icons';

const DropdownItem = ({
    children, onEscape, onItemClick, selectedItem,
}) => (
    <div
        className="flex items-center gap-2 self-stretch justify-between p-2 body-s cursor-pointer text-purple-gray-700 hover:text-purple-gray-800 hover:bg-purple-gray-200"
        role="menuitem"
        onClick={() => onItemClick(children)}
        tabIndex={0}
        onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                onItemClick(children);
            } else if (event.key === 'Escape') {
                onEscape();
            }
        }}
    >
        {typeof children === 'object' ? children.label : children}
        {selectedItem === children ? <CheckIcon className="text-blue-500" /> : null}
    </div>
);

DropdownItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            label: PropTypes.string.isRequired,
        }),
    ]),
    onItemClick: PropTypes.func,
    onEscape: PropTypes.func,
    selectedItem: PropTypes.node,
};

DropdownItem.defaultProps = {
    children: null,
    onItemClick: () => { },
    onEscape: () => { },
    selectedItem: null,
};

export default DropdownItem;
