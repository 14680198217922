import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { reduxForm, isValid, stopSubmit, change } from 'redux-form';
import { replace } from '../../../../services/navigation';
import Component from './component';
import { formName } from './constants';
import { ID as DataID, SpecialtyID, UsersID, RolesID } from '../../../../data';
import { updateUser, createUser } from '../../../../data/users/actions';
import { AppHcUserID, lookupAppHcUser, lookupAppHcUserCompleted } from '../../../../data/appHcUsers/slice';
import {
    filteredSpecsSelector,
    selectedRoleIdSelector,
    selectedAppHcIdSelector,
    sortedSpecialtiesSelector,
} from './selectors';
import { WithRouterHOC } from '../../../../components/Router';
import { restoreUser } from './actions';
import validations, { isEmailAndNpiValid } from './validations';
import { forAppHc, getPermissionOptionsFromRole, isPreSelected } from './rolesAndPermissionsMapping';
import { isExternalUser as isExternal, isAdminOrModerator } from '../../../../utils/roleHelper';

const mapRoles = roles => roles.map(role => ({
    key: role.id,
    value: role.id,
    text: role.name,
}));

const mapStateToProps = (state, {
    match: {
        params: {
            id: userId,
        },
    },
    location: {
        state: locationState,
    },
}) => {
    const {
        [DataID]: {
            [SpecialtyID]: {
                loadingSpecialties,
                loadingSubSpecialties,
            },
            [UsersID]: {
                loading: loadingUser,
                updating: updatingUser,
                users,
            },
            [AppHcUserID]: {
                appHcUserIds,
            },
            [RolesID]: {
                roles,
                loading: loadingRoles,
            },
        },
    } = state;

    const parsedId = parseInt(userId, 10);
    const selectedUser = users.find(({ id }) => id === parsedId) || {};
    const roleName = roles.find(r => r.id === selectedRoleIdSelector(state))?.name;
    const isExternalUser = isExternal(roleName);
    const isSpecialtyRequired = !isAdminOrModerator(roleName);
    const {
        id: selectedUserId,
        firstName,
        lastName,
        roleId,
        email,
        npi,
        specialtyId,
        subspecialtyId,
        disabled = false,
        permissions,
        isExpert,
        auth0UserId,
    } = selectedUser;

    const additionalProps = {};
    if (locationState) {
        const {
            restoreUserAvailable,
            npiUniqueError,
        } = locationState;
        additionalProps.restoreMessageVisible = restoreUserAvailable;
        additionalProps.shouldGenerateInternalIdNumber = Boolean(npiUniqueError);
    }

    const isUpdate = !!(userId);
    let appHcUserId = null;
    if (isUpdate) {
        appHcUserId = selectedUser?.appHcUserId;
    } else {
        appHcUserId = selectedAppHcIdSelector(state);
    }

    return {
        specialties: sortedSpecialtiesSelector(state),
        roles: mapRoles(roles),
        subSpecialties: filteredSpecsSelector(state),
        selectedUser,
        isExternalUser,
        isSpecialtyRequired,
        isFormValid: isValid(formName)(state),
        loading: loadingSpecialties || loadingSubSpecialties ||
            loadingUser || loadingRoles || updatingUser,
        initialValues: (isUpdate && selectedUser) ? {
            id: selectedUserId,
            firstName,
            lastName,
            specialtyId,
            subspecialtyId,
            roleId,
            email,
            npi,
            internalNpi: npi,
            permissions,
            appHcUserId,
            isExpert,
            disableAccountUpdateEmail: true,
            auth0UserId,
        } : {
            disableAccountUpdateEmail: false,
        },
        isUpdate,
        ...additionalProps,
        disabled,
        permissionOptions: getPermissionOptionsFromRole(roleName),
        isRoleSelected: Boolean(roleName),
        roleName,
        appHcUserIds,
    };
};

const generateRandomInternalId = () => {
    const min = 100000000; // Smallest 9-digit number
    const max = 999999999; // Largest 9-digit number
    return Math.floor(Math.random() * ((max - min) + 1)) + min;
};

const mapDispatchToProps = (dispatch, { location: { state: locationState } }) => {
    if (locationState) {
        const {
            userId,
        } = locationState;
        return {
            changeInternalNpiField: () => dispatch(change(formName, 'internalNpi', generateRandomInternalId())),
            replace: state => dispatch(replace(state || {})),
            restoreUser: () => dispatch(restoreUser({ id: userId })),
        };
    }
    return {
        changeInternalNpiField: () => dispatch(change(formName, 'internalNpi', generateRandomInternalId())),
        replace: () => null,
        restoreUser: () => null,
    };
};

const lookupAppHcUserIds = (values, dispatch, props, oldValues) => {
    if (props.isUpdate) return;

    const { email, npi, roleId } = values;
    const emailChanged = email !== oldValues.email;
    const npiChanged = npi !== oldValues.npi;
    const roleChanged = roleId !== oldValues.roleId;
    if (emailChanged || npiChanged || roleChanged) {
        if (props.isExternalUser && isEmailAndNpiValid(npi, email)) {
            dispatch(lookupAppHcUser({ email, npi }));
        } else {
            // clear out appHcUserIds in redux store
            dispatch(lookupAppHcUserCompleted({ data: [] }));
        }
    }
};

export default compose(
    WithRouterHOC,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: formName,
        onSubmit: (values, dispatch, props) => {
            const {
                isUpdate, isExternalUser, permissionOptions, roleName,
            } = props;
            const action = isUpdate ? updateUser : createUser;

            // only send the permissions that are selected by the user and ignore preSelected permissions
            // preSelected permissions match the default permissions for a Role in Auth0
            // reassigning them will result in a "Direct" association instead of a Role association.
            const auth0Permissions = [...values.permissions || []]
                .filter(permission => !isPreSelected(permission, roleName));

            const preSelectedPermissions = permissionOptions
                .filter(permissionOption => permissionOption.preSelected)
                .map(permissionOption => permissionOption.value);

            // on update, there can be duplicate permissions here,
            // so we should dedup them by using a Set to avoid issues
            const allSelectedPermissions = [
                ...new Set([
                    ...values.permissions || [],
                    ...preSelectedPermissions,
                ]),
            ];

            const appHcPermissions = allSelectedPermissions
                .filter(permission => forAppHc(permission, roleName));

            const updatedValues = {
                ...values,
                auth0Permissions,
                appHcPermissions,
                isExternalUser,
                auth0RoleName: roleName,
            };

            if (isExternalUser) {
                dispatch(action(updatedValues));
            } else {
                const { internalNpi, ...rest } = updatedValues;
                const payload = { ...rest, npi: internalNpi };
                dispatch(action(payload));
            }
        },
        onChange: (values, dispatch, props, oldValues) => {
            dispatch(stopSubmit(formName, {}));

            const isAtPrefillStageOnEdit = oldValues.roleId === undefined;
            const rolesHaveChanged = values.roleId !== oldValues.roleId;
            if (!isAtPrefillStageOnEdit && rolesHaveChanged) {
                dispatch(change(formName, 'permissions', []));

                const { changeInternalNpiField, roleName, roles } = props;
                if (isExternal(roleName)) {
                    // clear out the internalNpi value from npi field
                    dispatch(change(formName, 'npi', null));
                } else {
                    const { roleId } = oldValues;
                    const oldRoleName = roles.find(r => r.key === roleId)?.text;
                    const wasExternalUser = isExternal(oldRoleName);
                    // only generate new internalNpi if the user was previously external
                    // for ex: if user role was changed from Admin to Moderator, we should not generate a new internalNpi
                    // but if user role was changed from HCP to Moderator, we should generate a new internalNpi
                    if (wasExternalUser) {
                        changeInternalNpiField();
                    }
                }
            }

            lookupAppHcUserIds(values, dispatch, props, oldValues);
        },
        enableReinitialize: true,
        validate: validations,
    }),
)(Component);
