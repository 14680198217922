import { ID } from './actionTypes';
import DataID from '../config';

export const currentTopicIdSelector = ({
    [DataID]: {
        [ID]: {
            currentTopic,
        },
    },
}) => currentTopic;

export const questionsSelector = ({
    [DataID]: {
        [ID]: {
            topics,
        },
    },
}) => topics;

export const questionLoading = ({
    [DataID]: {
        [ID]: {
            loading,
        },
    },
}) => loading;

export const nextQuestionLoadingSelector = ({
    [DataID]: {
        [ID]: {
            loadingNextTopic,
        },
    },
}) => loadingNextTopic;

export const nextQuestionSelection = ({
    [DataID]: {
        [ID]: {
            nextTopic,
        },
    },
}) => nextTopic;

export const targetSizeSelector = ({
    [DataID]: {
        [ID]: {
            relatedUsersCount,
        },
    },
}) => relatedUsersCount;

export const eligibleCountSelector = ({
    [DataID]: {
        [ID]: {
            eligibleUsersCount,
        },
    },
}) => eligibleUsersCount;

export const currentTopicViewIdSelector = (state) => {
    const {
        [DataID]: {
            [ID]: {
                currentTopic,
                topics,
            },
        },
    } = state;
    const topic = topics.filter(x => x.id === currentTopic)[0];
    return topic.topicViewId;
};

export const currentTopicUseNpiSelector = (state) => {
    const {
        [DataID]: {
            [ID]: {
                currentTopic,
                topics,
            },
        },
    } = state;

    const topic = topics.find(x => x.id === currentTopic);

    return topic ? topic.useNpiMap : true;
};

export const questionsNpisSelector = ({
    [DataID]: {
        [ID]: {
            questionsNpis,
        },
    },
}) => questionsNpis;

export const questionsNpisLoadingSelector = ({
    [DataID]: {
        [ID]: {
            questionsNpisLoading,
        },
    },
}) => questionsNpisLoading;

export const questionsNpisUpdatingSelector = ({
    [DataID]: {
        [ID]: {
            questionsNpisUpdating,
        },
    },
}) => questionsNpisUpdating;

export default {
    currentTopicIdSelector,
    questionsSelector,
    questionLoading,
    nextQuestionLoadingSelector,
    nextQuestionSelection,
    targetSizeSelector,
    eligibleCountSelector,
    currentTopicViewIdSelector,
    currentTopicUseNpiSelector,
    questionsNpisSelector,
    questionsNpisLoadingSelector,
    questionsNpisUpdatingSelector,
};
