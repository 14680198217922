/* eslint-disable import/prefer-default-export */
import { ID as DataID } from '../';
import { ID } from './actionTypes';

export const deleteStatusSelector = ({
    [DataID]: {
        [ID]: {
            deletingInProgress,
        },
    },
}) => deletingInProgress;

export const updateStatusSelector = ({
    [DataID]: {
        [ID]: {
            updating,
        },
    },
}) => updating;
