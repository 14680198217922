import React, { useState } from 'react';
import { BackpackIcon, ChevronDownIcon } from '@radix-ui/react-icons';
import Chip from '../components/chip';
import ConsensusTile from '../components/consensusTile';
import Button from '../components/button';
import Pagination from '../components/pagination';
import Link from '../components/link';
import Dropdown from '../components/dropdown';
import DropdownItem from '../components/dropdownItem';
import Avatar from '../components/avatar';
import Logo from '../components/Logo/logo';
import UserDropdown from '../components/userDropdown';

function DesignComponents() {
    const [currentPage, setCurrentPage] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const itemsPerPage = 12;
    const totalItems = 100;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleDropdownItemClick = (value) => {
        setSelectedItem(value);
        setIsDropdownOpen(false);
    };

    return (
        <div>
            <h2>Buttons</h2>
            <div className="bg-white">
                <p className="p-4 m-0">
                    Normal | Disabled | With Left Icon | With Left Icon and Right Chevron
                </p>
                <div className="flex gap-2 p-4">
                    <Button>Default</Button>
                    <Button disabled>Default</Button>
                    <Button>
                        <BackpackIcon />
                        Default
                    </Button>
                    <Button>
                        <BackpackIcon />
                        Default
                        <ChevronDownIcon />
                    </Button>
                </div>
                <div className="flex gap-2 p-4">
                    <Button variant="filled">Filled</Button>
                    <Button variant="filled" disabled>Filled</Button>
                    <Button variant="filled">
                        <BackpackIcon />
                        Filled
                    </Button>
                    <Button variant="filled">
                        <BackpackIcon />
                        Filled
                        <ChevronDownIcon />
                    </Button>
                </div>
                <div className="flex gap-2 p-4">
                    <Button variant="outlined">Outlined</Button>
                    <Button variant="outlined" disabled>Outlined</Button>
                    <Button variant="outlined">
                        <BackpackIcon />
                        Outlined
                    </Button>
                    <Button variant="outlined">
                        <BackpackIcon />
                        Outlined
                        <ChevronDownIcon />
                    </Button>
                </div>
                <div className="flex gap-2 p-4">
                    <Button variant="text">Text</Button>
                    <Button variant="text" disabled>Text</Button>
                    <Button variant="text">
                        <BackpackIcon />
                        Text
                    </Button>
                    <Button variant="text">
                        <BackpackIcon />
                        Text
                        <ChevronDownIcon />
                    </Button>
                </div>
                <div className="flex gap-2 p-4">
                    <Button variant="icon" icon={<BackpackIcon />} />
                    <Button variant="icon" disabled icon={<BackpackIcon />} />
                </div>
                <div className="flex gap-2 p-4">
                    <Button variant="warning">Warning</Button>
                    <Button variant="warning" disabled>Warning</Button>
                    <Button variant="warning">
                        <BackpackIcon />
                        Warning
                    </Button>
                    <Button variant="warning">
                        <BackpackIcon />
                        Warning
                        <ChevronDownIcon />
                    </Button>
                </div>
            </div>
            <hr />
            <h2>Link</h2>
            <div className="flex gap-2 p-4 bg-white">
                <Link>Link</Link>
            </div>
            <hr />
            <h2>Logo</h2>
            <div className="flex mb-4 p-4 bg-gradient-to-r from-purple-gray-800 from-0% via-blue-450 via-50% to-purple-600 to-100%">
                <Logo />
            </div>
            <div className="flex p-4 bg-gradient-to-r from-purple-gray-800 from-0% via-blue-450 via-50% to-purple-600 to-100%">
                <Logo isHcpOrTestHcp={false} />
            </div>
            <hr />
            <h2>Chip</h2>
            <div className="flex gap-2 ml-4">
                <Chip>Default</Chip>
                <Chip variant="blue">Blue</Chip>
                <Chip variant="purple">Purple</Chip>
                <Chip variant="orange">Orange</Chip>
                <Chip variant="light-blue">Light Blue</Chip>
                <Chip variant="light-purple">Light Purple</Chip>
                <Chip variant="light-orange">Light Orange</Chip>
            </div>
            <hr />
            <h2>Consensus Tile</h2>
            <div className="flex gap-2 p-4 bg-white">
                <ConsensusTile
                    assetType="Clinical"
                    title="Title Title Title Title Title"
                    authorName="Author Name"
                    authorTitle="Author Title"
                    imageUrl="https://devcmshealthcasts.s3.us-east-2.amazonaws.com/drugs_d905950138.png"
                    href="#"
                />
            </div>
            <hr />
            <h2>Pagination</h2>
            <div className="flex gap-2 p-4 bg-white">
                <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(totalItems / itemsPerPage)}
                    onPageChange={handlePageChange}
                />
            </div>
            <hr />
            <h2>Dropdowns</h2>
            <div className="flex gap-2 p-4 pl-8 bg-white">
                <div className="relative inline-block text-left">
                    <Button
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                        <BackpackIcon />
                        Filter{selectedItem ? `: ${selectedItem}` : ''}
                        <ChevronDownIcon />
                    </Button>
                    {isDropdownOpen && (
                        <Dropdown className="mt-2">
                            {
                                [
                                    { id: 1, name: 'Allergy' },
                                    { id: 2, name: 'Dermatology' },
                                    { id: 3, name: 'Cardiology' },
                                ].map(({ id, name }) => (
                                    <DropdownItem
                                        key={id}
                                        selectedItem={selectedItem}
                                        onItemClick={handleDropdownItemClick}
                                        onEscape={() => setIsDropdownOpen(false)}
                                    >
                                        {name}
                                    </DropdownItem>
                                ))
                            }
                        </Dropdown>
                    )}
                </div>
            </div>
            <hr />
            <h2>Avatar</h2>
            <div className="flex gap-2 p-4 pl-8">
                <Avatar
                    imageUrl="https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?&w=128&h=128&dpr=2&q=80"
                    initials="AR"
                />
                <Avatar initials="AR" />
            </div>
            <hr />
            <h2>User Dropdown</h2>
            <div className="flex p-4 pl-20 bg-gradient-to-r from-purple-gray-800 from-0% via-blue-450 via-50% to-purple-600 to-100%">
                <UserDropdown initials="AJ" />
            </div>
            <hr />
        </div>
    );
}

export default DesignComponents;
