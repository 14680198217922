
import ID from './config';
import { ID as QuestionsID } from './questions';
import { ID as AnswersID } from './answers';
import { ID as UsersID } from './users';
import { ID as RolesID } from './roles';
import { ID as SpecialtyID } from './specialties';
import { ID as CampaignsID } from './campaigns';
import { ID as ProgramsID } from './programs';
import { ID as GlobalHcmpID } from './globalHcmp';
import { ID as EventID } from './events';
import { ID as RewardsID } from './rewards';
import reducer from './reducer';

export { default as dataLoadMiddleware } from './middleware';
export { default as saga } from './saga';

export { ID };

export {
    QuestionsID,
    AnswersID,
    UsersID,
    SpecialtyID,
    RolesID,
    CampaignsID,
    ProgramsID,
    GlobalHcmpID,
    EventID,
    RewardsID,
};

export default reducer;
