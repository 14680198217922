import { createSelector } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { ID as DataID, QuestionsID } from '../../data';
import { selectors } from '../../data/questions';

// values that are returned from TOPIC_STATUS db function
const ACTIVE = 1;

export const loadingSelector = ({
    [DataID]: {
        [QuestionsID]: {
            loading,
        },
    },
}) => loading;

const {
    questionsSelector,
} = selectors;

export default createSelector(
    [questionsSelector, (_, param) => param],
    (questions, isMyFeed) => {
        const activeQuestions = [];
        const answeredQuestions = [];
        for (let i = 0; i < questions.length; i += 1) {
            const {
                personalized = true, // because moderators use myFeed as their only feed
                ...question
            } = questions[i];
            if (isMyFeed === personalized && question.status === ACTIVE) {
                if (question.commentedAt) {
                    answeredQuestions.push(question);
                } else {
                    activeQuestions.push(question);
                }
            }
        }

        return {
            activeQuestions: orderBy(activeQuestions, ['expirationDate'], ['desc']),
            answeredQuestions: orderBy(answeredQuestions, ['expirationDate'], ['desc']),
        };
    },
);
