import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import Error from './Error';

const RadioGroup = ({
    options,
    containerClasses,
    formName,
}) => {
    const { getFieldProps, touched, errors } = useFormikContext();

    const showError = touched[formName] && errors[formName];

    const combinedClasses = clsx(
        'flex flex-col font-body',
        containerClasses,
    );
    return (
        <div className={combinedClasses}>
            {options.map(({ value, label }) => (
                <div key={value} className="flex mb-2 last:mb-0">
                    <input
                        type="radio"
                        className="w-5 h-5 blue-500"
                        {...getFieldProps(formName)}
                        value={value}
                        checked={getFieldProps(formName).value === value}
                    />
                    <label
                        htmlFor={value}
                        className="ml-2 font-body text-base font-normal"
                    >
                        {label}
                    </label>
                </div>
            ))}
            {showError && <Error message="Required" />}
        </div>
    );
};

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    containerClasses: PropTypes.string,
    formName: PropTypes.string.isRequired,
};

RadioGroup.defaultProps = {
    containerClasses: '',
};

export default RadioGroup;
