import { connect } from 'react-redux';
import { othersHaveCommentedSelector } from '../../selectors';
import {
    openReplyForm,
    openAnswerEditForm,
    clearUpdatedComment,
} from '../../../actions';
import {
    rateAnswer,
    deleteAnswer,
} from '../../../../../data/answers';
import AnswerView from './container';
import { showModal } from '../../../../../services/sharedUIActions';
import { isAdmin } from '../../../../../utils/roleHelper';
import { roleNameSelector } from '../../../../../services/session';

const mapStateToProps = (state, {
    id,
}) => ({
    canEditAll: isAdmin(roleNameSelector(state)),
    othersHaveCommented: othersHaveCommentedSelector(state, id),
});

export default connect(mapStateToProps, {
    rateAnswer,
    openReplyForm,
    openAnswerEditForm,
    showModal,
    deleteAnswer,
    clearUpdatedComment,
})(AnswerView);
