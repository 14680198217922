import { createSlice } from '@reduxjs/toolkit';
import { analytics } from '../../utils/analytics';

export const STRAPI_ID = 'STRAPI';

const initialState = {
    isSubmitting: false,
    resource: null,
};

const slice = createSlice({
    name: `${STRAPI_ID}`,
    initialState,
    reducers: {
        getConsensusSummary: state => state,
        getConsensusSummaryCompleted: (state, { payload: resource }) => {
            state.resource = resource;
        },
        postConsensusSummary: (state) => {
            state.isSubmitting = true;
        },
        postConsensusSummaryCompleted: (state, { payload: setSubmissionStep }) => {
            analytics.trackEvent('custom_submit_successful');
            setSubmissionStep('submissionComplete');
            state.isSubmitting = false;
        },
        postConsensusSummaryFailed: (state) => {
            state.isSubmitting = false;
        },
    },
});

export const {
    getConsensusSummary,
    getConsensusSummaryCompleted,
    postConsensusSummary,
    postConsensusSummaryCompleted,
    postConsensusSummaryFailed,
} = slice.actions;

export const { reducer } = slice;
