import {
    all,
    select,
    call,
    takeLeading,
    put,
} from 'redux-saga/effects';
import { selectors as questionSelectors } from '../questions';
import { logAnswerFormFocusEvent } from './api';
import { ANSWER_INPUT_FOCUSED } from './actionTypes';
import { answerFocusedExecutedSelector } from './selectors';
import { answerInputFocusedCompleted, answerInputFocusedFailed } from './actions';

function* answerFormFocusedSaga() {
    try {
        const isAlreadySent = yield select(state => answerFocusedExecutedSelector(state));
        if (!isAlreadySent) {
            const topicViewId =
                yield select(state => questionSelectors.currentTopicViewIdSelector(state));
            yield call(logAnswerFormFocusEvent, topicViewId);
            yield put(answerInputFocusedCompleted());
        }
    } catch (error) {
        yield put(answerInputFocusedFailed({ error }));
    }
}

function* eventSaga() {
    yield all([
        takeLeading(ANSWER_INPUT_FOCUSED, answerFormFocusedSaga),
    ]);
}

export default eventSaga;
