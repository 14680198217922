import { createAction } from 'redux-actions';
import {
    LOAD_TOOLTIP,
    LOAD_TOOLTIP_COMPLETED,
    LOAD_TOOLTIP_FAILED,
    MARK_TOOLTIP,
    MARK_TOOLTIP_COMPLETED,
    MARK_TOOLTIP_FAILED,
} from './actionTypes';

export const loadTooltip = createAction(LOAD_TOOLTIP);
export const loadTooltipCompleted = createAction(LOAD_TOOLTIP_COMPLETED);
export const loadTooltipFailed = createAction(LOAD_TOOLTIP_FAILED);

export const markTooltip = createAction(MARK_TOOLTIP);
export const markTooltipCompleted = createAction(MARK_TOOLTIP_COMPLETED);
export const markTooltipFailed = createAction(MARK_TOOLTIP_FAILED);
