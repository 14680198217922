import {
    all,
    takeEvery,
    select,
    call,
    put,
    race,
    take,
    delay,
} from 'redux-saga/effects';
import {
    GET_DTI_LINK,
    GET_PROGRAM_FOR_USER,
    VALIDATE_DTI_CACHE_TIMEOUT,
} from './actionTypes';
import { LOGOUT } from '../../services/session/actionTypes';
import {
    getProgramForUserCompleted,
    getProgramForUserFailed,
    getDtiLinkCompleted,
    getDtiLinkFailed,
    validateDtiCacheTimeout,
    validateDtiCacheTimeoutCompleted,
    validateDtiCacheTimeoutFailed,
} from './actions';
import { getDtiForUser, getProgramForUser } from './api';
import { permissionsSelector } from '../../services/session/selectors';
import { differenceTime } from '../../utils/dates';
import {
    dtiCacheTime,
    showAnswersInterstitialEnabled,
    hcmpEnabled,
} from '../../environment';
import { Permissions } from '../../permissions';

function* loadDti({ payload: { expiresAt } }) {
    try {
        const permissions = yield select(permissionsSelector);
        if (permissions.includes(Permissions.DISEASES_AND_TREATMENT_INFORMATION)) {
            const currentDate = Date.now();

            let cacheTimeout = true;
            let expiration = dtiCacheTime;
            if (expiresAt) {
                cacheTimeout = expiresAt < currentDate;
                expiration = cacheTimeout
                    ? expiration
                    : differenceTime(expiresAt, currentDate);
            }

            yield put(validateDtiCacheTimeout({ expiration }));
            if (cacheTimeout) {
                const { url, success } = yield call(getDtiForUser);
                if (success) {
                    yield put(getDtiLinkCompleted({
                        url,
                        expiration,
                        currentDate,
                    }));
                } else {
                    yield put(getDtiLinkFailed());
                }
            }
        }
    } catch (error) {
        yield put(getDtiLinkFailed({ error }));
    }
}

function* loadProgram({ payload: { topicId } }) {
    try {
        const askForLink = Boolean(topicId) === !showAnswersInterstitialEnabled;
        if (hcmpEnabled && askForLink) {
            const { url, id } = yield call(getProgramForUser, topicId);
            yield put(getProgramForUserCompleted({ url, id }));
        }
    } catch (error) {
        yield put(getProgramForUserFailed({ error }));
    }
}

function* delayedCacheClearSaga({ payload: { expiration } }) {
    try {
        const { cache } = yield race({
            cache: delay(expiration),
            logout: take([LOGOUT]),
        });

        if (cache) {
            yield put(validateDtiCacheTimeoutCompleted());
            yield loadDti({ payload: { expiresAt: null } });
        }
    } catch (error) {
        yield put(validateDtiCacheTimeoutFailed({ error }));
    }
}

export default function* programsSaga() {
    yield all([
        takeEvery(GET_DTI_LINK, loadDti),
        takeEvery(GET_PROGRAM_FOR_USER, loadProgram),
        takeEvery(VALIDATE_DTI_CACHE_TIMEOUT, delayedCacheClearSaga),
    ]);
}
