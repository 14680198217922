import React from 'react';
import PropTypes from 'prop-types';

const Link = ({
    children,
    className,
    href,
}) => (
    <a
        className={`body-s text-purple-500 cursor-pointer hover:text-purple-600 focus:ring-4 focus:ring-blue-200 ${className}`}
        href={href}
    >
        {children}
    </a>
);

Link.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string,
};

Link.defaultProps = {
    children: null,
    className: '',
    href: '#',
};

export default Link;
