import React from 'react';
import PropTypes from 'prop-types';
import { Comment } from 'semantic-ui-react';
import FlipMove from 'react-flip-move';
import { FlashIndicator } from '../../.././../../components';
import AnswerComment from '../Comment';

const Comments = ({
    comments,
    children,
    lastUpdatedCommentId,
    clearUpdatedComment,
    topicExpired,
    canEditAll,
}) => (
    <Comment.Group>
        <FlipMove
            duration={500}
            easing="ease-in"
            typeName={null}
        >
            {children}
            {comments.map(({
                id: cId,
                createdByUser: {
                    id: createdById,
                    ...createdByUser
                },
                ...comment
            }) => (
                <FlashIndicator
                    active={cId === lastUpdatedCommentId}
                    key={cId}
                    makeInactive={clearUpdatedComment}
                    activeClass="highlighted"
                    inactiveClass="inactive"
                >
                    <AnswerComment
                        id={cId}
                        {...comment}
                        topicExpired={topicExpired}
                        createdByUser={{
                            ...createdByUser,
                            id: createdById,
                        }}
                        canEditAll={canEditAll}
                    />
                </FlashIndicator>
            ))}
        </FlipMove>
    </Comment.Group>);

Comments.propTypes = {
    comments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    children: PropTypes.PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    lastUpdatedCommentId: PropTypes.number,
    clearUpdatedComment: PropTypes.func.isRequired,
    topicExpired: PropTypes.bool.isRequired,
    canEditAll: PropTypes.bool.isRequired,
};

Comments.defaultProps = {
    lastUpdatedCommentId: null,
};

export default Comments;
