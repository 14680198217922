// reference https://github.com/chakra-ui/zag/blob/main/packages/machines/pagination/src/pagination.utils.ts

const R_ELLIPSIS = 'right ellipsis';
const L_ELLIPSIS = 'left ellipsis';

const range = (start, end) => {
    const length = (end - start) + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

const transform = items => items.map(value => (typeof value === 'number' ? { type: 'page', value } : { type: 'ellipsis', value }));

const getRange = (currentPage, totalPages, siblingCount) => {
    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    const leftSiblingIndex = Math.max(currentPage - siblingCount, firstPageIndex);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, lastPageIndex);

    const showLeftEllipsis = leftSiblingIndex > firstPageIndex + 1;
    const showRightEllipsis = rightSiblingIndex < lastPageIndex - 1;

    const totalPageNumbers = Math.min((2 * siblingCount) + 5, totalPages);
    const itemCount = totalPageNumbers - 2;

    if (!showLeftEllipsis && showRightEllipsis) {
        const leftRange = range(1, itemCount);
        return [...leftRange, R_ELLIPSIS, lastPageIndex];
    }

    if (showLeftEllipsis && !showRightEllipsis) {
        const rightRange = range((lastPageIndex - itemCount) + 1, lastPageIndex);
        return [firstPageIndex, L_ELLIPSIS, ...rightRange];
    }

    if (showLeftEllipsis && showRightEllipsis) {
        const middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, L_ELLIPSIS, ...middleRange, R_ELLIPSIS, lastPageIndex];
    }

    const fullRange = range(firstPageIndex, lastPageIndex);
    return fullRange;
};

export const getPaginationRange = (currentPage, totalPages, siblingCount) => {
    const pages = getRange(currentPage, totalPages, siblingCount);
    return transform(pages);
};
