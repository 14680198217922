import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Button } from 'semantic-ui-react';
import './style.scss';

export const tableClassName = 'with-expandable-rows';

class ExpandableRow extends Component {
    static tableClassName = tableClassName;

    static propTypes = {
        renderHeader: PropTypes.func.isRequired,
        renderBody: PropTypes.func.isRequired,
    }

    state = {
        opened: false,
    }

    toggleState = () => {
        this.setState(({ opened }) => ({
            opened: !opened,
        }));
    }

    render() {
        const {
            renderHeader,
            renderBody,
        } = this.props;
        const {
            opened,
        } = this.state;
        return (
            <Fragment>
                <TableRow className={`header-row ${opened ? 'opened' : ''}`}>
                    {renderHeader()}
                    <TableCell width={1}>
                        <Button
                            className="action-button"
                            onClick={this.toggleState}
                            icon={`chevron ${opened ? 'up' : 'down'}`}
                            basic
                        />
                    </TableCell>
                </TableRow>
                {opened && <TableRow className={`body-row ${opened ? 'opened' : ''}`}>{renderBody()}</TableRow>}
            </Fragment>);
    }
}

export default ExpandableRow;
