import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import {
    DOWNLOAD_TARGET_LIST,
    DOWNLOAD_TARGET_LIST_COMPLETED,
    DOWNLOAD_TARGET_LIST_FAILED,
    DOWNLOAD_ANSWER_REPORT,
    DOWNLOAD_ANSWER_REPORT_COMPLETED,
    DOWNLOAD_ANSWER_REPORT_FAILED,
    EXPORT_USERS_LIST,
    EXPORT_USERS_LIST_COMPLETE,
    EXPORT_USERS_LIST_FAILED,
    LOAD_CAMPAIGNS,
    LOAD_CAMPAIGNS_COMPLETED,
    LOAD_CAMPAIGNS_FAILED,
    CREATE_CAMPAIGN,
    CREATE_CAMPAIGN_COMPLETED,
    CREATE_CAMPAIGN_FAILED,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_COMPLETED,
    DELETE_CAMPAIGN_FAILED,
    CREATE_SPECIALTY_CAMPAIGN,
    CREATE_SPECIALTY_CAMPAIGN_COMPLETED,
    CREATE_SPECIALTY_CAMPAIGN_FAILED,
    CREATE_NPI_CAMPAIGN,
    CREATE_NPI_CAMPAIGN_COMPLETED,
    CREATE_NPI_CAMPAIGN_FAILED,
    LOAD_GENERAL_CAMPAIGNS,
    LOAD_GENERAL_CAMPAIGNS_COMPLETED,
    LOAD_GENERAL_CAMPAIGNS_FAILED,
    DELETE_GENERAL_CAMPAIGN,
    DELETE_GENERAL_CAMPAIGN_COMPLETED,
    DELETE_GENERAL_CAMPAIGN_FAILED,
} from './actionTypes';

const initialState = Immutable.from({
    downloadingTargetList: false,
    campaignIdAction: null,
    downloadingAnswerReport: false,
    currentlyExporting: false,
    loadingCampaigns: false,
    creatingCampaign: false,
    deletingCampaign: false,
    campaigns: {},
    total: 0,
    pageNumber: 1,
    createCampaignErrorMessage: null,
});

const reducer = handleActions({
    [DOWNLOAD_TARGET_LIST]: (state, { payload: { campaignId } }) => state.merge({
        downloadingTargetList: true,
        campaignIdAction: campaignId,
    }),
    [DOWNLOAD_TARGET_LIST_COMPLETED]:
        state => state.merge({
            downloadingTargetList: false,
            campaignIdAction: null,
        }),
    [DOWNLOAD_TARGET_LIST_FAILED]: state => state.merge({
        downloadingTargetList: false,
        campaignIdAction: null,
    }),
    [DOWNLOAD_ANSWER_REPORT]: state => state.merge({
        downloadingAnswerReport: true,
    }),
    [DOWNLOAD_ANSWER_REPORT_COMPLETED]:
        state => state.merge({
            downloadingAnswerReport: false,
        }),
    [DOWNLOAD_ANSWER_REPORT_FAILED]: state => state.merge({
        downloadingAnswerReport: false,
    }),
    [EXPORT_USERS_LIST]: state => state.set('currentlyExporting', true),
    [EXPORT_USERS_LIST_COMPLETE]:
        state => state.merge({
            currentlyExporting: false,
        }),
    [EXPORT_USERS_LIST_FAILED]: state => state.merge({
        currentlyExporting: false,
    }),
    [LOAD_CAMPAIGNS]: state => state.set('loadingCampaigns', true),
    [LOAD_CAMPAIGNS_COMPLETED]: (state, { payload: { campaigns, topicId } }) => state.merge({
        campaigns: { ...state.campaigns, [topicId]: campaigns },
        loadingCampaigns: false,
    }),
    [LOAD_CAMPAIGNS_FAILED]: state => state.set('loadingCampaigns', false),
    [CREATE_CAMPAIGN]: state => state.set('creatingCampaign', true),
    [CREATE_CAMPAIGN_COMPLETED]: (state, { payload: { campaign } }) => {
        const campaigns = state.campaigns[campaign.topicId] || [];
        return state.merge({
            campaigns: { ...state.campaigns, [campaign.topicId]: [campaign, ...campaigns] },
            creatingCampaign: false,
        }, { deep: true });
    },
    [CREATE_CAMPAIGN_FAILED]: state => state.set('creatingCampaign', false),
    [DELETE_CAMPAIGN]: (state, { payload: { campaignId } }) => state.merge({
        deletingCampaign: true,
        campaignIdAction: campaignId,
    }),
    [DELETE_CAMPAIGN_COMPLETED]: (state, { payload: { topicId } }) => {
        const {
            campaigns,
            campaignIdAction,
        } = state;

        const campaignsList = campaigns[topicId].filter(({ id }) => id !== campaignIdAction);
        return state.merge({
            campaigns: {
                [topicId]: campaignsList,
            },
            campaignIdAction: null,
        }, { deep: true });
    },
    [DELETE_CAMPAIGN_FAILED]: state => state.merge({
        campaignIdAction: null,
    }),

    [DELETE_GENERAL_CAMPAIGN]: (state, { payload: { campaignId } }) => state.merge({
        deletingCampaign: true,
        campaignIdAction: campaignId,
    }),
    [DELETE_GENERAL_CAMPAIGN_COMPLETED]: (state) => {
        const {
            campaigns,
            campaignIdAction,
        } = state;

        const campaignsList = campaigns.campaigns.filter(({ id }) => id !== campaignIdAction);
        return state.merge({
            campaigns: {
                campaigns: campaignsList,
            },
            campaignIdAction: null,
        }, { deep: true });
    },
    [DELETE_GENERAL_CAMPAIGN_FAILED]: state => state.merge({
        campaignIdAction: null,
    }),

    [CREATE_SPECIALTY_CAMPAIGN]: state => state.set('creatingCampaign', true),
    [CREATE_SPECIALTY_CAMPAIGN_COMPLETED]: (state, { payload: { campaigns } }) => {
        const storedCampaigns = state.campaigns.campaigns || [];
        return state.merge({
            campaigns: { ...state.campaigns, campaigns: [...campaigns, ...storedCampaigns] },
            creatingCampaign: false,
        }, { deep: true });
    },
    [CREATE_SPECIALTY_CAMPAIGN_FAILED]: state => state.set('creatingCampaign', false),

    [CREATE_NPI_CAMPAIGN]: state => state.set('creatingCampaign', true),
    [CREATE_NPI_CAMPAIGN_COMPLETED]: (state, { payload: { campaign } }) => {
        const campaigns = state.campaigns.campaigns || [];
        return state.merge({
            campaigns: { ...state.campaigns, campaigns: [campaign, ...campaigns] },
            creatingCampaign: false,
            createCampaignErrorMessage: null,
        }, { deep: true });
    },
    [CREATE_NPI_CAMPAIGN_FAILED]: (state, { payload: { error } }) => state.merge({
        creatingCampaign: false,
        createCampaignErrorMessage: (typeof error.data !== 'undefined') ? error.data.message : null,
    }),
    [LOAD_GENERAL_CAMPAIGNS]: state => state.merge({
        loadingCampaigns: true,
    }),
    [LOAD_GENERAL_CAMPAIGNS_COMPLETED]: (state, {
        payload: {
            campaigns,
            total,
            page,
        },
    }) => state.merge({
        campaigns: { ...state.campaigns, campaigns },
        pageNumber: parseInt(page, 10),
        total,
        loadingCampaigns: false,
    }),
    [LOAD_GENERAL_CAMPAIGNS_FAILED]: state => state.set('loadingCampaigns', false),
}, initialState);

export default reducer;
