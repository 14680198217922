import React from 'react';
import PropTypes from 'prop-types';
import {
    Divider,
    Button,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import UserEngagement from './UserEngagement';
import EmailList from './EmailList';
import ConnectedPrograms from './ConnectedPrograms';
import localization from '../../../../localization';
import QuestionCardContent from '../QuestionCard/QuestionCardContent/container';
import { LoadingIndicator } from '../../../../components';
import { requiredWhenLoaded } from '../../../../utils/propTypes';
import DetachModal from '../Modals/DetachProgram';
import AttachModal from '../Modals/AttachProgram';
import NewCampaignModal from './NewCampaign';
import { topicProgramsEnabled } from '../../../../environment';
import './style.scss';

const {
    admin: {
        questions: {
            details: {
                pageTitle,
            },
        },
    },

} = localization;

const QuestionDetails = ({
    id,
    questionLink,
    loading,
    // functions
    status,
    ...props
}) => (
    <div className="question-details">
        <DetachModal topicId={id} />
        <NewCampaignModal />
        <div className="header">
            <Button
                icon="arrow left"
                className="action-button"
                as={Link}
                to={questionLink}
                href={questionLink}
            />
            <div className="header-text">{pageTitle}</div>
        </div>
        <AttachModal />
        <LoadingIndicator active={loading} />
        <div className="page-container question-card">
            <QuestionCardContent
                id={id}
                {...props}
                status={status}
                loading={loading}
                extended
            />

        </div>
        <div className="page-container">
            <UserEngagement id={id} loading={loading} {...props} />
            <Divider />
            <EmailList topicId={id} status={status} {...props} />
        </div>
        {
            topicProgramsEnabled &&
            <div className="page-container">
                <ConnectedPrograms topicId={id} status={status} {...props} />
            </div>
        }
    </div>
);

QuestionDetails.propTypes = {
    id: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    questionLink: PropTypes.string.isRequired,
    status: requiredWhenLoaded(PropTypes.number.isRequired),
};

QuestionDetails.defaultProps = {
    status: null,
};

export default QuestionDetails;
