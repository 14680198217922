import Immutable from 'seamless-immutable';
import { handleActions } from 'redux-actions';
import { priorityWay } from '../../../../data/enums';
import { LOCATION_CHANGE } from '../../../../services/navigation';
import {
    SET_ANSWER_REPORT_FAILED,
    CLEAR_ANSWER_REPORT_FAILED,
    OPEN_NEW_EMAIL_MODAL,
    CLOSE_NEW_EMAIL_MODAL,
    CLOSE_EMAIL_FAILED,
    OPEN_ATTACH_MODAL,
    CLOSE_ATTACH_MODAL,
    OPEN_DETACH_MODAL,
    CLOSE_DETACH_MODAL,
} from './actionTypes';
import {
    LOAD_CAMPAIGNS,
    LOAD_CAMPAIGNS_COMPLETED,
    LOAD_CAMPAIGNS_FAILED,
    DOWNLOAD_ANSWER_REPORT_COMPLETED,
    CREATE_CAMPAIGN_FAILED,
    CREATE_CAMPAIGN_COMPLETED,
} from '../../../../data/campaigns/actionTypes';
// question_programs
import {
    CLEAR_ERROR_MESSAGE,
    PROGRAM_PRIORITY_CHANGE,
    PROGRAM_PRIORITY_CHANGE_COMPLETED,
    PROGRAM_PRIORITY_CHANGE_FAILED,
    ATTACH_PROGRAMS_FAILED,
    DETACH_PROGRAM,
    DETACH_PROGRAM_COMPLETED,
    DETACH_PROGRAM_FAILED,
    DETACH_ALL_PROGRAMS_FAILED,
    GET_ATTACHED_PROGRAMS,
    GET_ATTACHED_PROGRAMS_COMPLETED,
    GET_ATTACHED_PROGRAMS_FAILED,
    GET_UNATTACHED_PROGRAMS,
    GET_UNATTACHED_PROGRAMS_COMPLETED,
    GET_UNATTACHED_PROGRAMS_FAILED,
} from '../../../../data/topicPrograms/actionTypes';

import { DELETE_QUESTION_FAILED } from '../../../../data/questions/actionTypes';

const initialState = Immutable({
    answerReportStatus: null,
    newEmailModalOpened: false,
    deleteSuccess: null,
    errorCreatingCampaign: false,
    // question_programs
    isAttachModalOpen: false,
    isDetachModalOpen: false,
    priorityUpLoading: false,
    priorityDownLoading: false,
    programDetaching: false,
    programInActionId: -1,
    programId: -1,
    loadingNewPrograms: false,
    loadingPrograms: false,
    loadingEmailList: false,
    attachingError: false,
    detachingError: false,
    priorityChangeError: false,
});

export default handleActions({
    [CLEAR_ERROR_MESSAGE]: state => state.merge({
        attachingError: false,
        detachingError: false,
        priorityChangeError: false,
    }),
    [LOAD_CAMPAIGNS]: state => state.set('loadingEmailList', true),
    [LOAD_CAMPAIGNS_COMPLETED]: state => state.set('loadingEmailList', false),
    [LOAD_CAMPAIGNS_FAILED]: state => state.set('loadingEmailList', false),
    [DOWNLOAD_ANSWER_REPORT_COMPLETED]: state => state.set('answerReportStatus', true),
    [SET_ANSWER_REPORT_FAILED]: state => state.set('answerReportStatus', false),
    [LOCATION_CHANGE]: state => state.merge({
        answerReportStatus: null,
        newEmailModalOpened: false,
        deleteSuccess: null,
        deleteModalOpen: false,
        errorCreatingCampaign: false,
        attachingError: false,
        detachingError: false,
        priorityChangeError: false,
    }),
    [CLEAR_ANSWER_REPORT_FAILED]: state => state.set('answerReportStatus', null),
    [OPEN_NEW_EMAIL_MODAL]: state => state.set('newEmailModalOpened', true),
    [CLOSE_NEW_EMAIL_MODAL]: state => state.set('newEmailModalOpened', false),
    [CLEAR_ANSWER_REPORT_FAILED]: state => state.set('answerReportStatus', null),
    [DELETE_QUESTION_FAILED]: state => state.set('deleteSuccess', false),
    [CREATE_CAMPAIGN_COMPLETED]: state => state.set('newEmailModalOpened', false),
    [CREATE_CAMPAIGN_FAILED]: state => state.merge({
        newEmailModalOpened: false,
        errorCreatingCampaign: true,
    }),
    [CLOSE_EMAIL_FAILED]: state => state.set('errorCreatingCampaign', false),
    // question_programs
    [OPEN_ATTACH_MODAL]: state => state.set('isAttachModalOpen', true),
    [CLOSE_ATTACH_MODAL]: state => state.set('isAttachModalOpen', false),
    [OPEN_DETACH_MODAL]: (state, { payload: { programId } }) => state.merge({
        isDetachModalOpen: true,
        programId,
    }),
    [CLOSE_DETACH_MODAL]: state => state.merge({
        isDetachModalOpen: false,
        programId: -1,
    }),
    [PROGRAM_PRIORITY_CHANGE]: (state, { payload: { programId, change } }) => state.merge({
        programInActionId: programId,
        priorityUpLoading: change === priorityWay.up,
        priorityDownLoading: change === priorityWay.down,
    }),
    [PROGRAM_PRIORITY_CHANGE_COMPLETED]: state => state.merge({
        programInActionId: -1,
        priorityUpLoading: false,
        priorityDownLoading: false,
    }),
    [PROGRAM_PRIORITY_CHANGE_FAILED]: state => state.merge({
        programInActionId: -1,
        priorityUpLoading: false,
        priorityDownLoading: false,
        priorityChangeError: true,
    }),
    [DETACH_PROGRAM]: (state, { payload: { programId } }) => state.merge({
        programInActionId: programId,
        programDetaching: true,
    }),
    [DETACH_PROGRAM_COMPLETED]: state => state.merge({
        programInActionId: -1,
        programDetaching: false,
    }),
    [DETACH_PROGRAM_FAILED]: state => state.merge({
        programInActionId: -1,
        programDetaching: false,
        detachingError: true,
    }),
    [GET_ATTACHED_PROGRAMS]: state => state.set('loadingPrograms', true),
    [GET_ATTACHED_PROGRAMS_COMPLETED]: state => state.set('loadingPrograms', false),
    [GET_ATTACHED_PROGRAMS_FAILED]: state => state.set('loadingPrograms', false),
    [GET_UNATTACHED_PROGRAMS]: state => state.set('loadingNewPrograms', true),
    [GET_UNATTACHED_PROGRAMS_COMPLETED]: state => state.set('loadingNewPrograms', false),
    [GET_UNATTACHED_PROGRAMS_FAILED]: state => state.set('loadingNewPrograms', false),
    [ATTACH_PROGRAMS_FAILED]: state => state.set('attachingError', true),
    [DETACH_ALL_PROGRAMS_FAILED]: state => state.set('detachingError', true),
}, initialState);
