import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import times from 'lodash/times';
import './style.scss';

const duration = 500;

const Loadable = ({
    children,
    in: inProp,
    size,
    rows,
    as,
}) => {
    const Wrapper = as;
    return (
        <Transition
            timeout={duration}
            in={inProp}
        >
            {(status) => {
                let spacePlaceholder = null;
                if (status !== 'entered') {
                    spacePlaceholder = times(rows, i => <br key={i} />);
                }
                return (
                    <Wrapper
                        className={`loading-${status} ${size}`}
                    >
                        {children}
                        {!inProp && spacePlaceholder}
                    </Wrapper>
                );
            }}
        </Transition>
    );
};

Loadable.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]),
    in: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    rows: PropTypes.number,
    as: (...args) => PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.symbol,
    ])(...args),
};

Loadable.defaultProps = {
    size: 'small',
    children: null,
    rows: 1,
    as: 'div',
};

export default Loadable;
