import { createSelector } from '@reduxjs/toolkit';
import { ID as ModeratorID } from '../../';
import { ID } from './actionTypes';

const selectUsers = ({
    [ModeratorID]: {
        [ID]: {
            users,
        },
    },
}) => users;

export default createSelector(
    selectUsers,
    users => users.map(({ firstName, lastName, ...other }) => ({
        name: `${firstName} ${lastName}`,
        ...other,
    })),
);
