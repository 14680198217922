export const ID = 'USERS';

export const CREATE_USER = `${ID}/CREATE_USER`;
export const CREATE_USER_COMPLETED = `${ID}/CREATE_USER_COMPLETED`;
export const CREATE_USER_FAILED = `${ID}/CREATE_USER_FAILED`;

export const LOAD_USERS = `${ID}/LOAD_USERS`;
export const LOAD_USERS_COMPLETED = `${ID}/LOAD_USERS_COMPLETED`;
export const LOAD_USERS_FAILED = `${ID}/LOAD_USERS_FAILED`;

export const LOAD_USER = `${ID}/LOAD_USER`;
export const LOAD_USER_COMPLETED = `${ID}/LOAD_USER_COMPLETED`;
export const LOAD_USER_FAILED = `${ID}/LOAD_USER_FAILED`;

export const UPDATE_USER = `${ID}/UPDATE_USER`;
export const UPDATE_USER_COMPLETED = `${ID}/UPDATE_USER_COMPLETED`;
export const UPDATE_USER_FAILED = `${ID}/UPDATE_USER_FAILED`;

export const DELETE_USER = `${ID}/DELETE_USER`;
export const DELETE_USER_COMPLETED = `${ID}/DELETE_USER_COMPLETED`;
export const DELETE_USER_FAILED = `${ID}/DELETE_USER_FAILED`;

export const RESTORE_USER = `${ID}/RESTORE_USER`;
export const RESTORE_USER_COMPLETED = `${ID}/RESTORE_USER_COMPLETED`;
export const RESTORE_USER_FAILED = `${ID}/RESTORE_USER_FAILED`;

export const LOAD_USER_NPI = `${ID}/LOAD_USER_NPI`;
export const LOAD_USER_NPI_COMPLETED = `${ID}/LOAD_USER_NPI`;
export const LOAD_USER_NPI_FAILED = `${ID}/LOAD_USER_NPI`;
