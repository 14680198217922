import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import { priorityWay } from '../enums';
import { filterProgram } from '../../utils/data';
import {
    PROGRAM_PRIORITY_CHANGE_COMPLETED,
    DETACH_PROGRAM_COMPLETED,
    ATTACH_PROGRAMS_COMPLETED,
    DETACH_ALL_PROGRAMS_COMPLETED,
    GET_ATTACHED_PROGRAMS_COMPLETED,
    GET_UNATTACHED_PROGRAMS_COMPLETED,
} from './actionTypes';

const initialState = Immutable.from({
    attachedPrograms: [],
    unAttachedPrograms: [],
});

const reducer = handleActions({
    [GET_ATTACHED_PROGRAMS_COMPLETED]: (state, { payload: { programs } }) => state.merge({
        attachedPrograms: filterProgram(programs),
    }),
    [GET_UNATTACHED_PROGRAMS_COMPLETED]:
        (state, { payload: { programs } }) => state.merge({
            unAttachedPrograms: programs.sort((p1, p2) => p2.hcmpPrgId - p1.hcmpPrgId),
        }),
    [PROGRAM_PRIORITY_CHANGE_COMPLETED]: (state, { payload: { programId, change } }) => {
        const attachedArray = [...state.attachedPrograms];
        const switchIndex = attachedArray.findIndex(program => program.id === programId);
        const changedArray = attachedArray.map(({ id, priority, ...rest }, index) => {
            let newPriority = priority;

            if (change === priorityWay.up) {
                if (index === switchIndex) {
                    newPriority = priority - 1;
                } else if (index === switchIndex - 1) {
                    newPriority = priority + 1;
                }
            } else if (change === priorityWay.down) {
                if (index === switchIndex) {
                    newPriority = priority + 1;
                } else if (index === switchIndex + 1) {
                    newPriority = priority - 1;
                }
            }

            return {
                id,
                ...rest,
                priority: newPriority,
            };
        });

        return state.merge({
            attachedPrograms: filterProgram(changedArray),
        });
    },
    [ATTACH_PROGRAMS_COMPLETED]: (state, { payload: { programs, newPrograms } }) => {
        const filteredPrograms = programs.map(({
            id,
            name,
            airDate,
            hcmpPrgId,
        }, index) => ({
            id,
            name,
            airDate,
            hcmpPrgId,
            priority: newPrograms[index].priority,
        }));
        return state.merge({
            attachedPrograms: filterProgram([...state.attachedPrograms, ...filteredPrograms]),
        });
    },
    [DETACH_PROGRAM_COMPLETED]: (state, { payload: { programId } }) => {
        const { attachedPrograms } = state;
        const { priority: deletedPriority } =
            attachedPrograms.find(program => program.id === programId);

        return state.merge({
            attachedPrograms: filterProgram([...attachedPrograms.filter(program =>
                program.id !== programId).map(({
                priority,
                ...rest
            }) => ({
                priority: deletedPriority < priority ? priority - 1 : priority,
                ...rest,
            }))]),
        });
    },
    [DETACH_ALL_PROGRAMS_COMPLETED]: state => state.merge({
        attachedPrograms: [],
    }),
}, initialState);

export default reducer;
