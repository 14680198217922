import { connect } from 'react-redux';
import Answer from './container';
import { ID as ContainerID } from '../../actionTypes';
import { updateAnswer } from '../../../../data/answers';
import {
    openAnswerEditForm,
    clearUpdatedComment,
} from '../../actions';
import { ID as DataID, AnswersID } from '../../../../data';

const mapStateToProps = (state, {
    answer: {
        id,
    },
}) => {
    const {
        [ContainerID]: {
            commentedAnswer,
            editedAnswer,
            lastUpdatedCommentId,
            ratedAnswer: {
                answerId,
                pressedRating,
            },
        },
        [DataID]: {
            [AnswersID]: {
                ratingInProgress,
                deleting,
                updating,
            },
        },
    } = state;

    return ({
        replyFormOpen: id === commentedAnswer,
        editMode: id === editedAnswer,
        lastUpdatedCommentId,
        ratingInProgress: ratingInProgress && answerId === id,
        pressedRating,
        deleting,
        updating,
    });
};

export default connect(mapStateToProps, {
    updateAnswer,
    openAnswerEditForm,
    clearUpdatedComment,
})(Answer);
