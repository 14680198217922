import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';

const RadioGroup = ({
    input,
    options,
    withDivider,
}) => (
    <div className="pb-4">
        {options.map(({ value, label }) => (
            <div
                key={value}
                className={`flex ${withDivider && 'py-4 border-b border-b-[#CECCD6]'}`}
                // border-bottom-style seems not to be an option in tailwind when preflight is true
                style={withDivider && { borderBottomStyle: 'solid' }}
            >
                <input
                    id={value}
                    className="w-5 h-5 accent-[#0B47C1]"
                    type="radio"
                    onChange={() => input.onChange(value)}
                    checked={value === input.value}
                    value={value}
                />
                <label
                    htmlFor={value}
                    className="pl-4 font-body text-sm font-normal text-[#0C0033]"
                >
                    {label}
                </label>
            </div>
        ))}
    </div>
);

RadioGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    withDivider: PropTypes.bool,
    ...fieldPropTypes,
};

RadioGroup.defaultProps = {
    withDivider: true,
};

export default RadioGroup;
