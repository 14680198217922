import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Button, Comment } from 'semantic-ui-react';
import localization from '../../../../../localization';
import { syncValidations, warn, submitValidations, answerLength } from '../../validations';
import { preSubmitValidation } from '../../../../../utils/form/validators';
import { editAnswerFormName } from '../../constants';
import { AnswerCommentForm } from '../../components';

const {
    min: minAnswerLength,
    max: maxAnswerLength,
} = answerLength;

const AnswerEditForm = reduxForm({
    form: editAnswerFormName,
    enableReinitialize: true,
    validate: syncValidations(maxAnswerLength),
    warn: warn(maxAnswerLength),
})(AnswerCommentForm);

const {
    common: {
        save,
        cancel,
    },
} = localization;

const AnswerEdit = ({
    id,
    text,
    topicId,
    updateAnswer,
    openAnswerEditForm,
    updateInProgress,
}) => (
    <AnswerEditForm
        onSave={preSubmitValidation(
            submitValidations(minAnswerLength),
            ans => updateAnswer({ ...ans, topicId }),
        )}
        initialValues={{
            text,
            id,
        }}
        submitButtonProps={{
            label: save,
            icon: 'save',
            loading: updateInProgress,
        }}
    >
        <Comment.Action>
            <Button
                onClick={() => openAnswerEditForm(null)}
                content={cancel}
            />
        </Comment.Action>
    </AnswerEditForm>
);

AnswerEdit.propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    topicId: PropTypes.number.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    openAnswerEditForm: PropTypes.func.isRequired,
    updateInProgress: PropTypes.bool.isRequired,
};

export default AnswerEdit;
