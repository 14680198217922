import { ExclamationTriangleIcon } from '@radix-ui/react-icons';

// The time in ms that two toasts would be considered to be shown at roughly the same time
const ROUGHLY_SAME_TIME_MS = 300;

let toastId = 0; // a simple identifier so we know which toasts to dismiss

export const showToast = ({
    header, body, icon, isPersistent, iconColor, link,
}) => {
    toastId += 1;
    return {
        type: 'toast/SHOW',
        payload: {
            id: toastId,
            shownAt: Date.now(),
            dismissedAt: 0,
            header,
            body,
            icon,
            isPersistent,
            iconColor,
            link,
        },
    };
};

// A helper action that pre-sets the error icon, and persists the toast (until dismissed by the user)
export const showErrorToast = ({ header, body = 'If the issue persists, please contact technical support.', link }) => showToast({
    header, body, icon: ExclamationTriangleIcon, isPersistent: true, iconColor: '#BE4335', link,
});

export const showGlobalErrorToast = () => showErrorToast({
    header: 'There was an unexpected error.',
    body: 'If the error persists, please contact member services.',
    link: { href: 'https://healthcasts.com/contact', text: 'Contact Member Services' },
});

export const dismissToast = id => ({
    type: 'toast/DISMISS',
    payload: { id },
});

export const removeToast = id => ({
    type: 'toast/REMOVE',
    payload: { id },
});

// check if two toasts are showing the user the same content at about the same time; used to detect toast spamming
const toastsAreDuplicates = (a, b) =>
    a && b && a.header === b.header && a.body === b.body && Math.abs(a.shownAt - b.shownAt) < ROUGHLY_SAME_TIME_MS;
// the `dismissedAt == 0` check is to prevent the transition timer from being reset if the toast is clicked repeatedly
const setDismissedAt = toast => (toast.dismissedAt === 0 ? { ...toast, dismissedAt: Date.now() } : toast);

const initialState = { toasts: [] };

export const reducer = (state = initialState, { type, payload }) => {
    const { toasts } = state;
    const id = payload?.id;
    switch (type) {
        case 'toast/SHOW':
            // If the newest toast matches the previously shown toast, don't show the new toast (prevent spamming)
            return toastsAreDuplicates(payload, toasts && toasts[0]) ? state : { toasts: [payload, ...toasts] };
        case 'toast/DISMISS':
            return { toasts: toasts.map(toast => (toast.id === id ? setDismissedAt(toast) : toast)) };
        case 'toast/REMOVE':
            return { toasts: toasts.filter(toast => toast.id !== id) };
        default:
            return state;
    }
};
