import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { fetchUser } from '../../services/session';
import api from '../../api';

// We need to pass `getAccessTokenSilently` to the API module so
// that calls can use auth0 for getting accessTokens and refreshTokens.
const AppInitializer = ({ children, handlePostLogin }) => {
    const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();

    React.useEffect(() => {
        if (isAuthenticated) {
            handlePostLogin();
        }
    }, [isAuthenticated, handlePostLogin]);

    api.init(getAccessTokenSilently, logout);
    return children;
};

const mapDispatchToProps = dispatch => ({
    handlePostLogin: () => {
        dispatch(fetchUser());
    },
});

export default connect(null, mapDispatchToProps)(AppInitializer);
