import unionBy from 'lodash/unionBy';

/**
 * Utility that builds hieararchy tree from flat list
 * @param {Object[]} list - flat list of elements with parent ID
 * @param {string} pk - primary key name, default value is id
 * @param {string} fk - reference name, default is parentId
 * @param {string} childrenProperty - name of the property that
 * should be filed with children, default value is children
 *
 * @returns {Object[]} Array of tree roots
 */
export const buildTree = (list = [], pk = 'id', fk = 'parentId', childrenProperty = 'children') => {
    const dictionary = list.reduce((accTree, { [pk]: ID, ...props }) => {
        accTree[ID] = { [pk]: ID, ...props, [childrenProperty]: [] }; // eslint-disable-line
        return accTree;
    }, {});

    const tree = list.reduce((treeList, { [pk]: ID, [fk]: parentId }) => {
        if (parentId) {
            dictionary[parentId][childrenProperty].push(dictionary[ID]);
        } else {
            treeList.push(dictionary[ID]);
        }
        return treeList;
    }, []);
    return tree;
};

/*
 * Merges arrays by replacing later object with newer using a key as a discriminator
 * @param {entity} key used as identifier for objects
 * @param {entity[][]} rest array of array which contains object with
 */
export const mergeArraysByKey = (arr1, arr2, key = 'id') => unionBy(arr2, arr1, key);

/**
 * Returns a new object with selected properties from the passed object
 * @param {any} originalObject
 * @param {string[]} fields properties which to include in the returning object
 */
export const pick = (originalObject, fields) =>
    fields.reduce((obj, field) => {
        const value = originalObject[field];
        if (value !== undefined) {
            return {
                ...obj,
                [field]: originalObject[field],
            };
        }
        return obj;
    }, {});

/**
 * Returns a reordered array of programs regarding priority
 * @param {{}[]} programs
 */
export const filterProgram = (programs) => {
    const unordered = programs.map(({
        hcmpPrgId,
        id,
        name,
        priority,
        airDate,
    }) => {
        const newProgram = {
            id,
            hcmpPrgId,
            airDate,
            name,
            priority,
            actions: {},
        };

        if (newProgram.priority === 1 && programs.length > 1) {
            newProgram.actions.down = true;
        } else if (newProgram.priority > 1 &&
            programs.length > newProgram.priority
        ) {
            newProgram.actions.up = true;
            newProgram.actions.down = true;
        } else if (newProgram.priority > 1 &&
            programs.length === newProgram.priority
        ) {
            newProgram.actions.up = true;
        }

        return newProgram;
    });

    return unordered.sort((p1, p2) => p1.priority - p2.priority);
};
