export const ID = 'THREAD_CONTAINER';

export const OPEN_REPLY_FORM = `${ID}/OPEN_REPLY_FORM`;
export const OPEN_ANSWER_EDIT_FORM = `${ID}/OPEN_ANSWER_EDIT_FORM`;
export const CLEAR_LAST_UPDATED_COMMENT = `${ID}/CLEAR_LAST_UPDATED_COMMENT`;
export const MARK_ANSWER_AS_NEW = `${ID}/MARK_ANSWER_AS_NEW`;

export const SHOW_PROGRAM = `${ID}/SHOW_PROGRAM`;
export const SHOW_NOTICE = `${ID}/SHOW_NOTICE`;
export const SHOW_NEXT_QUESTION = `${ID}/SHOW_NEXT_QUESTION`;
export const HIDE_NOTICE = `${ID}/HIDE_NOTICE`;
export const SHOW_PROGRAM_FAILED = `${ID}/SHOW_PROGRAM_FAILED`;

export const PREPARE_PROGRAM = `${ID}/PREPARE_PROGRAM`;

export const ANSWER_INPUT_FOCUSED = `${ID}/ANSWER_INPUT_FOCUSED`;
