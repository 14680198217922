import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { LoadingIndicator } from '../../components';
import { logoutUser } from '../../services/session';

const Logout = ({ dispatchLogout }) => {
    const { logout } = useAuth0();

    useEffect(() => {
        dispatchLogout();
        logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
    }, [logout, dispatchLogout]);

    return (<LoadingIndicator active="true" />);
};

const mapDispatchToProps = dispatch => ({
    dispatchLogout: () => dispatch(logoutUser()),
});

Logout.propTypes = {
    dispatchLogout: PropTypes.func.isRequired,
};

export default compose(connect(null, mapDispatchToProps))(Logout);
