import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../../../../localization';
import './style.scss';

const {
    admin: {
        globalHcmpList: {
            yes,
            no,
        },
    },
} = localization;

const StatusLabel = ({ enabled }) => (
    <div className={`label label-${enabled ? 'positive' : 'negative'}`}>
        {enabled ? yes : no}
    </div>
);

StatusLabel.propTypes = {
    enabled: PropTypes.bool.isRequired,
};

export default StatusLabel;
