
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { Dropdown, Checkbox } from '../../../../../components';
import localization from '../../../../../localization';
import { questionStatus } from '../../../../../data/enums';
import './style.scss';

const {
    admin: {
        questions: {
            questionSearch: {
                keywords,
                specialty,
                active,
                scheduled,
                expired,
                searchReset,
                filterResults,
                keywordsHolder,
                specialtyHolder,
            },
        },
    },
} = localization;

const {
    scheduled: scheduledEnum,
    active: activeEnum,
    expired: expiredEnum,
} = questionStatus;

class Search extends Component {
    static propTypes = {
        location: PropTypes.shape({}).isRequired,
        resetSearchValues: PropTypes.func.isRequired,
        specialties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        scheduledReadOnly: PropTypes.bool.isRequired,
        activeReadOnly: PropTypes.bool.isRequired,
        expiredReadOnly: PropTypes.bool.isRequired,
        changeSearchValues: PropTypes.func.isRequired,
    }

    componentDidMount() {
        const {
            location: {
                query: {
                    status = [],
                },
            },
            changeSearchValues,
        } = this.props;

        if (status.length) {
            const scheduledStatus = status.indexOf(scheduledEnum.toString()) !== -1;
            const activeStatus = status.indexOf(activeEnum.toString()) !== -1;
            const expiredStatus = status.indexOf(expiredEnum.toString()) !== -1;

            changeSearchValues(scheduledStatus, activeStatus, expiredStatus, true);
        }
    }

    render() {
        const {
            resetSearchValues,
            specialties,
            scheduledReadOnly,
            activeReadOnly,
            expiredReadOnly,
        } = this.props;

        return (
            <Form className="moderation-search">
                <div className="search-box">
                    <div className="keywords">
                        <Field
                            className="search-form"
                            component={Form.Input}
                            label={keywords}
                            name="term"
                            icon="search"
                            placeholder={keywordsHolder}
                        />
                    </div>
                    <div>
                        <Field
                            className="search-form"
                            component={Form.Field}
                            control={Dropdown}
                            multiple
                            options={specialties}
                            label={specialty}
                            name="specs"
                            placeholder={specialtyHolder}
                        />
                    </div>
                </div>
                <div>
                    <br />
                    <span className="search-form">{filterResults}</span>
                    <br /> <br />
                    <span className="inline-align">
                        <span className="inline-align">
                            <Field
                                component={Checkbox}
                                className="checkbox-filters"
                                label={scheduled}
                                name="status.scheduled"
                                readOnly={scheduledReadOnly}
                            />
                            <Field
                                component={Checkbox}
                                className="checkbox-filters"
                                label={active}
                                name="status.active"
                                readOnly={activeReadOnly}
                            />
                            <Field
                                component={Checkbox}
                                className="checkbox-filters"
                                label={expired}
                                name="status.expired"
                                readOnly={expiredReadOnly}
                            />
                        </span>
                        <Form.Button
                            basic
                            active
                            labelPosition="left"
                            content={searchReset}
                            onClick={resetSearchValues}
                            icon="refresh"
                            type="button"
                        />
                    </span>
                </div>
            </Form>
        );
    }
}

export default Search;
