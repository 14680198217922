import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './button.scss';

const Button = forwardRef(({
    children,
    type,
    className,
    disabled,
    variant,
    icon,
    iconPosition,
    ...props
}, ref) => {
    const classNames = clsx('ui-button', variant, className);

    return (
        <button className={classNames} type={type} ref={ref} disabled={disabled} {...props}>
            {icon && iconPosition === 'left' && <span className="icon">{icon}</span>}
            {children}
            {icon && iconPosition === 'right' && <span className="icon">{icon}</span>}
        </button>
    );
});

Button.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['submit', 'button']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(['primary', 'secondary', 'text', 'warning', 'icon']),
    icon: PropTypes.element,
    iconPosition: PropTypes.oneOf(['left', 'right']),
};

Button.defaultProps = {
    type: 'button',
    className: '',
    disabled: false,
    variant: 'primary',
    icon: null,
    iconPosition: 'left',
};

Button.displayName = 'Button';
export default Button;
