import React from 'react';
import PropTypes from 'prop-types';
import { SpecialtyProps } from '../../utils/propTypes';
import './style.scss';

const SpecialtyTag = ({
    name,
    type,
}) => <div className={`tag-${type}`}>{name}</div>;

SpecialtyTag.propTypes = {
    ...SpecialtyProps,
    type: PropTypes.oneOf(['specialty', 'subspecialty']).isRequired,
};

export default SpecialtyTag;
