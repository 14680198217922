import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Header, TableRow, TableCell, Message } from 'semantic-ui-react';
import localization from '../../../../../localization';
import { questionStatus, priorityWay } from '../../../../../data/enums';
import TableEmptyState from '../../../../../components/TableEmptyState';
import './style.scss';

const {
    admin: {
        questions: {
            details: {
                tableHeaders,
                connectedPrograms,
                attachProgram,
                detachAll,
                detachAllWarning,
                attachError,
                detachError,
                priorityError,
            },
            emptyProgramsState,
        },
    },
} = localization;

const {
    expired,
} = questionStatus;

const ConnectedPrograms = ({
    topicId,
    loadingPrograms,
    attachedHcmp,
    status,
    priorityUpLoading,
    priorityDownLoading,
    programDetaching,
    programInActionId,
    attachingError,
    detachingError,
    priorityChangeError,
    // functions
    showModal,
    openAttachModal,
    programPriorityChange,
    openDetachModal,
    getUnattachedPrograms,
    detachAllPrograms,
    clearErrorMessage,
}) => {
    const buttonsDisabled = status === expired;

    const activeAction = priorityUpLoading || priorityDownLoading || programDetaching;

    const sameId = id => programInActionId === id;

    const loader = id => (
        priorityUpLoading ||
        priorityDownLoading ||
        programDetaching
    ) && !sameId(id);

    const upActionDisabled = id => buttonsDisabled || loader(id) || (
        (priorityDownLoading || programDetaching) && sameId(id)
    );

    const downActionDisabled = id => buttonsDisabled || loader(id) || (
        (priorityUpLoading || programDetaching) && sameId(id)
    );

    const detachActionDisabled = id => buttonsDisabled || loader(id) || (
        (priorityUpLoading || priorityDownLoading) && sameId(id)
    );

    let errorMessage = '';
    let showErrorMessage = false;
    if (attachingError) {
        errorMessage = attachError;
        showErrorMessage = true;
    } else if (detachingError) {
        errorMessage = detachError;
        showErrorMessage = true;
    } else if (priorityChangeError) {
        errorMessage = priorityError;
        showErrorMessage = true;
    }

    const rows = attachedHcmp.map(({
        id,
        hcmpPrgId,
        name,
        airDate,
        priority,
        actions,
    }) => (
        <TableRow key={id} className="programs">
            <TableCell textAlign="center">
                {hcmpPrgId}
            </TableCell>
            <TableCell className="program-title">
                {name}
            </TableCell>
            <TableCell textAlign="center">
                {new Date(airDate).toLocaleDateString()}
            </TableCell>
            <TableCell width={3} textAlign="left" className="priority">
                <span className="text">{priority}</span>
                <span className="action-buttons">
                    {
                        actions.up ?
                            <Button
                                disabled={upActionDisabled(id)}
                                loading={priorityUpLoading && sameId(id)}
                                className="action-button"
                                icon="long arrow up"
                                onClick={() => (activeAction ? null : programPriorityChange({
                                    programId: id,
                                    topicId,
                                    change: priorityWay.up,
                                }))}
                            /> : <span className="empty-box" />
                    }
                    {
                        actions.down ?
                            <Button
                                disabled={downActionDisabled(id)}
                                loading={priorityDownLoading && sameId(id)}
                                icon="long arrow down"
                                className="action-button"
                                onClick={() => (activeAction ? null : programPriorityChange({
                                    programId: id,
                                    topicId,
                                    change: priorityWay.down,
                                }))}
                            /> : <span className="empty-box" />
                    }
                </span>
            </TableCell>
            <TableCell textAlign="right" className="actions">
                <Button
                    circular
                    className="action-button"
                    disabled={detachActionDisabled(id)}
                    loading={programDetaching && sameId(id)}
                    icon="remove circle"
                    onClick={() => (activeAction ? null : openDetachModal({ programId: id }))}
                />
            </TableCell>
        </TableRow>
    ));
    return (
        <div className="programs-list">
            <Message
                hidden={!showErrorMessage}
                negative
                content={errorMessage}
                onDismiss={() => clearErrorMessage()}
            />
            <div className="top-half">
                <Header>{connectedPrograms}</Header>
                {typeof status === 'number' && status !== expired &&
                    <div>
                        <Button
                            primary
                            disabled={buttonsDisabled}
                            content={attachProgram}
                            onClick={() => {
                                getUnattachedPrograms({ topicId });
                                openAttachModal();
                            }}
                        />
                        <Button
                            disabled={buttonsDisabled || !attachedHcmp.length}
                            color="red"
                            content={detachAll}
                            onClick={() => showModal({
                                content: null,
                                header: detachAllWarning,
                                positiveResponseAction: () => {
                                    detachAllPrograms({ topicId });
                                },
                            })}
                        />
                    </div>
                }
            </div>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">{tableHeaders.programId}</Table.HeaderCell>
                        <Table.HeaderCell>{tableHeaders.programName}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{tableHeaders.programAirDate}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{tableHeaders.priority}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{tableHeaders.actions}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        rows.length ? rows : <TableEmptyState
                            loading={loadingPrograms}
                            text={emptyProgramsState}
                        />
                    }
                </Table.Body>
            </Table>
        </div>);
};

ConnectedPrograms.propTypes = {
    attachedHcmp: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        airDate: PropTypes.string,
        priority: PropTypes.number,
    })).isRequired,
    priorityUpLoading: PropTypes.bool.isRequired,
    priorityDownLoading: PropTypes.bool.isRequired,
    programDetaching: PropTypes.bool.isRequired,
    programInActionId: PropTypes.number.isRequired,
    openAttachModal: PropTypes.func.isRequired,
    programPriorityChange: PropTypes.func.isRequired,
    detachAllPrograms: PropTypes.func.isRequired,
    openDetachModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    topicId: PropTypes.number.isRequired,
    getUnattachedPrograms: PropTypes.func.isRequired,
    loadingPrograms: PropTypes.bool.isRequired,
    attachingError: PropTypes.bool.isRequired,
    detachingError: PropTypes.bool.isRequired,
    priorityChangeError: PropTypes.bool.isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    status: PropTypes.number,
};

ConnectedPrograms.defaultProps = {
    status: null,
};

export default ConnectedPrograms;
