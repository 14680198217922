import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Header, Message } from 'semantic-ui-react';
import localization from '../../../../localization';
import UnassignedPrograms from './UnassignedPrograms';
import AssignedPrograms from './AssignedPrograms';
import { LoadingIndicator } from '../../../../components';
import Navigation from '../../../../components/GlobalPrograms/Navigation';

const {
    admin: {
        globalHcmpList: {
            programsOverview,
            errorText,
        },
    },
} = localization;

const GlobalHcmpList = ({ loading, error, errorMessage }) => (
    <div className="page-container">
        <div className="inline-align">
            <Header>{programsOverview}</Header>

            <Navigation active="index" />
        </div>

        <Divider className="expanded" />
        <LoadingIndicator active={loading} />
        <Message
            error
            hidden={!error}
        >
            {(errorMessage !== '') ? errorMessage : errorText}
        </Message>
        <UnassignedPrograms />
        <AssignedPrograms />
    </div>);

GlobalHcmpList.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
};

export default GlobalHcmpList;
