import { connect } from 'react-redux';
import DetachModal from './component';
import { closeDetachModal } from '../../QuestionDetails/actions';
import { detachProgram } from '../../../../../data/topicPrograms';
import { deleteModalSelector } from '../../QuestionDetails/selectors';
import { currentTopicIdSelector } from '../../../../../data/questions/selectors';

const mapStateToProps = (state) => {
    const {
        programId,
        isDetachModalOpen,
    } = deleteModalSelector(state);

    const currentTopic = currentTopicIdSelector(state);

    return {
        topicId: currentTopic,
        programId,
        isDetachModalOpen,
    };
};

const mapDispatchToProps = {
    closeDetachModal,
    detachProgram,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetachModal);
