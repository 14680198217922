import { connect } from 'react-redux';
import MyRewards from './component';
import { ID as DataID } from '../../../data';
import { ID as RewardsID } from '../../../data/rewards';

const mapStateToProps = (state) => {
    const {
        [DataID]: {
            [RewardsID]: {
                historyRewards,
                rewards,
            },
        },
    } = state;
    return ({
        historyRewards,
        rewards,
    });
};

export default connect(mapStateToProps)(MyRewards);
