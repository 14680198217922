export const ID = 'QUESTIONS';

export const UPDATE_QUESTION = `${ID}/UPDATE_QUESTION`;
export const UPDATE_QUESTION_COMPLETED = `${ID}/UPDATE_QUESTION_COMPLETED`;
export const UPDATE_QUESTION_FAILED = `${ID}/UPDATE_QUESTION_FAILED`;

export const CREATE_QUESTION_COMPLETED = `${ID}/CREATE_QUESTION_COMPLETED`;
export const CREATE_QUESTION_FAILED = `${ID}/CREATE_QUESTION_FAILED`;

export const LOAD_QUESTIONS = `${ID}/LOAD_QUESTIONS`;
export const LOAD_QUESTIONS_SEARCH = `${ID}/LOAD_QUESTION_SEARCH`;
export const LOAD_QUESTIONS_COMPLETED = `${ID}/LOAD_QUESTIONS_COMPLETED`;
export const LOAD_QUESTIONS_FAILED = `${ID}/LOAD_QUESTIONS_FAILED`;

export const DELETE_QUESTION = `${ID}/DELETE_QUESTION`;
export const DELETE_QUESTION_COMPLETED = `${ID}/DELETE_QUESTION_COMPLETED`;
export const DELETE_QUESTION_FAILED = `${ID}/DELETE_QUESTION_FAILED`;

export const LOAD_QUESTION = `${ID}/LOAD_QUESTION`;
export const LOAD_SEARCH_COMPLETED = `${ID}/LOAD_SEARCH_COMPLETED`;
export const LOAD_QUESTION_COMPLETED = `${ID}/LOAD_QUESTION_COMPLETED`;
export const LOAD_QUESTION_FAILED = `${ID}/LOAD_QUESTION_FAILED`;

export const LOAD_NEXT_QUESTION = `${ID}/LOAD_NEXT_QUESTION`;
export const LOAD_NEXT_QUESTION_COMPLETED = `${ID}/LOAD_NEXT_QUESTION_COMPLETED`;
export const LOAD_NEXT_QUESTION_FAILED = `${ID}/LOAD_NEXT_QUESTION_FAILED`;

export const FLAG_QUESTION = `${ID}/FLAG_QUESTION`;
export const FLAG_QUESTION_COMPLETED = `${ID}/FLAG_QUESTION_COMPLETED`;
export const FLAG_QUESTION_FAILED = `${ID}/FLAG_QUESTION_FAILED`;
export const RESET_FLAGGED_STATE = `${ID}/RESET_FLAGGED_STATE`;

export const CLEAR_QUESTIONS = `${ID}/CLEAR_QUESTIONS`;

export const LOAD_RELATED_USERS_COUNT = `${ID}/LOAD_RELATED_USERS_COUNT`;
export const LOAD_RELATED_USERS_COUNT_COMPLETED = `${ID}/LOAD_RELATED_USERS_COUNT_COMPLETED`;
export const LOAD_RELATED_USERS_COUNT_FAILED = `${ID}/LOAD_RELATED_USERS_COUNT_FAILED`;

export const LOAD_ELIGIBLE_USERS_COUNT = `${ID}/LOAD_ELIGIBLE_USERS_COUNT`;
export const LOAD_ELIGIBLE_USERS_COUNT_COMPLETED = `${ID}/LOAD_ELIGIBLE_USERS_COUNT_COMPLETED`;
export const LOAD_ELIGIBLE_USERS_COUNT_FAILED = `${ID}/LOAD_ELIGIBLE_USERS_COUNT_FAILED`;

export const LOAD_QUESTIONS_NPIS = `${ID}/LOAD_QUESTION_NPIS`;
export const LOAD_QUESTIONS_NPIS_COMPLETED = `${ID}/LOAD_QUESTION_NPIS_COMPLETED`;
export const LOAD_QUESTIONS_NPIS_FAILED = `${ID}/LOAD_QUESTION_NPIS_FAILED`;

export const UPDATE_QUESTIONS_NPIS = `${ID}/UPDATE_QUESTIONS_NPIS`;
export const UPDATE_QUESTIONS_NPIS_COMPLETED = `${ID}/UPDATE_QUESTIONS_NPIS_COMPLETED`;
export const UPDATE_QUESTIONS_NPIS_FAILED = `${ID}/UPDATE_QUESTIONS_NPIS_FAILED`;
