import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form, Modal, Button } from 'semantic-ui-react';
import { TextInput, RadioGroup } from '../../../../../components';
import { targetAudience as targetAudienceEnum } from '../../../../../data/enums';
import './style.scss';
import localization from '../../../../../localization';

const {
    common: {
        cancel,
    },
    admin: {
        questions: {
            newCampaignForm: {
                all,
                viewed,
                notViewed,
                notResponded,
                eligible,
                responded,
                title,
                emailName,
                targetAudience,
                selectTemplatePlaceholder,
                createEmail,
            },
        },
    },
} = localization;

const targetAudienceOptions = [{
    value: targetAudienceEnum.all,
    label: all,
}, {
    value: targetAudienceEnum.viewed,
    label: viewed,
}, {
    value: targetAudienceEnum.notViewed,
    label: notViewed,
}, {
    value: targetAudienceEnum.responded,
    label: responded,
}, {
    value: targetAudienceEnum.notResponded,
    label: notResponded,
}, {
    value: targetAudienceEnum.eligible,
    label: eligible,
}];

const audience = (useNpi) => {
    if (useNpi === null) {
        return <p>Audience Loading</p>;
    } else if (!useNpi) {
        return (
            <div className="form-row">
                <div className="field-label">
                    {targetAudience}
                </div>
                <Field
                    component={Form.Field}
                    control={RadioGroup}
                    name="targetAudience"
                    options={targetAudienceOptions}
                />
            </div>
        );
    }

    return null;
};

const NewCampaignComponent = ({
    open,
    handleSubmit,
    creatingCampaign,
    closeNewEmailModal,
    useNpi,
}) => (
    <Modal open={open} size="tiny" className="new-campaign">
        <Modal.Content>
            <div className="title">{title}</div>
            <Form
                loading={creatingCampaign}
            >
                <div className="form-row align">
                    <div className="field-label">
                        {emailName}
                    </div>
                    <Field
                        component={Form.Field}
                        control={TextInput}
                        readOnly
                        name="name"
                        placeholder={selectTemplatePlaceholder}
                        width={8}
                    />
                </div>
                {audience(useNpi)}
            </Form>
        </Modal.Content>
        <Modal.Actions className="actions">
            <Button
                content={cancel}
                disabled={creatingCampaign}
                type="button"
                onClick={() => closeNewEmailModal()}
            />
            <Button
                content={createEmail}
                disabled={creatingCampaign}
                positive
                onClick={() => handleSubmit()}
            />
        </Modal.Actions>
    </Modal>
);

NewCampaignComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    closeNewEmailModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    creatingCampaign: PropTypes.bool.isRequired,
    useNpi: PropTypes.bool.isRequired,
};

export default NewCampaignComponent;
