import { createAction } from 'redux-actions';
import {
    CLEAR_ERROR_MESSAGE,
    PROGRAM_PRIORITY_CHANGE,
    PROGRAM_PRIORITY_CHANGE_COMPLETED,
    PROGRAM_PRIORITY_CHANGE_FAILED,
    ATTACH_PROGRAMS,
    ATTACH_PROGRAMS_COMPLETED,
    ATTACH_PROGRAMS_FAILED,
    DETACH_PROGRAM,
    DETACH_PROGRAM_COMPLETED,
    DETACH_PROGRAM_FAILED,
    DETACH_ALL_PROGRAMS,
    DETACH_ALL_PROGRAMS_COMPLETED,
    DETACH_ALL_PROGRAMS_FAILED,
    GET_ATTACHED_PROGRAMS,
    GET_ATTACHED_PROGRAMS_COMPLETED,
    GET_ATTACHED_PROGRAMS_FAILED,
    GET_UNATTACHED_PROGRAMS,
    GET_UNATTACHED_PROGRAMS_COMPLETED,
    GET_UNATTACHED_PROGRAMS_FAILED,
} from './actionTypes';

export const clearErrorMessage = createAction(CLEAR_ERROR_MESSAGE);

export const programPriorityChange = createAction(PROGRAM_PRIORITY_CHANGE);
export const programPriorityChangeCompleted = createAction(PROGRAM_PRIORITY_CHANGE_COMPLETED);
export const programPriorityChangeFailed = createAction(PROGRAM_PRIORITY_CHANGE_FAILED);

export const detachProgram = createAction(DETACH_PROGRAM);
export const detachProgramCompleted = createAction(DETACH_PROGRAM_COMPLETED);
export const detachProgramFailed = createAction(DETACH_PROGRAM_FAILED);

export const detachAllPrograms = createAction(DETACH_ALL_PROGRAMS);
export const detachAllProgramsCompleted = createAction(DETACH_ALL_PROGRAMS_COMPLETED);
export const detachAllProgramsFailed = createAction(DETACH_ALL_PROGRAMS_FAILED);

export const attachPrograms = createAction(ATTACH_PROGRAMS);
export const attachProgramsCompleted = createAction(ATTACH_PROGRAMS_COMPLETED);
export const attachProgramsFailed = createAction(ATTACH_PROGRAMS_FAILED);

export const getAttachedPrograms = createAction(GET_ATTACHED_PROGRAMS);
export const getAttachedProgramsCompleted = createAction(GET_ATTACHED_PROGRAMS_COMPLETED);
export const getAttachedProgramsFailed = createAction(GET_ATTACHED_PROGRAMS_FAILED);

export const getUnattachedPrograms = createAction(GET_UNATTACHED_PROGRAMS);
export const getUnattachedProgramsCompleted = createAction(GET_UNATTACHED_PROGRAMS_COMPLETED);
export const getUnattachedProgramsFailed = createAction(GET_UNATTACHED_PROGRAMS_FAILED);
