import React from 'react';
import PropTypes from 'prop-types';
import EmptyState from '../../../components/QuestionEmptyState';
import localization from '../../../localization';
import links from '../../../links';
import './style.scss';

const {
    thread: {
        noQuestions: {
            noQuestionsMessage,
            noQuestionsMessageOther,
            noQuestionsMessageAdmin,
            viewOtherQuestions,
        },
    },
} = localization;

const NoQuestionFeed = ({ hasManagementPermission, isMyFeed }) => {
    let text;
    if (hasManagementPermission) {
        text = noQuestionsMessageAdmin;
    } else if (isMyFeed) {
        text = noQuestionsMessage;
    } else {
        text = noQuestionsMessageOther;
    }
    const link = hasManagementPermission ? links.moderator.questions.index : links.topics.otherFeed;

    return (
        <EmptyState
            content={text}
            link={isMyFeed ? link : ''}
            buttonContent={viewOtherQuestions}
        />
    );
};

NoQuestionFeed.propTypes = {
    hasManagementPermission: PropTypes.bool.isRequired,
    isMyFeed: PropTypes.bool,
};

NoQuestionFeed.defaultProps = {
    isMyFeed: false,
};

export default NoQuestionFeed;
