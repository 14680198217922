import { call, put, all, takeEvery } from 'redux-saga/effects';
import { LOAD_ROLES } from './actionTypes';
import {
    loadRolesCompleted,
    loadRolesFailed,
} from './actions';
import { loadRoles } from './api';

function* loadRolesSaga() {
    try {
        const response = yield call(loadRoles);
        yield put(loadRolesCompleted(response));
    } catch (error) {
        yield put(loadRolesFailed({ error }));
    }
}

function* specialtiesSaga() {
    yield all([
        takeEvery(LOAD_ROLES, loadRolesSaga),
    ]);
}

export default specialtiesSaga;
