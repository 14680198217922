import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import links from '../../links';
import './style.scss';

const {
    infoPages: {
        about: aboutUrl,
        disclaimer: disclaimerUrl,
        support: supportUrl,
        faq: faqUrl,
    },
} = links;


const FooterNavigation = () => (
    <div className="footer">
        <div className="content">
            <div className="links">
                <Link href={aboutUrl} to={aboutUrl}>About</Link>
                <Link href={disclaimerUrl} to={disclaimerUrl}>Disclaimer</Link>
                <a href={supportUrl} target="_blank" rel="noopener noreferrer">Contact Us</a>
                <Link href={faqUrl} to={faqUrl}>FAQ</Link>
            </div>
            <div className="contact-info">
                <div>Submit Inquiry to <a href={supportUrl} target="_blank" rel="noopener noreferrer">Member Services</a></div>
                <div>2875 South Ocean Blvd</div>
                <div>Suite 200-08</div>
                <div>Palm Beach, FL 33480</div>
                <div className="company-name">© {new Date().getFullYear()} Healthcasts, LLC. All rights reserved.</div>
            </div>
            <div className="logo-area">
                <Logo />
            </div>
        </div>
    </div>
);

export default FooterNavigation;
