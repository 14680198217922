import React from 'react';
import PropTypes from 'prop-types';
import useAdButler from '../../hooks/useAdButler';

const Ad = ({ page, placement, resourceId }) => {
    const ad = useAdButler(page, placement, resourceId);
    return (
        <div className="flex justify-center">{ad}</div>
    );
};

Ad.propTypes = {
    page: PropTypes.string.isRequired,
    placement: PropTypes.string.isRequired,
    resourceId: PropTypes.string,
};

Ad.defaultProps = {
    resourceId: null,
};

export default Ad;
