import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { push, replace } from '../../../../services/navigation';
import {
    UPDATE_USER_COMPLETED,
    UPDATE_USER_FAILED,
    CREATE_USER_COMPLETED,
    CREATE_USER_FAILED,
    RESTORE_USER_COMPLETED,
    RESTORE_USER_FAILED,
} from '../../../../data/users/actionTypes';
import links from '../../../../links';
import { formName } from './constants';
import { restoreUser } from '../../../../data/users/actions';
import localization from '../../../../localization';
import errorCodes from '../../../../api/errorCodes';
import { RESTORE_USER } from './actionTypes';
import { showToast, showErrorToast, PersonIcon } from '../../../Toast';

const {
    admin: {
        users: {
            form: {
                emailUniqueError,
                npiUniqueError,
            },
        },
    },
} = localization;

const showSuccessToast = ({ header, body }) => showToast({
    header, body, icon: PersonIcon, isPersistent: false,
});

const showCreatePasswordChangeErrorToast = () => showErrorToast({
    header: 'The account update Email failed to send.',
    body: 'Please directly reach out to the new user and instruct them use the "Forgot Password" link on the login page to create a new password.',
});

const showAccountUpdateEmailErrorToast = setPasswordURL => showErrorToast({
    header: 'The account update Email failed to send.',
    body: 'Please send the user this link to create their password.',
    link: {
        href: `javascript:navigator.clipboard.writeText('${setPasswordURL}');`,
        text: 'Copy link to clipboard',
    },
});

function* showToastSaga({ type, payload }) {
    if (type === CREATE_USER_COMPLETED || type === UPDATE_USER_COMPLETED) {
        const { createdPasswordChangeTicket, setPasswordURL, sentAccountUpdateEmail } = payload;
        if (!createdPasswordChangeTicket) {
            yield put(showCreatePasswordChangeErrorToast());
        } else if (!sentAccountUpdateEmail) {
            yield put(showAccountUpdateEmailErrorToast(setPasswordURL));
        } else if (type === UPDATE_USER_COMPLETED) {
            yield put(showSuccessToast({ header: 'User was successfully updated.' }));
        } else {
            yield put(showSuccessToast({ header: 'New user was successfully created.' }));
        }
    } else if (type === RESTORE_USER_COMPLETED) {
        yield put(showSuccessToast({ header: 'User was successfully restored.' }));
    } else if (type === RESTORE_USER_FAILED) {
        yield put(showSuccessToast({ header: 'There was an issue restoring this user.' }));
    }
}

function* navigateToUsers() {
    yield put(push({
        pathname: links.moderator.users.index,
        state: {},
    }));
}
function* handleValidationErrors({ type, payload: { errorCode, ...others } }) {
    if (errorCode === errorCodes.userEmailNotUnique) {
        yield put(stopSubmit(formName, { email: emailUniqueError }));
    } else if (errorCode === errorCodes.existsUserWithEmail) {
        const {
            error: {
                description: {
                    data,
                },
            },
        } = others;
        if (data && data.id) {
            yield put(replace({
                state: {
                    restoreUserAvailable: true,
                    userId: data.id,
                },
            }));
        }
    } else if (errorCode === errorCodes.userNpiNotUnique) {
        yield put(stopSubmit(formName, { npi: npiUniqueError, internalNpi: npiUniqueError }));
        yield put(replace({
            state: {
                npiUniqueError: true,
            },
        }));
    } else if (errorCode === errorCodes.userCreateFailed || type === CREATE_USER_FAILED) {
        yield put(showErrorToast({ header: 'There was an issue creating this user.' }));
    } else if (type === UPDATE_USER_FAILED) {
        yield put(showErrorToast({ header: 'There was an issue updating this user.' }));
    }
}

function* restoreUserSaga({ payload: { id } }) {
    yield put(restoreUser({
        id,
    }));
}

function* setNavigationSaga() {
    yield all([
        takeEvery([
            UPDATE_USER_COMPLETED,
            CREATE_USER_COMPLETED,
            RESTORE_USER_COMPLETED,
        ], navigateToUsers),
        takeEvery([
            UPDATE_USER_COMPLETED,
            CREATE_USER_COMPLETED,
            RESTORE_USER_COMPLETED,
            RESTORE_USER_FAILED,
        ], showToastSaga),
        takeEvery([CREATE_USER_FAILED, UPDATE_USER_FAILED], handleValidationErrors),
        takeLatest(RESTORE_USER, restoreUserSaga),
    ]);
}

export default setNavigationSaga;
