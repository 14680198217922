import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Cross2Icon } from '@radix-ui/react-icons';
import './style.scss';
import { dismissToast, removeToast } from './redux';

export { PersonIcon } from '@radix-ui/react-icons';
export { dismissToast, reducer, removeToast, showErrorToast, showGlobalErrorToast, showToast } from './redux';

// Automatically dismiss toasts after 6 seconds (unless their `isPersistent` flag is `true`)
const AUTO_DISMISS_TIMEOUT = 6000;
// Toasts are shown, then dismissed (they transition out of frame), and then they're removed
// from the list of toasts. This timing value should roughly match the `transition: transform`
// declaration in the `style.scss file`. Once this amount of time has elapsed, we know the
// transition animation has finished, and we can remove the toast.
const DISMISS_TRANSITION_TIMING = 300;
// Interval to check if any toasts should be auto-dismissed
const AUTO_DISMISS_INTERVAL = 40;

/* eslint-disable no-shadow */
const Toast = ({
    header,
    body,
    icon: Icon, // need to upper-case name so it can be used as a component
    link,
    id,
    dismissToast,
    dismissedAt,
    iconColor,
    isPersistent,
    zIndex,
}) => {
    /* eslint-enable no-shadow */
    const handleDismiss = useCallback(() => dismissToast(id), [id, dismissToast]);
    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleDismiss();
        }
    }, [handleDismiss]);
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setShow(true), 40);
        return () => clearTimeout(timer);
    }, []);
    let animationState = '';
    if (dismissedAt) {
        animationState = 'dismiss';
    } else if (show) {
        animationState = 'show';
    }
    return (
        <div className={['toast', animationState].filter(c => !!c).join(' ')} style={{ zIndex }}>
            <Icon aria-hidden={true} alt="" width="20" height="20" style={{ color: iconColor }} />
            <div className="content">
                <p className="headline">{header}</p>
                {body ? <p className="body">{body}</p> : null}
                {link ? <div className="links"><a href={link.href}>{link.text}</a></div> : null}
            </div>
            {isPersistent ? (
                <Cross2Icon
                    className="close"
                    role="button"
                    tabIndex="0"
                    onClick={handleDismiss}
                    onKeyPress={handleKeyPress}
                    alt="Close"
                    width="20"
                    height="20"
                />
            ) : null}
        </div>
    );
};

Toast.propTypes = {
    header: PropTypes.string.isRequired,
    body: PropTypes.string,
    icon: PropTypes.string.isRequired,
    link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    dismissToast: PropTypes.func.isRequired,
    dismissedAt: PropTypes.number.isRequired,
    iconColor: PropTypes.string,
    isPersistent: PropTypes.bool.isRequired,
    zIndex: PropTypes.number.isRequired,
};

Toast.defaultProps = {
    body: undefined,
    link: undefined,
    iconColor: 'inherit',
};

// eslint-disable-next-line no-shadow
const Toaster = ({ toast, dismissToast, removeToast }) => {
    const { toasts } = toast;
    // We have an interval that needs to continue running even with re-renders, but
    // it also needs to be cleared when the component unmounts. Using a ref allows us to do that.
    const timeoutRef = useRef(null);
    const toastsRef = useRef(toasts);
    const handleDismiss = useCallback(id => dismissToast(id), [dismissToast]);
    const handleRemove = useCallback(id => removeToast(id), [removeToast]);

    useEffect(() => { toastsRef.current = toasts; }, [toasts]);

    useEffect(() => {
        const checkToAutoDismiss = () => {
            (toastsRef.current || []).forEach((t) => {
                // if the toast is dismissing, check to see if the transition has ended, and then remove it
                if (t.dismissedAt) {
                    if (Date.now() - t.dismissedAt >= DISMISS_TRANSITION_TIMING) {
                        handleRemove(t.id);
                    }
                    return;
                }
                // otherwise, don't dismiss it automatically unless `isPeristent` is false
                if (t.isPersistent) {
                    return;
                }
                if (Date.now() - t.shownAt >= AUTO_DISMISS_TIMEOUT) {
                    handleDismiss(t.id);
                }
            });
        };
        timeoutRef.current = setInterval(checkToAutoDismiss, AUTO_DISMISS_INTERVAL);
        return () => {
            clearInterval(timeoutRef.current);
        };
    }, [handleDismiss]);

    return (
        <div className="toast-container">
            {
                toasts.map((t, i) => <Toast {...t} dismissToast={dismissToast} key={`toast_${t.id}`} zIndex={toasts.length - i} />)
            }
        </div>);
};

Toaster.propTypes = {
    toast: PropTypes.shape({
        toasts: PropTypes.array.isRequired,
    }).isRequired,
    dismissToast: PropTypes.func.isRequired,
    removeToast: PropTypes.func.isRequired,
};

export default connect(({ toast }) => ({ toast }), { dismissToast, removeToast })(Toaster);
