import PropTypes from 'prop-types';

export const quotePropType = PropTypes.shape({
    Quote: PropTypes.string,
    Author: PropTypes.string,
    Specialty: PropTypes.string,
});

export const caseShape = PropTypes.shape({
    id: PropTypes.number,
    publishDate: PropTypes.string,
    totalResponseCount: PropTypes.number,
    description: PropTypes.string,
});

export const consensusShape = PropTypes.shape({
    ConsensusTitle: PropTypes.string,
    ConsensusOpinionStatement: PropTypes.string,
    ClinicalAnalysis: PropTypes.shape({
        AnalysisText: PropTypes.string,
        Quotes: PropTypes.arrayOf(quotePropType),
    }),
    Summary: PropTypes.shape({
        SummaryText: PropTypes.string,
        Quotes: PropTypes.arrayOf(quotePropType),
    }),
    PatientCases: PropTypes.arrayOf(PropTypes.shape({
        CaseNumber: PropTypes.number,
        CaseText: PropTypes.string,
        View: PropTypes.string,
    })),
    Sections: PropTypes.arrayOf(PropTypes.shape({
        Title: PropTypes.string,
        Text: PropTypes.string,
        Quotes: PropTypes.arrayOf(quotePropType),
    })),
    Definitions: PropTypes.arrayOf(PropTypes.shape({
        Term: PropTypes.string,
        Definition: PropTypes.string,
    })),
    References: PropTypes.arrayOf(PropTypes.shape({
        Text: PropTypes.string,
        Url: PropTypes.string,
    })),
});

export default {
    quotePropType,
    consensusShape,
    caseShape,
};
