import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Dropdown, Button, Popup } from 'semantic-ui-react';
import StatusLabel from '../StatusLabel';
import localization from '../../../../../../localization';

const {
    admin: {
        globalHcmpList: {
            disableProgram,
            enableProgram,
        },
    },
} = localization;

class AssignedProgramRow extends Component {
    static propTypes = {
        hcmpPrgId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        priorityOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
        globalPriority: PropTypes.number,
        changeGlobalHcmpStatus: PropTypes.func.isRequired,
        changeGlobalHcmpPriority: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
    }

    static defaultProps = {
        globalPriority: null,
    }

    state = {
        selectedPriority: this.props.globalPriority,
    }

    onChangePriorityHandler = (event, { value }) => {
        const {
            hcmpPrgId,
            changeGlobalHcmpPriority,
        } = this.props;
        if (value !== this.props.globalPriority) {
            changeGlobalHcmpPriority({
                hcmpId: hcmpPrgId,
                priority: value,
            });
        }
    }

    render() {
        const {
            hcmpPrgId,
            name,
            priorityOptions,
            changeGlobalHcmpStatus,
            globalPriority,
            loading,
        } = this.props;
        const isEnabled = Boolean(globalPriority);
        let nameTableCell = (
            <Table.Cell className="program-name">
                {name}
            </Table.Cell>
        );

        if (name && name.length > 78) {
            nameTableCell = (
                <Popup
                    wide="very"
                    content={name}
                    hoverable
                    trigger={
                        <Table.Cell className="program-name">
                            {name}
                        </Table.Cell>
                    }
                />
            );
        }
        return (
            <Table.Row>
                <Table.Cell>{hcmpPrgId}</Table.Cell>
                { nameTableCell }
                <Table.Cell textAlign="center">
                    <StatusLabel enabled={isEnabled} />
                </Table.Cell>
                <Table.Cell textAlign="center">
                    {isEnabled && <Dropdown
                        button
                        scrolling
                        value={this.state.selectedPriority}
                        options={priorityOptions}
                        onChange={this.onChangePriorityHandler}
                        disabled={loading}
                        className="programs-dropdown"
                    />}
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <Dropdown
                        direction="left"
                        disabled={loading}
                        icon={<Button
                            icon="ellipsis horizontal"
                            className="action-button"
                        />}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                content={isEnabled ? disableProgram : enableProgram}
                                className={isEnabled ? 'disabled-menu-item' : ''}
                                onClick={() => changeGlobalHcmpStatus({ hcmpId: hcmpPrgId })}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default AssignedProgramRow;
