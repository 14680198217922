import React from 'react';
import PropTypes from 'prop-types';
import { Header, Button, Message } from 'semantic-ui-react';
import RotatingLoadingIndicator from '../../../../../components/InfoBox';
import localization from '../../../../../localization';
import './style.scss';

const {
    admin: {
        questions: {
            details: {
                userEngagementTitle,
                answers,
                totalResponses,
                uniqueViews,
                targetList,
                eligibleList,
                email,
                sumRewards,
                sumPeriodRewards,
                answerReport,
                downloadReportError,
            },
        },
    },
} = localization;

const UserEngagementComponent = ({
    // data
    id,
    totalResponseCount,
    answerCount,
    eligibleCount,
    uniqueViewsCount,
    targetSize,
    emailsCount,
    loading,
    totalRewardsSum,
    totalRewardsPeriodSum,
    // functions
    downloadAnswerReport,
    clearAnswerReportError,
    // meta
    downloadingAnswerReport,
    answerReportStatus,
}) => {
    const answerReportButton = (<Button
        loading={downloadingAnswerReport}
        content={answerReport}
        onClick={() => downloadAnswerReport({ topicId: id })}
        primary
    />);

    return (
        <div className="user-engagement">
            <Message
                hidden={answerReportStatus !== false}
                content={downloadReportError}
                onDismiss={() => clearAnswerReportError()}
                negative
            />
            <div className="top-half">
                <Header>{userEngagementTitle}</Header>
                {answerReportButton}
            </div>
            <div className="bottom-half">
                <RotatingLoadingIndicator
                    loading={loading}
                    totalCount={totalResponseCount}
                    totalText={totalResponses}
                    detailedCount={answerCount}
                    detailedText={answers}
                />
                <RotatingLoadingIndicator
                    loading={loading}
                    totalCount={uniqueViewsCount}
                    totalText={uniqueViews}
                />
                <RotatingLoadingIndicator
                    loading={loading || targetSize === null}
                    totalCount={eligibleCount}
                    totalText={eligibleList}
                    detailedCount={targetSize}
                    detailedText={targetList}
                />
                <RotatingLoadingIndicator
                    loading={loading}
                    totalCount={emailsCount}
                    totalText={email}
                />
                <RotatingLoadingIndicator
                    loading={loading}
                    totalCount={totalRewardsSum}
                    totalText={sumRewards}
                    totalCountPrefix="$"
                />
                <RotatingLoadingIndicator
                    loading={loading}
                    totalCount={totalRewardsPeriodSum}
                    totalText={sumPeriodRewards}
                    totalCountPrefix="$"
                />
            </div>
        </div>);
};

UserEngagementComponent.propTypes = {
    emailsCount: PropTypes.number.isRequired,
    downloadAnswerReport: PropTypes.func.isRequired,
    downloadingAnswerReport: PropTypes.bool.isRequired,
    clearAnswerReportError: PropTypes.func.isRequired,
    targetSize: PropTypes.number,
    totalResponseCount: PropTypes.number,
    totalRewardsSum: PropTypes.number,
    totalRewardsPeriodSum: PropTypes.number,
    uniqueViewsCount: PropTypes.number,
    id: PropTypes.number.isRequired,
    answerReportStatus: PropTypes.bool,
    answerCount: PropTypes.number,
    eligibleCount: PropTypes.number,
    loading: PropTypes.bool.isRequired,
};

UserEngagementComponent.defaultProps = {
    answerReportStatus: null,
    totalResponseCount: 0,
    totalRewardsSum: 0,
    totalRewardsPeriodSum: 0,
    uniqueViewsCount: 0,
    targetSize: null,
    answerCount: 0,
    eligibleCount: 0,
};

export default UserEngagementComponent;
