import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import {
    LOAD_TOOLTIP,
    LOAD_TOOLTIP_COMPLETED,
    LOAD_TOOLTIP_FAILED,
    MARK_TOOLTIP,
    MARK_TOOLTIP_COMPLETED,
    MARK_TOOLTIP_FAILED,
} from './actionTypes';

const initialState = Immutable.from({});

const reducer = handleActions({
    [LOAD_TOOLTIP]: (state, { payload: { name } }) => state.merge({
        [name]: {
            loading: true,
        },
    }),
    [LOAD_TOOLTIP_COMPLETED]: (state, { payload: { name, ...data } }) => state.merge({
        [name]: {
            loading: false,
            ...data,
        },
    }),
    [LOAD_TOOLTIP_FAILED]: (state, { payload: { name, ...error } }) => state.merge({
        [name]: {
            loading: false,
            loadingFailed: true,
            loadingError: error,
        },
    }),
    [MARK_TOOLTIP]: (state, { payload: { name } }) => state.merge({
        [name]: {
            marking: true,
        },
    }),
    [MARK_TOOLTIP_COMPLETED]: (state, { payload: { name, ...data } }) => state.merge({
        [name]: {
            marking: false,
            alreadyViewed: true,
            ...data,
        },
    }),
    [MARK_TOOLTIP_FAILED]: (state, { payload: { name, ...error } }) => state.merge({
        [name]: {
            marking: false,
            alreadyViewed: false,
            markingError: error,
        },
    }),
}, initialState);

export default reducer;
