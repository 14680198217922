import { createAction } from 'redux-actions';
import {
    SET_ANSWER_REPORT_FAILED,
    CLEAR_ANSWER_REPORT_FAILED,
    OPEN_NEW_EMAIL_MODAL,
    CLOSE_NEW_EMAIL_MODAL,
    CLOSE_EMAIL_FAILED,
    OPEN_ATTACH_MODAL,
    CLOSE_ATTACH_MODAL,
    OPEN_DETACH_MODAL,
    CLOSE_DETACH_MODAL,
} from './actionTypes';

export const setAnswerReportError = createAction(SET_ANSWER_REPORT_FAILED);
export const clearAnswerReportError = createAction(CLEAR_ANSWER_REPORT_FAILED);
export const openNewEmailModal = createAction(OPEN_NEW_EMAIL_MODAL);
export const closeNewEmailModal = createAction(CLOSE_NEW_EMAIL_MODAL);
export const closeEmailError = createAction(CLOSE_EMAIL_FAILED);
export const openAttachModal = createAction(OPEN_ATTACH_MODAL);
export const closeAttachModal = createAction(CLOSE_ATTACH_MODAL);
export const openDetachModal = createAction(OPEN_DETACH_MODAL);
export const closeDetachModal = createAction(CLOSE_DETACH_MODAL);
