import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reduxForm, getFormError, isValid, getFormValues } from 'redux-form';
import moment from 'moment';
import { ID as DataID, SpecialtyID, QuestionsID } from '../../../../data';
import Component from './component';
import {
    syncValidations,
    syncWarnings,
} from './validations';
import { updateQuestion } from '../../../../data/questions/actions';
import { questionSelector, resultsSelector } from './selectors';
import { searchAuthor } from './actions';
import { push, goBack, selectors } from '../../../../services/navigation';
import links from '../../../../links';

const formName = 'questionModeration';

const initialValues = {
    publishDate: moment(),
    expirationDate: moment().add(7, 'd'),
    useNpiMap: false,
};

const errorSelector = getFormError(formName);

const mapStateToProps = (state, props) => {
    const {
        [DataID]: {
            [SpecialtyID]: {
                specialties,
                subSpecialties,
                loadingSpecialties,
                loadingSubSpecialties,
            },
            [QuestionsID]: {
                saving,
            },
        },
    } = state;
    const {
        match: {
            params: {
                id: questionId,
            },
        },
    } = props;
    const hasBack = !!selectors.previousPathnameSelector(state);
    const formError = errorSelector(state);
    return {
        specialties: specialties.map(({
            id,
            name,
        }) => ({
            key: id,
            value: id,
            text: name,
        })),
        subSpecialties: subSpecialties.map(({
            id,
            name,
        }) => ({
            key: id,
            value: id,
            text: name,
        })),
        isFormValid: isValid(formName)(state),
        id: questionId,
        loading: loadingSpecialties || loadingSubSpecialties || saving,
        initialValues: questionId ? questionSelector(state, questionId) : initialValues,
        isUpdate: !!(questionId),
        error: formError,
        hasBack,
        formValues: getFormValues(formName)(state),
        ...resultsSelector(state),
    };
};

const mapDispatchToProps = {
    goBack,
    push,
    searchAuthor,
};

const mergeProps = (
    {
        hasBack,
        isUpdate,
        id,
        ...stateProps
    },
    { goBack: back, push: navigate, ...actions },
) => {
    const returnToDetails = () => (isUpdate ?
        navigate(links.moderator.questions.details.replace(':id', id)) : navigate(links.moderator.questions.index));
    return ({
        id,
        ...stateProps,
        ...actions,
        isUpdate,
        backAction: (hasBack && isUpdate) ? back : returnToDetails,
        handleCancel: returnToDetails,
    });
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: formName,
        validate: syncValidations,
        warn: syncWarnings,
        enableReinitialize: true,
        onSubmit: ({
            author: { id },
            expirationDate,
            publishDate,
            ...rest
        }, dispatch) => {
            const values = {
                expirationDate: expirationDate.toISOString(),
                publishDate: publishDate.toISOString(),
                authorUserId: id,
                ...rest,
            };
            dispatch(updateQuestion(values));
        },
    }),
)(Component);
