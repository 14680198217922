import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form, Modal, Button } from 'semantic-ui-react';
import { TextInput, RadioGroup } from '../../../../../components';
import { targetAudience as targetAudienceEnum } from '../../../../../data/enums';
import './style.scss';
import localization from '../../../../../localization';

const {
    common: {
        cancel,
    },
    admin: {
        globalHcmpList: {
            programs: {
                createEmails: {
                    newSpecialtyCampaignForm: {
                        title,
                        specialtyAll,
                        specialtyEligible,
                        emailName,
                        selectTemplatePlaceholder,
                        createEmail,
                        targetAudience,
                    },
                },
            },
        },
    },
} = localization;

const targetAudienceOptions = [
    {
        value: targetAudienceEnum.specialtyAll,
        label: specialtyAll,
    },
    {
        value: targetAudienceEnum.specialtyEligible,
        label: specialtyEligible,
    },
];

const NewEmailComponent = ({
    open,
    handleSubmit,
    creatingCampaign,
    closeNewEmailModal,
}) => (
    <Modal open={open} size="tiny" className="new-email">
        <Modal.Content>
            <div className="title">{title}</div>
            <Form
                loading={creatingCampaign}
            >
                <div className="form-row align">
                    <div className="field-label">
                        {emailName}
                    </div>
                    <Field
                        component={Form.Field}
                        control={TextInput}
                        name="name"
                        placeholder={selectTemplatePlaceholder}
                        width={8}
                    />
                </div>
                <div className="form-row">
                    <div className="field-label">
                        {targetAudience}
                    </div>
                    <Field
                        component={Form.Field}
                        control={RadioGroup}
                        name="targetAudience"
                        options={targetAudienceOptions}
                    />
                </div>
            </Form>
        </Modal.Content>
        <Modal.Actions className="actions">
            <Button
                content={cancel}
                type="button"
                onClick={() => closeNewEmailModal()}
            />
            <Button
                content={createEmail}
                disabled={creatingCampaign}
                positive
                onClick={() => handleSubmit()}
            />
        </Modal.Actions>
    </Modal>
);

NewEmailComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    closeNewEmailModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    creatingCampaign: PropTypes.bool.isRequired,
};

export default NewEmailComponent;
