import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { ID as DataID, QuestionsID } from '../../../../data';
import { ID as ModeratorID } from '../../actionTypes';
import { ID } from './actionTypes';

/* eslint-disable import/prefer-default-export */

const questionsStateSelector = ({
    [DataID]: {
        [QuestionsID]: {
            topics: questions,
        },
    },
}) => questions;

export const questionsSelector = createSelector(
    questionsStateSelector,
    (questions) => {
        const allQuestions = questions
            .map(({
                createdAt,
                publishDate,
                expirationDate,
                totalResponsesCount,
                ...question
            }) => ({
                createdAt: new Date(createdAt),
                publishDate: new Date(publishDate),
                expirationDate: new Date(expirationDate),
                totalResponsesCount: parseInt(totalResponsesCount, 10),
                ...question,
            }));
        return orderBy(allQuestions, ['status', 'expirationDate', 'id'], ['asc', 'desc', 'asc'])
            .map(({
                createdAt,
                publishDate,
                expirationDate,
                ...rest
            }) => ({
                createdAt: moment(createdAt).format('L'),
                publishDate: moment(publishDate).format('L'),
                expirationDate: moment(expirationDate).format('L'),
                ...rest,
            }));
    },
);

export const ownStateSelector = ({
    [ModeratorID]: {
        [ID]: ownState,
    },
}) => ownState;
