import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CardStackPlusIcon } from '@radix-ui/react-icons';
import { Input, TextArea, Form } from 'semantic-ui-react';
import { FormikProvider, FieldArray, useFormik } from 'formik';
import moment from 'moment';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './consensusSubmissionForm.scss';
import client from '../../api/apiSaga';
import { globalHcmp, users } from '../../api/endpoints';
import ConsensusSummary from '../../models/consensusSummary';
import Button from '../Button';
import Chip from '../Chip';
import QuoteSelect from '../QuoteSelect';
import { caseShape } from './shared/types';
import { analytics } from '../../utils/analytics';

const fetchProgramName = async (programId) => {
    try {
        const data = await client.get(globalHcmp.singleProgram(programId));
        return { success: true, name: data.name };
    } catch (e) {
        return { success: false, error: e };
    }
};

const fetchAuthorInfo = async (npi) => {
    try {
        const { data } = await client.get(users.index, {
            params: {
                count: 1,
                term: npi,
            },
        });
        const author = data[0];
        if (author) {
            const name = `${author.firstName} ${author.lastName}`;
            const specialty = author.userProfile?.specialty?.name;
            return { success: true, name, specialty };
        }
        return { success: false };
    } catch (e) {
        return { success: false, error: e };
    }
};

const ConsensusSubmissionForm = ({
    caseToSummarize,
    onSubmit,
    onCaseEdit,
    onChangeForm,
    quotes,
}) => {
    const tempConsensusSummary = new ConsensusSummary();

    // TODO: remove empty placeholders, replace with add new buttons
    tempConsensusSummary.addSection('', '');
    tempConsensusSummary.addClinicalAnalysisQuote('', '');
    tempConsensusSummary.addConclusionQuote('', '');
    tempConsensusSummary.addDefinition('', '');
    tempConsensusSummary.addReference('', '');
    tempConsensusSummary.setPatientCases([caseToSummarize]);

    const stringToEditorState = (markdownString) => {
        const rawData = markdownToDraft(markdownString);
        const contentState = convertFromRaw(rawData);
        return EditorState.createWithContent(contentState);
    };

    const [editorState, setEditorState] = useState({
        clinicalAnalysisText: stringToEditorState(tempConsensusSummary.data.ClinicalAnalysis.AnalysisText),
        conclusionText: stringToEditorState(tempConsensusSummary.data.Conclusion.ConclusionText),
        sectionText: [stringToEditorState(tempConsensusSummary.data.Sections[0].Text)],
    });
    const formik = useFormik({
        initialValues: tempConsensusSummary,
        onSubmit: async (values) => {
            onSubmit(values);
        },
    });

    const casePublishDate = moment(caseToSummarize.publishDate).format('MMMM YYYY');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sectionTop = useRef(null);

    const formikUpdateData = (manipulateConsensusSummary) => {
        const tmp = new ConsensusSummary();
        tmp.data = formik.values.data;
        manipulateConsensusSummary(tmp);
        formik.values = tmp;
        onChangeForm(formik.values);
    };

    const addSection = () => {
        analytics.trackEvent('custom_add_section_click');
        formikUpdateData((consensusSummary) => {
            consensusSummary.addSection('', '');
            const newState = { ...editorState };
            newState.sectionText.push(stringToEditorState(''));
            setEditorState(newState);
        });
    };

    const scrollToTopOfSection = () => {
        const headerElement = document.querySelector('.navbar.mobile');
        const navElement = document.querySelector('.navbar.desktop');
        const headerHeight = headerElement.offsetHeight + navElement.offsetHeight;
        const sectionRect = sectionTop.current.getBoundingClientRect();
        const topOffset = (window.pageYOffset + sectionRect.top) - headerHeight;

        window.scrollTo({
            top: topOffset,
            behavior: 'smooth',
        });
    };

    const removeSection = (index) => {
        analytics.trackEvent('custom_remove_section_click');
        // TODO: replace with style consistant modal box
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to remove this section? You will lose all data in the corresponding section fields.')) {
            formikUpdateData((consensusSummary) => {
                consensusSummary.removeSection(index);
                const newState = { ...editorState };
                newState.sectionText.splice(index, 1);
                setEditorState(newState);
            });
            scrollToTopOfSection();
        }
    };

    const addClinicalAnalysisQuote = () => {
        analytics.trackEvent('custom_add_consult_click');
        formikUpdateData((consensusSummary) => {
            consensusSummary.addClinicalAnalysisQuote('', '');
        });
    };

    const removeClinicalAnalysisQuote = index => (event) => {
        event.preventDefault();
        formikUpdateData((consensusSummary) => {
            consensusSummary.removeClinicalAnalysisQuote(index);
        });
    };

    const addConclusionQuote = () => {
        analytics.trackEvent('custom_add_consult_click');
        formikUpdateData((consensusSummary) => {
            consensusSummary.addConclusionQuote('', '');
        });
    };

    const removeConclusionQuote = index => (event) => {
        event.preventDefault();
        formikUpdateData((consensusSummary) => {
            consensusSummary.removeConclusionQuote(index);
        });
    };

    const addConsultQuote = (index) => {
        analytics.trackEvent('custom_add_consult_click');
        formikUpdateData((consensusSummary) => {
            consensusSummary.addConsultQuote(index, '', '');
        });
    };

    const removeSectionQuote = (sectionIndex, quoteIndex) => (event) => {
        event.preventDefault();
        formikUpdateData((consensusSummary) => {
            consensusSummary.removeSectionQuote(sectionIndex, quoteIndex);
        });
    };

    const addDefinition = () => {
        analytics.trackEvent('custom_add_definition_click');
        formikUpdateData((consensusSummary) => {
            consensusSummary.addDefinition('', '');
        });
    };

    const removeDefinition = index => (event) => {
        event.preventDefault();
        formikUpdateData((consensusSummary) => {
            consensusSummary.removeDefinition(index);
        });
    };

    const addReference = () => {
        analytics.trackEvent('custom_add_reference_click');
        formikUpdateData((consensusSummary) => {
            consensusSummary.addReference('', '');
        });
    };

    const removeReference = index => (event) => {
        event.preventDefault();
        formikUpdateData((consensusSummary) => {
            consensusSummary.removeReference(index);
        });
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        onChangeForm(formik.values);
    };

    const [programNameError, setProgramNameError] = useState('');
    const [authorInfoError, setAuthorInfoError] = useState('');

    const updateProgramName = async (programId) => {
        if (!programId) {
            setProgramNameError('');
            return;
        }
        const { success, name, error } = await fetchProgramName(programId);
        const errorStatus = error?.status;
        formik.setFieldValue('data.Program.Name', success ? name : '');
        let errorMessage = '';
        if (errorStatus) {
            errorMessage = errorStatus === 404 ? 'Error: not found' : 'Error: refresh the page and try again';
        }
        setProgramNameError(errorMessage);
    };

    const updateAuthorInfo = async (npi) => {
        if (!npi) {
            setAuthorInfoError('');
            formik.setFieldValue('data.Author.Name', '');
            formik.setFieldValue('data.Author.Specialty', '');
            return;
        }

        const { success, name, specialty } = await fetchAuthorInfo(npi);
        if (success) {
            setAuthorInfoError('');
            formik.setFieldValue('data.Author.Name', name);
            formik.setFieldValue('data.Author.Specialty', specialty);
        } else {
            formik.setFieldValue('data.Author.Name', '');
            formik.setFieldValue('data.Author.Specialty', '');
            setAuthorInfoError('No author found');
        }
    };

    // Formik's values prop won't reflect the current state after `formik.handleState` is
    // called cause `formik.handleState` is executed async. Therefore, to get the current
    // value of the ProgramId field, we need to check the event object directly.
    const handleProgramIdChange = (e) => {
        handleChange(e);
        updateProgramName(e.target.value);
    };

    const handleAuthorNpiChange = (e) => {
        handleChange(e);
        updateAuthorInfo(e.target.value);
    };

    const handleCaseEdit = (e) => {
        e.preventDefault();
        onCaseEdit();
    };

    const toolbarOptions = {
        options: ['inline', 'list', 'link'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic'],
        },
        list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered', 'indent', 'outdent'],
        },
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link', 'unlink'],
            linkCallback: undefined,
        },
    };

    const quoteOptions = quotes.map(({ label }) => ({ value: label, label }));

    return (
        <div className="consensus-submission-form">
            <h1>Submit your summary</h1>
            <p className="description">
                Share your summary of HCP consults provided on this patient case. Once submitted,
                your patient case summary will be reviewed and published within the Healthcasts network.
            </p>
            <p className="instruction">Required field</p>
            <FormikProvider value={formik} >
                <Form onSubmit={formik.handleSubmit}>
                    <label className="required" htmlFor="consensusTitle">Summary title</label>
                    <p htmlFor="consensusTitle">
                        Create an interesting title, it does not necessarily have
                        to match what was presented in the patient case. (up to 40 words)
                    </p>
                    <Input
                        id="data.ConsensusTitle"
                        name="data.ConsensusTitle"
                        placeholder="Title Text"
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={formik.values.data.ConsensusTitle}
                    />
                    <label htmlFor="data.Program.ProgramId">Program</label>
                    <p htmlFor="data.Program.ProgramId">
                        Add the id of the program associated with this summary.
                    </p>
                    <div className="program-container">
                        <Input
                            id="data.Program.ProgramId"
                            name="data.Program.ProgramId"
                            placeholder="1234"
                            onChange={handleProgramIdChange}
                            onBlur={handleChange}
                            value={formik.values.data.Program.ProgramId}
                        />
                        <p className={programNameError && 'error'} >{formik.values.data.Program.Name || programNameError}</p>
                    </div>
                    <label htmlFor="data.Author.Npi">Author</label>
                    <p htmlFor="data.Author.Npi">
                        Search for an author using their NPI
                    </p>
                    <div className="author-container">
                        <Input
                            id="data.Author.Npi"
                            name="data.Author.Npi"
                            placeholder="8181818181"
                            onChange={handleAuthorNpiChange}
                            onBlur={handleChange}
                            value={formik.values.data.Author?.Npi}
                        />
                        {authorInfoError && (
                            <p className={authorInfoError && 'error'} >{authorInfoError}</p>
                        )}
                        {formik.values.data.Author?.Name && (
                            <p>Name: {formik.values.data.Author.Name}</p>
                        )}
                        {formik.values.data.Author?.Specialty && (
                            <p>Specialty: {formik.values.data.Author.Specialty}</p>
                        )}
                    </div>
                    <a className="anchor" id="patient">patient</a>
                    <div className="case-container">
                        <div className="case-header">
                            <div className="case-number">
                                <Chip variant="purple">Case Number #{caseToSummarize.id}</Chip>
                                <button type="button" onClick={handleCaseEdit}>Edit</button>
                            </div>
                            <div className="case-data">{casePublishDate} - {caseToSummarize.totalResponseCount} Respondents</div>
                        </div>
                        <div className="case-body">{caseToSummarize.description}</div>
                    </div>
                    <a className="anchor" id="community">community</a>
                    <h2>Community Consensus</h2>
                    <label className="required" htmlFor="consensusOpinionStatement">High level Summary statement</label>
                    <p htmlFor="consensusOpinionStatement">
                        This is your overall summary of the responses to this case. It answers the question(s)
                        above with the majority opinion. It may also mention a strong minority opinion. You may
                        also include the number of consults that support your claim(s). (up to 40 words)
                    </p>
                    <Input
                        id="data.ConsensusOpinionStatement"
                        name="data.ConsensusOpinionStatement"
                        placeholder="Consensus opinion statement"
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={formik.values.data.ConsensusOpinionStatement}
                    />
                    <label className="required" htmlFor="ClinicalAnalysis">Clinical analysis of HCP consults</label>
                    <p htmlFor="data.ClinicalAnalysis.AnalysisText">
                        Provide more details in support of your summary statement, including the number of
                        consults. Avoid using percentages as many responses include more than one opinion,
                        or conditional recommendations. (up to 150 words)
                    </p>
                    <Editor
                        toolbar={toolbarOptions}
                        editorState={editorState.clinicalAnalysisText}
                        toolbarClassName="wysiwygToolbar"
                        wrapperClassName="wysiwygWrapper"
                        editorClassName="wysiwygEditor"
                        onEditorStateChange={(state) => {
                            const newState = { ...editorState };
                            newState.clinicalAnalysisText = state;
                            setEditorState(newState);
                            const text = draftToMarkdown(convertToRaw(state.getCurrentContent()));
                            formik.values.data.ClinicalAnalysis.AnalysisText = text;
                            onChangeForm(formik.values);
                        }}
                    />
                    <FieldArray
                        name="ClinicalAnalysis.Quotes"
                        render={() => (
                            <div className="quote-container">
                                {formik.values.data.ClinicalAnalysis.Quotes.map((_, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="quote" key={`opinionQuote${index}`}>
                                        <label>Add HCP Consult</label>
                                        <QuoteSelect
                                            onChange={(e) => {
                                                const quote = quotes.find(q => q.label === e.value);
                                                formik.setFieldValue(`data.ClinicalAnalysis.Quotes[${index}].Quote`, quote.text);
                                                formik.setFieldValue(`data.ClinicalAnalysis.Quotes[${index}].Author`, quote.user);
                                                formik.setFieldValue(`data.ClinicalAnalysis.Quotes[${index}].Specialty`, quote.specialty);
                                            }}
                                            options={quoteOptions}
                                        />
                                        <TextArea
                                            id={`data.ClinicalAnalysis.Quotes[${index}].Quote`}
                                            name={`data.ClinicalAnalysis.Quotes[${index}].Quote`}
                                            placeholder="Quote Text"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.ClinicalAnalysis.Quotes[index].Quote}
                                        />
                                        <Input
                                            id={`data.ClinicalAnalysis.Quotes[${index}].Author`}
                                            name={`data.ClinicalAnalysis.Quotes[${index}].Author`}
                                            placeholder="Contributor's Full Name"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.ClinicalAnalysis.Quotes[index].Author}
                                        />
                                        <Input
                                            id={`data.ClinicalAnalysis.Quotes[${index}].Specialty`}
                                            name={`data.ClinicalAnalysis.Quotes[${index}].Specialty`}
                                            placeholder="Contributor's Specialty"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.ClinicalAnalysis.Quotes[index].Specialty}
                                        />
                                        {
                                            formik.values.data.ClinicalAnalysis.Quotes.length > 1 && (
                                                <Button variant="text" className="remove-quote" onClick={removeClinicalAnalysisQuote(index)}>Remove This Quote</Button>
                                            )
                                        }
                                    </div>
                                ))}
                                <Button variant="secondary" type="button" onClick={addClinicalAnalysisQuote}>+ Add Consult</Button>
                            </div>
                        )}
                    />
                    <FieldArray
                        name="sections"
                        render={() => (
                            <div className="additional-sections" ref={sectionTop}>
                                {formik.values.data.Sections.map((_, sectionIndex) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="section" key={`section${sectionIndex}`}>
                                        <hr />
                                        <label htmlFor={`Sections[${sectionIndex}].Title`}>Differing clinical consult title</label>
                                        <p htmlFor={`Sections[${sectionIndex}].Title`}>
                                            Use this optional section to elaborate on other aspects of the patient case,
                                            including a strong minority opinion or varying treatment approach, if you feel
                                            it&apos;s interesting and clinically relevant. (up to 40 words)
                                        </p>
                                        <Input
                                            id={`data.Sections[${sectionIndex}].Title`}
                                            name={`data.Sections[${sectionIndex}].Title`}
                                            placeholder="Title text"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.Sections[sectionIndex].Title}
                                        />
                                        <label htmlFor={`Sections[${sectionIndex}].Text`}>Clinical analysis of differing consult</label>
                                        <p htmlFor={`Sections[${sectionIndex}].Text`}>
                                            Use this optional section to elaborate on other aspects of the patient case,
                                            including a strong minority opinion or varying treatment approach, if you feel
                                            it&apos;s interesting and clinically relevant. (up to 150 words)
                                        </p>
                                        <Editor
                                            toolbar={toolbarOptions}
                                            editorState={editorState.sectionText[sectionIndex]}
                                            toolbarClassName="wysiwygToolbar"
                                            wrapperClassName="wysiwygWrapper"
                                            editorClassName="wysiwygEditor"
                                            onEditorStateChange={(state) => {
                                                const newState = { ...editorState };
                                                newState.sectionText[sectionIndex] = state;
                                                setEditorState(newState);
                                                const text = draftToMarkdown(convertToRaw(state.getCurrentContent()));
                                                formik.values.data.Sections[sectionIndex].Text = text;
                                                onChangeForm(formik.values);
                                            }}
                                        />
                                        <FieldArray
                                            name="Conclusion.Quotes"
                                            render={() => (
                                                <div className="quote-container">
                                                    {formik.values.data.Sections[sectionIndex].Quotes.map((__, quoteIndex) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <div className="quote" key={`sectionQuotes${quoteIndex}`}>
                                                            <label>Add HCP Consult</label>
                                                            <QuoteSelect
                                                                onChange={(e) => {
                                                                    const quote = quotes.find(q => q.label === e.value);
                                                                    formik.setFieldValue(`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Quote`, quote.text);
                                                                    formik.setFieldValue(`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Author`, quote.user);
                                                                    formik.setFieldValue(`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Specialty`, quote.specialty);
                                                                }}
                                                                options={quoteOptions}
                                                            />
                                                            <TextArea
                                                                id={`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Quote`}
                                                                name={`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Quote`}
                                                                placeholder="Quote Text"
                                                                onChange={handleChange}
                                                                onBlur={handleChange}
                                                                value={formik.values.data.Sections[sectionIndex].Quotes[quoteIndex].Quote}
                                                            />
                                                            <Input
                                                                id={`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Author`}
                                                                name={`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Author`}
                                                                placeholder="Contributor's Full Name"
                                                                onChange={handleChange}
                                                                onBlur={handleChange}
                                                                value={formik.values.data.Sections[sectionIndex].Quotes[quoteIndex].Author}
                                                            />
                                                            <Input
                                                                id={`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Specialty`}
                                                                name={`data.Sections[${sectionIndex}].Quotes[${quoteIndex}].Specialty`}
                                                                placeholder="Contributor's Specialty"
                                                                onChange={handleChange}
                                                                onBlur={handleChange}
                                                                value={formik.values.data.Sections[sectionIndex].Quotes[quoteIndex].Specialty}
                                                            />
                                                            {
                                                                formik.values.data.Sections[sectionIndex].Quotes.length > 1 && (
                                                                    <Button variant="text" className="remove-quote" onClick={removeSectionQuote(sectionIndex, quoteIndex)}>Remove This Quote</Button>
                                                                )
                                                            }
                                                        </div>
                                                    ))}
                                                    <Button variant="secondary" type="button" onClick={() => addConsultQuote(sectionIndex)}>+ Add Consult</Button>
                                                </div>
                                            )}
                                        />
                                        {
                                            formik.values.data.Sections.length > 1 && (
                                                <Button variant="secondary" type="button" className="remove-section" onClick={() => removeSection(sectionIndex)}>Remove Section</Button>
                                            )
                                        }
                                    </div>
                                ))}
                            </div>
                        )}
                    />
                    <hr />
                    <Button variant="secondary" type="button" onClick={addSection}>
                        <CardStackPlusIcon />
                        Add Section
                    </Button>
                    <br />
                    <a className="anchor" id="conclusion">conclusion</a>
                    <h2>Conclusion</h2>
                    <label htmlFor="conclusionTitle">Conclusion Title</label>
                    <p htmlFor="conclusionTitle">
                        To end the piece, you may include a short conclusion that nicely sums up the consensus. (up to 40 words)
                    </p>
                    <Input
                        id="data.Conclusion.ConclusionTitle"
                        name="data.Conclusion.ConclusionTitle"
                        placeholder="Title Text"
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={formik.values.data.Conclusion.ConclusionTitle}
                    />
                    <label htmlFor="Conclusion">Conclusion Paragraph</label>
                    <p htmlFor="Conclusion">
                        This is a space for an HCP to include your own final conclusion or clinical opinion/pearl,
                        and include your own full name and specialty. It&apos;s also valuable to readers to finish with
                        a full (usually lengthier) quote from the response report, positioned as a &quot;Final Consult&quot;
                        from a colleague. (up to 150 words)
                    </p>
                    <Editor
                        toolbar={toolbarOptions}
                        editorState={editorState.conclusionText}
                        toolbarClassName="wysiwygToolbar"
                        wrapperClassName="wysiwygWrapper"
                        editorClassName="wysiwygEditor"
                        onEditorStateChange={(state) => {
                            const newState = { ...editorState };
                            newState.conclusionText = state;
                            setEditorState(newState);
                            const text = draftToMarkdown(convertToRaw(state.getCurrentContent()));
                            formik.values.data.Conclusion.ConclusionText = text;
                            onChangeForm(formik.values);
                        }}
                    />
                    <FieldArray
                        name="Conclusion.Quotes"
                        render={() => (
                            <div className="quote-container conclusion-quotes">
                                {formik.values.data.Conclusion.Quotes.map((_, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="quote" key={`Summary${index}`}>
                                        <label>Add HCP Consult</label>
                                        <QuoteSelect
                                            onChange={(e) => {
                                                const quote = quotes.find(q => q.label === e.value);
                                                formik.setFieldValue(`data.Conclusion.Quotes[${index}].Quote`, quote.text);
                                                formik.setFieldValue(`data.Conclusion.Quotes[${index}].Author`, quote.user);
                                                formik.setFieldValue(`data.Conclusion.Quotes[${index}].Specialty`, quote.specialty);
                                            }}
                                            options={quoteOptions}
                                        />
                                        <TextArea
                                            id={`data.Conclusion.Quotes[${index}].Quote`}
                                            name={`data.Conclusion.Quotes[${index}].Quote`}
                                            placeholder="Quote Text"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.Conclusion.Quotes[index].Quote}
                                        />
                                        <Input
                                            id={`data.Conclusion.Quotes[${index}].Author`}
                                            name={`data.Conclusion.Quotes[${index}].Author`}
                                            placeholder="Contributor's Full Name"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.Conclusion.Quotes[index].Author}
                                        />
                                        <Input
                                            id={`data.Conclusion.Quotes[${index}].Specialty`}
                                            name={`data.Conclusion.Quotes[${index}].Specialty`}
                                            placeholder="Contributor's Specialty"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.Conclusion.Quotes[index].Specialty}
                                        />
                                        {
                                            formik.values.data.Conclusion.Quotes.length > 1 && (
                                                <Button variant="text" className="remove-quote" onClick={removeConclusionQuote(index)}>Remove This Quote</Button>
                                            )
                                        }
                                    </div>
                                ))}
                                <Button variant="secondary" type="button" onClick={addConclusionQuote}>+ Add Consult</Button>
                            </div>
                        )}
                    />
                    <a className="anchor" id="definitions">definitions</a>
                    <h2>Definitions</h2>
                    <FieldArray
                        name="definitions"
                        render={() => (
                            <div className="definitions-container">
                                {formik.values.data.Definitions.map((_, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="definition" key={`Definitions${index}`}>
                                        <label htmlFor={`Definitions[${index}].Term`}>Definition</label>
                                        <p>Define each acronym or term you included in your text, if applicable.</p>
                                        <Input
                                            id={`data.Definitions[${index}].Term`}
                                            name={`data.Definitions[${index}].Term`}
                                            placeholder="Acronym or Term"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.Definitions[index].Term}
                                        />
                                        <Input
                                            id={`data.Definitions[${index}].Definition`}
                                            name={`data.Definitions[${index}].Definition`}
                                            placeholder="Definition"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.Definitions[index].Definition}
                                        />
                                        {
                                            formik.values.data.Definitions.length > 1 && (
                                                <Button variant="text" className="remove-definition" onClick={removeDefinition(index)}>Remove This Definition</Button>
                                            )
                                        }
                                    </div>
                                ))}
                                <Button variant="secondary" type="button" onClick={addDefinition}>+ Add Definition</Button>
                            </div>
                        )}
                    />
                    <a className="anchor" id="references">references</a>
                    <h2>List of references</h2>
                    <FieldArray
                        name="references"
                        render={() => (
                            <div className="references-container">
                                <label>Reference Links</label>
                                <p>Include APA-style citations and references if applicable.</p>
                                {formik.values.data.References.map((_, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="reference" key={`References${index}`}>
                                        <Input
                                            id={`data.References[${index}].Text`}
                                            name={`data.References[${index}].Text`}
                                            placeholder="Link text"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.References[index].Text}
                                        />
                                        <Input
                                            id={`data.References[${index}].Url`}
                                            name={`data.References[${index}].Url`}
                                            placeholder="URL"
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            value={formik.values.data.References[index].Url}
                                        />
                                        {
                                            formik.values.data.References.length > 1 && (
                                                <Button variant="text" className="remove-reference" onClick={removeReference(index)}>Remove This Reference</Button>
                                            )
                                        }
                                    </div>
                                ))}
                                <Button variant="secondary" type="button" onClick={addReference}>+ Add Reference</Button>
                            </div>
                        )}
                    />
                </Form>
            </FormikProvider>
        </div>
    );
};

ConsensusSubmissionForm.propTypes = {
    caseToSummarize: caseShape.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCaseEdit: PropTypes.func.isRequired,
    onChangeForm: PropTypes.func.isRequired,
    quotes: PropTypes.arrayOf(PropTypes.shape({
        user: PropTypes.string,
        text: PropTypes.string,
        label: PropTypes.string,
        specialty: PropTypes.string,
    })),
};

ConsensusSubmissionForm.defaultProps = {
    quotes: [],
};

export default ConsensusSubmissionForm;
