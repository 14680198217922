export const targetAudienceShort = {
    all: 'all',
    viewed: 'view',
    notViewed: 'no-view',
    responded: 'respond',
    notResponded: 'no-respond',
    eligible: 'eligible',
    specialtyAll: 'all',
    specialtyEligible: 'eligible',
    npi: 'npi',
};

export const targetAudience = {
    all: 'all',
    viewed: 'viewed',
    notViewed: 'notViewed',
    responded: 'responded',
    notResponded: 'notResponded',
    eligible: 'eligible',
    specialtyAll: 'specialtyAll',
    specialtyEligible: 'specialtyEligible',
    npi: 'npi',
};

export const questionStatus = {
    scheduled: 0,
    active: 1,
    expired: 2,
};

export const priorityWay = {
    up: 'up',
    down: 'down',
};

export const tooltipNames = {
    questionsAvailableRewardsIcon: 'questions-available-rewards-icon',
    questionsGiftCardIcon: 'questions-gift-card-icon',
    questionGiftCardIcon: 'question-gift-card-icon',
    questionAfterUserEarned: 'question-after-user-earned',
    pointToMyRewards: 'point-to-my-rewards',
    rewardsHistory: 'rewards-history',
    finishRewards: 'finish-rewards',
};

export const namesOfTooltips = {
    'questions-available-rewards-icon': 'questionsAvailableRewardsIcon',
    'questions-gift-card-icon': 'questionsGiftCardIcon',
    'question-gift-card-icon': 'questionGiftCardIcon',
    'question-after-user-earned': 'questionAfterUserEarned',
    'point-to-my-rewards': 'pointToMyRewards',
    'rewards-history': 'rewardsHistory',
    'finish-rewards': 'finishRewards',
};
