import React from 'react';
import PropTypes from 'prop-types';
import './actionLinkTiles.scss';

const ActionLinkTiles = ({ children }) => (
    <div className="action-link-tiles">
        {children}
    </div>
);

ActionLinkTiles.propTypes = {
    children: PropTypes.node.isRequired,
};
export default ActionLinkTiles;
