import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SpecialtyTag, Loading } from '../../../../../components';
import { QuestionProps, requiredWhenLoaded, wrapPropTypes } from '../../../../../utils/propTypes';
import { calculateQuestionStatus } from '../../../../../utils/dates';
import { questionStatus } from '../../../../../data/enums';
import localization from '../../../../../localization';
import links from '../../../../../links';

const {
    admin: {
        questions: {
            list: {
                authorPrefix,
            },
            details: {
                deleteQuestionModal,
            },
        },
    },
} = localization;

const QuestionCardContent = ({
    title,
    extended,
    description,
    specialties,
    subspecialties,
    status,
    author: {
        firstName,
        lastName,
        specialty,
    } = {},
    createdAt,
    publishDate,
    expirationDate,
    id,
    // meta
    loading,
    // extended
    editLink,
    publicLink,
    showModal,
    deleteQuestion,
}) => {
    const timedStatus = calculateQuestionStatus(status, createdAt, publishDate, expirationDate);
    const actionButtons = extended ?
        (
            <div>
                <Button
                    icon="edit"
                    size="large"
                    as={Link}
                    className="action-button"
                    to={editLink}
                    href={editLink}
                />
                <Button
                    disabled={status === null || status === questionStatus.scheduled}
                    size="large"
                    icon="comment"
                    className="action-button"
                    as={Link}
                    to={publicLink}
                    href={publicLink}
                />
                <Button
                    icon="trash"
                    size="large"
                    className="action-button negative-hover"
                    onClick={() => showModal({
                        content: null,
                        header: deleteQuestionModal,
                        positiveResponseAction: () => {
                            deleteQuestion({ id });
                        },
                    })}
                />
            </div>
        ) : null;
    return (
        <div className={extended ? 'right-column extended' : 'right-column'}>
            <div>
                <Loading in={!loading} size="large" >
                    <div className="question-header">
                        <div className="top-metadata">
                            <div className="created-at">
                                <div>{` • ${createdAt}`}</div>
                            </div>
                            <div className="duration">
                                <Icon name="calendar" />
                                <div>{publishDate}</div>
                                {' - '}
                                <div>{expirationDate}</div>
                            </div>
                            <div className="status">
                                {` / ${timedStatus}`}
                            </div>
                        </div>
                        {actionButtons}
                    </div>
                </Loading>
                <Loading in={!loading} size="large">
                    <Link
                        className={!extended ? 'text full' : 'text'}
                        href
                        to={`${links.moderator.questions.details.replace(':id', id)}`}
                    >
                        <div className="title">{title}</div>
                        <div className="description">{description}</div>
                    </Link>
                </Loading>
                <Loading in={!loading} size="medium">
                    <div className="tags">
                        {specialties.map(({ name, id: specId }) =>
                            <SpecialtyTag key={specId} name={name} type="specialty" id={id} />)}
                        {subspecialties.map(({ name, id: specId }) =>
                            <SpecialtyTag key={specId} name={name} type="subspecialty" id={id} />)}
                    </div>
                    <div className="author">{`${authorPrefix} ${firstName} ${lastName} ${specialty ? '•' : ''} ${specialty || ''}`}</div>
                </Loading>
            </div>
        </div>);
};

QuestionCardContent.propTypes = {
    ...wrapPropTypes(QuestionProps, requiredWhenLoaded),
    specialties: QuestionProps.specialties, // eslint-disable-line react/no-typos
    subspecialties: QuestionProps.subspecialties, // eslint-disable-line react/no-typos
    status: requiredWhenLoaded(PropTypes.number.isRequired),
    loading: PropTypes.bool,
    extended: PropTypes.bool,
    editLink: PropTypes.string,
    publicLink: PropTypes.string,
    deleteQuestion: PropTypes.func,
    showModal: PropTypes.func,
};

QuestionCardContent.defaultProps = {
    specialties: [],
    subspecialties: [],
    loading: false,
    status: null,
    extended: false,
    editLink: '',
    publicLink: '',
    deleteQuestion: null,
    showModal: null,
};

export default QuestionCardContent;
