import * as Raven from 'raven-js';

/**
 * setup sentry logging
 * @param {bool} isEnabled is it enabled
 */
export const configureSentry = (isEnabled) => {
    Raven.config(isEnabled && process.env.REACT_APP_SENTRY_DSN, {
        logger: 'react',
        release: process.env.npm_package_version,
        environment: process.env.CIRCLE_BRANCH,
        autoBreadcrumbs: true,
        ignoreErrors: [
            // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
            '/^Object Not Found Matching Id/',
        ],
    }).install();

    window.onunhandledrejection = (event) => {
        Raven.captureMessage('Unhandled Rejection', {
            extra: {
                event: event.reason || event,
            },
        });
    };
};

// TODO: Change user type from 'any' to proper type when this is defined later in development
export const setUser = (user) => {
    Raven.setUserContext(user);
};
