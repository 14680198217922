/* eslint-disable import/prefer-default-export */

const handleCtrlEnter = callback => (event) => {
    if (event.ctrlKey && event.key === 'Enter') {
        callback(event);
    }
};

const handleLinkClick = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export {
    handleCtrlEnter,
    handleLinkClick,
};
