import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { logoutUser } from '../../services/session';
import Button from '../Button';
import './style.scss';

const NoPermissions = ({
    dispatchLogout,
}) => {
    const { isAuthenticated, logout } = useAuth0();
    const handleLogout = useCallback(() => {
        dispatchLogout();
        logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
    }, [logout, dispatchLogout]);

    return (
        <div className="no-permissions-center">
            <div className="no-permissions-content">
                You do not have appropriate permissions for this application.
            </div>
            {isAuthenticated && (
                <>
                    <Button onClick={handleLogout}>
                        Logout
                    </Button>
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    dispatchLogout: () => dispatch(logoutUser()),
});

NoPermissions.propTypes = {
    dispatchLogout: PropTypes.func.isRequired,
};

export default compose(connect(null, mapDispatchToProps))(NoPermissions);
