import React from 'react';
import { Message } from 'semantic-ui-react';

const FieldError = ({
    error,
    touched,
}) => {
    const hasError = touched && error && error.length > 0;
    return (hasError && (
        <Message
            visible
            error={hasError}
            content={error}
        />)) || null;
};

export default FieldError;
