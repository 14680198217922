import React from 'react';
import propTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Form } from 'semantic-ui-react';
import FieldError from '../FieldError';

const TextInput = ({
    label,
    meta: {
        error,
        touched,
        warning,
        pristine,
    },
    input,
    required,
    disabled,
    warnOnBlur,
    ...props
}) => {
    const hasError = touched && error && error.length > 0;
    let infoArea = hasError && <FieldError error={error} touched={touched} />;
    const shouldShowWarning = ((warnOnBlur && touched) || (!warnOnBlur && !pristine));
    if (!infoArea && warning && shouldShowWarning) {
        infoArea = (<div className="form-warning" >{warning}</div>);
    }
    return (
        <div>
            <Form.Input
                label={label}
                {...input}
                required={required}
                disabled={disabled}
                {...props}
            />
            {infoArea}
        </div>
    );
};

TextInput.propTypes = {
    ...fieldPropTypes,
    required: propTypes.bool,
    disabled: propTypes.bool,
    meta: propTypes.shape({
        active: propTypes.bool.isRequired,
        pristine: propTypes.bool.isRequired,
    }).isRequired,
};

TextInput.defaultProps = {
    required: false,
    disabled: false,
};

export default TextInput;
