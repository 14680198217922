import React from 'react';
import { Icon, Grid, Popup } from 'semantic-ui-react';

const NoRewardsSection = () => (
    <div className="feed-rewards-section">
        <Grid>
            <Grid.Row only="computer" className="reward-row">
                <Grid.Column computer={2} className="vertical-middle-content align-right">
                    <Icon circular name="gift" size="large" className="grey-icon-color-inverted" />
                </Grid.Column >
                <Grid.Column computer={13} className="vertical-middle-content">
                    <h6 className="gift-card-header">
                        <strong>
                            No rewards currently available
                        </strong>
                    </h6>
                    <p className="gift-card-content">Rewards are added weekly, check back soon.</p>
                </Grid.Column>
                <Grid.Column computer={1} className="vertical-middle-content">
                    <div className="horizontal-right-content">
                        <div className="horizontal-right-wrapper">
                            <Popup
                                trigger={<Icon circular name="info" className="info-icon grey-icon-color" />}
                                size="tiny"
                                position="bottom center"
                                inverted
                                style={{ opacity: '#442A5C', padding: '0.5rem' }}
                                hoverable
                                wide
                            >
                                <Popup.Content>
                                    Earn rewards for answering questions.
                                </Popup.Content>
                            </Popup>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row >

            <Grid.Row only="mobile tablet" className="reward-row">
                <Grid.Column width="2" className="vertical-middle-content align-right">
                    <Icon circular name="gift" className="grey-icon-color-inverted" />
                </Grid.Column>
                <Grid.Column width="14" className="vertical-middle-content">
                    <h6 className="gift-card-header">
                        <strong>
                            No rewards currently available
                        </strong>
                    </h6>
                    <p className="gift-card-content">Rewards are added weekly, check back soon.</p>
                </Grid.Column>
            </Grid.Row>
        </Grid >
    </div >
);

export default NoRewardsSection;
