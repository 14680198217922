import { connect } from 'react-redux';
import AssignedProgramTable from './container';
import { assignedHcmpSelector } from '../selectors';
import { changeHcmpGlobalStatus, changeHcmpGlobalPriority, loadingSelector } from '../../../../../data/globalHcmp';

const mapStateToProps = state => ({
    assignedPrograms: assignedHcmpSelector(state),
    loading: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
    changeGlobalHcmpStatus: hcmpId => dispatch(changeHcmpGlobalStatus(hcmpId)),
    changeGlobalHcmpPriority: (hcmpId, priority) =>
        dispatch(changeHcmpGlobalPriority(hcmpId, priority)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedProgramTable);
