import React from 'react';
import './SubmissionComplete.scss';
import JoinAConversation from '../JoinAConversation/joinAConversation.svg';

const SubmissionComplete = () => (
    <div className="submission-complete-container">
        <h1>Thank you for submitting your summary!</h1>
        <p>Our team will review your submission and reach out if there are any questions. Once reviewed, we will let you know when it&apos;s been shared in the clinical queue.</p>
        <div className="cta">
            <div className="cta-image">
                <JoinAConversation />
            </div>
            <div className="cta-text">
                <h2>Join a Conversation</h2>
                <p>Review patient cases in your and other specialties. Add your opinions and review your colleagues&apos; verbatim consults.</p>
                <a href="/questions">View patient cases</a>
            </div>
        </div>
    </div>
);

export default SubmissionComplete;
