import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Button } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import { fieldPropTypes } from 'redux-form';
import localization from '../../localization';
import FieldError from '../FieldError';

/* eslint-disable react/no-multi-comp */

/* eslint-disable react/prefer-stateless-function */
// DatePicker adds a ref to this component so it must be a class component
class ReadOnlyInput extends Component {
    render() {
        const {
            onClick,
            value,
        } = this.props;
        const buttonLabel = value || localization.common.datePickerButton;
        return (
            <Button
                onClick={onClick}
                content={buttonLabel}
            />
        );
    }
}
/* eslint-enable react/prefer-stateless-function */


ReadOnlyInput.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

ReadOnlyInput.defaultProps = {
    value: null,
};


class CustomDatePicker extends Component {
    static propTypes = {
        ...fieldPropTypes,
    }

    state = {
        open: false,
    }

    toggleOpen() {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        const {
            input: {
                onChange,
                value,
                onBlur,
            },
            meta: {
                error,
                touched,
                warning,
                pristine,
            },
            ...props
        } = this.props;
        const selected = value || null;
        const hasError = touched && error && error.length > 0;
        let infoArea = hasError && <FieldError error={error} touched={touched} />;
        if (!infoArea && warning && !pristine) {
            infoArea = (<div className="form-warning" >{warning}</div>);
        }
        return (
            <div>
                <DatePicker
                    customInput={<ReadOnlyInput value={value} onClick={this.toggleOpen} />}
                    selected={selected}
                    onChange={onChange}
                    onClickOutside={() => onBlur(value)}
                    {...props}
                />
                {infoArea}
            </div>);
    }
}

const DatePickerField = ({
    required,
    label,
    ...props
}) => {
    const className = `${required ? 'required ' : ''} field`;
    /* eslint-disable jsx-a11y/label-has-for */
    return (
        <div className={className}>
            <label htmlFor={props.input.name} >{label}</label>
            <CustomDatePicker {...props} />
        </div>);
};

DatePickerField.propTypes = {
    required: PropTypes.bool,
    label: PropTypes.string,
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

DatePickerField.defaultProps = {
    required: false,
    label: null,
};

export default DatePickerField;
