import PropTypes from 'prop-types';

export default ({
    max: PropTypes.number,
    earned: PropTypes.number,
    value: PropTypes.string,
    npiUnRewardedTopics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
    })),
    npiRewardedTopics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
    })),
    rewards: PropTypes.arrayOf(PropTypes.string),
});
