import { connect } from 'react-redux';
import { loadingSelector, errorSelector, errorMessageSelector } from '../../../../data/globalHcmp';
import GlobalList from './container';

const mapStateToProps = state => ({
    loading: loadingSelector(state),
    error: errorSelector(state),
    errorMessage: errorMessageSelector(state),
});

export default connect(mapStateToProps)(GlobalList);
