import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
    CREATE_USER,
    LOAD_USERS,
    LOAD_USER,
    UPDATE_USER,
    DELETE_USER,
    RESTORE_USER,
} from './actionTypes';
import {
    createUserCompleted,
    createUserFailed,
    loadUsersCompleted,
    loadUsersFailed,
    loadUserCompleted,
    loadUserFailed,
    updateUserCompleted,
    updateUserFailed,
    deleteUserCompleted,
    deleteUserFailed,
    restoreUserCompleted,
    restoreUserFailed,
} from './actions';
import {
    createUser,
    loadUsers,
    loadUser,
    updateUser,
    deleteUser,
    restoreUser,
} from './api';
import errorCodes from '../../api/errorCodes';
import { handle404 } from '../../services/navigation';
import { lookupAppHcUser } from '../appHcUsers/slice';

function* createUserSaga({ payload: user }) {
    try {
        const response = yield call(createUser, user);
        const { createdPasswordChangeTicket, setPasswordURL, sentAccountUpdateEmail } = response;
        yield put(createUserCompleted({ createdPasswordChangeTicket, setPasswordURL, sentAccountUpdateEmail }));
    } catch (e) {
        const {
            data: {
                error,
                errorCode,
            } = { errorCode: null },
        } = e;
        yield put(createUserFailed({ errorCode, error }));
    }
}

function* loadUsersSaga({ payload: { count, pageNumber, searchValue: term } }) {
    try {
        const response = yield call(loadUsers, {
            count,
            pageNumber,
            term,
        });
        yield put(loadUsersCompleted(response));
    } catch (error) {
        yield put(loadUsersFailed({ error }));
    }
}

function* loadUserSaga({ payload: id }) {
    try {
        const { data: user } = yield call(loadUser, id);
        yield put(loadUserCompleted(user));
        yield put(lookupAppHcUser({ email: user.email, npi: user.npi }));
    } catch (error) {
        if (error.status === 404 ||
            (error.data && error.data.errorCode === errorCodes.wrongArguments)) {
            yield put(handle404());
        }
        yield put(loadUserFailed({ error }));
    }
}

function* updateUserSaga({ payload }) {
    try {
        const response = yield call(updateUser, payload);
        const { createdPasswordChangeTicket, setPasswordURL, sentAccountUpdateEmail } = response;
        yield put(updateUserCompleted({ createdPasswordChangeTicket, setPasswordURL, sentAccountUpdateEmail }));
    } catch (error) {
        const {
            data: {
                errorCode,
            } = { errorCode: null },
        } = error;
        yield put(updateUserFailed({ errorCode }));
    }
}

function* deleteUserSaga({ payload: { id } }) {
    try {
        yield call(deleteUser, id);
        yield put(deleteUserCompleted({ id }));
    } catch (error) {
        yield put(deleteUserFailed({ error }));
    }
}

function* restoreUserSaga({ payload: { id } }) {
    try {
        yield call(restoreUser, { id });
        yield put(restoreUserCompleted());
    } catch (error) {
        yield put(restoreUserFailed({ error }));
    }
}

function* usersSaga() {
    yield all([
        takeLatest(CREATE_USER, createUserSaga),
        takeLatest(LOAD_USERS, loadUsersSaga),
        takeLatest(LOAD_USER, loadUserSaga),
        takeLatest(UPDATE_USER, updateUserSaga),
        takeLatest(DELETE_USER, deleteUserSaga),
        takeLatest(RESTORE_USER, restoreUserSaga),
    ]);
}

export default usersSaga;
