import { connect } from 'react-redux';
import NavigationItem from './container';
import { ID as SessionID } from '../../../services/session';

const userHasUnseenQuestionsSelector = ({
    [SessionID]: {
        userHasUnseenTopics,
    },
}) => userHasUnseenTopics;

const showCtaPopupSelector = ({
    [SessionID]: {
        ctaPopupDisabled,
    },
}) => !ctaPopupDisabled;


const mapStateToProps = state => ({
    userHasUnseenTopics: userHasUnseenQuestionsSelector(state),
    showCtaPopup: showCtaPopupSelector(state),
});

export default connect(mapStateToProps)(NavigationItem);
