import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux/actions';
import {
    UPDATE_QUESTION_COMPLETED,
    CREATE_QUESTION_COMPLETED,
} from '../../data/questions/actionTypes';
import { LOCATION_CHANGE } from '../navigation';
import { redirectToUnseenQuestions } from '../session';
import links from '../../links';

// marked for refactor, move this to moderator/questions/saga
function* navigateToQuestionDetails({ type, payload }) {
    let location = {};

    const {
        question: {
            id,
        },
    } = payload;
    const pathname = links.moderator.questions.details.replace(':id', id);

    switch (type) {
        case UPDATE_QUESTION_COMPLETED:
            location = {
                state: {
                    updateSuccess: true,
                },
                pathname,
            };
            break;
        case CREATE_QUESTION_COMPLETED:
            location = {
                state: {
                    createSuccess: true,
                },
                pathname,
            };
            break;
        default:
            break;
    }
    yield put(push(location));
}


function* navigateFromQuestion({ payload: { pathname = '' } = {} }) {
    // This saga listens if user is navigating from a question to My Feed.
    // We cannot just turn off CTA notifications on LOCATION_CHANGE because
    // that action is executed every time when user opens app.
    if (pathname === '/questions') {
        yield put(redirectToUnseenQuestions());
    }
}

function* setNavigationSaga() {
    yield all([
        takeEvery([
            UPDATE_QUESTION_COMPLETED,
            CREATE_QUESTION_COMPLETED,
        ], navigateToQuestionDetails),
        takeLatest([LOCATION_CHANGE], navigateFromQuestion),
    ]);
}

export default setNavigationSaga;
