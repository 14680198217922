import React from 'react';
import PropTypes from 'prop-types';
import './chip.scss';

const Chip = ({ children, variant }) => (
    <p className={`chip ${variant}`}>{children}</p>
);

Chip.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['blue', 'purple', 'orange', 'light-blue']),
};

Chip.defaultProps = {
    variant: 'blue',
};

export default Chip;
