import PropTypes from 'prop-types';

const AuthorProps = {
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    specialty: PropTypes.oneOfType([
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        PropTypes.string.isRequired,
    ]),
};

export const SpecialtyProps = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};

const SubspecialtyProps = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};

const QuestionProps = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
    publishDate: PropTypes.string.isRequired,
    author: PropTypes.shape(AuthorProps),
    specialties: PropTypes.arrayOf(PropTypes.shape(SpecialtyProps)),
    subspecialties: PropTypes.arrayOf(PropTypes.shape(SubspecialtyProps)),
};

const SpecsProps = {
    specialties: PropTypes.arrayOf(PropTypes.shape(SpecialtyProps)),
    subspecialties: PropTypes.arrayOf(PropTypes.shape(SubspecialtyProps)),
};

/**
 * executes the propType check only if the flagProperty is false
 * @param {function} propTypeFunction normal propType check function
 * @param {string} flagProp name of the flag property
 */
const requiredWhenLoaded = (propTypeFunction, flagProp = 'loading') => (props, ...args) => {
    if (!props[flagProp]) {
        return propTypeFunction && propTypeFunction(props, ...args);
    }
    return null;
};

/**
 * wraps the propTypes with a wrapper function
 * @param {Object} propTypes
 * @param {function} wrapper
 */
const wrapPropTypes = (propTypes, wrapper) =>
    Object.entries(propTypes).reduce((total, { propName, propType }) => ({
        ...total,
        [propName]: wrapper(propType),
    }), {});

export {
    AuthorProps,
    QuestionProps,
    SpecsProps,
    requiredWhenLoaded,
    wrapPropTypes,
};
