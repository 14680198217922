import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import {
    GET_GLOBAL_HCMP_LIST,
    GET_GLOBAL_HCMP_LIST_COMPLETED,
    GET_GLOBAL_HCMP_LIST_FAILED,
    CHANGE_HCMP_GLOBAL_PRIORITY,
    CHANGE_HCMP_GLOBAL_PRIORITY_COMPLETED,
    CHANGE_HCMP_GLOBAL_PRIORITY_FAILED,
    CHANGE_HCMP_GLOBAL_STATUS,
    CHANGE_HCMP_GLOBAL_STATUS_COMPLETED,
    CHANGE_HCMP_GLOBAL_STATUS_FAILED,
    CONFIRM_HCMP_GLOBAL,
    CONFIRM_HCMP_GLOBAL_COMPLETED,
    CONFIRM_HCMP_GLOBAL_FAILED,
} from './actionTypes';

const initialState = Immutable.from({
    loading: false,
    error: false,
    errorMessage: '',
    globalPrograms: [],
});

const reducer = handleActions({
    [GET_GLOBAL_HCMP_LIST]: state => state.merge({
        loading: true,
        error: false,
        errorMessage: '',
    }),
    [GET_GLOBAL_HCMP_LIST_COMPLETED]: (state, { payload: { globalPrograms } }) =>
        state.merge({
            loading: false,
            globalPrograms,
            error: false,
            errorMessage: '',
        }),
    [GET_GLOBAL_HCMP_LIST_FAILED]: state => state.merge({
        loading: false,
        globalPrograms: null,
        errorMessage: '',
        error: true,
    }),
    [CHANGE_HCMP_GLOBAL_PRIORITY]: state => state.merge({
        loading: true,
        error: false,
        errorMessage: '',
    }),
    [CHANGE_HCMP_GLOBAL_PRIORITY_COMPLETED]: (state, {
        payload: {
            hcmpId,
            priority: newPriority,
        },
    }) => {
        const hcmpList = state.globalPrograms;
        const hcmp = hcmpList.find(x => x.hcmpPrgId === hcmpId);
        const oldPriority = hcmp.globalPriority;

        const isHcmpMovedDown = newPriority > oldPriority;
        const newList = hcmpList.map((x) => {
            const priority = x.globalPriority;

            if (x.hcmpPrgId === hcmpId) {
                return x.set('globalPriority', newPriority);
            }

            if (isHcmpMovedDown && (priority > oldPriority) && (priority <= newPriority)) {
                return x.set('globalPriority', priority - 1);
            } else if (!isHcmpMovedDown && (priority < oldPriority) && (priority >= newPriority)) {
                return x.set('globalPriority', priority + 1);
            }
            return x;
        });

        return state.merge({
            loading: false,
            error: false,
            globalPrograms: newList,
            errorMessage: '',
        });
    },
    [CHANGE_HCMP_GLOBAL_PRIORITY_FAILED]: (state, { payload: { error: { data } } }) => {
        const errorMsg = (typeof data !== 'undefined') ? data.message : '';
        return state.merge({
            loading: false,
            error: true,
            errorMessage: errorMsg,
        });
    },
    [CHANGE_HCMP_GLOBAL_STATUS]: state => state.merge({
        loading: true,
        error: false,
        errorMessage: '',
    }),
    [CHANGE_HCMP_GLOBAL_STATUS_COMPLETED]: (state, { payload: hcmpId }) => {
        const hcmpGlobalList = state.globalPrograms;
        const hcmp = hcmpGlobalList.find(x => x.hcmpPrgId === hcmpId);

        const newPriority = hcmp.globalPriority ? null :
            hcmpGlobalList.reduce((max, currentValue) =>
                (currentValue.globalPriority > max ? currentValue.globalPriority : max), 0) + 1;

        const newList = hcmpGlobalList.map((x) => {
            if (x.hcmpPrgId === hcmpId) {
                return x.set('globalPriority', newPriority);
            } else if (!newPriority && x.globalPriority > hcmp.globalPriority) {
                return x.set('globalPriority', x.globalPriority - 1);
            }
            return x;
        });

        return state.merge({
            loading: false,
            error: false,
            globalPrograms: newList,
            errorMessage: '',
        });
    },
    [CHANGE_HCMP_GLOBAL_STATUS_FAILED]: (state, { payload: { error: { data } } }) => {
        const errorMsg = (typeof data !== 'undefined') ? data.message : '';
        return state.merge({
            loading: false,
            error: true,
            errorMessage: errorMsg,
        });
    },
    [CONFIRM_HCMP_GLOBAL]: state => state.merge({
        loading: true,
        error: false,
        errorMessage: '',
    }),
    [CONFIRM_HCMP_GLOBAL_COMPLETED]: (state, { payload: { hcmpId, priority } }) => {
        const globalHcmpList = state.globalPrograms;
        const newList = globalHcmpList.map((x) => {
            if (x.hcmpPrgId === hcmpId) {
                return x.merge({
                    globalPriority: priority || null,
                    confirmedAt: new Date(),
                });
            } else if (priority && x.globalPriority >= priority) {
                return x.set('globalPriority', x.globalPriority + 1);
            }
            return x;
        });

        return state.merge({
            loading: false,
            error: false,
            globalPrograms: newList,
            errorMessage: '',
        });
    },
    [CONFIRM_HCMP_GLOBAL_FAILED]: (state, { payload: { error: { data } } }) => {
        const errorMsg = (typeof data !== 'undefined') ? data.message : '';
        return state.merge({
            loading: false,
            error: true,
            errorMessage: errorMsg,
        });
    },
}, initialState);

export default reducer;
