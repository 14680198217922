import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

import { DOWNLOAD_TARGET_LIST } from '../../../../data/campaigns/actionTypes';

const initialState = Immutable.from({
    qToDownloadTargetList: null,
});

export default handleActions({
    [DOWNLOAD_TARGET_LIST]: (state, { payload: { topicId } }) => state.set('qToDownloadTargetList', topicId),
}, initialState);
