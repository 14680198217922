import { connect } from 'react-redux';
import { compose } from 'redux';
import { WithRouterHOC } from '../../../../components/Router';
import CreateEmails from './container';
import { specialtiesSelector, loadingSpecialtiesSelector, dataStateSelector } from './selectors';
import { openNewEmailModal, openNewNPIEmailModal } from './actions';
import { showModal } from '../../../../services/sharedUIActions';
import {
    downloadTargetList,
    deleteGeneralCampaign as deleteCampaign,
    loadGeneralCampaigns,
} from '../../../../data/campaigns/actions';

import { ID as DATA_ID } from '../../../../data';
import { ID as CAMPAIGNS_ID } from '../../../../data/campaigns';
import { ID as MODERATOR_ID } from '../../actionTypes';
import { ID as PROGRAM_EMAILS_ID } from '../../GlobalPrograms/CreateEmails/actionTypes';

// used to prevent rerendering. New arrays in mapStateToProps would get new references;

const emptyArray = [];
const defaultPageSize = 10; // has to be the same as the default pagesize on backend

const mapStateToProps = (state) => {
    const {
        [MODERATOR_ID]: {
            [PROGRAM_EMAILS_ID]: {
                errorCreatingCampaign,
            },
        },
        [DATA_ID]: {
            [CAMPAIGNS_ID]: {
                campaigns: {
                    campaigns: emails = emptyArray,
                },
                downloadingTargetList,
                deletingCampaign,
                campaignIdAction,
                createCampaignErrorMessage,
                total,
                pageNumber,
            },
        },
    } = state;
    const creatingTargetList = emails.some(email => !email.targetListCreatedAt);
    return {
        specialties: specialtiesSelector(state),
        loadingSpecialties: loadingSpecialtiesSelector(state),
        emails: dataStateSelector(state).campaigns.campaigns,
        loadingCampaigns: dataStateSelector(state).loadingCampaigns,
        pageNumber,
        pageSize: defaultPageSize,
        totalEmails: total,
        downloadingTargetList,
        creatingTargetList,
        campaignIdAction,
        deletingCampaign,
        errorCreatingCampaign,
        createCampaignErrorMessage,
    };
};

const mapDispatchToProps = {
    openNewEmailModal,
    openNewNPIEmailModal,
    showModal,
    downloadTargetList,
    deleteCampaign,
    loadGeneralCampaigns,
};

export default compose(
    WithRouterHOC,
    connect(mapStateToProps, mapDispatchToProps),
)(CreateEmails);
