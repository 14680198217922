import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Comment } from 'semantic-ui-react';
import { ownAnswerWithComments } from '../../selectors';
import { AnswerCommentForm } from '../components';
import { syncValidations, warn, answerLength } from '../validations';
import { newAnswerFormName } from '../constants';
import { answerInputFocused } from '../../actions';
import Answer from '../../Answers/Detail';
import localization from '../../../../localization';

const {
    topic: {
        answerBoxLabel,
    },
} = localization;

const {
    max: maxAnswerLength,
} = answerLength;

const NewAnswerForm = reduxForm({
    form: newAnswerFormName,
    validate: syncValidations(maxAnswerLength),
    warn: warn(maxAnswerLength),
})(AnswerCommentForm);

const OwnAnswer = ({
    onSave,
    answer,
    topicExpired,
    updating,
    onFocusHandler,
}) => {
    if (answer) {
        return (
            <div className="page-container answer own-answer">
                <Comment.Group><Answer
                    topicExpired={topicExpired}
                    answer={answer}
                />
                </Comment.Group>
            </div>);
    }
    let content;
    if (answer) {
        content = (
            <Comment.Group><Answer
                topicExpired={topicExpired}
                answer={answer}
            />
            </Comment.Group>);
    } else {
        content = (<NewAnswerForm
            onSave={onSave}
            onFocus={onFocusHandler}
            fieldLabel={answerBoxLabel}
            rows={4}
            submitButtonProps={{
                loading: updating,
            }}
        />);
    }
    return (
        <div className="page-container answer own-answer">
            {content}
        </div>);
};

OwnAnswer.propTypes = {
    onSave: PropTypes.func.isRequired,
    onFocusHandler: PropTypes.func.isRequired,
    answer: PropTypes.shape({}),
    topicExpired: PropTypes.bool.isRequired,
    updating: PropTypes.bool,
};

OwnAnswer.defaultProps = {
    answer: null,
    updating: false,
};

const mapStateToProps = (state, { topicId }) => ({
    answer: ownAnswerWithComments(topicId)(state),
});

const mapDispatchToProps = dispatch => ({
    onFocusHandler: () => dispatch(answerInputFocused()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnAnswer);
