import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import Error from './Error';

const Input = ({
    type,
    placeholder,
    label,
    id,
    inputClasses,
    serverErrorMessage,
}) => {
    const { getFieldProps, touched, errors } = useFormikContext();

    const showError = touched[id] && errors[id];

    const combinedClasses = clsx(
        'border text-base rounded-md px-3 h-12 py-2 focus:outline-none focus:ring focus:border-blue-500',
        showError ? 'border-red-500' : 'border-gray-300',
        inputClasses,
    );

    return (
        <div className="flex flex-col">
            {label && <label className="mb-2 text-base" htmlFor={id}>{label}</label>}
            <input
                id={id}
                type={type}
                {...getFieldProps(id)}
                placeholder={placeholder}
                className={combinedClasses}
            />
            {showError && <Error id={`${label}-error`} message={errors[id]} />}
            {serverErrorMessage && !showError && <Error id={`${label}-server-error`} message={serverErrorMessage} />}
        </div>
    );
};

Input.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    inputClasses: PropTypes.string,
    serverErrorMessage: PropTypes.string,
};

Input.defaultProps = {
    label: null,
    type: 'text',
    placeholder: '',
    inputClasses: '',
    serverErrorMessage: null,
};

export default Input;
