import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import localization from '../../../localization';
import { resetFlaggedState } from '../../../data/questions/actions';

const {
    topic: {
        flagQuestion: {
            errorMessage,
            errorTitle,
        },
    },
} = localization;

class ErrorModal extends Component {
    state = { open: false };

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen) {
            this.open();
        }
    }

    open = () => this.setState({ open: true });
    close = () => {
        this.props.resetFlaggedState();
        this.setState({ open: false });
    }

    render() {
        return (
            <Modal
                basic
                open={this.state.open}
            >
                <Modal.Header>{errorTitle}</Modal.Header>
                <Modal.Content>
                    {errorMessage}
                </Modal.Content>
                <Modal.Actions>
                    <Button icon="check" content="Ok" onClick={this.close} color="green" inverted />
                </Modal.Actions>
            </Modal>
        );
    }
}

// TODO: remove after UNSAFE method below is refactored
/* eslint-disable react/no-unused-prop-types */
ErrorModal.propTypes = {
    resetFlaggedState: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
};
/* eslint-enable react/no-unused-prop-types */

ErrorModal.defaultProps = {
    isOpen: false,
};

export default connect(
    (_, ownProps) => ownProps,
    dispatch => ({
        resetFlaggedState: () => dispatch(resetFlaggedState()),
    }),
)(ErrorModal);
