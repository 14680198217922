import { call, put, all, takeLatest } from 'redux-saga/effects';
import { handleLinkClick } from '../../utils/form/keyHandlers';

import {
    LOAD_CAMPAIGNS,
    DOWNLOAD_ANSWER_REPORT,
    DOWNLOAD_TARGET_LIST,
    EXPORT_USERS_LIST,
    CREATE_CAMPAIGN,
    DELETE_CAMPAIGN,
    CREATE_SPECIALTY_CAMPAIGN,
    CREATE_NPI_CAMPAIGN,
    LOAD_GENERAL_CAMPAIGNS,
    DELETE_GENERAL_CAMPAIGN,
} from './actionTypes';
import {
    downloadTargetList,
    downloadTargetListCompleted,
    downloadAnswerReportCompleted,
    downloadTargetListFailed,
    downloadAnswerReportFailed,
    exportUsersListCompleted,
    exportUsersListFailed,
    createCampaignCompleted,
    createCampaignFailed,
    loadCampaignsCompleted,
    loadCampaignsFailed,
    deleteCampaignCompleted,
    deleteCampaignFailed,
    createSpecialtyCampaignCompleted,
    createSpecialtyCampaignFailed,
    createNpiCampaignCompleted,
    createNpiCampaignFailed,
    loadGeneralCampaignsCompleted,
    loadGeneralCampaignsFailed,
    deleteGeneralCampaignCompleted,
    deleteGeneralCampaignFailed,
} from './actions';
import { hideModal, showModalLoading } from '../../services/sharedUIActions';
import {
    createCampaign as createCampaignApi,
    loadCampaigns as loadCampaignsApi,
    loadGeneralCampaigns as loadGeneralCampaignsApi,
    downloadTargetListCSV,
    downloadAnswerReportCSV,
    downloadUsersListCSV,
    deleteCampaign as deleteCampaignApi,
    createSpecialtyCampaign as createSpecialtyCampaignApi,
    createNpiCampaign as createNpiCampaignApi,
} from './api';

// defined in constants/links.ts on backend
const dataSeparator = '#data:';

function* createCampaign({ payload }) {
    try {
        const responseString = yield call(createCampaignApi, payload);
        const { campaign } = JSON.parse(responseString.split(dataSeparator)[1]);
        if (campaign) {
            yield put(createCampaignCompleted({ campaign }));
            yield put(downloadTargetList({ campaignId: campaign.id }));
        } else {
            yield put(createCampaignFailed());
        }
    } catch (error) {
        yield put(createCampaignFailed({ error }));
    }
}

function* deleteCampaign({ payload: { campaignId, topicId } }) {
    yield put(showModalLoading());
    try {
        yield call(deleteCampaignApi, campaignId);
        yield put(deleteCampaignCompleted({ topicId }));
    } catch (error) {
        yield put(deleteCampaignFailed({ error }));
    }
    yield put(hideModal());
}

function* deleteGeneralCampaign({ payload: { campaignId } }) {
    yield put(showModalLoading());
    try {
        yield call(deleteCampaignApi, campaignId);
        yield put(deleteGeneralCampaignCompleted());
    } catch (error) {
        yield put(deleteGeneralCampaignFailed({ error }));
    }
    yield put(hideModal());
}

function* loadCampaigns({ payload: { topicId } }) {
    try {
        const { data: campaigns } = yield call(loadCampaignsApi, { topicId });
        yield put(loadCampaignsCompleted({ campaigns, topicId }));
    } catch (error) {
        yield put(loadCampaignsFailed({ error }));
    }
}

function* loadGeneralCampaigns({ payload }) {
    try {
        const {
            data: campaigns,
            meta: {
                total,
                page,
            },
        } = yield call(loadGeneralCampaignsApi, payload);
        yield put(loadGeneralCampaignsCompleted({ campaigns, total, page }));
    } catch (error) {
        yield put(loadGeneralCampaignsFailed({ error }));
    }
}

function* downloadTargetListSaga({ payload }) {
    try {
        const responseString = yield call(downloadTargetListCSV, payload);
        const { url, segmentName: fileName } = JSON.parse(responseString.split(dataSeparator)[1]);
        if (url && fileName) {
            yield handleLinkClick(url, fileName);
            yield put(downloadTargetListCompleted());
        } else {
            yield put(downloadTargetListFailed());
        }
    } catch (error) {
        yield put(downloadTargetListFailed({ error }));
    }
}

function* downloadAnswerReportSaga({ payload }) {
    try {
        const { data: { url, fileName } } = yield call(downloadAnswerReportCSV, payload);
        yield handleLinkClick(url, fileName);
        yield put(downloadAnswerReportCompleted());
    } catch (error) {
        yield put(downloadAnswerReportFailed({ error }));
    }
}

function* exportUsersListSaga({ payload }) {
    try {
        const { data: { url, fileName } } = yield call(downloadUsersListCSV, payload);
        yield handleLinkClick(url, fileName);
        yield put(exportUsersListCompleted());
    } catch (error) {
        yield put(exportUsersListFailed({ error }));
    }
}

function* createSpecialtyCampaign({ payload }) {
    try {
        const responseString = yield call(createSpecialtyCampaignApi, payload);
        const { campaigns } = JSON.parse(responseString.split(dataSeparator)[1]);
        if (campaigns.length) {
            yield put(createSpecialtyCampaignCompleted({ campaigns }));
        } else {
            yield put(createSpecialtyCampaignFailed());
        }
    } catch (error) {
        yield put(createSpecialtyCampaignFailed({ error }));
    }
}

function* createNpiCampaign({ payload }) {
    try {
        const responseString = yield call(createNpiCampaignApi, payload);
        const { campaign } = JSON.parse(responseString.split(dataSeparator)[1]);
        if (campaign) {
            yield put(createNpiCampaignCompleted({ campaign }));
            yield put(downloadTargetList({ campaignId: campaign.id }));
        } else {
            yield put(createNpiCampaignFailed());
        }
    } catch (error) {
        yield put(createNpiCampaignFailed({ error }));
    }
}

function* campaignSaga() {
    yield all([
        takeLatest(CREATE_CAMPAIGN, createCampaign),
        takeLatest(DELETE_CAMPAIGN, deleteCampaign),
        takeLatest(DELETE_GENERAL_CAMPAIGN, deleteGeneralCampaign),
        takeLatest(LOAD_CAMPAIGNS, loadCampaigns),
        takeLatest(LOAD_GENERAL_CAMPAIGNS, loadGeneralCampaigns),
        takeLatest(DOWNLOAD_ANSWER_REPORT, downloadAnswerReportSaga),
        takeLatest(DOWNLOAD_TARGET_LIST, downloadTargetListSaga),
        takeLatest(EXPORT_USERS_LIST, exportUsersListSaga),
        takeLatest(CREATE_SPECIALTY_CAMPAIGN, createSpecialtyCampaign),
        takeLatest(CREATE_NPI_CAMPAIGN, createNpiCampaign),
    ]);
}

export default campaignSaga;
