import { connect } from 'react-redux';
import UnassignedProgramsContainer from './container';
import { confirmHcmpGlobalStatus, loadingSelector } from '../../../../../data/globalHcmp';
import { unassignedHcmpSelector, numberOfAssignedHcmpSelector } from '../selectors';

const mapStateToProps = state => ({
    unassignedPrograms: unassignedHcmpSelector(state),
    numOfAssignedHcmps: numberOfAssignedHcmpSelector(state),
    loading: loadingSelector(state),
});

const mapDispatchToProps = dispatch => ({
    confirmHcmp: (hcmpId, newPriority) => dispatch(confirmHcmpGlobalStatus(hcmpId, newPriority)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedProgramsContainer);
