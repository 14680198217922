import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const selectStyles = {
    container: base => ({
        ...base,
        width: '100%',
        marginBottom: '16px',
    }),
    placeholder: base => ({
        ...base,
        background: '#ffffff',
        opacity: '0.7',
    }),
    option: base => ({
        ...base,
        cursor: 'pointer',
    }),
};

const QuoteSelect = ({ options, onChange }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (option) => {
        setSelectedOption(option);
        onChange(option);
    };

    return (
        <Select
            defaultValue={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="Start typing to search for a quote"
            styles={selectStyles}
        />
    );
};

QuoteSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default QuoteSelect;
