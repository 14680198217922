import { ID as DataID } from '../';
import { ID } from './actionTypes';

/* eslint-disable import/prefer-default-export */

export const selectDownloadStatus = ({
    [DataID]: {
        [ID]: {
            downloadingAnswerReport,
            downloadingTargetList,
        },
    },
}) => ({
    downloadingAnswerReport,
    downloadingTargetList,
});
