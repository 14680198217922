import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

class ExpandableTable extends Component {
    static propTypes = {
        renderHeader: PropTypes.func.isRequired,
        renderBody: PropTypes.func.isRequired,
    }

    state = {
        opened: false,
    }

    clickEventHandler = () => {
        this.setState(({ opened }) => ({
            opened: !opened,
        }));
    }

    render() {
        const {
            renderHeader,
            renderBody,
        } = this.props;
        const {
            opened,
        } = this.state;
        return (
            <Fragment>
                <Table columns="4">
                    <Table.Header>
                        <Table.Row className={`header-row ${opened ? 'opened' : ''}`}>
                            {renderHeader(this.clickEventHandler, this.state.opened)}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {opened && renderBody()}
                    </Table.Body>
                </Table>
            </Fragment>
        );
    }
}

export default ExpandableTable;
