export const ID = 'TOPIC_PROGRAMS';

export const CLEAR_ERROR_MESSAGE = `${ID}/CLEAR_ERROR_MESSAGE`;

export const PROGRAM_PRIORITY_CHANGE = `${ID}/PROGRAM_PRIORITY_CHANGE`;
export const PROGRAM_PRIORITY_CHANGE_COMPLETED = `${ID}/PROGRAM_PRIORITY_CHANGE_COMPLETED`;
export const PROGRAM_PRIORITY_CHANGE_FAILED = `${ID}/PROGRAM_PRIORITY_CHANGE_FAILED`;

export const DETACH_PROGRAM = `${ID}/DETACH_PROGRAM`;
export const DETACH_PROGRAM_COMPLETED = `${ID}/DETACH_PROGRAM_COMPLETED`;
export const DETACH_PROGRAM_FAILED = `${ID}/DETACH_PROGRAM_FAILED`;

export const DETACH_ALL_PROGRAMS = `${ID}/DETACH_ALL_PROGRAMS`;
export const DETACH_ALL_PROGRAMS_COMPLETED = `${ID}/DETACH_ALL_PROGRAMS_COMPLETED`;
export const DETACH_ALL_PROGRAMS_FAILED = `${ID}/DETACH_ALL_PROGRAMS_FAILED`;

export const ATTACH_PROGRAMS = `${ID}/ATTACH_PROGRAMS`;
export const ATTACH_PROGRAMS_COMPLETED = `${ID}/ATTACH_PROGRAMS_COMPLETED`;
export const ATTACH_PROGRAMS_FAILED = `${ID}/ATTACH_PROGRAMS_FAILED`;

export const GET_ATTACHED_PROGRAMS = `${ID}/GET_ATTACHED_PROGRAMS`;
export const GET_ATTACHED_PROGRAMS_COMPLETED = `${ID}/GET_ATTACHED_PROGRAMS_COMPLETED`;
export const GET_ATTACHED_PROGRAMS_FAILED = `${ID}/GET_ATTACHED_PROGRAMS_FAILED`;

export const GET_UNATTACHED_PROGRAMS = `${ID}/GET_UNATTACHED_PROGRAMS`;
export const GET_UNATTACHED_PROGRAMS_COMPLETED = `${ID}/GET_UNATTACHED_PROGRAMS_COMPLETED`;
export const GET_UNATTACHED_PROGRAMS_FAILED = `${ID}/GET_UNATTACHED_PROGRAMS_FAILED`;
