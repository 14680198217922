import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import Component from './component';
import { Form, errorSelector } from './form';
import {
    questionsNpisSelector,
    questionsNpisLoadingSelector,
    questionsNpisUpdatingSelector,
} from '../../../../data/questions/selectors';

const mapStateToProps = state => ({
    loading: questionsNpisLoadingSelector(state),
    updating: questionsNpisUpdatingSelector(state),
    errors: errorSelector(state).questionsNpisErrors,
    initialValues: {
        questionsNpisText: questionsNpisSelector(state).map(row => row.join(',')).join('\n'),
    },
});

export default compose(connect(mapStateToProps), Form)(Component);
