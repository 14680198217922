import ReactDOMServer from 'react-dom/server';

/**
 * ReactDOMServer.renderToStaticMarkup automatically converts html entities like &mdash; to —
 * however app.healthcasts.com is not able to render these characters and instead renders â€.
 */
export const renderToStaticMarkup = component =>
    ReactDOMServer.renderToStaticMarkup(component)
        .replaceAll('—', '&mdash;')
        .replaceAll('–', '&ndash;')
        .replaceAll('•', '&bull;');
