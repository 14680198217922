import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Input } from 'semantic-ui-react';
import Button from '../Button';
import { analytics } from '../../utils/analytics';
import './caseSearch.scss';

const Case = ({ onSubmit, error }) => {
    const formik = useFormik({
        initialValues: {
            caseNumber: '',
        },
        onSubmit: async (values) => {
            analytics.trackEvent('custom_summarize_this_case_click');
            onSubmit(values.caseNumber);
        },
    });

    return (
        <div className="case-search">
            <h1>Submit your Summary</h1>
            <p className="description">Help to summarize the community response to a patient case. Once submitted, your consensus will be reviewed and published by our team.</p>
            <p className="instruction">Required field</p>
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="caseNumber">Patient Case Number</label>
                <p htmlFor="caseNumber">This is the number of the patient case you&apos;ve been invited to respond to via email.</p>
                <Input
                    id="caseNumber"
                    name="caseNumber"
                    placeholder="00000"
                    error={!isEmpty(error) && !error.success}
                    onChange={formik.handleChange}
                    value={formik.values.caseNumber}
                />
                {!!error && <p className="error">{error.message}</p>}
                <Button
                    type="submit"
                    disabled={!formik.values.caseNumber || Number.isNaN(+formik.values.caseNumber)}
                >
                    Summarize these consults
                </Button>
            </form>
        </div>
    );
};

Case.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.shape({
        success: PropTypes.bool,
        message: PropTypes.string,
    }),
};

Case.defaultProps = {
    error: {},
};

export default Case;
