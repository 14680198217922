import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { ID } from './actionTypes';
import { ID as parentId } from '../../actionTypes';
import {
    questionsSelector,
    questionLoading,
} from '../../../../data/questions/selectors';
import { ID as CAMPAIGNS_ID } from '../../../../data/campaigns/actionTypes';
import { ID as TOPIC_PROGRAMS_ID } from '../../../../data/topicPrograms';
import { ID as DATA_ID } from '../../../../data';

export const ownStateSelector = ({
    [parentId]: {
        [ID]: ownState,
    },
}) => ownState;

export const dataStateSelector = ({
    [DATA_ID]: {
        [CAMPAIGNS_ID]: dataState,
    },
}) => dataState;

export const deleteModalSelector = ({
    [parentId]: {
        [ID]: {
            programId,
            isDetachModalOpen,
        },
    },
}) => ({
    programId,
    isDetachModalOpen,
});

export const questionsDetailsSelector = createSelector(ownStateSelector, questionDetails => questionDetails);
export const campaignsDetailsSelector = createSelector(dataStateSelector, campaignsDetails => campaignsDetails);

export const attachedProgramsSelector = ({
    [DATA_ID]: {
        [TOPIC_PROGRAMS_ID]: {
            attachedPrograms,
        },
    },
}) => attachedPrograms;

export const unAttachedProgramsSelector = ({
    [DATA_ID]: {
        [TOPIC_PROGRAMS_ID]: {
            unAttachedPrograms,
        },
    },
}) => ({ unAttachedPrograms });

export const programsStateSelector = ({
    [parentId]: {
        [ID]: {
            priorityUpLoading,
            priorityDownLoading,
            programDetaching,
            programInActionId,
            loadingPrograms,
            loadingNewPrograms,
            attachingError,
            detachingError,
            priorityChangeError,
        },
    },
}) => ({
    priorityUpLoading,
    priorityDownLoading,
    programDetaching,
    programInActionId,
    loadingPrograms,
    loadingNewPrograms,
    attachingError,
    detachingError,
    priorityChangeError,
});

export const questionSelector = createSelector(
    [questionsSelector, questionLoading, (_, id) => id],
    (questions, loading, qId) => {
        const question = questions.find(({ id }) => (id === qId));
        if (question) {
            const {
                createdAt,
                publishDate: pDate,
                expirationDate: eDate,
            } = question;
            return ({
                ...question,
                createdAt: moment(createdAt).format('L'),
                publishDate: moment(pDate).format('L'),
                expirationDate: moment(eDate).format('L'),
                loading: false,
            });
        }
        return {
            loading,
        };
    },
);

export default {
    ownStateSelector,
    dataStateSelector,
    deleteModalSelector,
    questionsDetailsSelector,
    campaignsDetailsSelector,
    attachedProgramsSelector,
    unAttachedProgramsSelector,
    programsStateSelector,
    questionSelector,
};
