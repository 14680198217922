import { createAction } from 'redux-actions';

import {
    LOAD_ROLES,
    LOAD_ROLES_COMPLETED,
    LOAD_ROLES_FAILED,
} from './actionTypes';

export const loadRoles = createAction(LOAD_ROLES);
export const loadRolesCompleted = createAction(LOAD_ROLES_COMPLETED);
export const loadRolesFailed = createAction(LOAD_ROLES_FAILED);
