import Immutable from 'seamless-immutable';
import { handleActions } from 'redux-actions';
import { addToCurrentTime } from '../../utils/dates';
import {
    GET_PROGRAM_FOR_USER_COMPLETED,
    GET_DTI_LINK_COMPLETED,
} from './actionTypes';

const initialState = Immutable({
    program: {
        id: null,
        url: null,
    },
    dtiLink: null,
    expiresAt: null,
});

export default handleActions(
    {
        [GET_PROGRAM_FOR_USER_COMPLETED]: (state, { payload: { url, id } }) =>
            state.merge({ program: { url, id } }),
        [GET_DTI_LINK_COMPLETED]: (
            state,
            { payload: { url, expiration, currentDate } },
        ) =>
            state.merge({
                dtiLink: url,
                expiresAt: addToCurrentTime(expiration, currentDate),
            }),
    },
    initialState,
);
