import { connect } from 'react-redux';
import { ID as DataID, AnswersID } from '../../../../../data';
import { ID } from '../../../actionTypes';
import AnswerEdit from './container';
import { openAnswerEditForm } from '../../../actions';
import { updateAnswer } from '../../../../../data/answers';

const mapStateToProps = ({
    [DataID]: {
        [AnswersID]: {
            updating,
        },
    },
    [ID]: {
        editedAnswer,
    },
}, { id }) => ({
    updateInProgress: updating && editedAnswer === id,
});

export default connect(mapStateToProps, {
    openAnswerEditForm,
    updateAnswer,
})(AnswerEdit);
