import { ID } from './actionTypes';
import { ID as DataID, SpecialtyID, CampaignsID } from '../../../../data';
import { ID as parentId } from '../../actionTypes';

export const ownStateSelector = ({
    [parentId]: {
        [ID]: ownState,
    },
}) => ownState;

export const specialtiesSelector = ({
    [DataID]: {
        [SpecialtyID]: {
            specialties,
        },
    },
}) => specialties;


export const loadingSpecialtiesSelector = ({
    [DataID]: {
        [SpecialtyID]: {
            loadingSpecialties,
        },
    },
}) => loadingSpecialties;

export const dataStateSelector = ({
    [DataID]: {
        [CampaignsID]: dataState,
    },
}) => dataState;
