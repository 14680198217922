import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
} from 'semantic-ui-react';
import localization from '../../../../../localization';

const {
    admin: {
        questions: {
            details: {
                detachWarning,
                detach,
            },
        },
    },
    common: {
        cancel,
        warning,
    },

} = localization;

class DetachModal extends Component {
    static propTypes = {
        topicId: PropTypes.number.isRequired,
        programId: PropTypes.number.isRequired,
        isDetachModalOpen: PropTypes.bool.isRequired,
        detachProgram: PropTypes.func.isRequired,
        closeDetachModal: PropTypes.func.isRequired,
    };

    handlePositive = () => {
        const {
            topicId,
            programId,
            detachProgram,
            closeDetachModal,
        } = this.props;

        detachProgram({ programId, topicId });
        closeDetachModal();
    }

    handleNegative = () => {
        const {
            closeDetachModal,
        } = this.props;

        closeDetachModal();
    }

    render() {
        const {
            isDetachModalOpen,
        } = this.props;

        return (
            <Modal open={isDetachModalOpen} size="tiny">
                <Modal.Header className="hcmp">
                    {warning}
                </Modal.Header>
                <Modal.Content>
                    {detachWarning}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={this.handleNegative}
                    >
                        {cancel}
                    </Button>
                    <Button
                        color="red"
                        onClick={this.handlePositive}
                    >
                        {detach}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default DetachModal;
