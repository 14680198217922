import { createAction } from 'redux-actions';
import {
    OPEN_NEW_EMAIL_MODAL,
    CLOSE_NEW_EMAIL_MODAL,
    CLOSE_NEW_EMAIL_MODAL_FAILED,
    OPEN_NEW_NPI_EMAIL_MODAL,
    CLOSE_NEW_NPI_EMAIL_MODAL,
    CLOSE_NEW_NPI_EMAIL_MODAL_FAILED,
} from './actionTypes';

export const openNewEmailModal = createAction(OPEN_NEW_EMAIL_MODAL);
export const closeNewEmailModal = createAction(CLOSE_NEW_EMAIL_MODAL);
export const closeNewEmailModalError = createAction(CLOSE_NEW_EMAIL_MODAL_FAILED);

export const openNewNPIEmailModal = createAction(OPEN_NEW_NPI_EMAIL_MODAL);
export const closeNewNPIEmailModal = createAction(CLOSE_NEW_NPI_EMAIL_MODAL);
export const closeNewNPIEmailModalError = createAction(CLOSE_NEW_NPI_EMAIL_MODAL_FAILED);
