import { reduxForm, change } from 'redux-form';
import Component from './component';
import { formatDateForEmail } from '../../../../../utils/dates';

const formName = 'newCampaign';

const form = reduxForm({
    form: formName,
    onSubmit: (values, dispatch, { createCampaign, topicId }) => {
        createCampaign({ topicId, ...values });
    },
    onChange: (values, dispatch, { topicId }, pValues) => {
        if (values.targetAudience !== pValues.targetAudience) {
            dispatch(change(formName, 'name', formatDateForEmail(topicId, values.targetAudience)));
        }
    },
});

export default form(Component);
