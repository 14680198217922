import { stringTemplates } from '../../../localization';
import {
    minLength,
    maxLength,
} from '../../../utils/form/validators';

const {
    warnings: {
        stringCharactersLeft,
    },
} = stringTemplates;

export const answerLength = { min: 5, max: 4000 };
export const commentLength = { min: 5, max: 1000 };

export const syncValidations = maxTextLength => ({
    text = '',
}) => ({
    text: maxLength(text, maxTextLength),
});

export const submitValidations = minTextLength => ({
    text = '',
}) => ({
    text: minLength(text, minTextLength),
});

export const warn = maxTextLength => ({
    text = '',
}) => ({
    text: stringCharactersLeft(Math.max(maxTextLength - text.length, 0)),
});
