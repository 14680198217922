import React from 'react';
import PropTypes from 'prop-types';
import { ConsensusContent } from '../../components/ConsensusSummarySubmission/ConsensusPreview';
import HcpUserActionLinkTiles from '../../components/ActionLinkTile/hcpUserActionLinkTiles';
import { consensusShape } from '../../components/ConsensusSummarySubmission/shared/types';
import './summaryPreview.scss';

const SummaryContent = ({ resourceId, resource }) => (
    <div className="summary-preview-container">
        <ConsensusContent resourceId={resourceId} consensusData={resource} />
        <HcpUserActionLinkTiles />
    </div>
);

SummaryContent.propTypes = {
    resourceId: PropTypes.string,
    resource: PropTypes.shape({ data: consensusShape }),
};

SummaryContent.defaultProps = {
    resourceId: null,
    resource: null,
};

export default SummaryContent;
