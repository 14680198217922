import { reduxForm, change } from 'redux-form';
import Component from './component';
import { formatDateForSpecialtySpecificEmail } from '../../../../../utils/dates';

const formName = 'newEmail';

const form = reduxForm({
    form: formName,
    onSubmit: (values, dispatch, { createSpecialtyCampaign }) => {
        createSpecialtyCampaign({ ...values });
    },
    onChange: (values, dispatch, pValues, oldValues) => {
        if (values.name !== oldValues.name) {
            dispatch(change(formName, 'name', values.name));
        }
        if (values.targetAudience !== oldValues.targetAudience) {
            dispatch(change(formName, 'name', formatDateForSpecialtySpecificEmail(values.targetAudience)));
        }
    },
});

export default form(Component);
