import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import {
    LOAD_CURRENT_REWARDS,
    LOAD_CURRENT_REWARDS_COMPLETED,
    LOAD_CURRENT_REWARDS_FAILED,
    LOAD_HISTORY_REWARDS,
    LOAD_HISTORY_REWARDS_COMPLETED,
    LOAD_HISTORY_REWARDS_FAILED,
} from './actionTypes';

const initialState = Immutable.from({
    rewards: {},
    historyRewards: [],
    loadingCurrentRewardsError: '',
    loadingCurrentRewardsFailed: false,
    loadingCurrentRewards: false,
    loadedCurrentRewards: false,
    loadingHistoryRewardsError: '',
    loadingHistoryRewardsFailed: false,
    loadingHistoryRewards: false,
    loadedHistoryRewards: false,
});

const reducer = handleActions({
    [LOAD_CURRENT_REWARDS]: state =>
        state.merge({
            loadingCurrentRewards: true,
            loadingCurrentRewardsFailed: false,
            loadingCurrentRewardsError: null,
        }),
    [LOAD_CURRENT_REWARDS_COMPLETED]: (state, { payload: response }) =>
        state.merge({
            rewards: response,
            loadingCurrentRewards: false,
            loadingCurrentRewardsFailed: false,
            loadingCurrentRewardsError: null,
            loadedCurrentRewards: true,
        }),
    [LOAD_CURRENT_REWARDS_FAILED]: (state, { payload: error }) =>
        state.merge({
            rewards: {},
            loadingCurrentRewards: false,
            loadingCurrentRewardsFailed: true,
            loadingCurrentRewardsError: error,
        }),
    [LOAD_HISTORY_REWARDS]: state =>
        state.merge({
            loadingHistoryRewards: true,
            loadingHistoryRewardsFailed: false,
            loadingHistoryRewardsError: null,
        }),
    [LOAD_HISTORY_REWARDS_COMPLETED]: (state, { payload: response }) =>
        state.merge({
            historyRewards: response,
            loadingHistoryRewards: false,
            loadingHistoryRewardsFailed: false,
            loadingHistoryRewardsError: null,
            loadedHistoryRewards: true,
        }),
    [LOAD_HISTORY_REWARDS_FAILED]: (state, { payload: error }) =>
        state.merge({
            historyRewards: [],
            loadingHistoryRewards: false,
            loadingHistoryRewardsFailed: true,
            loadingHistoryRewardsError: error,
        }),
}, initialState);

export default reducer;
