import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Header, Message, Button, Popup, Dropdown, Icon, Pagination } from 'semantic-ui-react';
import './style.scss';
import { ExpandableRow } from '../../../../../components';
import TableEmptyState from '../../../../../components/TableEmptyState';
import localization from '../../../../../localization';
import SpecialtyTag from '../../../../../components/SpecialtyTag';

const {
    admin: {
        globalHcmpList: {
            programs: {
                createEmails: {
                    tableHeaders,
                    targetSpecialties,
                    targetAudience: locTargetAudience,
                    userCount: locUserCount,
                    emailListTitle,
                    deleteEmail,
                    waitCompletion,
                    campaignDeleteModal: {
                        header,
                        content,
                    },
                    newSpecialtyCampaignForm: {
                        ...strings
                    },
                    emptyEmailsState,
                    createEmailFailed,
                },
            },
        },
    },
} = localization;

const EmailList = ({
    emails,
    loadingEmailList,
    campaignIdAction,
    downloadingTargetList,
    creatingTargetList,
    deletingCampaign,
    downloadTargetList,
    deleteCampaign,
    showModal,
    errorCreatingCampaign,
    createCampaignErrorMessage,
    specialties,
    loadGeneralCampaigns,
    pageNumber,
    pageSize,
    total,
}) => {
    const downloadButton = (id, targetListCreatedAt) => (
        <Button
            loading={campaignIdAction === id ?
                downloadingTargetList || !targetListCreatedAt : false}
            basic
            disabled={downloadingTargetList || creatingTargetList}
            icon="download"
            className="action-button"
            onClick={() => downloadTargetList({ campaignId: id })}
        />
    );
    const specialtyFilter = (id) => {
        if (id !== null) {
            const specs = specialties.filter(spec => id === spec.id);
            return (
                <div>
                    <div className="title">{targetSpecialties}</div>
                    <div className="tags">
                        <SpecialtyTag type="specialty" name={specs[0].name} />
                    </div>
                </div>
            );
        }
        return null;
    };
    const rows = emails.map(({
        id,
        name,
        createdAt,
        segmentSize,
        targetListCreatedAt,
        specialtyId,
        targetAudience,
    }) => (
        <ExpandableRow
            key={id}
            renderHeader={() =>
                (
                    <Fragment>
                        <Table.Cell width={2}>{moment(createdAt).format('L')}</Table.Cell>
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell width={2} textAlign="right">{id}</Table.Cell>
                        <Table.Cell width={2} textAlign="center" className="actions">
                            {
                                (
                                    <div className="actions">
                                        {
                                            targetListCreatedAt ? downloadButton(id, targetListCreatedAt) : (/* eslint-disable-line max-len */
                                                <Popup
                                                    trigger={
                                                        <span>
                                                            { downloadButton(id, targetListCreatedAt) /* eslint-disable-line max-len */ }
                                                        </span>
                                                    }
                                                    content={<div dangerouslySetInnerHTML={{ __html: waitCompletion }} />} // eslint-disable-line react/no-danger, max-len
                                                    position="bottom center"
                                                    basic
                                                    wide
                                                    className="tooltip"
                                                />
                                            )
                                        }

                                        <Dropdown
                                            icon={<Button
                                                icon="ellipsis vertical"
                                                className="action-button"
                                            />}
                                            disabled={downloadingTargetList}
                                        >
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    disabled={campaignIdAction === id ?
                                                        deletingCampaign : false}
                                                    onClick={() => showModal({
                                                        content,
                                                        header,
                                                        positiveResponseAction: () =>
                                                            deleteCampaign({
                                                                campaignId: id,
                                                            }),
                                                    })}
                                                    content={deleteEmail}
                                                    icon="trash"
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>)
                            }
                        </Table.Cell>
                    </Fragment>)
            }
            renderBody={() => (
                <Fragment>
                    <Table.Cell />
                    <Table.Cell>
                        {specialtyFilter(specialtyId)}
                        <div className="card-bottom">
                            <div>
                                <div className="title">{locTargetAudience}</div>
                                <div>{strings[targetAudience]}</div>
                            </div>
                            <div>
                                <div className="title">{locUserCount}</div>
                                <div>{segmentSize}</div>
                            </div>
                        </div>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell collapsing />
                </Fragment>)}
        />));
    const numPages = Math.ceil((total / pageSize));
    return (
        <div className="programs-email-list">
            <div className="top-half">
                <Header>
                    {emailListTitle}
                    <span>
                        <Button style={{ marginLeft: '10px', padding: '5px' }} loading={emails.length > 0 && loadingEmailList} positive icon size="big" onClick={() => loadGeneralCampaigns({ pageNumber })}>
                            <Icon name="refresh" />
                        </Button>
                    </span>
                </Header>
            </div>
            <Message
                hidden={!errorCreatingCampaign}
                header={(createCampaignErrorMessage !== null) ?
                    createCampaignErrorMessage :
                    createEmailFailed
                }
                negative
            />
            <Table className={ExpandableRow.tableClassName}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{tableHeaders.createdAt}</Table.HeaderCell>
                        <Table.HeaderCell>{tableHeaders.emailName}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">{tableHeaders.emailId}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{tableHeaders.actions}</Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        rows.length ? rows : <TableEmptyState
                            loading={loadingEmailList}
                            text={emptyEmailsState}
                        />
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="5">
                            <Pagination
                                stackable
                                defaultActivePage={pageNumber}
                                totalPages={numPages}
                                onPageChange={(_event, { activePage }) => {
                                    const page = (activePage <= 0) ? 1 : activePage;
                                    loadGeneralCampaigns({ pageNumber: page });
                                }}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};


EmailList.propTypes = {
    emails: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        createdAt: PropTypes.string,
    })).isRequired,
    downloadingTargetList: PropTypes.bool.isRequired,
    campaignIdAction: PropTypes.number,
    errorCreatingCampaign: PropTypes.bool.isRequired,
    downloadTargetList: PropTypes.func.isRequired,
    deletingCampaign: PropTypes.bool.isRequired,
    deleteCampaign: PropTypes.func.isRequired,
    loadGeneralCampaigns: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    loadingEmailList: PropTypes.bool.isRequired,
    creatingTargetList: PropTypes.bool.isRequired,
    createCampaignErrorMessage: PropTypes.string,
    specialties: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    total: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
};

EmailList.defaultProps = {
    campaignIdAction: null,
    createCampaignErrorMessage: null,
};

export default EmailList;
