import { createSelector } from '@reduxjs/toolkit';
import { userIdSelector, permissionsSelector } from '../../../services/session/selectors';
import { selectors } from '../../../data/answers';
import { Permissions } from '../../../permissions';

const answerIdSelector = (state, answerId) => answerId;

export const hasManagementPermission = createSelector(
    permissionsSelector,
    permissions => permissions.includes(Permissions.ACTIVE_PATIENT_CASE_MANAGEMENT),
);

export const othersHaveCommentedSelector = createSelector(
    selectors.allAnswersSelector,
    answerIdSelector,
    userIdSelector,
    (answers, answerId, currentUserId) => {
        const answer = answers.find(({ id }) => id === answerId);
        let userHasAnswered = false;
        if (answer) {
            userHasAnswered = answer.createdByUser.id === currentUserId;
        }
        const comments = answers.filter(({ replyTo, createdByUser: { id } = {} }) =>
            (replyTo === answerId && currentUserId !== id));
        return !userHasAnswered || comments.length > 0;
    },
);
