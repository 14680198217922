import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import { ANSWER_INPUT_FOCUSED_COMPLETED } from './actionTypes';
import { LOCATION_CHANGE } from '../../services/navigation';

const initialState = Immutable.from({
    answerFocusedExecuted: false,
});

const reducer = handleActions({
    [ANSWER_INPUT_FOCUSED_COMPLETED]: state => state.set('answerFocusedExecuted', true),
    [LOCATION_CHANGE]: state => state.set('answerFocusedExecuted', false),
}, initialState);

export default reducer;
