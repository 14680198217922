import React from 'react';
import propTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Form } from 'semantic-ui-react';
import FieldError from '../FieldError';

const TextArea = ({
    label,
    meta: {
        error,
        touched,
        warning,
        pristine,
    },
    input,
    required,
    disabled,
    WarningRenderer,
    warningProps,
    ...props
}) => {
    const hasError = touched && error && error.length > 0;
    let infoArea = hasError && <FieldError error={error} touched={touched} />;
    if (!infoArea && warning && !pristine) {
        if (WarningRenderer) {
            infoArea = <WarningRenderer msg={warning} value={input.value} {...warningProps} />;
        } else {
            infoArea = (<div className="form-warning" >{warning}</div>);
        }
    }
    return (
        <div>
            <Form.TextArea
                label={label}
                {...input}
                required={required}
                disabled={disabled}
                {...props}
            />
            {infoArea}
        </div>
    );
};

TextArea.propTypes = {
    ...fieldPropTypes,
    required: propTypes.bool,
    disabled: propTypes.bool,
    meta: propTypes.shape({
        active: propTypes.bool.isRequired,
        pristine: propTypes.bool.isRequired,
    }).isRequired,
    WarningRenderer: propTypes.func,
    warningProps: propTypes.shape(),
};

TextArea.defaultProps = {
    required: false,
    disabled: false,
    WarningRenderer: null,
    warningProps: {},
};

export default TextArea;
