import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import PropTypes from 'prop-types';
import Logo from './Logo/logo';
import UserDropdown from './userDropdown';
import HamburgerMenu from './hamburgerMenu';

const Header = ({
    dropdownItems, navItems, isAdmin, initials, fullName, isPublic,
}) => {
    const adminStyles = 'bg-purple-gray-700';
    const hcpStyles = 'bg-gradient-to-r from-purple-gray-800 from-0% via-blue-450 via-50% to-purple-600 to-100% ';

    return (
        <header className={`flex-col px-6 py-3 flex w-full fixed z-10 ${isAdmin ? adminStyles : hcpStyles}`}>
            <nav className="max-w-screen-xl flex gap-6 justify-between self-center w-full">
                <Logo isAdmin={isAdmin} />
                {Boolean(navItems.length) &&
                    <div className="hidden lg:flex lg:visible gap-8 items-center">
                        {
                            navItems.map(({
                                key, label, url, isExternal, newTab,
                            }) => {
                                if (isExternal) {
                                    return (
                                        <a
                                            href={url}
                                            target={newTab ? '_blank' : undefined}
                                            key={key}
                                            className="body-s text-white hover:opacity-80 hover:text-white flex items-center whitespace-nowrap"
                                            rel="noopener noreferrer"
                                        >
                                            {label}
                                            {newTab && <ExternalLinkIcon className="ml-0.5" />}
                                        </a>
                                    );
                                }

                                return (
                                    <Link
                                        to={url}
                                        key={key}
                                        className="body-s text-white hover:opacity-80 hover:text-white"
                                    >
                                        {label}
                                    </Link>);
                            })
                        }
                        <UserDropdown initials={initials} dropdownItems={dropdownItems} />
                    </div>
                }
                {Boolean(dropdownItems.length) && <HamburgerMenu
                    navItems={navItems}
                    dropdownItems={dropdownItems}
                    fullName={fullName}
                    initials={initials}
                />}
                {isPublic &&
                    <div className="flex flex-col text-right sm:text-start sm:flex-row h-full self-center">
                        <p className=" text-white mb-0 mr-1 leading-5 opacity-70">Already have an account?</p>
                        <Link to="/login" className="text-white font-bold">Login</Link>
                    </div>
                }
            </nav>
        </header>
    );
};

Header.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        label: PropTypes.string,
        url: PropTypes.string,
    })),
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number,
        label: PropTypes.string,
        url: PropTypes.string,
    })),
    isAdmin: PropTypes.bool,
    initials: PropTypes.string,
    fullName: PropTypes.string,
    isPublic: PropTypes.bool,
};

Header.defaultProps = {
    isAdmin: false,
    initials: null,
    fullName: null,
    navItems: [],
    dropdownItems: [],
    isPublic: false,
};

export default Header;
