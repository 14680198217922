import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Dropdown = forwardRef(({
    children, className,
}, ref) => (
    <div
        className={`absolute p-2 border border-solid border-purple-gray-400 rounded-1 shadow-sm bg-white z-[4] ${className}`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
        ref={ref}
    >
        {children}
    </div>
));

Dropdown.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

Dropdown.defaultProps = {
    children: null,
    className: '',
};

export default Dropdown;
