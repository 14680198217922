import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import takeRight from 'lodash/takeRight';
import { Message, Divider, Header, Button, Loader } from 'semantic-ui-react';
import VisibilitySensor from 'react-visibility-sensor';
import links from '../../../../links';
import { QuestionProps } from '../../../../utils/propTypes';
import localization from '../../../../localization';
import QuestionCard from '../QuestionCard';
import Search from './Search';
import { LoadingIndicator } from '../../../../components';

import './style.scss';

const bottomLoadOffset = 3;

const {
    admin: {
        questions: {
            questionForm: {
                createQuestion,
                questionsNpis,
                questionModeration,
                results,
            },
        },
    },
} = localization;

class QuestionList extends Component {
    state = {
        lastRequestedPage: 1,
        initialLoadFinished: false,
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.match !== nextProps.match) {
            this.setState(() => ({
                lastRequestedPage: 1,
            }));
        }

        if (!this.state.initialLoadFinished && !nextProps.loading) {
            this.setState({ initialLoadFinished: true });
        }
    }

    hasMore() {
        const {
            questions,
            total,
        } = this.props;

        return total > questions.length;
    }

    loadMore() {
        const { loadQuestionsSearch } = this.props;
        const { lastRequestedPage } = this.state;

        if (this.hasMore()) {
            loadQuestionsSearch({ append: true, values: { pageNumber: lastRequestedPage + 1 } });
            this.setState(prevState => ({
                lastRequestedPage: prevState.lastRequestedPage + 1,
            }));
        }
    }

    render() {
        const {
            msgHidden,
            msgContent,
            msgOnDismiss,
            questions,
            locationState,
            additionalMsgProps,
            total,
            loading,
            ...rest
        } = this.props;

        const noOffset = bottomLoadOffset > questions.length;

        const beforeSensorList =
            questions.slice(0, questions.length - bottomLoadOffset).map(({ id, ...q }) => (
                <QuestionCard
                    key={id}
                    id={id}
                    {...q}
                    {...rest}
                    {...locationState}
                />));

        const afterSensorList = takeRight(questions, bottomLoadOffset).map(({ id, ...q }) =>
            <QuestionCard key={id} id={id} {...q} {...rest} {...locationState} />);

        const shouldShowQuestions = !loading || this.state.initialLoadFinished;

        const questionsList = shouldShowQuestions &&
            <Fragment>
                {!noOffset && beforeSensorList}
                <VisibilitySensor onChange={visible => visible && this.loadMore()}>
                    <Fragment>
                        {afterSensorList}
                    </Fragment>
                </VisibilitySensor>
            </Fragment>;

        return (
            <div className="moderator-questions">
                <Fragment>
                    <div className="page-container">
                        <Message
                            hidden={msgHidden}
                            content={msgContent}
                            onDismiss={msgOnDismiss}
                            {...additionalMsgProps}
                        />
                        <div className="inline-align">
                            <Header>{questionModeration}</Header>
                            <Link
                                href
                                to={links.moderator.questions.npis}
                            >
                                <Button
                                    className="question-npis-buttion"
                                    floated="right"
                                    primary
                                >
                                    {questionsNpis}
                                </Button>
                            </Link>
                            <Link
                                href
                                to={links.moderator.questions.new}
                            >
                                <Button
                                    className="create-button"
                                    floated="right"
                                    primary
                                >
                                    {createQuestion}
                                </Button>
                            </Link>
                        </div>
                        <Divider className="expanded" />
                        <Search />
                    </div>
                    <div className="page-container">
                        <Header>{`${results} (${shouldShowQuestions ? total : 0})`}</Header>
                        <br />
                        <LoadingIndicator active={loading} />
                        {questionsList}
                        <Loader className="loader" active={this.hasMore()} inline />
                    </div>
                </Fragment>
            </div>
        );
    }
}

QuestionList.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.shape(QuestionProps)),
    locationState: PropTypes.shape({}),
    msgHidden: PropTypes.bool.isRequired,
    additionalMsgProps: PropTypes.shape({
        positive: PropTypes.bool,
        negative: PropTypes.bool,
    }),
    msgOnDismiss: PropTypes.func.isRequired,
    msgContent: PropTypes.string,
    total: PropTypes.number.isRequired,
    loadQuestionsSearch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({}).isRequired,
};

QuestionList.defaultProps = {
    questions: [],
    locationState: {},
    msgContent: null,
    additionalMsgProps: {},
};

export default QuestionList;
