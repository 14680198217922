import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import Icon from '../Navigation/menu.svg';

import './style.scss';

import localization from '../../../localization';
import links from '../../../links';
import { rewardsEnabled } from '../../../environment';

const {
    my: {
        profile: {
            myProfileHeading,
        },
        rewards: {
            myRewardsHeading,
        },
    },
} = localization;

const {
    my: {
        profile,
        rewards,
    },
} = links;

const Navigation = ({ active }) => (
    <div className="my-navigation">
        <div className="tab-navigation">
            <Link to={profile} href={profile} className={(active === 'profile') ? 'active' : ''}>
                {myProfileHeading}
            </Link>
            {rewardsEnabled &&
            <Link to={rewards} href={rewards} className={(active === 'rewards') ? 'active' : ''}>
                {myRewardsHeading}
            </Link>}
        </div>

        <Dropdown item icon={<Icon />} className="mobile-navigation">
            <Dropdown.Menu>
                <Dropdown.Item
                    as={Link}
                    to={profile}
                    active={active === 'profile'}
                    text={myProfileHeading}
                />
                {rewardsEnabled && <Dropdown.Item
                    as={Link}
                    to={rewards}
                    active={active === 'rewards'}
                    text={myRewardsHeading}
                />}
            </Dropdown.Menu>
        </Dropdown>
    </div>
);

Navigation.propTypes = {
    active: PropTypes.string,
};

Navigation.defaultProps = {
    active: '',
};

export default Navigation;
