import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withRouter } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { permissionsSelector } from '../services/session/selectors';
import { ID as SessionID, roleNameSelector } from '../services/session';
import Header from '../ui/components/header';
import { generateNavConfiguration } from '../components/Navigation/SharedComponents/container';
import { isHcpOrTestHcp } from '../utils/roleHelper';
import links from '../links';

const HeaderContainer = ({
    isHcpOrTestHcpRole,
    permissions,
    initials,
    fullName,
}) => {
    const { isAuthenticated } = useAuth0();

    if (!isAuthenticated) {
        return <Header isPublic />;
    }

    const navItemsWithUrl = generateNavConfiguration(permissions).filter(({ url }) => url);

    const profile = { key: 1, label: 'Profile', url: links.my.profile };
    const rewards = { key: 2, label: 'Rewards', url: links.my.rewards };
    const logout = { key: 3, label: 'Logout', url: links.logout };

    const dropdownItems = isHcpOrTestHcpRole ? [profile, rewards, logout] : [profile, logout];

    return (
        <Header
            initials={initials}
            fullName={fullName}
            isAdmin={!isHcpOrTestHcpRole}
            navItems={navItemsWithUrl}
            dropdownItems={dropdownItems}
        />
    );
};

const mapStateToProps = (state) => {
    const {
        [SessionID]: {
            firstName = '',
            lastName = '',
        },
    } = state;

    const roleName = roleNameSelector(state);
    const isHcpOrTestHcpRole = roleName ? isHcpOrTestHcp(roleName) : true;

    return {
        initials: `${firstName[0]} ${lastName[0]}`,
        fullName: `${firstName} ${lastName}`,
        permissions: permissionsSelector(state),
        isHcpOrTestHcpRole,
    };
};


HeaderContainer.propTypes = {
    isHcpOrTestHcpRole: PropTypes.bool,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    initials: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

HeaderContainer.defaultProps = {
    isHcpOrTestHcpRole: true,
};


export default compose(
    withRouter,
    connect(mapStateToProps),
)(HeaderContainer);
