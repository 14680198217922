import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { WithRouterHOC } from '../components/Router';

// HEA-376 - added this component as a means to help with easing the burden of sunsetting magicLinks
// this component's job is to take a magicLink URL and gather the redirectUrl so that a user may be
// sent to the requested resource after authentication occurs.
const LoginMagic = ({ location }) => {
    const { redirectUrl } = location.query;

    return <Redirect to={redirectUrl} />;
};

LoginMagic.propTypes = {
    location: PropTypes.shape({
        pathName: PropTypes.string,
        search: PropTypes.string,
        query: PropTypes.shape({}),
    }).isRequired,
};

export default WithRouterHOC(LoginMagic);
