import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { TextInput, TextArea } from '../../../components';
import localization from '../../../localization';

const {
    common: {
        cancel,
    },
    topic: {
        flagQuestion: {
            submitButton,
            userEmailLabel,
            titleLabel,
            messageLabel,
            flagQuestion,
        },
    },
} = localization;

class FlagQuestionModal extends React.Component {
    state = { modalOpen: false };

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            loadingSuccess,
            loadingError,
        } = nextProps;

        if (loadingSuccess || loadingError) {
            this.handleClose();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            id,
            loading,
        } = this.props;

        const {
            modalOpen,
        } = this.state;

        const {
            id: nextId,
            loading: nextLoading,
        } = nextProps;

        if (id !== nextId ||
            modalOpen !== nextState.modalOpen ||
            loading !== nextLoading) {
            return true;
        }

        return false;
    }

    handleOpen = () => this.setState({ modalOpen: true });
    handleClose = () => this.setState({ modalOpen: false });

    render() {
        const {
            handleSubmit,
            loading,
        } = this.props;
        return (
            <Modal
                trigger={<Button className="flag-button" content={flagQuestion} onClick={this.handleOpen} />}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                on="click"
            >
                <Modal.Content>
                    <Form loading={loading}>
                        <Field
                            name="userEmail"
                            label={userEmailLabel}
                            component={TextInput}
                            required
                        />
                        <Field
                            name="title"
                            label={titleLabel}
                            component={TextInput}
                            required
                        />
                        <Field
                            name="message"
                            label={messageLabel}
                            component={TextArea}
                            required
                        />
                        <Modal.Actions className="form-buttons">
                            <Form.Button
                                secondary
                                type="button"
                                onClick={this.handleClose}
                                content={cancel}
                                floated="right"
                            />
                            <Form.Button
                                primary
                                onClick={handleSubmit}
                                content={submitButton}
                                floated="right"
                            />
                        </Modal.Actions>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}

// TODO: remove after UNSAFE method below is refactored
/* eslint-disable react/no-unused-prop-types */
FlagQuestionModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    loadingError: PropTypes.bool,
    loadingSuccess: PropTypes.bool,
};
/* eslint-enable react/no-unused-prop-types */

FlagQuestionModal.defaultProps = {
    loading: false,
    loadingError: false,
    loadingSuccess: false,
};


export default FlagQuestionModal;
