import Immutable from 'seamless-immutable';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from '../../../../services/navigation';
import {
    OPEN_NEW_EMAIL_MODAL,
    CLOSE_NEW_EMAIL_MODAL,
    OPEN_NEW_NPI_EMAIL_MODAL,
    CLOSE_NEW_NPI_EMAIL_MODAL,
} from './actionTypes';
import {
    CREATE_SPECIALTY_CAMPAIGN_COMPLETED,
    CREATE_SPECIALTY_CAMPAIGN_FAILED,
    CREATE_NPI_CAMPAIGN_COMPLETED,
    CREATE_NPI_CAMPAIGN_FAILED,
} from '../../../../data/campaigns/actionTypes';

const initialState = Immutable({
    newEmailModalOpened: false,
    newNPIEmailModalOpened: false,
    errorCreatingCampaign: false,
});

export default handleActions({
    [LOCATION_CHANGE]: state => state.merge({
        newEmailModalOpened: false,
        newNPIEmailModalOpened: false,
    }),
    [OPEN_NEW_EMAIL_MODAL]: state => state.set('newEmailModalOpened', true),
    [CLOSE_NEW_EMAIL_MODAL]: state => state.set('newEmailModalOpened', false),
    [OPEN_NEW_NPI_EMAIL_MODAL]: state => state.set('newNPIEmailModalOpened', true),
    [CLOSE_NEW_NPI_EMAIL_MODAL]: state => state.set('newNPIEmailModalOpened', false),
    [CREATE_SPECIALTY_CAMPAIGN_COMPLETED]: state => state.set('newEmailModalOpened', false),
    [CREATE_SPECIALTY_CAMPAIGN_FAILED]: state => state.merge({
        newEmailModalOpened: false,
        errorCreatingCampaign: true,
    }),
    [CREATE_NPI_CAMPAIGN_COMPLETED]: state => state.merge({
        newNPIEmailModalOpened: false,
        errorCreatingCampaign: false,
    }),
    [CREATE_NPI_CAMPAIGN_FAILED]: state => state.merge({
        newNPIEmailModalOpened: false,
        errorCreatingCampaign: true,
    }),
}, initialState);
