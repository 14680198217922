import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import QuestionDetails from './component';
import links from '../../../../links';
import { ownStateSelector, questionSelector } from './selectors';
import { targetSizeSelector, eligibleCountSelector } from '../../../../data/questions/selectors';
import { deleteQuestion } from '../../../../data/questions';
import { showModal } from '../../../../services/sharedUIActions';

const mapStateToProps = (state, {
    match: {
        params: {
            id,
        },
    },
}) => {
    const {
        isAttachModalOpen,
    } = ownStateSelector(state);
    const questionId = parseInt(id, 10);
    const question = questionSelector(state, questionId);
    const targetSize = questionId && targetSizeSelector(state)[questionId];
    const eligibleCount = questionId && eligibleCountSelector(state)[questionId];

    return {
        id: questionId,
        isAttachModalOpen,
        targetSize,
        eligibleCount,
        editLink: links.moderator.questions.edit.replace(':id', questionId),
        publicLink: links.topics.view.replace(':id', questionId),
        questionLink: links.moderator.questions.index,
        ...question,
    };
};

const mapDispatchToProps = {
    deleteQuestion,
    showModal,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(QuestionDetails);
