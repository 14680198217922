import { createAction } from 'redux-actions';
import {
    GET_PROGRAM_FOR_USER,
    GET_PROGRAM_FOR_USER_COMPLETED,
    GET_PROGRAM_FOR_USER_FAILED,
    GET_DTI_LINK,
    GET_DTI_LINK_COMPLETED,
    GET_DTI_LINK_FAILED,

    VALIDATE_DTI_CACHE_TIMEOUT,
    VALIDATE_DTI_CACHE_TIMEOUT_COMPLETED,
    VALIDATE_DTI_CACHE_TIMEOUT_FAILED,
} from './actionTypes';

export const getProgramForUser = createAction(GET_PROGRAM_FOR_USER);
export const getProgramForUserCompleted = createAction(GET_PROGRAM_FOR_USER_COMPLETED);
export const getProgramForUserFailed = createAction(GET_PROGRAM_FOR_USER_FAILED);

export const getDtiLink = createAction(GET_DTI_LINK);
export const getDtiLinkCompleted = createAction(GET_DTI_LINK_COMPLETED);
export const getDtiLinkFailed = createAction(GET_DTI_LINK_FAILED);
export const validateDtiCacheTimeout = createAction(VALIDATE_DTI_CACHE_TIMEOUT);
export const validateDtiCacheTimeoutCompleted = createAction(VALIDATE_DTI_CACHE_TIMEOUT_COMPLETED);
export const validateDtiCacheTimeoutFailed = createAction(VALIDATE_DTI_CACHE_TIMEOUT_FAILED);
