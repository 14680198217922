import { takeLatest, call, put, all } from 'redux-saga/effects';
import { lookupAppHcUser, lookupAppHcUserCompleted, lookupAppHcUserFailed } from './slice';
import { lookupAppHcUserApi } from './api';

function* lookupAppHcUserIdsSaga({ payload: data }) {
    try {
        const response = yield call(lookupAppHcUserApi, data);
        const appHcUserIds = response.data.users.map(user => String(user.id));
        yield put(lookupAppHcUserCompleted({ data: appHcUserIds }));
    } catch (error) {
        yield put(lookupAppHcUserFailed({ error }));
    }
}

function* appHcUsersSaga() {
    yield all([
        takeLatest(lookupAppHcUser, lookupAppHcUserIdsSaga),
    ]);
}

export default appHcUsersSaga;
