import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Comment, Header, Button, Message, Icon, Grid } from 'semantic-ui-react';
import moment from 'moment';
import { shallowEqual } from 'recompose';
import FlipMove from 'react-flip-move';
import { Loading, LoadingIndicator } from '../../components';
import Answer from './Answers/Detail';
import localization from '../../localization';
import QuestionRewardBadge from '../../components/QuestionRewardBadge';
import OwnAnswer from './Answers/OwnAnswer';
import SuccessMessage from './Flag/success';
import ErrorMessage from './Flag/error';
import NoticeModal from './NoticeModal';
import { splitStringByDelimiter } from '../../utils/misc';
import { AuthorProps } from '../../utils/propTypes';
import links from '../../links';
import { hiddenAnswersEnabled } from '../../environment';
import './style.scss';

const {
    topic: {
        orderDate,
        orderRating,
        responsesHeader,
        noResponses,
        yourResponseOnly,
        expires,
        topicExpired: topicExpiredWarning,
        faq,
        viewResponses,
        nextQuestion,
    },
} = localization;

const expiredQuestionMsgParts = splitStringByDelimiter(topicExpiredWarning);
const redirectedUser = window.location.search.endsWith('type=redirected');

class Thread extends React.Component {
    static propTypes = {
        addAnswerAction: PropTypes.func.isRequired,
        showNextQuestion: PropTypes.func.isRequired,
        navigateAction: PropTypes.func.isRequired,
        rewards: PropTypes.shape({
            earned: PropTypes.number,
            max: PropTypes.number,
            rewards: PropTypes.arrayOf(PropTypes.number),
        }).isRequired,
        topic: PropTypes.shape({
            id: PropTypes.number,
            description: PropTypes.string, // 0948792980 Hodesova
            ratingCount: PropTypes.number,
            rewarded: PropTypes.bool,
            personalized: PropTypes.bool,
            // eslint-disable-next-line react/no-typos
            author: PropTypes.shape(AuthorProps),
        }).isRequired,
        nextTopic: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
        }),
        topicExpired: PropTypes.bool.isRequired,
        answers: PropTypes.arrayOf(PropTypes.shape({})),
        orderby: PropTypes.string.isRequired,
        hasAnswered: PropTypes.bool.isRequired,
        flaggingSuccess: PropTypes.bool,
        flaggingFailed: PropTypes.bool,
        // meta
        topicLoaded: PropTypes.bool.isRequired,
        loadingNextTopic: PropTypes.bool.isRequired,
        updating: PropTypes.bool,
        answersLoading: PropTypes.bool,
        areResponsesShown: PropTypes.bool.isRequired,
        showResponsesLoading: PropTypes.bool.isRequired,
        showAnswers: PropTypes.func.isRequired,
    };

    static defaultProps = {
        answers: [],
        nextTopic: null,
        flaggingSuccess: false,
        flaggingFailed: false,
        updating: false,
        answersLoading: false,
    };

    state = {
        hovering: false,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.hovering !== nextState.hovering) {
            return true;
        }
        if (this.props !== nextProps || !shallowEqual(this.props, nextProps)) {
            return true;
        }
        return false;
    }

    saveAnswer = (data) => {
        const {
            addAnswerAction,
            topic: { id },
        } = this.props;
        addAnswerAction({
            ...data,
            topicId: id,
        });
    };

    nextQuestion = (currentId, nextId) => {
        const {
            showNextQuestion,
            hasAnswered,
        } = this.props;

        showNextQuestion({ currentId, nextId, hasAnswered });
    };

    changeFilter = (value) => {
        const { navigateAction } = this.props;
        navigateAction({ search: `?orderby=${value}` });
    };

    expiresText = expirationDate => `${expires} ${moment(expirationDate).fromNow()}`;

    hoverHandler = (hovering) => {
        const {
            answers,
            areResponsesShown,
            showResponsesLoading,
        } = this.props;
        this.setState({
            hovering: answers.length &&
                !areResponsesShown &&
                !showResponsesLoading &&
                hiddenAnswersEnabled &&
                hovering,
        });
    };

    showAnswers = () => {
        const {
            topic: {
                id: topicId,
            },
            areResponsesShown,
            showResponsesLoading,
            showAnswers,
            answers,
        } = this.props;

        if (!areResponsesShown &&
            !showResponsesLoading &&
            hiddenAnswersEnabled &&
            answers.length
        ) {
            showAnswers({ topicId });
            this.setState({
                hovering: false,
            });
        }
    };

    renderNextQuestion = ({
        loadingNextTopic,
        nextTopic,
        topicId,
        personalized,
    }) => {
        if (personalized) {
            return (
                <Loading in={!loadingNextTopic}>
                    {nextTopic !== null &&
                        <Grid>
                            <Grid.Column floated="right" mobile={16} tablet={6} computer={6}>
                                <Button onClick={() => this.nextQuestion(topicId, nextTopic.id)}>
                                    <div className="next-topic-left">
                                        <div className="next-topic-label">{nextQuestion}</div>
                                        <div className="next-topic-title">{nextTopic.title}</div>
                                    </div>
                                    <Icon name="chevron right" className="next-topic-chevron" />
                                </Button>
                            </Grid.Column>
                        </Grid>
                    }
                </Loading>
            );
        }

        return <Fragment />;
    };

    render() {
        const {
            topic: {
                id: topicId,
                title,
                description,
                expirationDate,
                rewarded,
                personalized = false,
                useNpiMap,
                author: {
                    firstName,
                    lastName,
                    specialty,
                } = {},
            },
            nextTopic,
            rewards,
            flaggingSuccess,
            flaggingFailed,
            orderby,
            answers,
            topicLoaded,
            topicExpired,
            hasAnswered,
            // meta
            updating,
            loadingNextTopic,
            answersLoading,
            areResponsesShown,
            showResponsesLoading,
        } = this.props;

        const {
            hovering,
        } = this.state;

        const nextSpecialtyParams = {
            loadingNextTopic,
            nextTopic,
            topicId,
            personalized,
        };

        let content;
        if (answers.length) {
            if (redirectedUser || (areResponsesShown || !hiddenAnswersEnabled)) {
                const answersList = answers.map(ans => (<Answer
                    topicExpired={topicExpired}
                    key={ans.id}
                    answer={ans}
                />));
                content = (
                    <Fragment>
                        <div className="header">
                            <Header className="section-title">
                                {responsesHeader}
                            </Header>
                            <Button.Group className="sorter" size="large">
                                <Button
                                    className={orderby === 'date' ? 'active' : ''}
                                    onClick={() => this.changeFilter('date')}
                                    content={orderDate}
                                />
                                <Button
                                    className={orderby === 'rating' ? 'active' : ''}
                                    onClick={() => this.changeFilter('rating')}
                                    content={orderRating}
                                />
                            </Button.Group>
                        </div>
                        <Comment.Group size="large">
                            <FlipMove
                                duration={500}
                                easing="ease-in-out"
                                typeName={null}
                            >
                                {answersList}
                            </FlipMove>
                        </Comment.Group>
                    </Fragment>);
            } else if (!areResponsesShown && hiddenAnswersEnabled) {
                content = (
                    <Fragment>
                        <span>
                            {!showResponsesLoading &&
                                <Header
                                    size="tiny"
                                    textAlign="center"
                                    className="show-more"
                                >
                                    <span id="show-answers" className={`expand-text ${hovering ? 'hover' : ''}`}>{viewResponses}</span>
                                    <span>
                                        <Button
                                            id="show-answers-down-button"
                                            icon={<Icon name="angle down" />}
                                            inline="true"
                                            className={`expand-button ${hovering ? 'hover' : ''}`}
                                        />
                                    </span>
                                </Header>
                            }
                        </span>
                    </Fragment>
                );
            }
        } else {
            const answerLabel = hasAnswered ? yourResponseOnly : noResponses;
            content = (
                <Header size="tiny" textAlign="center">
                    <span className="no-responses">{answersLoading || answerLabel}</span>
                </Header>
            );
        }
        return (
            <div className="qa-page">
                <NoticeModal />
                <SuccessMessage isOpen={flaggingSuccess} />
                <ErrorMessage isOpen={flaggingFailed} />
                <div className="page-container question">
                    {topicExpired &&
                        <Message
                            info
                            icon="info"
                            content={
                                <div className="expired-message">
                                    {expiredQuestionMsgParts[0]}
                                    {moment(expirationDate).format('L')}
                                    {expiredQuestionMsgParts[1]}
                                    <Link
                                        to={links.infoPages.faq}
                                        href={links.infoPages.faq}
                                    >
                                        {faq}
                                    </Link>
                                    {expiredQuestionMsgParts[2]}
                                </div>}
                        />
                    }
                    <div className="question-item">
                        <LoadingIndicator active={!topicLoaded} />
                        <div className="question-relative-duration">
                            <Loading in={topicLoaded}>
                                <QuestionRewardBadge
                                    rewardable={useNpiMap || (rewards.earned < rewards.max)}
                                    rewarded={rewarded}
                                    isQuestionDetail
                                    hasExpired={topicExpired}
                                />
                                <span className="text">{!topicExpired && topicLoaded && this.expiresText(expirationDate)}</span>
                            </Loading>
                        </div>
                        <div className="question-title">
                            <Loading in={topicLoaded} size="medium">
                                {title}
                            </Loading>
                        </div>
                    </div>
                    <div className="question-content-full">
                        <Loading in={topicLoaded} size="large" rows={1} >
                            <div>{topicLoaded && description}</div>
                        </Loading>
                    </div>
                    <div className="question-author">
                        <Loading in={topicLoaded}>
                            <div>
                                {topicLoaded && `${firstName} ${lastName} `}
                                {topicLoaded && specialty && `• ${specialty}`}
                            </div>
                        </Loading>
                    </div>
                    <div className="question-next">
                        {this.renderNextQuestion(nextSpecialtyParams)}
                    </div>
                </div>
                <OwnAnswer
                    topicId={topicId}
                    onSave={this.saveAnswer}
                    topicExpired={topicExpired}
                    updating={updating}
                />
                <div
                    className={
                        `page-container thread ${hovering ? 'hover' : ''
                        } ${!areResponsesShown &&
                            !showResponsesLoading &&
                            hiddenAnswersEnabled &&
                            answers.length ? 'cursor padding' : ''
                        } ${!answers.length ? 'padding' : ''
                        }`
                    }
                    onMouseEnter={() => this.hoverHandler(true)}
                    onMouseLeave={() => this.hoverHandler(false)}
                    onClick={this.showAnswers}
                    onKeyDown={this.showAnswers}
                    role="button"
                    tabIndex={0}
                >
                    <LoadingIndicator
                        active={answersLoading || showResponsesLoading}
                    />
                    {content}
                </div>
            </div>);
    }
}

export default Thread;
