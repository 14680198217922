import React from 'react';
import { Root, Image, Fallback } from '@radix-ui/react-avatar';
import PropTypes from 'prop-types';

const Avatar = ({ imageUrl, initials }) => (
    <Root className="inline-flex items-center justify-center align-middle overflow-hidden select-none w-8 h-8 rounded-full">
        <Image
            className="w-full h-full object-cover"
            src={imageUrl}
            alt="Profile"
        />
        <Fallback className="body-s text-purple-400 font-bold w-full h-full flex items-center justify-center bg-purple-gray-200 text-center">
            {initials}
        </Fallback>
    </Root>
);

Avatar.propTypes = {
    imageUrl: PropTypes.string,
    initials: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
    imageUrl: '',
};


export default Avatar;
