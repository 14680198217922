import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';
import {
    UPDATE_QUESTION,
    UPDATE_QUESTION_COMPLETED,
    UPDATE_QUESTION_FAILED,
    DELETE_QUESTION,
    DELETE_QUESTION_COMPLETED,
    DELETE_QUESTION_FAILED,
    LOAD_QUESTIONS,
    LOAD_SEARCH_COMPLETED,
    LOAD_QUESTIONS_COMPLETED,
    LOAD_QUESTIONS_FAILED,
    LOAD_QUESTION_COMPLETED,
    LOAD_NEXT_QUESTION,
    LOAD_NEXT_QUESTION_COMPLETED,
    LOAD_NEXT_QUESTION_FAILED,
    FLAG_QUESTION,
    FLAG_QUESTION_COMPLETED,
    FLAG_QUESTION_FAILED,
    CREATE_QUESTION_COMPLETED,
    CREATE_QUESTION_FAILED,
    CLEAR_QUESTIONS,
    LOAD_QUESTION,
    RESET_FLAGGED_STATE,
    LOAD_RELATED_USERS_COUNT_COMPLETED,
    LOAD_ELIGIBLE_USERS_COUNT_COMPLETED,
    LOAD_QUESTIONS_NPIS,
    LOAD_QUESTIONS_NPIS_COMPLETED,
    LOAD_QUESTIONS_NPIS_FAILED,
    UPDATE_QUESTIONS_NPIS,
    UPDATE_QUESTIONS_NPIS_COMPLETED,
    UPDATE_QUESTIONS_NPIS_FAILED,
} from './actionTypes';
import { mergeArraysByKey } from '../../utils/data';

const initialState = Immutable.from({
    currentTopic: -1,
    topics: [],
    loadingFailed: false,
    loading: false,
    loaded: false,
    flagging: false,
    flaggingSuccess: false,
    flaggingFailed: false,
    flaggingError: null,
    saving: false,
    deleting: false,
    total: 0,
    relatedUsersCount: {},
    eligibleUsersCount: {},
    questionsNpis: [],
    questionsNpisLoading: false,
    questionsNpisError: null,
    questionsNpisUpdating: false,
});

const reducer = handleActions({
    [UPDATE_QUESTION]: state => state.set('saving', true),
    [UPDATE_QUESTION_COMPLETED]:
        (state, { payload: { question: { id: questionId, ...question } } }) => {
            const questionIndex = state.topics.findIndex(({ id }) => questionId === id);
            let topics;
            if (questionIndex === -1) {
                (topics = state.topics.concat([{ id: questionId, ...question }]));
            } else {
                const oldQuestion = state.topics[questionIndex];
                topics = state.topics.set(
                    questionIndex,
                    { id: questionId, ...oldQuestion, ...question },
                );
            }
            return state.merge({
                topics,
                saving: false,
            });
        },
    [UPDATE_QUESTION_FAILED]: (state, { payload: { error } }) =>
        state.merge({ saving: false, loadingError: error }),
    [CREATE_QUESTION_COMPLETED]: (state, { payload: { question } }) => state.merge({
        topics: [...state.topics, question],
        saving: false,
    }),
    [CREATE_QUESTION_FAILED]: (state, { payload: { error } }) =>
        state.merge({ saving: false, loadingError: error }),
    [DELETE_QUESTION]: state => state.set('deleting', true),
    [DELETE_QUESTION_COMPLETED]: (state, { payload: { id } }) => {
        const questions = state.topics.filter(({ id: ID }) => id !== ID);
        return state.merge({
            topics: questions,
            deleting: false,
        });
    },
    [DELETE_QUESTION_FAILED]: state => state.set('deleting', false),
    [LOAD_QUESTIONS]: state =>
        state.merge({ loading: true, loadingFailed: false, loadingError: null }),
    [LOAD_QUESTION]: (state, { payload: { id } }) => state.merge({
        flaggingSuccess: false,
        flaggingFailed: false,
        loading: true,
        currentTopic: id,
    }),
    [LOAD_QUESTION_COMPLETED]: (state, { payload: { topic } }) => state.merge({
        topics: mergeArraysByKey(state.topics, [topic]),
        loading: false,
        loaded: true,
    }),
    [LOAD_NEXT_QUESTION]: state => state.merge({
        loadingNextTopic: true,
    }),
    [LOAD_NEXT_QUESTION_COMPLETED]: (state, { payload: { topic } }) => state.merge({
        nextTopic: topic,
        loadingNextTopic: false,
    }),
    [LOAD_NEXT_QUESTION_FAILED]: state => state.merge({
        nextTopic: null,
        loadingNextTopic: false,
    }),
    [LOAD_QUESTIONS_COMPLETED]: (state, { payload: { topics, total } }) => {
        const mergedTopics = mergeArraysByKey(state.topics, topics);
        return state.merge({
            topics: mergedTopics,
            loading: false,
            loaded: true,
            total,
        });
    },
    [LOAD_SEARCH_COMPLETED]: (state, { payload: { topics, total } }) => state.merge({
        topics,
        loading: false,
        loaded: true,
        total,
    }),
    [LOAD_QUESTIONS_FAILED]: (state, { payload: { error } }) =>
        state.merge({ loading: false, loadingFailed: true, loadingError: error }),
    [FLAG_QUESTION]: state =>
        state.merge({
            flagging: true,
            flaggingFailed: false,
            flaggingError: null,
        }),
    [FLAG_QUESTION_COMPLETED]: state =>
        state.merge({
            flagging: false,
            flaggingFailed: false,
            flaggingError: null,
            flaggingSuccess: true,
        }),
    [FLAG_QUESTION_FAILED]: (state, { payload: { error } }) =>
        state.merge({
            flagging: false,
            flaggingFailed: true,
            flaggingError: error,
            flaggingSuccess: false,
        }),
    [RESET_FLAGGED_STATE]: state =>
        state.merge({
            flagging: false,
            flaggingFailed: false,
            flaggingError: null,
            flaggingSuccess: false,
        }),
    [CLEAR_QUESTIONS]: state => state.set('topics', []),
    [LOAD_RELATED_USERS_COUNT_COMPLETED]: (state, { payload: { questionId, userCount } }) =>
        state.setIn(['relatedUsersCount', questionId], userCount),
    [LOAD_ELIGIBLE_USERS_COUNT_COMPLETED]: (state, { payload: { questionId, userCount } }) =>
        state.setIn(['eligibleUsersCount', questionId], userCount),
    [LOAD_QUESTIONS_NPIS]: state => state.set('questionsNpisLoading', true),
    [LOAD_QUESTIONS_NPIS_COMPLETED]: (state, { payload: { questionsNpis } }) =>
        state.merge({
            questionsNpis,
            questionsNpisLoading: false,
        }),
    [LOAD_QUESTIONS_NPIS_FAILED]: (state, { payload: { error } }) =>
        state.merge({
            questionsNpisError: error,
            questionsNpisLoading: false,
        }),
    [UPDATE_QUESTIONS_NPIS]: state => state.set('questionsNpisUpdating', true),
    [UPDATE_QUESTIONS_NPIS_COMPLETED]: state => state.set('questionsNpisUpdating', false),
    [UPDATE_QUESTIONS_NPIS_FAILED]: state => state.set('questionsNpisUpdating', false),
}, initialState);

export default reducer;
