import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import {
    GET_ATTACHED_PROGRAMS,
    GET_UNATTACHED_PROGRAMS,
    PROGRAM_PRIORITY_CHANGE,
    ATTACH_PROGRAMS,
    DETACH_PROGRAM,
    DETACH_ALL_PROGRAMS,
} from './actionTypes';
import {
    getAttachedProgramsCompleted,
    getAttachedProgramsFailed,
    getUnattachedProgramsCompleted,
    getUnattachedProgramsFailed,
    attachProgramsCompleted,
    attachProgramsFailed,
    detachProgramCompleted,
    detachProgramFailed,
    detachAllProgramsCompleted,
    detachAllProgramsFailed,
    programPriorityChangeCompleted,
    programPriorityChangeFailed,
} from './actions';
import {
    getAttachedPrograms,
    getUnattachedPrograms,
    attachPrograms as attachProgramsApi,
    detachProgram as detachProgramApi,
    detachAllPrograms as detachAllProgramsApi,
    programPriorityChange as programPriorityChangeApi,
} from './api';
import { closeAttachModal } from '../../containers/Moderator/Questions/QuestionDetails/actions';
import { hideModal, showModalLoading } from '../../services/sharedUIActions';
import { topicProgramsEnabled } from '../../environment';

function* loadAttachedPrograms({ payload: { topicId } }) {
    try {
        if (topicProgramsEnabled) {
            const { programs } = yield call(getAttachedPrograms, topicId);
            if (programs) {
                const filteredPrograms = programs.map(({
                    topicPrograms,
                    ...rest
                }) => ({
                    priority: topicPrograms[0].priority,
                    ...rest,
                }));
                yield put(getAttachedProgramsCompleted({ programs: filteredPrograms }));
            }
        }
    } catch (error) {
        yield put(getAttachedProgramsFailed({ error }));
    }
}

function* loadUnattachedPrograms({ payload: { topicId } }) {
    try {
        const { programs } = yield call(getUnattachedPrograms, topicId);
        if (programs) {
            yield put(getUnattachedProgramsCompleted({ programs, topicId }));
        }
    } catch (error) {
        yield put(getUnattachedProgramsFailed({ error }));
    }
}

function* attachPrograms({ payload: { topicId, programs } }) {
    try {
        const { programs: newPrograms } = yield call(
            attachProgramsApi,
            topicId,
            programs.map(({ id }) => id),
        );

        if (newPrograms.length) {
            yield put(attachProgramsCompleted({ programs, newPrograms }));
        }
    } catch (error) {
        yield put(attachProgramsFailed({ error }));
    }
    yield put(closeAttachModal());
}

function* detachProgram({ payload: { topicId, programId } }) {
    try {
        const { success } = yield call(detachProgramApi, topicId, programId);
        if (success) {
            yield put(detachProgramCompleted({ programId }));
        }
    } catch (error) {
        yield put(detachProgramFailed({ error }));
    }
}

function* detachAllPrograms({ payload: { topicId } }) {
    try {
        yield put(showModalLoading());
        const { success } = yield call(detachAllProgramsApi, topicId);
        if (success) {
            yield put(detachAllProgramsCompleted());
        }
    } catch (error) {
        yield put(detachAllProgramsFailed({ error }));
    }

    yield put(hideModal());
}

function* priorityChange({ payload: { topicId, programId, change } }) {
    try {
        const { success } = yield call(programPriorityChangeApi, topicId, programId, change);
        if (success) {
            yield put(programPriorityChangeCompleted({ programId, change }));
        }
    } catch (error) {
        yield put(programPriorityChangeFailed({ error }));
    }
}

function* setQuestionsSaga() {
    yield all([
        takeEvery(GET_ATTACHED_PROGRAMS, loadAttachedPrograms),
        takeEvery(GET_UNATTACHED_PROGRAMS, loadUnattachedPrograms),
        takeLatest(ATTACH_PROGRAMS, attachPrograms),
        takeLatest(DETACH_PROGRAM, detachProgram),
        takeLatest(DETACH_ALL_PROGRAMS, detachAllPrograms),
        takeLatest(PROGRAM_PRIORITY_CHANGE, priorityChange),
    ]);
}

export default setQuestionsSaga;
