import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Segment, Grid, Image, Icon } from 'semantic-ui-react';
import giftCard from '../../containers/My/Rewards/amazon_gift_card.png';
import localization from '../../localization';
import links from '../../links';

const {
    my: {
        rewards: {
            amazonGiftCard,
            answeredQuestionsAddon,
        },
    },
} = localization;

const Reward = ({
    giftCardValue,
    questionId,
    questionTitle,
    earnedAt,
}) => (
    <Segment>
        <Grid>
            <Grid.Row only="computer">
                <Grid.Column width="2" className="vertical-middle-content">
                    <Image src={giftCard} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={14} className="vertical-middle-content">
                    <h6 className="gift-card-header">${giftCardValue} {amazonGiftCard}</h6>
                    <div className="gift-card-content">
                        {moment(earnedAt).format('MM/DD/YYYY')}, {answeredQuestionsAddon}&nbsp;
                        <Link to={links.topics.view.replace(':id', questionId)} href={links.topics.view.replace(':id', questionId)}>{questionTitle}</Link>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row only="mobile tablet">
                <Grid.Column width="16" className="vertical-middle-content">
                    <h6 className="gift-card-header">
                        <Icon inverted name="gift" color="blue" size="large" />
                        {amazonGiftCard} - ${giftCardValue}
                    </h6>
                    <div className="gift-card-content">
                        {moment(earnedAt).format('MM/DD/YYYY')}, {answeredQuestionsAddon}:&nbsp;
                        <p>
                            <Link to={links.topics.view.replace(':id', questionId)} href={links.topics.view.replace(':id', questionId)}>{questionTitle}</Link>
                        </p>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
);


Reward.defaultProps = {
    earnedAt: '',
};

Reward.propTypes = {
    giftCardValue: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTitle: PropTypes.string.isRequired,
    earnedAt: PropTypes.string,
};

export default Reward;
