import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NavigationItem from '../NavigationItem';
import localization from '../../../localization';
import links from '../../../links';
import Tooltip from '../../Tooltip';
import { Permissions } from '../../../permissions';

const {
    navigationLinks: {
        myFeed,
        otherQuestions,
        publicQuestions,
        allQuestions,
        users,
        programs,
        search,
        home,
        submitConsensus,
        caseConsensuses,
        resources,
        submitPatientCase,
    },
} = localization;

const mapPermissionToNavConfig = {
    [Permissions.CONSENSUS_MD_BETA]: {
        key: 1,
        label: home,
        url: links.consensusMd,
        disabled: false,
        isExternal: true,
        newTab: false,
        action: null,
    },
    [Permissions.PATIENT_CASES_MY_SPECIALTY]: {
        key: 2,
        label: myFeed,
        url: links.topics.myFeed,
        disabled: false,
        action: null,
        notificationsEnabled: true,
    },
    [Permissions.PATIENT_CASES_OTHER_SPECIALTIES]: {
        key: 3,
        label: otherQuestions,
        url: links.topics.otherFeed,
        disabled: false,
        action: null,
    },
    [Permissions.USER_CONTENT]: {
        key: 4,
        label: caseConsensuses,
        url: links.consensus.all,
        disabled: false,
        action: null,
    },
    [Permissions.SUMMARY_SUBMISSION]: {
        key: 5,
        label: submitConsensus,
        url: links.moderator.consensus.submit,
        disabled: false,
        action: null,
    },
    [Permissions.ACTIVE_PATIENT_CASE_MANAGEMENT]: {
        key: 6,
        label: publicQuestions,
        url: links.topics.myFeed,
        disabled: false,
        action: null,
    },
    [Permissions.ALL_PATIENT_CASES_MANAGEMENT]: {
        key: 7,
        label: allQuestions,
        url: links.moderator.questions.index,
        disabled: false,
        action: null,
    },
    [Permissions.PROGRAMS]: {
        key: 8,
        label: programs,
        url: links.moderator.globalPrograms.index,
        disabled: false,
        action: null,
    },
    [Permissions.USER_MANAGEMENT]: {
        key: 9,
        label: users,
        url: links.moderator.users.index,
        disabled: false,
        action: null,
    },
    [Permissions.SEARCH]: {
        key: 10,
        label: search,
        url: links.moderator.search.index,
        disabled: false,
        action: null,
    },
    [Permissions.DISEASES_AND_TREATMENT_INFORMATION]: {
        key: 11,
        label: resources,
        url: links.resources,
        disabled: false,
        isExternal: true,
        action: null,
    },
    [Permissions.PATIENT_CASE_SUBMISSION]: {
        key: 12,
        label: submitPatientCase,
        url: links.submitPatientCase,
        disabled: false,
        isExternal: true,
        newTab: true,
        action: null,
    },
};

export const generateNavConfiguration = (permissions) => {
    const navConfiguration = permissions
        .map(permission => mapPermissionToNavConfig[permission])
        .filter(Boolean);
    return [...navConfiguration].sort((a, b) => a.key - b.key);
};

const NavLinks = ({
    permissions,
    pathname,
    showTooltips,
}) => {
    const navConfiguration = generateNavConfiguration(permissions);

    const navItems = navConfiguration.map(({
        key,
        label,
        url,
        disabled,
        action,
        notificationsEnabled = false,
    }) => (
        label === myFeed && showTooltips
            ? <Tooltip
                key={key}
                trigger={<NavigationItem
                    label={label}
                    url={url}
                    action={action}
                    pathname={pathname}
                    notificationsEnabled={notificationsEnabled}
                />}
                name="finish-rewards"
            />
            : <NavigationItem
                label={label}
                url={url}
                key={key}
                disabled={disabled}
                action={action}
                pathname={pathname}
                notificationsEnabled={notificationsEnabled}
            />
    ));

    return (
        <Fragment>
            {navItems}
        </Fragment>);
};

NavLinks.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    pathname: PropTypes.string.isRequired,
    showTooltips: PropTypes.bool,
};

NavLinks.defaultProps = {
    showTooltips: false,
};

export default NavLinks;
