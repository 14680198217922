import { createAction } from 'redux-actions';
import {
    CREATE_USER_COMPLETED,
    CREATE_USER_FAILED,
    CREATE_USER,
    LOAD_USERS,
    LOAD_USERS_COMPLETED,
    LOAD_USERS_FAILED,
    LOAD_USER,
    LOAD_USER_COMPLETED,
    LOAD_USER_FAILED,
    UPDATE_USER,
    UPDATE_USER_COMPLETED,
    UPDATE_USER_FAILED,
    DELETE_USER,
    DELETE_USER_COMPLETED,
    DELETE_USER_FAILED,
    RESTORE_USER,
    RESTORE_USER_COMPLETED,
    RESTORE_USER_FAILED,
} from './actionTypes';

export const createUser = createAction(CREATE_USER);
export const createUserCompleted = createAction(CREATE_USER_COMPLETED);
export const createUserFailed = createAction(CREATE_USER_FAILED);

export const loadUsers = createAction(LOAD_USERS);
export const loadUsersCompleted = createAction(LOAD_USERS_COMPLETED);
export const loadUsersFailed = createAction(LOAD_USERS_FAILED);

export const loadUser = createAction(LOAD_USER);
export const loadUserCompleted = createAction(LOAD_USER_COMPLETED);
export const loadUserFailed = createAction(LOAD_USER_FAILED);

export const updateUser = createAction(UPDATE_USER);
export const updateUserCompleted = createAction(UPDATE_USER_COMPLETED);
export const updateUserFailed = createAction(UPDATE_USER_FAILED);

export const deleteUser = createAction(DELETE_USER);
export const deleteUserCompleted = createAction(DELETE_USER_COMPLETED);
export const deleteUserFailed = createAction(DELETE_USER_FAILED);

export const restoreUser = createAction(RESTORE_USER);
export const restoreUserCompleted = createAction(RESTORE_USER_COMPLETED);
export const restoreUserFailed = createAction(RESTORE_USER_FAILED);
