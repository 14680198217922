import { push, replace, go, goBack, goForward } from 'react-router-redux/actions';
import { routerReducer, LOCATION_CHANGE } from 'react-router-redux';
import { parse } from 'qs';
import * as selectors from './selectors';
import links from '../../links';

export { ID } from './config';
export {
    LOCATION_CHANGE,
    selectors,
};

export const handle404 = () => replace(links.notFound);

export { push, go, goBack, replace, goForward };

export const reducer = (state, action) => {
    let previousPathname;
    let location;
    let other;

    switch (action.type) {
        case LOCATION_CHANGE:
            previousPathname = state.location && state.location.pathname;
            ({ location, ...other } = routerReducer(state, action));
            return {
                location: {
                    ...location,
                    previousPathname,
                },
                ...other,
            };
        default:
            break;
    }
    return routerReducer(state, action);
};

export const urlParser = search => parse(search.substring(1));
