import React, { Fragment } from 'react';
import { QuestionProps } from '../../../../utils/propTypes';
import { convertStatusToString } from '../../../../utils/dates';
import QuestionCardContent from './QuestionCardContent/container';
import './style.scss';

const Question = ({
    answerCount,
    emailCount,
    status,
    changeFieldValue,
    ...props
}) => {
    const statusString = convertStatusToString(status);
    const statusClass = statusString.toLowerCase();

    let active = false;
    let scheduled = false;
    let expired = false;
    if (statusClass === 'active') {
        active = true;
    } else if (statusClass === 'scheduled') {
        scheduled = true;
    } else if (statusClass === 'expired') {
        expired = true;
    }

    const topicRelatedCount = !scheduled && (
        <Fragment>
            <div className="count answers">
                {answerCount} <span className="text">answers</span>
            </div>
            <div className="count answers">
                {emailCount || 0} <span className="text">emails</span>
            </div>
        </Fragment>
    );

    return (
        <div className="moderator-question-item">
            <div className="left-column">
                <button
                    className={`activity-tag ${statusClass}`}
                    onClick={() => changeFieldValue(active, scheduled, expired)}
                >
                    {statusString}
                </button>
                {topicRelatedCount}
            </div>
            <QuestionCardContent {...props} status={status} />
        </div>);
};

Question.propTypes = QuestionProps;

export default Question;
