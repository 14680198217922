import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from '@reduxjs/toolkit';
import { ID as DataID, QuestionsID } from '../../../../data';
import { questionsSelector } from './selectors';
import { deleteQuestion, loadQuestionsSearch } from '../../../../data/questions';
import QuestionListComponent from './component';
import localization from '../../../../localization';

const {
    admin: {
        questions: {
            list: {
                editQuestionSuccess,
                createQuestionSuccess,
                deleteQuestionSuccess,
            },
        },
    },
    notFoundPage: {
        message: redirectedMessage,
    },
} = localization;

class QuestionsList extends Component {
    static propTypes = {
        location: PropTypes.shape({}).isRequired,
    }

    constructor(props) {
        super(props);

        const {
            location: {
                state: {
                    updateSuccess,
                    createSuccess,
                    deleteSuccess,
                    redirected,
                } = {},
            },
        } = props;

        this.state = {
            ...this.initialState,
            updateSuccessVisible: updateSuccess,
            createSuccessVisible: createSuccess,
            deleteSuccessVisible: deleteSuccess,
            redirectMsgVisible: redirected,
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps({
        location: {
            state: {
                redirected,
            } = {},
        },
    }) {
        let stateChanged = false;
        const newState = {};

        const {
            redirectMsgVisible,
        } = this.state;
        if (redirected && !redirectMsgVisible) {
            newState.redirectMsgVisible = true;
            stateChanged = true;
        }

        if (stateChanged) {
            this.setState({
                ...this.initialState,
                ...newState,
            }, this.clearLocationState);
        }
    }

    initialState = {
        deleteSuccessVisible: null,
        redirectMsgVisible: false,
        updateSuccessVisible: null,
        createSuccessVisible: null,
    }

    hideMessages = () =>
        this.setState(this.initialState);

    render() {
        const {
            location: {
                state: locationState = {},
            },
            ...rest
        } = this.props;
        const {
            updateSuccessVisible,
            createSuccessVisible,
            deleteSuccessVisible,
            redirectMsgVisible,
        } = this.state;

        const msgVisible = updateSuccessVisible ||
            createSuccessVisible ||
            deleteSuccessVisible ||
            redirectMsgVisible;

        let msgContent;
        const msgOnDismiss = this.hideMessages;
        const additionalMsgProps = {};
        if (updateSuccessVisible) {
            msgContent = editQuestionSuccess;
            additionalMsgProps.positive = true;
        } else if (createSuccessVisible) {
            msgContent = createQuestionSuccess;
            additionalMsgProps.positive = true;
        } else if (deleteSuccessVisible) {
            additionalMsgProps.positive = true;
            msgContent = deleteQuestionSuccess;
        } else if (redirectMsgVisible) {
            additionalMsgProps.info = true;
            msgContent = redirectedMessage;
        }

        return (<QuestionListComponent
            locationState={locationState}
            msgContent={msgContent}
            msgHidden={!msgVisible}
            msgOnDismiss={msgOnDismiss}
            additionalMsgProps={additionalMsgProps}
            {...rest}
        />);
    }
}

const mapStateToProps = (state) => {
    const {
        [DataID]: {
            [QuestionsID]: {
                deleting,
                loading,
                total,
            },
        },
    } = state;
    return ({
        questions: questionsSelector(state),
        total,
        loading,
        deleting,
    });
};

export default compose(
    withRouter,
    connect(mapStateToProps, {
        deleteQuestion,
        loadQuestionsSearch,
    }),
)(QuestionsList);
