import { call, put, takeEvery, all, select, takeLatest, take } from 'redux-saga/effects';
import {
    UPDATE_ANSWER,
    LOAD_ANSWERS,
    DELETE_ANSWER,
    RATE_ANSWER,
    SHOW_ANSWERS,
} from './actions';
import { SHOW_PROGRAM } from '../../containers/Thread/actionTypes';
import {
    updateAnswerCompleted,
    updateAnswerFailed,
    loadAnswersCompleted,
    loadAnswersFailed,
    deleteAnswerCompleted,
    deleteAnswerFailed,
    rateAnswerCompleted,
    rateAnswerFailed,
    showAnswersCompleted,
    showAnswersFailed,
} from './index';
import {
    loadAnswers,
    createAnswer,
    deleteAnswer,
    updateAnswer,
    rateAnswer,
    createAnswersView,
} from './api';
import { LOAD_QUESTION_COMPLETED } from '../../data/questions/actionTypes';
import { ID as SessionID } from '../../services/session';
import { selectors as questionSelectors } from '../questions';
import { prepareProgram } from '../../containers/Thread/actions';
import { actionTypes } from '../../data/programs';
import { ID as DataID } from '../../data';
import { showAnswersInterstitialEnabled } from '../../environment';
import localization from '../../localization';

const {
    thread: {
        programNotice: {
            showAnswersText,
        },
    },
} = localization;

function* loadSaga({ payload: { topicId } }) {
    try {
        const {
            data: {
                comments,
                showResponses,
            },
        } = yield call(loadAnswers, topicId);
        yield put(loadAnswersCompleted({ answers: comments, showResponses }));
    } catch (error) {
        yield put(loadAnswersFailed({ error }));
    }
}

function* updateAnswerSaga({ payload: { id, ...answer } }) {
    try {
        let updatedAnswer;

        if (id) {
            ({ data: updatedAnswer } = yield call(updateAnswer, id, answer));
            const {
                firstName,
                lastName,
                specialty,
                id: userId,
            } = yield select(state => state[SessionID]);
            updatedAnswer = {
                ...updatedAnswer,
                modifiedByUser: {
                    firstName,
                    lastName,
                    id: userId,
                    specialty: {
                        name: specialty,
                    },
                },
            };
        } else {
            const topicViewId =
                yield select(state => questionSelectors.currentTopicViewIdSelector(state));

            const answerPayload = {
                topicViewId,
                ...answer,
            };
            ({ data: updatedAnswer } = yield call(createAnswer, answerPayload));
            const {
                firstName,
                lastName,
                specialty,
                id: userId,
            } = yield select(state => state[SessionID]);
            updatedAnswer = {
                ...updatedAnswer,
                createdByUser: {
                    firstName,
                    lastName,
                    id: userId,
                    specialty: {
                        name: specialty,
                    },
                },
            };
        }
        // isNew is used when discrimination between updating and creating is needed
        yield put(updateAnswerCompleted({ answer: updatedAnswer, isNew: !id }));
    } catch (error) {
        yield put(updateAnswerFailed({ error }));
    }
}

function* deleteAnswerSaga({ payload: { id } }) {
    try {
        const { data } = yield call(deleteAnswer, id);
        yield put(deleteAnswerCompleted(data));
    } catch (error) {
        yield put(deleteAnswerFailed({ error }));
    }
}

function* rateAnswerSaga({ payload: { id, rating } }) {
    try {
        yield call(rateAnswer, id, rating);
        yield put(rateAnswerCompleted({ id, rating }));
    } catch (error) {
        yield put(rateAnswerFailed({ error }));
    }
}

function* completeShowAnswers(topicId, topicViewId) {
    const { success } = yield call(createAnswersView, {
        topicId,
        topicViewId,
    });
    yield put(showAnswersCompleted({ showResponses: success }));
}

function* createAnswersViewSaga({ payload: { topicId } }) {
    try {
        const isQuestionLoading = yield select(state => questionSelectors.questionLoading(state));
        if (isQuestionLoading) {
            yield take(LOAD_QUESTION_COMPLETED);
        }

        const topicViewId =
            yield select(state => questionSelectors.currentTopicViewIdSelector(state));


        const shouldShowInterstitial = !parseInt(sessionStorage.getItem('sai_interstitial_shown'), 10);

        let id = null;
        ({ id } = yield select(({
            [DataID]: {
                [actionTypes.ID]: { program = {} },
            },
        }) => program));

        const showProgram = shouldShowInterstitial &&
                            id !== 0 &&
                            showAnswersInterstitialEnabled;

        if (showProgram) {
            yield put(prepareProgram({ notice: showAnswersText }));
            yield take(SHOW_PROGRAM);
        }
        yield call(completeShowAnswers, topicId, topicViewId);
    } catch (error) {
        yield put(showAnswersFailed({ error }));
    }
}

function* setAnswersSaga() {
    yield all([takeEvery(LOAD_ANSWERS, loadSaga),
        takeEvery(UPDATE_ANSWER, updateAnswerSaga),
        takeEvery(DELETE_ANSWER, deleteAnswerSaga),
        takeEvery(RATE_ANSWER, rateAnswerSaga),
        takeLatest(SHOW_ANSWERS, createAnswersViewSaga),
    ]);
}

export default setAnswersSaga;
