import Immutable from 'seamless-immutable';
import { handleActions } from 'redux-actions';
import {
    OPEN_ANSWER_EDIT_FORM,
    OPEN_REPLY_FORM,
    CLEAR_LAST_UPDATED_COMMENT,
    MARK_ANSWER_AS_NEW,
    SHOW_NOTICE,
    HIDE_NOTICE,
} from './actionTypes';
import {
    UPDATE_ANSWER_COMPLETED,
    UPDATE_ANSWER,
    RATE_ANSWER,
    DELETE_ANSWER,
} from '../../data/answers/actions';
import { LOCATION_CHANGE } from '../../services/navigation';

const initialState = Immutable.from({
    commentedAnswer: null,
    editedAnswer: null,
    lastUpdatedCommentId: null,
    ratedAnswer: {
        pressedRating: null,
        answerId: null,
    },
    deletedComment: null,
    addNoticeVisible: false,
    noticeCustomMessage: '',
});

export default handleActions({
    [OPEN_REPLY_FORM]: (state, { payload: id }) => {
        const { commentedAnswer } = state;
        const commentedAnswerId = commentedAnswer !== id && id;
        return state.merge({
            commentedAnswer: commentedAnswerId,
            editedAnswer: null,
        });
    },
    [OPEN_ANSWER_EDIT_FORM]: (state, { payload: id }) => {
        const { editedAnswer } = state;
        const editedAnswerId = editedAnswer !== id && id;
        return state.merge({
            commentedAnswer: null,
            editedAnswer: editedAnswerId,
        });
    },
    [UPDATE_ANSWER]: state => state.set('lastUpdatedCommentId', null),
    [UPDATE_ANSWER_COMPLETED]: (state, { payload: { answer: { id } } }) => state.merge({
        commentedAnswer: null,
        editedAnswer: null,
        lastUpdatedCommentId: id,
    }),
    [CLEAR_LAST_UPDATED_COMMENT]: state => state.set('lastUpdatedCommentId', null),
    [RATE_ANSWER]: (state, {
        payload: {
            rating,
            id,
        },
    }) => state.set('ratedAnswer', { pressedRating: rating, answerId: id }),
    [DELETE_ANSWER]: (state, { payload: { id } }) => state.set('deletedAnswer', id),
    [MARK_ANSWER_AS_NEW]: (state, { payload: { id } }) => state.set('lastUpdatedCommentId', id),
    [SHOW_NOTICE]: (state, { payload: { noticeText } }) => state.merge({
        addNoticeVisible: true,
        noticeText,
    }),
    [HIDE_NOTICE]: state => state.merge({
        addNoticeVisible: false,
        noticeText: '',
    }),
    [LOCATION_CHANGE]: state => state.merge({
        addNoticeVisible: false,
        noticeText: '',
    }),
}, initialState);
