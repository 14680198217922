import React, { Fragment } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './style.scss';

const InfoBox = ({
    loading,
    totalCount,
    totalText,
    detailedCount,
    detailedText,
    totalCountPrefix,
}) => (
    <div className="info-box">
        {
            !loading ? (
                <Fragment>
                    <div>{totalCountPrefix}{totalCount}</div>
                    <div>{totalText}</div>
                    {detailedText ? (
                        <div>{`${detailedCount} ${detailedText}`}</div>
                    ) : <div className="empty-container" /> }
                </Fragment>
            ) : (
                <Loader active inline="centered" style={{ fontSize: '1em' }} />
            )
        }
    </div>
);

InfoBox.propTypes = {
    loading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    totalText: PropTypes.string.isRequired,
    detailedCount: PropTypes.number,
    detailedText: PropTypes.string,
    totalCountPrefix: PropTypes.string,
};

InfoBox.defaultProps = {
    detailedCount: 0,
    detailedText: '',
    totalCountPrefix: '',
};

export default InfoBox;
