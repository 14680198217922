import { connect } from 'react-redux';
import ProgramsList from './component';
import { showModal } from '../../../../../services/sharedUIActions';
import { openAttachModal, openDetachModal } from '../actions';
import { programPriorityChange, getUnattachedPrograms, detachAllPrograms, clearErrorMessage } from '../../../../../data/topicPrograms';
import { programsStateSelector, attachedProgramsSelector } from '../selectors';

const mapStateToProps = (state) => {
    const {
        priorityUpLoading,
        priorityDownLoading,
        programDetaching,
        programInActionId,
        loadingPrograms,
        attachingError,
        detachingError,
        priorityChangeError,
    } = programsStateSelector(state);
    const attachedHcmp = attachedProgramsSelector(state);
    return {
        attachedHcmp,
        priorityUpLoading,
        priorityDownLoading,
        programDetaching,
        programInActionId,
        loadingPrograms,
        attachingError,
        detachingError,
        priorityChangeError,
    };
};

const mapDispatchToProps = {
    openAttachModal,
    programPriorityChange,
    openDetachModal,
    getUnattachedPrograms,
    detachAllPrograms,
    clearErrorMessage,
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsList);
