import { stringify, parse } from 'qs';
import { put, all, takeEvery, throttle, select } from 'redux-saga/effects';
import { LOAD_QUESTIONS_SEARCH } from '../../../../data/questions/actionTypes';
import { DOWNLOAD_TARGET_LIST_FAILED } from '../../../../data/campaigns/actionTypes';
import { replace } from '../../../../services/navigation';
import { loadQuestions } from '../../../../data/questions';

const selectLocationSearch = state => state.router.location.search;

function* questionSearchSaga({
    payload: {
        append = false,
        values: {
            term,
            count,
            pageNumber = 1,
            specs,
            status,
        },
    },
}) {
    const previousSearch = yield select(selectLocationSearch);
    const {
        term: pTerm,
        count: pCount,
        pageNumber: pPageNumber,
        specs: pSpecs,
        status: pStatus,
    } = parse(previousSearch.substring(1));
    const searchOptions = {
        term: term === undefined ? pTerm : term,
        count: count || pCount,
        specs: specs || pSpecs,
        status: status || pStatus,
    };

    if (append) {
        yield put(loadQuestions({
            pageNumber: pageNumber || pPageNumber,
            ...searchOptions,
            internal: true,
            append,
        }));
    } else {
        yield put(replace({ search: `?${stringify(searchOptions)}` }));
    }
}

function* setDownloadErrorMessage({ type }) {
    window.scrollTo(0, 0);
    yield put(replace({
        state: {
            downloadFailedShow: type === DOWNLOAD_TARGET_LIST_FAILED,
        },
    }));
}

export default function* UserListSaga() {
    yield all([
        throttle(2000, LOAD_QUESTIONS_SEARCH, questionSearchSaga),
        takeEvery(DOWNLOAD_TARGET_LIST_FAILED, setDownloadErrorMessage),
    ]);
}
