import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import './style.scss';

const CharactersLeftWarning = ({
    infoStep,
    warningStep,
    msg,
    value = '',
}) => {
    const valueLength = value.length;
    let iconName;
    let iconColor;
    let className = '';
    if (valueLength < infoStep) {
        iconName = 'check';
        iconColor = 'green';
        className = 'info';
    } else if (valueLength < warningStep) {
        iconName = 'warning';
        iconColor = 'yellow';
        className = 'warning';
    } else {
        iconName = 'warning sign';
        iconColor = 'orange';
        className = 'critical';
    }
    return (
        <div className="characters-left" >
            <Icon name={iconName} color={iconColor} />
            <span className={`warning-text ${className}`}>{msg}</span>
        </div>
    );
};

CharactersLeftWarning.propTypes = {
    msg: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    infoStep: PropTypes.number.isRequired,
    warningStep: PropTypes.number.isRequired,
};

export default CharactersLeftWarning;
