import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { permissionsSelector, roleNameSelector, fetchStateSelector, postAuthRedirectUrlSelector, FetchState } from '../../services/session/selectors';
import { chooseRedirectPath } from '../../RouteConfig';
import links from '../../links';

const LoadingIndicator = () => (
    <Dimmer page active>
        <Loader />
    </Dimmer>
);

const Auth0ProtectedRouteHOC = ({
    component: Component,
    expectedPermissions,
    roleName,
    actualPermissions,
    redirectUrl,
    initialAuth0Redirect,
    fetchState,
    ...props
}) => {
    if (fetchState === FetchState.failure) {
        return (
            <Route {...props} />
        );
    }

    if (fetchState === FetchState.not_asked || fetchState === FetchState.loading) {
        return (
            <Route
                {...props}
                render={() => <LoadingIndicator />}
            />
        );
    }

    if (expectedPermissions.length === 0) {
        // this re-routing to choose the redirection path helps the app know
        // which expected permissions are needed for that particular route
        return (
            <Route
                {...props}
                render={() => {
                    const redirectPath = chooseRedirectPath(roleName, actualPermissions, redirectUrl);
                    if (redirectPath.startsWith('https')) {
                        window.location.href = redirectPath;
                        return null;
                    }
                    return (
                        <Redirect to={{
                            pathname: redirectPath,
                            state: { from: props.location, redirected: !initialAuth0Redirect },
                        }}
                        />
                    );
                }}
            />
        );
    }

    const hasAppropriatePermissions = actualPermissions.some(permission => expectedPermissions.includes(permission));
    if (!hasAppropriatePermissions) {
        return (
            <Route
                {...props}
                render={() => (
                    <Redirect to={{
                        pathname: links.noPermissions,
                    }}
                    />
                )}
            />
        );
    }

    return (
        <Route
            {...props}
            render={() => <Component {...props} />}
        />
    );
};

Auth0ProtectedRouteHOC.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    roleName: PropTypes.string,
    actualPermissions: PropTypes.arrayOf(PropTypes.string),
    expectedPermissions: PropTypes.arrayOf(PropTypes.string),
    redirectUrl: PropTypes.string,
    initialAuth0Redirect: PropTypes.bool,
    fetchState: PropTypes.string.isRequired,
};

Auth0ProtectedRouteHOC.defaultProps = {
    actualPermissions: [],
    expectedPermissions: [],
    roleName: null,
    initialAuth0Redirect: false,
    redirectUrl: '',
};

const mapStateToProps = state => ({
    fetchState: fetchStateSelector(state),
    roleName: roleNameSelector(state),
    actualPermissions: permissionsSelector(state),
    redirectUrl: postAuthRedirectUrlSelector(state),
});

const WithAuthentication = connect(mapStateToProps)(withAuthenticationRequired(Auth0ProtectedRouteHOC, {
    onRedirecting: () => <LoadingIndicator />,
}));

export default props => <WithAuthentication {...props} />;
