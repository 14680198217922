import { combineReducers } from 'redux';
import {
    UserListReducer,
    UsersListID,
} from './Users';
import {
    ListReducer as QuestionsReducer,
    ListId as QuestionsID,
    FormReducer as QuestionsFormReducer,
    FormId as QuestionsFormID,
    QuestionDetailsReducer,
    QuestionDetailsID,
} from './Questions';
import {
    CreateEmailsID,
    CreateEmailsReducer,
} from './GlobalPrograms';

export { ID } from './actionTypes';

export default combineReducers({
    [UsersListID]: UserListReducer,
    [QuestionsID]: QuestionsReducer,
    [QuestionsFormID]: QuestionsFormReducer,
    [QuestionDetailsID]: QuestionDetailsReducer,
    [CreateEmailsID]: CreateEmailsReducer,
});
