
export const analytics = {
    trackEvent: (eventString, id, npi) => {
        const event = {
            event: eventString,
        };
        if (id) {
            event.docdx_id = id;
        }
        if (npi) {
            event.docdx_npi = npi;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    },
};
