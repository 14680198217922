import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import QuestionDetails from './component';
import {
    ownStateSelector,
    unAttachedProgramsSelector,
} from '../../QuestionDetails/selectors';
import { closeAttachModal } from '../../QuestionDetails/actions';
import { attachPrograms } from '../../../../../data/topicPrograms';
import { currentTopicIdSelector } from '../../../../../data/questions/selectors';

const mapStateToProps = (state) => {
    const {
        isAttachModalOpen,
        loadingNewPrograms,
    } = ownStateSelector(state);
    const { unAttachedPrograms } = unAttachedProgramsSelector(state);
    const currentTopic = currentTopicIdSelector(state);

    return {
        currentTopic,
        isAttachModalOpen,
        programs: unAttachedPrograms,
        loadingNewPrograms,
    };
};

const mapDispatchToProps = {
    closeAttachModal,
    attachPrograms,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(QuestionDetails);
