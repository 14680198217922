import { createSlice } from '@reduxjs/toolkit';

export const AppHcUserID = 'APP_HC_USERS';

const initialState = {
    appHcUserIds: [],
    lookingUpAppHcUser: false,
};

const slice = createSlice({
    name: `${AppHcUserID}`,
    initialState,
    reducers: {
        lookupAppHcUser: (state) => {
            state.lookingUpAppHcUser = true;
        },
        lookupAppHcUserCompleted: (state, { payload }) => {
            state.appHcUserIds = payload?.data;
            state.lookingUpAppHcUser = false;
        },
        lookupAppHcUserFailed: (state) => {
            state.lookingUpAppHcUser = false;
        },
    },
});

export const {
    lookupAppHcUser,
    lookupAppHcUserCompleted,
    lookupAppHcUserFailed,
} = slice.actions;

export const { reducer } = slice;
