import { takeLatest, all, put, select } from 'redux-saga/effects';
import { Actions } from '../services/session';
import { actions as programActions } from '../data/programs';
import { FETCH_USER_COMPLETED } from '../services/session/actionTypes';
import { dtiExpirationDateSelector } from '../data/programs/selectors';

// eslint-disable-next-line require-yield
function* clearSessionStorage() {
    sessionStorage.clear();
}

function* appStart() {
    const saiInterstitialShown = !!sessionStorage.getItem('sai_interstitial_shown');
    const nextInterstitialShown = !!sessionStorage.getItem('next_interstitial_shown');

    if (!saiInterstitialShown) {
        sessionStorage.setItem('sai_interstitial_shown', 0);
    }

    if (!nextInterstitialShown) {
        sessionStorage.setItem('next_interstitial_shown', 0);
    }

    const expiresAt = yield select(dtiExpirationDateSelector);
    yield put(programActions.getDtiLink({ expiresAt }));
    yield put(programActions.getProgramForUser({}));
}

export default function* appSaga() {
    yield all([
        takeLatest(Actions.LOGOUT, clearSessionStorage),
        takeLatest([FETCH_USER_COMPLETED], appStart),
    ]);
}
