import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';


const Dot = ({
    open,
    mobileOnly,
}) => (!open ? (
    <div className={`dot-container ${mobileOnly ? 'dot-mobile' : ''}`} >
        <div className="dot-indicator dot-indicator__pulse-animation">
            <span className="dot-indicator__circle dot-indicator--color" />
        </div>
    </div>
) : null);
Dot.propTypes = {
    open: PropTypes.bool,
    mobileOnly: PropTypes.bool,
};

Dot.defaultProps = {
    open: false,
    mobileOnly: false,
};

export default Dot;
