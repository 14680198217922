import React from 'react';
import { privacyPolicyLink } from '../../links';

const pp =
`<p>Healthcasts, LLC ("Healthcasts") - is a peer-board reviewed and physician-validated private multi-channel network serving
    physicians and healthcare professionals. Healthcasts understands the importance of keeping personal physician information
    private. The following privacy policy ("Privacy Policy") applies only to the information we collect while processing
    your registration form to become a member of Healthcasts ("Physician Member") and/or your participation in our Programs
    and Clinical Consults (as further defined below).
    <br>
    <br> By registering to become a Physician Member of Healthcasts, you are agreeing to this Privacy Policy. This Privacy Policy supersedes the privacy policy of any vendor or service provider with which Healthcasts partners. This Privacy Policy does not include or apply to the collection of information from other sources.
    <br>
    <br> Registration Information and Participation Information described herein are together designated as "Physician Data" for purposes of this Privacy Policy.
    <br>
    <br>
    <h3>INFORMATION COLLECTED</h3>
    <br>
    <b>Registration Information</b>
    <br>Healthcasts collects identifiable information you submit to us when you sign up to become a Physician Member. This identifiable information includes your National Provider Identifier (NPI), Name, Address, Phone Number, E-mail Address, Gender, Age, Designation, Medical Specialty, and other professional information (e.g., Practice Type, Medical School, etc.).
    <br>
    <br>
    <b>Participation Information</b>
    <br>Healthcasts may provide you with the opportunity to submit additional identifiable and non-identifiable information through a program ("Program"). The following information ("Program Participation Data") is collected as part of your participation in a Program: NPI, name, address, specialty, date of your participation, your responses to a CME test, your responses to an evaluation/survey/questionnaire, and your general feedback or comments.
    <br>
    <br>Healthcasts may provide you with the opportunity to submit additional identifiable and non-identifiable information through peer-to-peer consultations ("Clinical Consult"). The following information ("Consult Participation Data") is collected as part of your participation in a Clinical Consult: NPI, name, address, specialty, date of your participation, your submitted consult questions, answers, ratings and your general feedback or comments.
    <br>
    <br>Healthcasts may automatically store and utilize cookies, tags, pixels and log files ("Web Trackers") on your personal devices (e.g., Mobile Phone, Tablet, Computer, Television, etc.) in order to help customize and improve your experience with our Programs and Clinical Consults. Healthcasts does not permit third parties to use Web Trackers to automatically collect identifiable information about you or your participation with our Programs or Clinical Consults.
    <br>
    <br>
    <h3>HOW COLLECTED INFORMATION IS USED</h3>
    <br>
    <b>Registration Information</b>
    <br>Healthcasts generally uses your Physician Data to respond to any requests or inquires that you might have, including but not limited to: sending you Program and/or Clinical Consult announcements and alerts, informing you of important updates, addressing your customer service requests, improving your user experience, etc.
    <br>
    <br>
    <b>Participation Information</b>
    <br>By participating in a Program, you are giving your consent to have your Program Participation Data disclosed only to the specific sponsor ("Sponsor") of the Program and the Sponsor's designated vendors ("Designated Vendors"), provided the Sponsor and/or Designated Vendors have executed a Non-Disclosure Agreement (NDA) with Healthcasts which protects the use of your Program Participation Data. To the extent reasonably enforceable by Healthcasts, your Program Participation Data may not be used, however, by the Program Sponsor and/or the Sponsor's Designated Vendors to contact you directly as a result of your participation in the Program, unless you have expressly indicated in advance, directly to Healthcasts, your willingness to be contacted directly by the Sponsor and/or the Sponsor's Designated Vendors. Program Participation Data will not be disclosed to any third party other than the Program Sponsor and the Sponsor's Designated Vendors without your express prior consent. Please note, Healthcasts Programs are frequently sponsored by pharmaceutical manufacturers, hospitals and other institutions.
    <br>
    <br>Program Participation Data will generally not be used for any purpose other than assisting Program Sponsors in aggregate, de-identified market research and analysis, unless such limitations of use are expressly waived in advance by an individual Physician Member.
    <br>
    <br>For Continuing Medical Education (CME) programs, no personally identifiable information will be disclosed to the supporter. The accreditor of the program will receive all necessary and required information in order to provide the Certificate of Completion to participants and fulfill any ACCME accreditor reporting and data requirements.
    <br>
    <br>By participating in a Clinical Consult, you are giving your consent to have your Consult Participation Data disclosed to specific 3rd party vendors ("Vendors"), which include pharmaceutical manufacturers, hospitals, affordable care organizations and other institutions, provided the Vendor has executed a Non-Disclosure Agreement (NDA) with Healthcasts which protects the use of your Consult Participation Data. Your Consult Participation Data may be used by Vendors to contact you directly as a result of your participation in a Clinical Consult.
    <br>
    <br>
    <b>Aggregate Information</b>
    <br>Healthcasts may aggregate non-identifiable information from your Physician Data and disclose it a Program Sponsor, the Sponsor's Designated Vendors, Vendors, or other third parties in order, but not limited to the following reasons: develop new products and services, medical content, market research and outcomes measurements. When Healthcasts aggregates your Physician Data, all identifying information is removed before the aggregate information is disclosed.
    <br>
    <br>
    <h3>HOW INFORMATION IS SECURED</h3>
    <br>
    <b>Security Procedures</b>
    <br>Healthcasts has implemented industry-standard security measures including encryption technology and policies to ensure that Physician Data is kept confidential and only used for the purposes listed in this Privacy Policy.
    <br>
    <br>
    <b>Employee Access</b>
    <br>Healthcasts limits access to Physician Data exclusively to Healthcasts employees who have a specific business need for the information. Healthcasts employees are required to keep Physician Data private and are required to sign a confidentiality agreement as part of their acceptance of employment with Healthcasts.
    <br>
    <br>
    <h3>CHANGES TO PRIVACY POLICY</h3>
    <br>Healthcasts may update this Privacy Policy at various times and for various reasons. When substantial changes are made to this Privacy Policy that may affect the way we collect or use Physician Data, we will notify you immediately so that you may acknowledge receipt and acceptance of the updated policy. The updated privacy policy will be posted on our website at the following address:
    <a href=${privacyPolicyLink}>${privacyPolicyLink}</a>
    <br>
    <br>
    <h3>HOW TO OPT-OUT, UPDATE OR REMOVE INFORMATION</h3>
    <br>
    <b>Opt-Out of Disclosing Information</b>
    <br>You may opt-out of disclosing your Physician Data by notifying Healthcasts via mail, phone, fax, or email.
    <br>
    <br>
    <b>Update or Delete Information</b>
    <br>Healthcasts allows you at any time to update your Physician Data by notifying Healthcasts via mail, phone, fax, or email.
    <br/>
    <br/>
</p>`;

export const policyAgreement = 'BY PARTICIPATING AS A PHYSICIAN MEMBER OF HEALTHCASTS, I REPRESENT THAT I HAVE READ AND AGREE TO THE ENTIRE PRIVACY POLICY AS STATED ABOVE.';

/* eslint-disable react/no-danger */
const PrivacyPolicy = () => (
    <div dangerouslySetInnerHTML={{ __html: pp }} />
);

export default PrivacyPolicy;
