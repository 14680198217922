import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    Table,
    TableRow,
    TableCell,
    Checkbox,
    Loader,
} from 'semantic-ui-react';
import localization from '../../../../../localization';
import TableEmptyState from '../../../../../components/TableEmptyState';
import './style.scss';

const {
    admin: {
        questions: {
            details: {
                modalHeader,
                loadingModalPrograms,
                programsCountText,
                tableHeaders: {
                    programId: idHeader,
                    programName,
                    programAirDate,
                },
                attach,
            },
            emptyAllProgramsState,
        },
    },
    common: {
        cancel,
    },

} = localization;

class AttachModal extends Component {
    state = {
        selectedProgramsCount: 0,
        programs: [],
        allSelection: false,
        loading: false,
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps({ programs }) {
        programs.forEach(({
            id,
            name,
            airDate,
        }) => ({
            id,
            name,
            airDate,
            selected: false,
        }));
        this.setState({ programs });
        this.clearSelectedCount();
    }

    handleAttachPrograms = () => {
        const {
            attachPrograms,
            currentTopic,
        } = this.props;

        this.setState({ loading: true });
        attachPrograms({
            programs: this.attachSelectedProgramsFilter(),
            topicId: currentTopic,
        });
    }

    handleCloseModal = () => {
        const {
            closeAttachModal,
        } = this.props;

        closeAttachModal();
    }

    clearSelectedCount() {
        this.setState({
            selectedProgramsCount: 0,
            allSelection: false,
            loading: false,
        });
    }

    attachSelectedProgramsFilter() {
        const selectedPrograms = this.state.programs.filter(prog => prog.selected === true);
        return selectedPrograms.map(({
            id,
            name,
            airDate,
            hcmpPrgId,
        }) => ({
            id,
            name,
            airDate,
            hcmpPrgId,
        }));
    }

    changeSelection = programId => (e, data) => {
        const { checked } = data;

        this.setState((prevState) => {
            const {
                programs,
                selectedProgramsCount,
            } = prevState;
            const newPrograms = programs.map(({
                id,
                selected,
                ...rest
            }) => ({
                id,
                ...rest,
                selected: id === programId ? checked : selected,
            }));

            const isAllSelected = newPrograms.every(({ selected }) => selected === true);

            const newCount = checked ?
                selectedProgramsCount + 1 :
                selectedProgramsCount - 1;
            return {
                selectedProgramsCount: newCount,
                programs: newPrograms,
                allSelection: isAllSelected,
            };
        });
    }

    selectOrDeselectAll = (e, data) => this.setState((prevState) => {
        const { checked } = data;
        const { programs } = prevState;
        const newPrograms = programs.map(({
            selected,
            ...rest
        }) => ({
            ...rest,
            selected: checked,
        }));

        const newCount = checked ? programs.length : 0;
        return {
            selectedProgramsCount: newCount,
            programs: newPrograms,
            allSelection: checked,
        };
    });

    render() {
        const {
            isAttachModalOpen,
            loadingNewPrograms,
        } = this.props;

        const {
            loading,
            programs,
            allSelection,
            selectedProgramsCount,
        } = this.state;

        const rows = programs.map(({
            id: programId,
            hcmpPrgId,
            name,
            airDate,
            selected,
        }) => (
            <TableRow key={programId} className="programs">
                <TableCell textAlign="center">
                    {hcmpPrgId}
                </TableCell>
                <TableCell>
                    {name}
                </TableCell>
                <TableCell textAlign="center">
                    {new Date(airDate).toLocaleDateString()}
                </TableCell>
                <TableCell width={3} textAlign="center" className="priority">
                    <Checkbox
                        className="select-checkbox"
                        checked={selected}
                        onClick={this.changeSelection(programId)}
                    />
                </TableCell>
            </TableRow>
        ));

        return (
            <Modal open={isAttachModalOpen} className="page-container modal-width">
                <Modal.Header className="hcmp">{modalHeader}</Modal.Header>
                <Modal.Content scrolling className="hcmp">
                    {loadingNewPrograms ?
                        <Loader size="big" className="modal-loader">
                            {loadingModalPrograms}
                        </Loader> :
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign="center">{idHeader}</Table.HeaderCell>
                                    <Table.HeaderCell>{programName}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">{programAirDate}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                        <Checkbox
                                            disabled={!programs.length}
                                            checked={allSelection}
                                            onClick={this.selectOrDeselectAll}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {programs.length ?
                                    rows : <TableEmptyState text={emptyAllProgramsState} />}
                            </Table.Body>
                        </Table>
                    }
                </Modal.Content>
                <Modal.Actions className="hcmp">
                    <span className="programs-selected">{`${programsCountText} ${selectedProgramsCount}`}</span>
                    <Button disabled={loading} onClick={this.handleCloseModal}>
                        {cancel}
                    </Button>
                    <Button
                        loading={loading}
                        color="green"
                        disabled={!selectedProgramsCount || loading}
                        onClick={this.handleAttachPrograms}
                    >
                        {attach}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

// TODO: remove after UNSAFE method above is refactored
/* eslint-disable react/no-unused-prop-types */
AttachModal.propTypes = {
    currentTopic: PropTypes.number.isRequired,
    programs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    closeAttachModal: PropTypes.func.isRequired,
    loadingNewPrograms: PropTypes.bool.isRequired,
    attachPrograms: PropTypes.func.isRequired,
    isAttachModalOpen: PropTypes.bool.isRequired,
};
/* eslint-enable react/no-unused-prop-types */

export default AttachModal;
