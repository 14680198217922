import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Error = ({ message, containerStyles, ...props }) => (
    <div className={clsx('text-red-500 text-base mt-1', containerStyles)} {...props}>
        {message}
    </div>
);

Error.propTypes = {
    message: PropTypes.string.isRequired,
    containerStyles: PropTypes.string,
};

Error.defaultProps = {
    containerStyles: '',
};

export default Error;
