import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Divider,
    Grid,
    Header,
    Input,
    Pagination,
    Segment,
} from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CsvDownloadButton from 'react-json-to-csv';
import links from '../../links';
import client from '../../api/apiSaga';
import { search } from '../../api/endpoints';

const getComments = async ({
    word, order, page, from, to, limit,
}) => {
    try {
        const data = await client.get(search.root, {
            params: {
                word,
                order,
                offset: page === 1 ? 0 : (page - 1) * 20,
                from,
                to,
                limit,
            },
        });
        return data;
    } catch (e) {
        return {
            success: false,
            message: 'Backend error, please review in chrome dev tools',
        };
    }
};

const getLogs = async () => {
    try {
        const data = await client.get(search.logs);
        return data;
    } catch (e) {
        return {
            success: false,
            message: 'Backend error, please review in chrome dev tools',
        };
    }
};

export default class Search extends React.Component {
    static propTypes = {
        allowCsvDownload: PropTypes.bool.isRequired,
    };

    constructor() {
        super();

        this.state = {
            page: 1,
            results: [],
            searchWord: '',
            countOfComments: 0,
            sortBy: 'newest',
            open: 0,
            loading: false,
            fromDate: '',
            toDate: '',
            loadedCSV: [],
            csvButtonText: 'Load CSV file',
            openedPC: -1,
            logs: [],
        };

        this.loadSearchLogs = async () => {
            this.setState({
                loading: true,
            });

            const result = await getLogs();

            if (!result.ok) {
                this.setState({
                    loading: false,
                });
            }

            this.setState({
                loading: false,
                logs: result.logs || [],
            });
        };

        this.loadComments = async (sortBy, from, to) => {
            this.setState({
                loading: true,
            });

            const result = await getComments({
                word: this.state.searchWord, order: sortBy || this.state.sortBy, page: this.state.page, from: from || this.state.fromDate, to: to || this.state.toDate,
            });

            if (!result.ok) {
                this.setState({
                    loading: false,
                });
            }

            this.setState({
                results: result.comment || [],
                countOfComments: result.commentsCount || 0,
                loading: false,
            });
        };


        this.loadCsvData = async () => {
            this.setState({
                loading: true,
                csvButtonText: 'Loading',
            });

            const result = await getComments({
                word: this.state.searchWord, order: this.state.sortBy, page: this.state.page, from: this.state.fromDate, to: this.state.toDate, limit: this.state.countOfComments,
            });

            this.setState({
                loading: false,
                csvButtonText: 'Click and download!',
                loadedCSV: result.comment || [],
            });


            if (!result.ok) {
                this.setState({
                    loading: false,
                });
            }
        };

        this.inputAction = {
            color: 'teal',
            icon: 'search',
            content: 'Search',
            type: 'button',
            disabled: this.state.loading,
            size: 'big',
            onClick: () => {
                if (!this.state.searchWord.length) {
                    return;
                }
                this.loadComments();
            },
        };
    }

    componentDidMount() {
        this.loadSearchLogs();
    }

    sortBy = (method) => {
        this.setState({ sortBy: method, page: 1 });
        this.loadComments(method);
    };

    sortByDate = (from, to) => {
        this.setState({ fromDate: from, toDate: to });
        this.loadComments(undefined, from, to);
    };

    changePage = (page) => {
        this.setState({ page: page - 0 });
        setTimeout(() => {
            this.loadComments();
        }, 1000);
    };

    render() {
        return (
            <div>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        event.target.reset();
                    }}
                >
                    <Segment>
                        <Header size="tiny" textAlign="center">
                            Search Patient Consults
                        </Header>
                        <Input
                            loading={this.state.loading}
                            disabled={this.state.loading}
                            fluid
                            action={this.inputAction}
                            size="large"
                            placeholder="Search..."
                            onChange={(event) => {
                                this.setState({ searchWord: event.target.value });
                            }}

                        />
                    </Segment>
                    <Segment>
                        <Grid columns={12} stackable verticalAlign="middle">
                            <Grid.Row columns={12}>
                                <Grid.Column width={4}>
                                    <Button.Group>
                                        <Button
                                            active={this.state.sortBy === 'relevant'}
                                            onClick={() => this.sortBy('relevant')}
                                        >
                                            Relevance
                                        </Button>
                                        <Button
                                            active={this.state.sortBy === 'newest'}
                                            onClick={() => this.sortBy('newest')}
                                        >
                                            Newest
                                        </Button>
                                        <Button
                                            active={this.state.sortBy === 'oldest'}
                                            onClick={() => this.sortBy('oldest')}
                                        >
                                            Oldest
                                        </Button>
                                    </Button.Group>
                                </Grid.Column>
                                <Grid.Column width={8} verticalAlign="right">
                                    <Input
                                        label="From"
                                        type="date"
                                        onChange={event => this.sortByDate(event.target.value, this.state.toDate)}
                                    />
                                </Grid.Column>
                                <Grid.Column verticalAlign="right">
                                    <Input
                                        label="To"
                                        type="date"
                                        onChange={event => this.sortByDate(this.state.fromDate, event.target.value)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </form>
                {
                    this.state.logs.length > 0 && this.props.allowCsvDownload && (
                        <Segment>
                            <CsvDownloadButton
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    width: '100%',
                                }}
                                filename={`Logs: ${this.state.searchWord} ${Date.now()}`}
                                data={this.state.logs}
                            >
                                Download search logs
                            </CsvDownloadButton>
                        </Segment>
                    )
                }
                {
                    this.state.results.length > 0 && (
                        <div>
                            <Segment>
                                <Grid columns={2} verticalAlign="middle">
                                    <Grid.Row divided>
                                        <Grid.Column>
                                            <Header size="tiny">
                                                {this.state.countOfComments} Patient Consults for `{this.state.searchWord}`
                                            </Header>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            {
                                                this.props.allowCsvDownload && (
                                                    <>
                                                        {
                                                            this.state.loadedCSV.length ?
                                                                <CsvDownloadButton
                                                                    style={{
                                                                        background: 'transparent',
                                                                        border: 'none',
                                                                    }}
                                                                    filename={`Search results: ${this.state.searchWord} ${Date.now()}`}
                                                                    data={this.state.loadedCSV.map(item => ({
                                                                        'Document id': item.id,
                                                                        'Topic id': item.topic_id,
                                                                        'First name': item.first_name,
                                                                        'Last name': item.last_name,
                                                                        'Patient case': item.description,
                                                                        'Answer text': item.text,
                                                                        'Therapy area': item.therapy_area,
                                                                        'Specialty ': item.specialty_name,
                                                                        'Subspecialty ': item.subspecialty_name,
                                                                        'Date ': item.created_at,
                                                                        'NPI ': item.npi,
                                                                    }))}
                                                                >
                                                                    <Button >
                                                                        {this.state.csvButtonText}
                                                                    </Button>
                                                                </CsvDownloadButton>
                                                                :
                                                                <Button onClick={() => this.loadCsvData()}>
                                                                    {this.state.csvButtonText}
                                                                </Button>
                                                        }
                                                    </>
                                                )
                                            }

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                                {
                                    /* eslint-disable */
                                    this.state.results.map((item, index) => {
                                        return (
                                            <li key={item.id + Math.random()} style={{ marginBottom: 10 }}>
                                                <Segment>
                                                    <Grid>
                                                        <Grid.Row columns={2} verticalAlign="middle">
                                                            <Grid.Column width={14} textAlign="left">
                                                                <div>
                                                                    <strong>{item.first_name + ` ` + item.last_name}</strong>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            fontSize: 14,
                                                                            color: '#222222',
                                                                            margin: 0
                                                                        }}
                                                                    >
                                                                        {item.specialty_name || "N/A"}
                                                                    </p>
                                                                </div>
                                                                <div style={{ marginTop: 10 }}>
                                                                    <div>
                                                                        {item.text}
                                                                    </div>
                                                                </div>
                                                            </Grid.Column>
                                                            <Grid.Column width={2} textAlign="right">
                                                                <div>
                                                                    <time>{moment(item.created_at).format('l')}</time>
                                                                </div>

                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    <Divider />
                                                    <Grid>
                                                        <Grid.Row columns={2} verticalAlign="middle">
                                                            <Grid.Column width={11} textAlign="left">
                                                                <p>
                                                                    Case associated with: <b>#{item.topic_id}</b> {this.state.openedPC == index ? item.description : item.description.slice(0, 220)} {(item.description.length > 220 && this.state.openedPC !== index) && <button style={{
                                                                        backgroundColor: '#fff',
                                                                        color: '#0000EE',
                                                                        textDecoration: 'none',
                                                                        border: 'none',
                                                                        cursor: 'pointer',
                                                                    }} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState({ openedPC: index });
                                                                    }}> Read more.</button>}
                                                                </p>
                                                            </Grid.Column>
                                                            <Grid.Column width={5} textAlign="right">
                                                                <div>
                                                                    <Link
                                                                        href
                                                                        to={{
                                                                            pathname: links.topics.myFeed + "/" + item.topic_id + "?type=redirected",
                                                                            state: item
                                                                        }}
                                                                        target="_blank"

                                                                    >
                                                                        <Button
                                                                            color="facebook"
                                                                        >
                                                                            View full case & Other consults
                                                                        </Button>
                                                                    </Link>

                                                                </div>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    {
                                                        this.state.open === item.id ? <div>
                                                            <Divider />
                                                            <p>Content</p>
                                                        </div> : null
                                                    }
                                                </Segment>
                                            </li>);
                                    })
                                }
                            </ul>
                            <div style={{ marginBottom: 10 }}>
                                <Segment>
                                    <Pagination
                                        boundaryRange={0}
                                        defaultActivePage={this.state.page}
                                        totalPages={Math.ceil(this.state.countOfComments / 20)}
                                        onPageChange={async (_, data) => {
                                            this.changePage(data.activePage);
                                            this.loadComments();
                                        }}
                                    />
                                </Segment>
                            </div>
                        </div>
                    )
                }
                {
                    !this.state.results.length && (
                        <Segment>
                            <Header size="tiny" textAlign="center">
                                No results
                            </Header>
                        </Segment>)
                }
            </div>
        );
    }
}
