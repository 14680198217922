import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { replace } from '../../services/navigation';
import { WithRouterHOC } from '../../components/Router';
import { updateAnswer, showAnswers } from '../../data/answers/';
import { ID as DataID, QuestionsID, AnswersID, RewardsID } from '../../data';
import { topicCommentsTree, defaultSortDirection, hasOwnAnswer } from './selectors';
import { showNextQuestion } from './actions';
import Thread from './container';

const mapStateToProps = (state, {
    match: {
        params: {
            id,
        },
    },
    location: {
        query: {
            orderby = defaultSortDirection,
        },
    },
}) => {
    const {
        [DataID]: {
            [QuestionsID]: {
                topics,
                flaggingSuccess,
                flaggingFailed,
                nextTopic,
                loadingNextTopic,
            },
            [AnswersID]: {
                updating,
                loading,
                areResponsesShown,
                showResponsesLoading,
            },
            [RewardsID]: {
                rewards,
            },
        },
    } = state;

    const parsedId = parseInt(id, 10);

    const emptyTopic = {};
    const topic = topics.find(({ id: topicId }) => topicId === parsedId) || emptyTopic;
    const topicExpired = new Date(topic.expirationDate) < new Date();
    const answers = topicCommentsTree(parsedId, orderby)(state);
    const topicLoaded = topic !== emptyTopic;
    const hasAnswered = hasOwnAnswer(state, parsedId);

    return {
        topic,
        topicExpired,
        nextTopic,
        orderby,
        answers,
        flaggingSuccess,
        flaggingFailed,
        hasAnswered,
        rewards,
        // meta
        topicLoaded,
        loadingNextTopic,
        updating,
        answersLoading: loading,
        areResponsesShown,
        showResponsesLoading,
    };
};

export default compose(
    WithRouterHOC,
    connect(mapStateToProps, {
        addAnswerAction: updateAnswer,
        showNextQuestion,
        navigateAction: replace,
        showAnswers,
    }),
)(Thread);

export { default as reducer } from './reducer';
export { ID } from './actionTypes';
