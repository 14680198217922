import { all } from 'redux-saga/effects';
import answerSaga from './answers/sagas';
import questionSaga from './questions/sagas';
import usersSaga from './users/sagas';
import appHcUsersSaga from './appHcUsers/sagas';
import specialtiesSaga from './specialties/sagas';
import campaignsSaga from './campaigns/sagas';
import rolesSaga from './roles/sagas';
import topicProgramsSaga from './topicPrograms/sagas';
import globalHcmpSaga from './globalHcmp/sagas';
import eventSaga from './events/sagas';
import { saga as programsSaga } from './programs';
import { hcmpEnabled } from '../environment';
import rewardsSaga from './rewards/sagas';
import tooltipsSaga from './tooltips/sagas';

export default function* dataSaga() {
    const sagas = [
        answerSaga(),
        questionSaga(),
        campaignsSaga(),
        usersSaga(),
        appHcUsersSaga(),
        specialtiesSaga(),
        rolesSaga(),
        topicProgramsSaga(),
        globalHcmpSaga(),
        eventSaga(),
        rewardsSaga(),
        tooltipsSaga(),
    ];

    if (hcmpEnabled) {
        sagas.push(programsSaga());
    }
    yield all(sagas);
}
