import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import links from '../../../links';
import localization from '../../../localization';
import { AuthorProps } from '../../../utils/propTypes';
import './style.scss';

const {
    thread: {
        authorPrefix,
        expired,
        expires,
        actionButtonsContent: {
            viewAnswer,
            viewQuestion,
        },
    },
} = localization;

const QuestionItem = ({
    hasExpired,
    title,
    id,
    expirationDate,
    description,
    author: {
        firstName,
        lastName,
        specialty,
    } = {},
    isAnswered,
}) => {
    const actionButton = isAnswered ? (
        <Button
            icon="comment"
            primary
            content={viewAnswer}
            className="view-answer"
            as={Link}
            to={links.topics.view.replace(':id', id)}
            href
        />
    ) : (
        <Button
            icon="pencil"
            primary
            content={viewQuestion}
            className="answer-question"
            as={Link}
            to={links.topics.view.replace(':id', id)}
            href
        />
    );


    return (
        <li className="question-item">
            <Link
                href
                to={links.topics.view.replace(':id', id)}
            >
                <div className="question-relative-duration">
                    {hasExpired ? expired : expires}
                    {' '}
                    {moment(expirationDate).fromNow()}
                </div>
                <h4 className="question-title short">{title}</h4>
                <div className="question-description">{description}</div>
                <div className="question-author">
                    {`${authorPrefix} ${firstName} ${lastName} `}
                    {specialty && `• ${specialty}`}
                </div>
            </Link>
            {actionButton}
        </li>
    );
};

QuestionItem.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    expirationDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-typos
    author: PropTypes.shape(AuthorProps).isRequired,
    hasExpired: PropTypes.bool,
    isAnswered: PropTypes.bool,
};

QuestionItem.defaultProps = {
    hasExpired: false,
    isAnswered: false,
};

export default QuestionItem;
