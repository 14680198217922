
const internal = 'internal';

export const programs = {
    root: 'programs',
    get index() {
        return this.root;
    },
    get dtiLink() {
        return `${this.root}/dti_link`;
    },
    get programTest() {
        return `${this.root}/test`;
    },
    get programsIdFetch() {
        return `${this.root}/`;
    },
};

export const comments = ({
    root: 'comments',
    get index() {
        return this.root;
    },
    get singleComment() {
        return id => `${this.root}/${id}`;
    },
    get ratings() {
        return id => `${this.root}/${id}/ratings`;
    },
    get createAnswersView() {
        return `${this.root}/answers_view`;
    },
});

export const rewards = ({
    root: 'rewards',
    get history() {
        return `${this.root}/history`;
    },
    get current() {
        return `${this.root}/current`;
    },
});

export const campaigns = ({
    root: 'campaigns',
    get index() {
        return this.root;
    },
    get general() {
        return `${this.root}/general`;
    },
    get specialty() {
        return `${this.root}/specialty`;
    },
    get npi() {
        return `${this.root}/npi`;
    },
    get remove() {
        return campaignId => `${this.root}/${campaignId}`;
    },
});

export const downloads = ({
    root: 'target_list',
    get index() {
        return this.root;
    },
    get targetList() {
        return campaignId => `${this.root}/${campaignId}`;
    },
    get answers() {
        return questionId => `${this.root}/answer_report/${questionId}`;
    },
    get allUsers() {
        return `${this.root}/users_and_specs`;
    },
});

export const questions = ({
    root: 'topics',
    get index() {
        return this.root;
    },
    get internal() {
        return `${internal}/${this.root}`;
    },
    get singleQuestionInternal() {
        return id => `${this.internal}/${id}`;
    },
    get singleQuestion() {
        return id => `${this.root}/${id}`;
    },
    get nextQuestion() {
        return id => `${this.root}/${id}/next`;
    },
    get flagQuestion() {
        const { root } = this;
        return questionId => `${root}/${questionId}/flag`;
    },
    get relatedUsersCount() {
        const { root } = this;
        return questionId => `${internal}/${root}/${questionId}/user_count`;
    },
    get eligibleUsersCount() {
        const { root } = this;
        return questionId => `${internal}/${root}/${questionId}/eligible_count`;
    },
    get npi() {
        return `${internal}/${this.root}/npi`;
    },
});

export const topicPrograms = ({
    root: 'pi',
    get attachedPrograms() {
        return `${this.root}/attached_programs`;
    },
    get unattachedPrograms() {
        return `${this.root}/active_programs`;
    },
    get programPriorityChange() {
        return `${this.root}/change_priority`;
    },
    get attachPrograms() {
        return `${this.root}/attach`;
    },
    get detachProgram() {
        return `${this.root}/detach`;
    },
    get detachAllPrograms() {
        return `${this.root}/detach_all`;
    },
});

export const roles = ({
    index: 'roles',
});

export const specialties = ({
    index: 'specialties',
});

export const subspecialties = ({
    index: 'subspecialties',
});

export const users = ({
    root: 'users',
    get index() {
        return this.root;
    },
    get singleUser() {
        return id => `${this.root}/${id}`;
    },
    get restore() {
        return id => `${this.singleUser(id)}/restore`;
    },
});

export const appHcUsers = ({
    root: 'app_hc_users',
    get index() {
        return this.root;
    },
});

export const globalHcmp = {
    root: 'global_programs',
    get index() {
        return this.root;
    },
    get changeStatus() {
        return `${this.root}/change_status`;
    },
    get confirm() {
        return `${this.root}/confirm`;
    },
    get changePriority() {
        return `${this.root}/change_priority`;
    },
    get singleProgram() {
        return id => `${this.root}/${id}`;
    },
};

export const tooltips = {
    root: 'tooltips',
    get load() {
        return name => `${this.root}/check/${name}`;
    },
    get mark() {
        return `${this.root}/view`;
    },
    get allow() {
        return `${this.root}/allow`;
    },
    get disable() {
        return `${this.root}/disable`;
    },
};

export const logEvents = {
    root: 'events',
    get logAnswerFormFocus() {
        return `${this.root}/log_answer_form_focus`;
    },
};

export const search = {
    root: 'search',
    logs: '/search/get_logs',
};
