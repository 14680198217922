import React from 'react';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import links from '../../../links';

const ErrorPage = () => (
    <div className="flex flex-col rounded-md border bg-gray-100 p-10 mb-20 mx-auto max-w-form">
        <div className="flex flex-col items-center justify-center h-full my-20 mx-10">
            <ExclamationTriangleIcon className="w-6 h-6 text-purple-gray-600" />
            <h1 className="text-2xl font-title my-6">
                We&apos;re reviewing your submission.
            </h1>
            <p className="text-lg font-body">
                <a className="!underline !text-purple-500" target="_blank" rel="noopener noreferrer" href={links.infoPages.registrationSupportForm}>Member Services</a> will email you soon with next steps.
            </p>
        </div>
    </div>
);


export default ErrorPage;
