/* eslint-disable import/prefer-default-export */
export const ID = 'GLOBAL_HCMP_LIST';

export const GET_GLOBAL_HCMP_LIST = `${ID}/GET_GLOBAL_HCMP_LIST`;
export const GET_GLOBAL_HCMP_LIST_COMPLETED = `${ID}/GET_GLOBAL_HCMP_LIST_COMPLETED`;
export const GET_GLOBAL_HCMP_LIST_FAILED = `${ID}/GET_GLOBAL_HCMP_LIST_FAILED`;

export const CHANGE_HCMP_GLOBAL_PRIORITY = `${ID}/CHANGE_HCMP_GLOBAL_PRIORITY`;
export const CHANGE_HCMP_GLOBAL_PRIORITY_COMPLETED = `${ID}/CHANGE_HCMP_GLOBAL_PRIORITY_COMPLETED`;
export const CHANGE_HCMP_GLOBAL_PRIORITY_FAILED = `${ID}/CHANGE_HCMP_GLOBAL_PRIORITY_FAILED`;

export const CHANGE_HCMP_GLOBAL_STATUS = `${ID}/CHANGE_HCMP_GLOBAL_STATUS`;
export const CHANGE_HCMP_GLOBAL_STATUS_COMPLETED = `${ID}/CHANGE_HCMP_GLOBAL_STATUS_COMPLETED`;
export const CHANGE_HCMP_GLOBAL_STATUS_FAILED = `${ID}/CHANGE_HCMP_GLOBAL_STATUS_FAILED`;

export const CONFIRM_HCMP_GLOBAL = `${ID}/CONFIRM_HCMP_GLOBAL`;
export const CONFIRM_HCMP_GLOBAL_COMPLETED = `${ID}/CONFIRM_HCMP_GLOBAL_COMPLETED`;
export const CONFIRM_HCMP_GLOBAL_FAILED = `${ID}/CONFIRM_HCMP_GLOBAL_FAILED`;
