import { ID } from './actionTypes';
import DataID from '../config';

export const loadingSelector = ({
    [DataID]: {
        [ID]: {
            loading,
        },
    },
}) => loading;

export const errorSelector = ({
    [DataID]: {
        [ID]: {
            error,
        },
    },
}) => error;

export const errorMessageSelector = ({
    [DataID]: {
        [ID]: {
            errorMessage,
        },
    },
}) => errorMessage;

export const getGlobalHcmpSelector = ({
    [DataID]: {
        [ID]: {
            globalPrograms,
        },
    },
}) => globalPrograms;
