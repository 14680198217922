import { connect } from 'react-redux';

import { ID as SessionID } from '../../../services/session';
import MyProfile from './component';
import { setShowTooltips } from './actions';

const mapStateToProps = (state) => {
    const {
        [SessionID]: {
            firstName = '',
            lastName = '',
            email = '',
            specialty = '',
            showTooltips = false,
            updatingShowTooltips = false,
            npi = '',
        },
    } = state;

    return {
        firstName,
        lastName,
        email,
        specialty,
        showTooltips,
        updatingShowTooltips,
        npi,
    };
};

export default connect(mapStateToProps, {
    setShowTooltips,
})(MyProfile);
