import { connect } from 'react-redux';
import Comments from './container';
import { ID as ContainerID } from '../../../actionTypes';
import { clearUpdatedComment } from '../../../actions';
import { roleNameSelector } from '../../../../../services/session';
import { isAdmin } from '../../../../../utils/roleHelper';

const mapStateToProps = (state) => {
    const {
        [ContainerID]: {
            lastUpdatedCommentId,
        },
    } = state;
    return {
        canEditAll: isAdmin(roleNameSelector(state)),
        lastUpdatedCommentId,
    };
};

export default connect(mapStateToProps, {
    clearUpdatedComment,
})(Comments);
