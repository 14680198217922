export const RoleNames = {
    ADMINISTRATOR: 'Admininstrator',
    HCP: 'Healthcare Provider',
    MODERATOR: 'Moderator',
    SALES_DEMO: 'Sales Demo',
    TEST_HCP: 'Test Healthcare Provider',
};

export const isAdmin = roleName => roleName === RoleNames.ADMINISTRATOR;

export const isHcpOrTestHcp = roleName => [RoleNames.HCP, RoleNames.TEST_HCP].includes(roleName);

export const isExternalUser = roleName => roleName === RoleNames.HCP;

export const isInternalUser = roleName => !isExternalUser(roleName);

export const isAdminOrModerator = roleName => [RoleNames.ADMINISTRATOR, RoleNames.MODERATOR].includes(roleName);
