import { connect } from 'react-redux';
import CtaPopup from './container';
import { disableCtaPopup } from '../../../services/session/index';


export default connect(
    null,
    dispatch => ({
        hidePopup: () => {
            dispatch(disableCtaPopup());
        },
    }),
)(CtaPopup);
