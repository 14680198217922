import { SubmissionError } from 'redux-form';
import getEmailValidationErrors from 'validator/lib/isEmail';
import localization, { stringTemplates } from '../../localization';
import { npiDigitCheck } from '../function';
import { maxNpiLength } from '../../containers/Moderator/Users/CreateOrEdit/constants';

const strings = localization.common.validationErrorMessages;

const {
    validations: {
        stringLengthMax,
        stringLengthMin,
    },
} = stringTemplates;

export const required = (value) => {
    let error = null;
    if (value && value.constructor === Array) {
        return !value.length && strings.required;
    }
    switch (typeof value) {
        case 'number':
            break;
        default:
            error = !value && strings.required;
    }
    return error;
};

export const isNpiValid = npi => npiDigitCheck(npi) === false && strings.npiInvalid;

export const isNpiLengthValid = (npi) => {
    if (npi) {
        const npiLength = npi.toString().length;
        if (npiLength !== 10 && npiLength <= maxNpiLength) {
            return strings.npiLengthInvalid;
        } else if (npiLength !== 10 && npiLength > maxNpiLength) {
            return strings.npiMaxLength;
        }
    }

    return '';
};

export const isNumber = number => !(!number || /^\d+$/.test(number)) && strings.npiFormat;

export const minLength = (value, min) => value.length < min && stringLengthMin(min);

export const maxLength = (value, max) => value.length > max && stringLengthMax(max);

export const minNullLength = (value, min) => value && value.length < min && stringLengthMax(min);

export const maxNullLength = (value, max) => value && value.length > max && stringLengthMax(max);

// getEmailValidationErrors doesn't validate undefined
export const isEmail = email => email && !getEmailValidationErrors(email) && strings.emailFormat;

/**
 * Returns a new function which runs validations before the submit function is called
 * @param {object} validations Function what runs validations
 * @param {*} submitFunction The function called after validation passes
 */
export const preSubmitValidation = (validations, submitFunction) => (values, ...other) => {
    const errors = validations(values);
    const hasErrors = Object.values(errors).some(err => err);
    if (hasErrors) {
        throw new SubmissionError(errors);
    }
    submitFunction(values, ...other);
};

/**
 * Returns true if the passed date is before the dateToCompare
 * @param {string|Date} date - the date which is compared against
 * @param {string|Date} [now] dateToCompare - the date which is compared to
 * @returns {bool|string} the validation fail result or msg
 */
export const dateBefore = (date, dateToCompare = new Date()) => {
    const a = typeof date === 'string' ? new Date(date) : date;
    const b = typeof date === 'string' ? new Date(dateToCompare) : dateToCompare;
    return (a > b);
};
