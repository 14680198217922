import React from 'react';
import { CheckCircledIcon } from '@radix-ui/react-icons';
import links from '../../../links';

const SuccessPage = () => (
    <div className="flex flex-col rounded-md border bg-gray-100 p-10 mb-20 mx-auto max-w-form">
        <div className="flex flex-col items-center justify-center h-full my-20 mx-10">
            <CheckCircledIcon className="w-6 h-6 text-purple-gray-600" />
            <h1 className="text-2xl font-title my-6">
                Your account has been created!
            </h1>
            <p className="flex text-lg font-body text-center">
                Please check your email for your login details to activate your account
            </p>
            <p className="flex text-lg font-body text-center mb-0">
                If you have any questions in the meantime, please&nbsp;
                <a className="!underline !text-purple-500" target="_blank" rel="noopener noreferrer" href={links.infoPages.registrationSupportForm}>
                    Contact Us
                </a>
            </p>
        </div>
    </div>
);


export default SuccessPage;
