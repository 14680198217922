import { all } from 'redux-saga/effects';
import QuestionListSaga from './List/saga';
import QuestionFormSaga from './CreateOrEdit/saga';
import { saga as QuestionDetailsSaga } from './QuestionDetails';

export { default as QuestionList } from './List';
export { default as QuestionForm } from './CreateOrEdit';
export {
    default as QuestionDetails,
    reducer as QuestionDetailsReducer,
    ID as QuestionDetailsID,
} from './QuestionDetails';
export { default as ListReducer } from './List/reducer';
export { default as FormReducer } from './CreateOrEdit/reducer';
export { ID as ListId } from './List/actionTypes';
export { ID as FormId } from './CreateOrEdit/actionTypes';

export function* saga() {
    yield all([
        QuestionListSaga(),
        QuestionFormSaga(),
        QuestionDetailsSaga(),
    ]);
}
