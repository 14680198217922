/* eslint-disable import/prefer-default-export */
import client from '../../api';
import { campaigns, downloads } from '../../api/endpoints';

const {
    index,
    general,
    specialty,
    npi,
    remove,
} = campaigns;

const {
    targetList,
    allUsers,
    answers,
} = downloads;

export const createCampaign = (campaign, topicId) =>
    client.post(index, { data: { topicId, ...campaign } });

export const createSpecialtyCampaign = campaign =>
    client.post(specialty, { data: { ...campaign } });

export const createNpiCampaign = campaign =>
    client.post(npi, { data: { ...campaign } });

export const loadCampaigns = ({ topicId }) =>
    client.get(index, { params: { topicId } });

export const loadGeneralCampaigns = query => client.get(general, { params: query });

export const downloadTargetListCSV = ({ campaignId }) =>
    client.get(targetList(campaignId));

export const downloadAnswerReportCSV = ({ topicId }) =>
    client.get(answers(topicId));

export const downloadUsersListCSV = () =>
    client.get(allUsers);

export const deleteCampaign = campaignId =>
    client.delete(remove(campaignId));
