import React from 'react';
import PropTypes from 'prop-types';

const Chip = ({ children, variant, className }) => {
    const variants = {
        blue: 'bg-blue-500 text-white',
        purple: 'bg-purple-500 text-white',
        orange: 'bg-orange-700 text-white',
        'light-blue': 'bg-blue-100 text-blue-600',
        'light-purple': 'bg-purple-200 text-purple-600',
        'light-orange': 'bg-orange-200 text-orange-800',
    };

    return (
        <div className={`${variants[variant]} body-s px-2 py-1 rounded-full w-fit ${className}`}>
            {children}
        </div>
    );
};

Chip.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['blue', 'purple', 'orange', 'light-blue', 'light-purple', 'light-orange']),
};

Chip.defaultProps = {
    variant: 'blue',
    className: '',
};

export default Chip;
