import { createAction } from 'redux-actions';
import * as Actions from './actionTypes';
import * as selectors from './selectors';

export { default as reducer } from './reducer';
export * from './actions';
export { selectors };

export const setPostAuthRedirectUrl = createAction(Actions.SET_POST_AUTH_REDIRECT_URL);
export const fetchUser = createAction(Actions.FETCH_USER);
export const fetchUserFailed = createAction(Actions.FETCH_USER_FAILED);
export const fetchUserCompleted = createAction(Actions.FETCH_USER_COMPLETED);
export const logoutUser = createAction(Actions.LOGOUT);
export const markDisclaimer = createAction(Actions.MARK_DISCLAIMER);
export const markDisclaimerRead = createAction(Actions.MARK_DISCLAIMER_READ);
export const markDisclaimerNotRead = createAction(Actions.MARK_DISCLAIMER_NOT_READ);
export const markDisclaimerReadFailed = createAction(Actions.MARK_DISCLAIMER_READ_FAILED);
export const redirectToUnseenQuestions = createAction(Actions.REDIRECT_TO_UNSEEN_QUESTIONS);
export const disableCtaPopup = createAction(Actions.DISABLE_CTA_POPUP);
export const disableCtaPopupCompleted = createAction(Actions.DISABLE_CTA_POPUP_COMPLETED);
export const disableCtaPopupFailed = createAction(Actions.DISABLE_CTA_POPUP_FAILED);

export { Actions };

export { ID } from './actionTypes';
export * from './selectors';
