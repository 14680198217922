import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Button } from 'semantic-ui-react';
import ExpandableTable from '../../../../../components/ExpandableTable';
import UnassignedProgramRow from './UnassignedProgramRow';
import localization from '../../../../../localization';
import './style.scss';

const {
    admin: {
        globalHcmpList: {
            programsWithoutPriority,
        },
    },
} = localization;

const UnassignedPrograms = ({
    unassignedPrograms,
    numOfAssignedHcmps = 0,
    confirmHcmp,
    loading,
}) => {
    const priorityOptions = [];
    const numOfItems = unassignedPrograms.length;
    for (let i = 1; i <= numOfAssignedHcmps + 1; i += 1) {
        priorityOptions.push({
            value: i,
            text: `${i}`,
        });
    }
    return (<ExpandableTable
        renderHeader={(onClickEvent, opened) => (
            <Fragment>
                <Table.Cell colSpan="6" className={numOfItems && !opened ? 'row-active' : 'row-inactive'}>
                    <div className="row">
                        <Icon name="info circle" className="icon" />
                        {`${programsWithoutPriority} (${unassignedPrograms.length})`}
                        <div className="chevron-container">
                            <Button
                                className="action-button"
                                onClick={onClickEvent}
                                icon={`chevron ${opened ? 'up' : 'down'}`}
                                basic
                            />
                        </div>
                    </div>
                </Table.Cell>
            </Fragment>
        )}
        renderBody={() => (
            <Fragment>
                {unassignedPrograms && unassignedPrograms.map(x => (
                    <UnassignedProgramRow
                        key={`${x.id}-${numOfAssignedHcmps}`}
                        hcmpId={x.hcmpPrgId}
                        name={x.name}
                        globalPriority={numOfAssignedHcmps + 1}
                        priorityOptions={priorityOptions}
                        confirmHcmp={confirmHcmp}
                        loading={loading}
                    />
                ))}
            </Fragment>
        )}
    />);
};

UnassignedPrograms.propTypes = {
    unassignedPrograms: PropTypes.arrayOf(PropTypes.shape).isRequired,
    numOfAssignedHcmps: PropTypes.number.isRequired,
    confirmHcmp: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default UnassignedPrograms;
