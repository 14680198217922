import React from 'react';
import AdButlerAd from '../components/AdButlerAd';
import { useViewportWidth } from './ViewportWidthContext';

// Use this hook when an ad campaign has a 728x90 desktop ad
// and a 300x250 (or 320x50) mobile ad. Not every campaign uses
// ads of these sizes, but it's a common-enough use-case.

const getZoneId = (page, placement, width, height) =>
    process.env[`REACT_APP_AD_BUTLER_${page}_${placement}_${width}x${height}`];

// This determines if we should show a mobile or desktop banner ad.
// It is independent of the breakpoints defined by the rest of the site.
const MIN_DESKTOP_WIDTH_PX = 1000;

const useAdButler = (page, placement, resourceId) => {
    const shouldShowDesktopAds = useViewportWidth() > MIN_DESKTOP_WIDTH_PX;
    const zoneDesktop728x90 = getZoneId(page, placement, 728, 90);
    const zoneMobile300x250 = getZoneId(page, placement, 300, 250);
    const zoneMobile320x50 = getZoneId(page, placement, 320, 50);

    if (shouldShowDesktopAds) {
        return <AdButlerAd zone={zoneDesktop728x90} width={728} height={90} resourceId={resourceId} />;
    }
    return (
        <>
            <AdButlerAd zone={zoneMobile300x250} width={300} height={250} resourceId={resourceId} />
            <AdButlerAd zone={zoneMobile320x50} width={320} height={50} resourceId={resourceId} />
        </>
    );
};


export default useAdButler;
