import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from '@reduxjs/toolkit';
// @no-extraneous-dependencies
import QuestionFeedContainer from './container';
import { redirectStateHandler } from '../../components/Enhancers';
import questionsSelector, { loadingSelector } from './selectors';
import { hasManagementPermission } from '../Thread/Answers/selectors';

const mapStateToProps = (state, { isMyFeed = false }) => ({
    questions: questionsSelector(state, isMyFeed),
    loading: loadingSelector(state),
    hasManagementPermission: hasManagementPermission(state),
});

/* eslint-disable */
export default compose(
    withRouter,
    connect(mapStateToProps),
    redirectStateHandler,
)(QuestionFeedContainer);
