import * as Immutable from 'seamless-immutable';
import { createTransform } from 'redux-persist';
import { pick } from './data/index';

/**
 * Transforms the state to an immutable before storing it into a reducer
 */
export const immutableTransform = createTransform(
    state => state,
    state => Immutable.from(state), {},
);

const persistID = '_persist';

/**
 * Merges the states from different sources using Immutable.merge
 * @param {*} inboundState the state being rehydrated from storage
 * @param {*} originalState the state before the REHYDRATE action
 * @param {*} reducedState the store state after the REHYDRATE action
 * but before the reconcilliation into final "rehydrated" state.
 */
export const immutableReconciliator = (
    inboundState = {},
    originalState,
    reducedState,
) => {
    const newState = { ...reducedState };
    Object.keys(reducedState).forEach((reducerKey) => {
        if (reducerKey === persistID || originalState[reducerKey] !== reducedState[reducerKey]) {
            return;
        }
        newState[reducerKey] = Immutable.isImmutable(newState[reducerKey])
            ? newState[reducerKey].merge(inboundState[reducerKey] || {}, { deep: true })
            : { ...newState[reducerKey], ...inboundState[reducerKey] };
    });

    return newState;
};


const pickState = ({ whitelists = {} }) => (state, reducer) => {
    if (!whitelists[reducer]) {
        return state;
    }
    return pick(state, whitelists[reducer]);
};
/**
 * Stores and reads only whitelisted reducer data
 * @param {object} config
 * @param {object} config.whitelists object with reducer names as keys
 * object with reducer names as keys
 * and arrays of attributes to whitelist
 */
export const whiteListFilterTransform = config => createTransform(
    pickState(config),
    pickState(config),
);
