import { put, takeLatest, all, call } from 'redux-saga/effects';
import {
    GET_GLOBAL_HCMP_LIST,
    CHANGE_HCMP_GLOBAL_STATUS,
    CHANGE_HCMP_GLOBAL_PRIORITY,
    CONFIRM_HCMP_GLOBAL,
} from './actionTypes';
import {
    getGlobalHcmpListCompleted,
    getGlobalHcmpListFailed,
    changeHcmpGlobalStatusCompleted,
    changeHcmpGlobalStatusFailed,
    changeHcmpGlobalPriorityCompleted,
    changeHcmpGlobalPriorityFailed,
    confirmHcmpGlobalStatusCompleted,
    confirmHcmpGlobalStatusFailed,
} from './actions';
import {
    getGlobalHcmpList,
    changeGlobalHcmpStatus,
    changeHcmpGlobalPriority,
    confirmGlobalHcmp,
} from './api';

function* getGlobalHcmpListSaga() {
    try {
        const hcmpListResponse = yield call(getGlobalHcmpList);
        yield put(getGlobalHcmpListCompleted(hcmpListResponse));
    } catch (error) {
        yield put(getGlobalHcmpListFailed({ error }));
    }
}

function* changeGlobalHcmpStatusSaga({ payload: { hcmpId } }) {
    try {
        yield call(changeGlobalHcmpStatus, hcmpId);
        yield put(changeHcmpGlobalStatusCompleted(hcmpId));
    } catch (error) {
        yield put(changeHcmpGlobalStatusFailed({ error }));
    }
}

function* confirmGlobalHcmpStatusSaga({ payload: { hcmpId, priority } }) {
    try {
        yield call(confirmGlobalHcmp, hcmpId, priority);
        yield put(confirmHcmpGlobalStatusCompleted({ hcmpId, priority }));
    } catch (error) {
        yield put(confirmHcmpGlobalStatusFailed({ error }));
    }
}

function* changeGlobalHcmpPrioritySaga({ payload: { hcmpId, priority } }) {
    try {
        yield call(changeHcmpGlobalPriority, hcmpId, priority);
        yield put(changeHcmpGlobalPriorityCompleted({ hcmpId, priority }));
    } catch (error) {
        yield put(changeHcmpGlobalPriorityFailed({ error }));
    }
}

function* hcmpGlobalSaga() {
    yield all([
        takeLatest(GET_GLOBAL_HCMP_LIST, getGlobalHcmpListSaga),
        takeLatest(CHANGE_HCMP_GLOBAL_STATUS, changeGlobalHcmpStatusSaga),
        takeLatest(CONFIRM_HCMP_GLOBAL, confirmGlobalHcmpStatusSaga),
        takeLatest(CHANGE_HCMP_GLOBAL_PRIORITY, changeGlobalHcmpPrioritySaga),
    ]);
}

export default hcmpGlobalSaga;
