import React from 'react';
import PropTypes from 'prop-types';
import Logo1x from './Healthcasts-Logo.png';
import Logo2x from './Healthcasts-Logo@2x.png';
import Logo3x from './Healthcasts-Logo@3x.png';
import AdminLogo1x from './Healthcasts-Admin-Logo.png';
import AdminLogo2x from './Healthcasts-Admin-Logo@2x.png';
import AdminLogo3x from './Healthcasts-Admin-Logo@3x.png';
import links from '../../../links';

const AdminLogo = () => (
    <div className="flex self-center">
        <img
            className="max-w-full max-h-full"
            src={AdminLogo1x}
            srcSet={`${AdminLogo1x} 1x, ${AdminLogo2x} 2x, ${AdminLogo3x} 3x`}
            alt="Healthcasts Admin logo"
        />
    </div>
);

const DefaultLogo = () => (
    <a href={links.consensusMd}>
        <div className="flex self-center">
            <img
                className="max-w-full max-h-full"
                src={Logo1x}
                srcSet={`${Logo1x} 1x, ${Logo2x} 2x, ${Logo3x} 3x`}
                alt="Healthcasts logo"
            />
        </div>
    </a>
);

const Logo = ({ isAdmin }) => (isAdmin ? <AdminLogo /> : <DefaultLogo />);

Logo.propTypes = {
    isAdmin: PropTypes.bool,
};

Logo.defaultProps = {
    isAdmin: false,
};

export default Logo;
