import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from '../../services/navigation';
import { ID as SessionID } from '../../services/session';
import { roleNameSelector } from '../../services/session/selectors';
import links from '../../links';
import { chooseRedirectPath } from '../../RouteConfig';

class NotFoundPage extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        redirect: PropTypes.func.isRequired,
        roleName: PropTypes.string.isRequired,
    }

    componentDidMount = () => {
        const {
            isAuthenticated,
            redirect,
            roleName,
        } = this.props;

        const authRedirectLink = chooseRedirectPath(roleName);

        const handleRedirect = () => redirect({
            pathname: isAuthenticated ? authRedirectLink : links.login,
            state: { redirected: true },
        });
        /* redirect cannot be called synchronously because ConnectedRouter has
        to be mounted first to handle LOCATION_CHANGE */
        setImmediate(handleRedirect);
    }

    render = () => null
}

const mapStateToProps = (state) => {
    const {
        [SessionID]: {
            id,
        },
    } = state;

    return {
        isAuthenticated: id !== null,
        roleName: roleNameSelector(state),
    };
};

export default connect(mapStateToProps, { redirect: replace })(NotFoundPage);
