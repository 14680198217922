import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Dropdown, Button } from 'semantic-ui-react';
import localization from '../../../../../../localization';

const {
    admin: {
        globalHcmpList: {
            assignPriority,
            confirm,
            disable,
        },
    },
} = localization;

class UnassginedProgramRow extends Component {
    static propTypes = {
        hcmpId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        priorityOptions: PropTypes.arrayOf(PropTypes.shape).isRequired,
        confirmHcmp: PropTypes.func.isRequired,
        globalPriority: PropTypes.number.isRequired,
        loading: PropTypes.bool.isRequired,
    }

    state = {
        selectedPriority: this.props.globalPriority,
    }

    onChangePriorityHandler = (event, { value }) => {
        this.setState({ selectedPriority: value });
    }

    render() {
        const {
            hcmpId,
            name,
            priorityOptions,
            confirmHcmp,
            loading,
        } = this.props;
        return (
            <Table.Row>
                <Table.Cell width={1}>{hcmpId}</Table.Cell>
                <Table.Cell width={7}>{name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <span className="assign-priority-text">
                        {assignPriority}
                    </span>
                    <Dropdown
                        button
                        scrolling
                        disabled={loading}
                        value={this.state.selectedPriority}
                        options={priorityOptions}
                        onChange={this.onChangePriorityHandler}
                        className="programs-dropdown"
                    />
                </Table.Cell>
                <Table.Cell width={3} textAlign="right">
                    <Button
                        disabled={loading}
                        size="mini"
                        onClick={() => confirmHcmp({
                            hcmpId,
                            priority: 0,
                        })}
                    >
                        {disable}
                    </Button>
                    <Button
                        primary
                        disabled={loading}
                        size="mini"
                        onClick={() => confirmHcmp({
                            hcmpId,
                            priority: this.state.selectedPriority,
                        })}
                    >
                        {confirm}
                    </Button>
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default UnassginedProgramRow;
