import { withStateHandlers } from 'recompose';

export default withStateHandlers(({
    location: { state: { redirected } = {} },
}) => ({
    msgVisible: redirected === true,
}), {
    hideMsg: () => () => ({
        msgVisible: false,
    }),
});
