export const emptySearchValue = '?searchValue=';

const links = {
    admin: {
        registration: '/admin/registration',
    },
    home: '/',
    registration: '/registration',
    login: '/login',
    logout: '/logout',
    noPermissions: '/no-permissions',
    redirecting: '/redirecting',
    topics: {
        myFeed: '/questions',
        otherFeed: '/questions_other',
        view: '/questions/:id',
    },
    infoPages: {
        support: 'https://healthcasts.com/contact',
        registrationSupportForm: 'https://healthcasts.com/contactAC-1',
        about: '/about',
        disclaimer: '/disclaimer',
        faq: '/faq',
    },
    moderator: {
        consensus: {
            submit: '/moderator/submitSummary',
            preview: '/moderator/summaryPreview/:id',
        },
        users: {
            new: '/moderator/users/new',
            edit: '/moderator/users/:id',
            rewards: '/moderator/users/:id/rewards',
            index: '/moderator/users',
        },
        questions: {
            new: '/moderator/questions/new',
            npis: '/moderator/questions-npis',
            edit: '/moderator/questions/:id',
            details: '/moderator/questions/:id/details',
            index: '/moderator/questions',
        },
        globalPrograms: {
            index: '/moderator/global_programs/overview',
            createEmails: '/moderator/global_programs/create_emails',
        },
        search: {
            index: '/moderator/search',
        },
        design: {
            components: '/moderator/design/components',
        },
    },
    consensus: {
        all: '/consensuses',
        view: '/consensuses/:id',
    },
    my: {
        profile: '/my/profile',
        rewards: '/my/rewards',
    },
    resources: process.env.REACT_APP_APP_HC_RESOURCES_URL,
    submitPatientCase: process.env.REACT_APP_SUBMIT_CASE_URL,
    consensusMd: process.env.REACT_APP_CONSENSUS_MD_URL,
    notFound: '/404', // can be any string that doesn't match a route in the router (and start with /)
};

export const privacyPolicyLink =
    'https://www.healthcasts.com/privacypolicy.html';

export const publicRoutes = Object.freeze([
    links.login,
    links.logout,
    links.noPermissions,
    links.registration,
    links.infoPages.support,
    links.infoPages.about,
    links.infoPages.disclaimer,
    links.infoPages.faq,
]);

export default links;
