import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { Comment, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { syncValidations, warn, submitValidations, commentLength } from '../../validations';
import { preSubmitValidation } from '../../../../../utils/form/validators';
import { editCommentFormName } from '../../constants';
import { AnswerCommentForm, AnswerCommentHeader, answerTypes } from '../../components';
import FlagModal from '../../../Flag';
import localization from '../../../../../localization';

const {
    topic: strings,
    common: commonStrings,
} = localization;

const {
    max: maxCommentLength,
    min: minCommentLength,
} = commentLength;

const CommentEditForm = reduxForm({
    form: editCommentFormName,
    enableReinitialize: true,
    validate: syncValidations(maxCommentLength),
    warn: warn(maxCommentLength),
})(AnswerCommentForm);

const AnswerComment = ({
    text,
    id,
    createdAt,
    updatedAt,
    createdByUser: {
        firstName,
        lastName,
        specialty,
    } = {},
    topicExpired,
    ownAnswer: ownComment,
    showModal,
    deleteAnswer,
    openAnswerEditForm,
    editMode,
    updateAnswer,
    canEditAll,
    // meta
    interactionsDisabled,
}) => {
    let content;

    const specialtyName = (specialty && specialty.name) || undefined;

    if (editMode) {
        content = (
            <CommentEditForm
                onSave={preSubmitValidation(
                    submitValidations(minCommentLength),
                    answer => updateAnswer({ ...answer }),
                )}
                initialValues={{
                    text,
                    id,
                }}
                submitButtonProps={{
                    label: commonStrings.save,
                    icon: 'save',
                }}
            >
                <Comment.Action>
                    <Button
                        onClick={() => openAnswerEditForm(null)}
                        content={commonStrings.cancel}
                    />
                </Comment.Action>
            </CommentEditForm>);
    } else {
        const actions = (
            <span>
                {!ownComment && <FlagModal commentId={id} content={text} />}
                {((ownComment && !topicExpired) || canEditAll) && (
                    <Fragment>
                        <Button
                            icon
                            loading={interactionsDisabled}
                            onClick={() => {
                                if (!interactionsDisabled) {
                                    showModal({
                                        hideOnPositiveClick: true,
                                        content: strings.deleteComment,
                                        header: strings.deleteConfirmation,
                                        positiveResponseAction: () => deleteAnswer({ id }),
                                    });
                                }
                            }}
                        >
                            {commonStrings.delete}
                            <Icon name="trash" />
                        </Button>
                        <Button
                            icon
                            disabled={interactionsDisabled}
                            className="edit-button"
                            onClick={() => {
                                if (!interactionsDisabled) {
                                    openAnswerEditForm(id);
                                }
                            }}
                        >
                            {commonStrings.edit}
                            <Icon name="edit" />
                        </Button>
                    </Fragment>
                )}
            </span>
        );

        content = (
            <Fragment>
                <div className="comment-actions">
                    <span className="metadata">{specialtyName}</span>
                    {actions}
                </div>
                <Comment.Text>{text}</Comment.Text>
            </Fragment>);
    }
    const author = `${firstName} ${lastName}`;
    return (
        <Comment id={`comment-${id}`} className={`answer-comment ${!editMode && 'view-mode'}`}>
            <Comment.Content>
                <div className="answer-header">
                    <AnswerCommentHeader
                        author={author}
                        updatedAt={updatedAt}
                        createdAt={createdAt}
                        type={answerTypes.comment}
                    />
                </div>
                <Comment.Text className="content-full">{content}</Comment.Text>
            </Comment.Content>
        </Comment>
    );
};

AnswerComment.propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdByUser: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    }).isRequired,
    topicExpired: PropTypes.bool.isRequired,
    ownAnswer: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
    openAnswerEditForm: PropTypes.func.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    updatedAt: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    canEditAll: PropTypes.bool,
    // meta
    interactionsDisabled: PropTypes.bool,
};

AnswerComment.defaultProps = {
    editMode: false,
    canEditAll: false,
    interactionsDisabled: false,
};

export default AnswerComment;
