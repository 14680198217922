import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { setPostAuthRedirectUrl as setPostAuthRedirectUrlAction } from '../../services/session';
import { Permissions } from '../../permissions';
import links from '../../links';

const domain = process.env.AUTH0_DOMAIN;
const audience = process.env.AUTH0_AUDIENCE;
const clientId = process.env.AUTH0_CLIENT_ID;

const AuthProvider = ({ children, setPostAuthRedirectUrl }) => {
    // Auth0 stores the url the user was attempting to access before they were
    // redirected to the login screen. After Auth0 redirects back to this site
    // after a successful login, store the `returnTo` URL, and redirect to that
    // page after the initial "fetch user" call has been completed.
    const handleRedirect = useCallback((appState) => {
        setPostAuthRedirectUrl(appState?.returnTo);
    }, [setPostAuthRedirectUrl]);

    const scope = `offline_access openid profile email ${Object.values(Permissions).join(' ')}`;

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: `${window.location.origin}${links.redirecting}`,
                audience,
                scope,
            }}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            onRedirectCallback={handleRedirect}
        >
            {children}
        </Auth0Provider>
    );
};

const mapDispatchToProps = dispatch => ({
    setPostAuthRedirectUrl: postAuthRedirectUrl =>
        dispatch(setPostAuthRedirectUrlAction({ postAuthRedirectUrl })),
});

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
    setPostAuthRedirectUrl: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AuthProvider);
