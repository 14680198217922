import React, { Component } from 'react';
import PropTypes from 'prop-types';

const duration = 1000;

class FlashIndicator extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
            PropTypes.string,
        ]).isRequired,
        active: PropTypes.bool.isRequired,
        makeInactive: PropTypes.func.isRequired,
        activeClass: PropTypes.string.isRequired,
        inactiveClass: PropTypes.string,
    }

    static defaultProps = {
        inactiveClass: '',
    }

    state = {
        flashApplied: false,
    }

    componentDidMount() {
        if (this.props.active) {
            this.startAnimation();
        }
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps({ active: nextActive }) {
        if (this.props.active === false && nextActive) {
            this.startAnimation();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    setTimer() {
        const { makeInactive } = this.props;
        this.timer = setTimeout(() => this.setState({
            flashApplied: false,
        }, makeInactive), duration);
    }

    startAnimation() {
        this.setState({
            flashApplied: true,
        }, this.setTimer);
    }

    render() {
        const { children, activeClass, inactiveClass } = this.props;
        const { flashApplied } = this.state;
        return (
            <div
                className={flashApplied ? activeClass : inactiveClass}
            >
                {children}
            </div>
        );
    }
}

export default FlashIndicator;
