import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

import {
    LOAD_USERS,
    LOAD_USERS_COMPLETED,
    DELETE_USER,
    DELETE_USER_COMPLETED,
} from '../../../../data/users';

const initialState = Immutable.from({
    users: [],
    total: 0,
    userToDelete: null,
    loading: false,
});

export default handleActions({
    [LOAD_USERS]: state => state.set('loading', true),
    [LOAD_USERS_COMPLETED]: (state, {
        payload: { data: users, meta: { total } },
    }) => state.merge({ users, total, loading: false }),
    [DELETE_USER]: (state, { payload: { id } }) => state.set('userToDelete', id),
    [DELETE_USER_COMPLETED]: state => state.merge({
        total: state.total - 1,
    }),
}, initialState);
