import React from 'react';
import { Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import links from '../../../links';
import localization from '../../../localization';
import { splitStringByDelimiter } from '../../../utils/misc';

const {
    about: {
        title,
        description1,
        description2,
        header1,
        content1,
        header2,
        content2,
        footer,
        faq,
    },
} = localization;

const footerParts = splitStringByDelimiter(footer);

export default () => (
    <div className="page-container">
        <Header content={title} />
        <p>{description1}</p>
        <p>{description2}</p>
        <Header content={header1} sub />
        <p>{content1}</p>
        <Header content={header2} sub />
        <p>{content2}</p>
        <br />
        <p>
            {footerParts[0]}
            <Link href={links.infoPages.faq} to={links.infoPages.faq}>{faq}</Link>
            {footerParts[1]}
        </p>
    </div>
);
