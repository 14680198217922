import client from '../../api';
import { topicPrograms } from '../../api/endpoints';

const {
    attachedPrograms,
    unattachedPrograms,
    programPriorityChange: programPriorityChangeApi,
    attachPrograms: attachProgramsApi,
    detachProgram: detachProgramApi,
    detachAllPrograms: detachAllProgramsApi,
} = topicPrograms;

export const getAttachedPrograms = topicId => client.get(attachedPrograms, {
    params: {
        topicId,
    },
});

export const getUnattachedPrograms = topicId => client.get(unattachedPrograms, {
    params: {
        topicId,
    },
});

export const programPriorityChange = (
    topicId,
    hcmpId,
    change,
) => client.post(programPriorityChangeApi, {
    data: {
        topicId,
        hcmpId,
        change,
    },
});

export const attachPrograms = (topicId, programs) => client.post(attachProgramsApi, {
    data: {
        topicId,
        hcmpIds: programs,
    },
});

export const detachProgram = (topicId, programId) => client.post(detachProgramApi, {
    data: {
        topicId,
        hcmpId: programId,
    },
});

export const detachAllPrograms = topicId => client.post(detachAllProgramsApi, {
    data: {
        topicId,
    },
});
