import { createAction } from 'redux-actions';
import {
    DOWNLOAD_TARGET_LIST,
    DOWNLOAD_TARGET_LIST_COMPLETED,
    DOWNLOAD_TARGET_LIST_FAILED,
    DOWNLOAD_ANSWER_REPORT,
    DOWNLOAD_ANSWER_REPORT_COMPLETED,
    DOWNLOAD_ANSWER_REPORT_FAILED,
    EXPORT_USERS_LIST,
    EXPORT_USERS_LIST_COMPLETE,
    EXPORT_USERS_LIST_FAILED,
    LOAD_CAMPAIGNS,
    LOAD_CAMPAIGNS_COMPLETED,
    LOAD_CAMPAIGNS_FAILED,
    LOAD_GENERAL_CAMPAIGNS,
    LOAD_GENERAL_CAMPAIGNS_COMPLETED,
    LOAD_GENERAL_CAMPAIGNS_FAILED,
    CREATE_CAMPAIGN,
    CREATE_CAMPAIGN_COMPLETED,
    CREATE_CAMPAIGN_FAILED,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_COMPLETED,
    DELETE_CAMPAIGN_FAILED,
    CREATE_SPECIALTY_CAMPAIGN,
    CREATE_SPECIALTY_CAMPAIGN_COMPLETED,
    CREATE_SPECIALTY_CAMPAIGN_FAILED,
    CREATE_NPI_CAMPAIGN,
    CREATE_NPI_CAMPAIGN_COMPLETED,
    CREATE_NPI_CAMPAIGN_FAILED,
    DELETE_GENERAL_CAMPAIGN,
    DELETE_GENERAL_CAMPAIGN_COMPLETED,
    DELETE_GENERAL_CAMPAIGN_FAILED,
} from './actionTypes';

export const downloadTargetList = createAction(DOWNLOAD_TARGET_LIST);
export const downloadTargetListCompleted = createAction(DOWNLOAD_TARGET_LIST_COMPLETED);
export const downloadTargetListFailed = createAction(DOWNLOAD_TARGET_LIST_FAILED);
export const downloadAnswerReport = createAction(DOWNLOAD_ANSWER_REPORT);
export const downloadAnswerReportCompleted = createAction(DOWNLOAD_ANSWER_REPORT_COMPLETED);
export const downloadAnswerReportFailed = createAction(DOWNLOAD_ANSWER_REPORT_FAILED);
export const exportUsersList = createAction(EXPORT_USERS_LIST);
export const exportUsersListCompleted = createAction(EXPORT_USERS_LIST_COMPLETE);
export const exportUsersListFailed = createAction(EXPORT_USERS_LIST_FAILED);
export const loadCampaigns = createAction(LOAD_CAMPAIGNS);
export const loadCampaignsCompleted = createAction(LOAD_CAMPAIGNS_COMPLETED);
export const loadCampaignsFailed = createAction(LOAD_CAMPAIGNS_FAILED);
export const createCampaign = createAction(CREATE_CAMPAIGN);
export const createCampaignCompleted = createAction(CREATE_CAMPAIGN_COMPLETED);
export const createCampaignFailed = createAction(CREATE_CAMPAIGN_FAILED);
export const deleteCampaign = createAction(DELETE_CAMPAIGN);
export const deleteCampaignCompleted = createAction(DELETE_CAMPAIGN_COMPLETED);
export const deleteCampaignFailed = createAction(DELETE_CAMPAIGN_FAILED);

export const loadGeneralCampaigns = createAction(LOAD_GENERAL_CAMPAIGNS);
export const loadGeneralCampaignsCompleted = createAction(LOAD_GENERAL_CAMPAIGNS_COMPLETED);
export const loadGeneralCampaignsFailed = createAction(LOAD_GENERAL_CAMPAIGNS_FAILED);
export const createSpecialtyCampaign = createAction(CREATE_SPECIALTY_CAMPAIGN);
export const createSpecialtyCampaignCompleted = createAction(CREATE_SPECIALTY_CAMPAIGN_COMPLETED);
export const createSpecialtyCampaignFailed = createAction(CREATE_SPECIALTY_CAMPAIGN_FAILED);
export const createNpiCampaign = createAction(CREATE_NPI_CAMPAIGN);
export const createNpiCampaignCompleted = createAction(CREATE_NPI_CAMPAIGN_COMPLETED);
export const createNpiCampaignFailed = createAction(CREATE_NPI_CAMPAIGN_FAILED);
export const deleteGeneralCampaign = createAction(DELETE_GENERAL_CAMPAIGN);
export const deleteGeneralCampaignCompleted = createAction(DELETE_GENERAL_CAMPAIGN_COMPLETED);
export const deleteGeneralCampaignFailed = createAction(DELETE_GENERAL_CAMPAIGN_FAILED);
