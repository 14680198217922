import { connect } from 'react-redux';
import { ID as ContainerID } from '../actionTypes';
import { showProgram } from '../actions';
import NoticeModal from './component';

const mapStateToProps = (state) => {
    const {
        [ContainerID]: {
            addNoticeVisible,
            noticeText,
        },
    } = state;

    return {
        open: addNoticeVisible,
        noticeText,
    };
};

export default connect(mapStateToProps, { showProgram })(NoticeModal);
