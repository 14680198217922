import { connect } from 'react-redux';
import { onlyUpdateForKeys } from 'recompose';
import { compose } from '@reduxjs/toolkit';
import AnswerComment from './container';
import { ID as ContainerID } from '../../../actionTypes';
import { showModal as showModalAction } from '../../../../../services/sharedUIActions';
import {
    deleteAnswer,
    updateAnswer,
} from '../../../../../data/answers';
import { openAnswerEditForm } from '../../../actions';

const mapStateToProps = ({
    [ContainerID]: {
        editedAnswer,
        deletedAnswer,
    },
}, {
    id,
}) => ({
    editMode: id === editedAnswer,
    interactionsDisabled: deletedAnswer === id,
});

export default compose(
    onlyUpdateForKeys(['text']),
    connect(mapStateToProps, {
        showModal: showModalAction,
        deleteAnswer,
        updateAnswer,
        openAnswerEditForm,
    }),
)(AnswerComment);
