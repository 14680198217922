import { connect } from 'react-redux';
import EmailList from './component';
import { openNewEmailModal, closeEmailError } from '../actions';
import { downloadTargetList, deleteCampaign } from '../../../../../data/campaigns/actions';
import { showModal } from '../../../../../services/sharedUIActions';
import { questionsDetailsSelector, campaignsDetailsSelector } from '../selectors';

const mapStateToProps = (state, { topicId }) => {
    const { errorCreatingCampaign, loadingEmailList } = questionsDetailsSelector(state);
    const {
        downloadingTargetList,
        deletingCampaign,
        campaignIdAction,
        campaigns,
    } = campaignsDetailsSelector(state);

    const emails = campaigns[topicId];

    return {
        emails,
        errorCreatingCampaign,
        downloadingTargetList,
        campaignIdAction,
        deletingCampaign,
        loadingEmailList,
    };
};

const mapDispatchToProps = {
    openNewEmailModal,
    closeEmailError,
    downloadTargetList,
    deleteCampaign,
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailList);
