import { handleActions } from 'redux-actions';
import * as Immutable from 'seamless-immutable';
import {
    UPDATE_ANSWER,
    UPDATE_ANSWER_COMPLETED,
    UPDATE_ANSWER_FAILED,
    DELETE_ANSWER,
    DELETE_ANSWER_COMPLETED,
    DELETE_ANSWER_FAILED,
    LOAD_ANSWERS,
    LOAD_ANSWERS_COMPLETED,
    LOAD_ANSWERS_FAILED,
    RATE_ANSWER,
    RATE_ANSWER_COMPLETED,
    RATE_ANSWER_FAILED,
    SHOW_ANSWERS,
    SHOW_ANSWERS_COMPLETED,
    SHOW_ANSWERS_FAILED,
} from './actions';
import { LOCATION_CHANGE } from '../../services/navigation';

export const initialState = Immutable.from({
    answers: [],
    loading: false,
    loaded: false, // marks that the data was loaded at least once
    updating: false, // marks that an answer is being updated or created
    deleting: false, // marks that an answer is being deleted
    areResponsesShown: false,
    showResponsesLoading: false,
    ratingInProgress: false, // self-explanatory
});

const reducer = handleActions({
    [UPDATE_ANSWER]: state => state.set('updating', true),
    [UPDATE_ANSWER_COMPLETED]:
        (state, { payload: { answer: { id: answerId, ...answer } } }) => {
            const answerIndex = state.answers.findIndex(({ id }) => answerId === id);
            let answers;
            if (answerIndex === -1) {
                (answers = state.answers.concat([{ id: answerId, ...answer }]));
            } else {
                const oldAnswer = state.answers[answerIndex];
                const newAnswer = Immutable.merge({
                    id: answerId,
                    ...oldAnswer,
                }, answer, { deep: true });
                // answers update doesn't return associated entities like ratings
                // so we merge the updated data with the new data
                answers = state.answers.set(answerIndex, newAnswer);
            }
            return state.merge({
                answers,
                updating: false,
            });
        },
    [UPDATE_ANSWER_FAILED]: state => state.set('updating', false),
    [DELETE_ANSWER]: state => state.set('deleting', true),
    [DELETE_ANSWER_COMPLETED]: (state, { payload: { id: answerId, deletedAt } }) => {
        const answerIndex = state.answers.findIndex(({ id }) => answerId === id);
        const answer = state.answers[answerIndex];
        let answers;
        if (answer.replyTo) { // don't show deleted comments
            answers = state.answers.filter(({ id }) => answerId !== id);
        } else { // only set deletedAt for answers
            answers = state.answers.set(answerIndex, {
                ...state.answers[answerIndex],
                deletedAt,
            });
        }
        return state.merge({
            answers,
            deleting: false,
        });
    },
    [DELETE_ANSWER_FAILED]: state => state.set('deleting', false),
    [LOAD_ANSWERS]: state => state.set('loading', true),
    [LOAD_ANSWERS_COMPLETED]: (state, { payload: { answers, showResponses } }) =>
        state.merge({
            answers,
            loading: false,
            loaded: true,
            areResponsesShown: showResponses,
        }),
    [LOAD_ANSWERS_FAILED]: state => state.set('loading', false),
    [RATE_ANSWER]: state => state.set('ratingInProgress', true),
    [RATE_ANSWER_COMPLETED]: (state, { payload: { id, rating } }) => {
        /* Updates the rating here after the server response
            can be moved to RATE_ANSWER for optimistic update
        */
        const ratedAnswerIndex = state.answers.findIndex(({ id: answerId }) => answerId === id);
        let ratedAnswer = state.answers[ratedAnswerIndex];
        if (rating === ratedAnswer.userVote) {
            const {
                rating: {
                    [rating]: currentRating,
                } = {},
            } = ratedAnswer;
            ratedAnswer = ratedAnswer.merge({
                userVote: null,
                rating: {
                    [rating]: currentRating - 1,
                },
            }, { deep: true });
        } else if (ratedAnswer.userVote !== undefined && ratedAnswer.userVote !== null) {
            const {
                rating: {
                    [rating]: currentRating = 0,
                    [ratedAnswer.userVote]: previousRating,
                } = {},
            } = ratedAnswer;
            ratedAnswer = ratedAnswer.merge({
                userVote: rating,
                rating: {
                    [rating]: currentRating + 1,
                    [ratedAnswer.userVote]: previousRating - 1,
                },
            }, { deep: true });
        } else {
            const {
                rating: {
                    [rating]: currentRating = 0,
                } = {},
            } = ratedAnswer;
            ratedAnswer = ratedAnswer.merge({
                userVote: rating,
                rating: {
                    [rating]: currentRating + 1,
                },
            }, { deep: true });
        }
        return state.merge({
            ratingInProgress: false,
            answers: state.answers.set(ratedAnswerIndex, ratedAnswer),
        });
    },
    [RATE_ANSWER_FAILED]: state => state.set('ratingInProgress', false),
    [SHOW_ANSWERS]: state => state.set('showResponsesLoading', true),
    [LOCATION_CHANGE]: state => state.set('showResponsesLoading', false),
    [SHOW_ANSWERS_COMPLETED]: (state, { payload: { showResponses } }) => state.merge({
        areResponsesShown: showResponses,
        showResponsesLoading: false,
    }),
    [SHOW_ANSWERS_FAILED]: state => state.merge({
        areResponsesShown: false,
        showResponsesLoading: false,
    }),
}, initialState);

export default reducer;
