import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ columns, data, className }) => {
    const keys = columns.map(column => column.key);
    return (
        <table className={className}>
            <thead>
                <tr>
                    {columns.map(column => (
                        <th className={column.className} key={column.heading}>{column.heading}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={`row-${i}`}>
                        {keys.map((key, j) => {
                            const clsName = columns[j].className || '';
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <td className={clsName} key={`cell-${i}-${j}`}>{row[key]}</td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        className: PropTypes.string,
    })),
    data: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
};

Table.defaultProps = {
    columns: [],
    data: [],
    className: '',
};

export default Table;
