import client from '../../api';
import { globalHcmp } from '../../api/endpoints';

const {
    index,
    changePriority,
    changeStatus,
    confirm,
} = globalHcmp;

export const getGlobalHcmpList = () => client.get(index);

export const changeHcmpGlobalPriority = (hcmpId, priority) => client.post(changePriority, {
    data: {
        hcmpId,
        priority,
    },
});

export const changeGlobalHcmpStatus = hcmpId => client.post(changeStatus, {
    data: {
        hcmpId,
    },
});

export const confirmGlobalHcmp = (hcmpId, priority) => client.post(confirm, {
    data: {
        hcmpId,
        priority,
    },
});
