import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useFormikContext } from 'formik';
import Error from './Error';

const Select = ({
    options,
    selectClasses,
    name,
    serverErrorMessage,
}) => {
    const { getFieldProps, touched, errors } = useFormikContext();
    const showError = touched[name] && errors[name];

    const combinedClasses = clsx(
        'block appearance-none w-full bg-white h-12 rounded-md text-base px-4 text-purple-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500',
        showError ? 'border-red-500 text-red-500' : 'border-gray-300',
        selectClasses,
    );

    return (
        <>
            <div className="relative">
                <select
                    className={combinedClasses}
                    id={name}
                    aria-invalid={showError ? 'true' : 'false'}
                    aria-describedby={`${name}-error`}
                    {...getFieldProps(name)}
                >
                    <option value="">Select</option>
                    {options.map(option => (
                        <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <ChevronDownIcon className="h-5 w-5 mr-2" />
                </div>
            </div>
            {showError && <Error id={`${name}-error`} message={errors[name]} />}
            {serverErrorMessage && !showError && <Error message={serverErrorMessage} />}
        </>
    );
};

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    selectClasses: PropTypes.string,
    name: PropTypes.string.isRequired,
    serverErrorMessage: PropTypes.string,
};

Select.defaultProps = {
    selectClasses: '',
    serverErrorMessage: null,
};

export default Select;
