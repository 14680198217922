import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Header, Grid, Form, Checkbox, Message } from 'semantic-ui-react';
import NewEmailModal from './NewEmail';
import NewNPIEmailModal from './NewNPIEmail';
import Navigation from '../../../../components/GlobalPrograms/Navigation';
import EmailList from './EmailList/component';
import { LoadingIndicator } from '../../../../components';
import localization from '../../../../localization';

const {
    admin: {
        globalHcmpList: {
            programs: {
                createEmails,
            },
        },
    },
} = localization;

class CreateEmails extends Component {
    state = {
        checkedSpecialties: [],
        isCheckedSpecialty: false,
        isValidNPITextField: false,
        isSelectedAll: false,
        npiRows: [],
    };
    componentDidMount() {
        this.validateNPITextField(window.localStorage.getItem('Programs.CreateEmails.npiTextarea'));
    }
    toggleSpecialty(id) {
        if (this.state.checkedSpecialties.indexOf(id) !== -1) {
            this.setState((prevState) => {
                const checked = prevState.checkedSpecialties;
                checked.splice(prevState.checkedSpecialties.indexOf(id), 1);
                return {
                    checkedSpecialties: checked,
                    isSelectedAll: false,
                };
            });
        } else {
            this.setState((prevState) => {
                const checked = prevState.checkedSpecialties;
                checked.push(id);
                if (checked.length === this.props.specialties.length) {
                    return {
                        checkedSpecialties: checked,
                        isSelectedAll: true,
                    };
                }
                return {
                    checkedSpecialties: checked,
                };
            });
        }
        this.state.isCheckedSpecialty = !!this.state.checkedSpecialties.length;
        this.setState(() => {
            const isCheckedSpecialty = !!this.state.checkedSpecialties.length;
            return {
                isCheckedSpecialty,
            };
        });
    }
    toggleAllSpecialties = () => {
        if (this.state.checkedSpecialties.length > 0 &&
            this.state.checkedSpecialties.length === this.props.specialties.length) {
            this.setState((prevState) => {
                const checked = prevState.checkedSpecialties;
                checked.splice(0, prevState.checkedSpecialties.length);
                return {
                    checkedSpecialties: checked,
                    isCheckedSpecialty: false,
                    isSelectedAll: false,
                };
            });
        } else {
            this.setState((prevState) => {
                const checked = prevState.checkedSpecialties;
                checked.splice(0, prevState.checkedSpecialties.length);
                this.props.specialties.map(specialty => checked.push(specialty.id));
                return {
                    checkedSpecialties: checked,
                    isCheckedSpecialty: true,
                    isSelectedAll: true,
                };
            });
        }
    };
    validateNPITextField = (value) => {
        const npiTextField = `${value}\n`;
        const csvPattern = /(^([0-9]+(?:,[0-9]+)(\\r\\n|\\n|$)*)$)|^([0-9]*)$/gm;
        const newLinePattern = /(\r|\n)/g;
        const foundNewLines = npiTextField.match(newLinePattern);
        const foundRows = npiTextField.match(csvPattern);
        if (foundRows[0] !== '' && foundRows.length > foundNewLines.length) {
            this.setState((prevState) => {
                const rows = prevState.npiRows;
                rows.splice(0, prevState.npiRows.length);
                value.match(csvPattern).map(row => rows.push(row));
                return {
                    isValidNPITextField: true,
                    npiRows: rows,
                };
            });
        } else {
            this.setState((prevState) => {
                const rows = prevState.npiRows;
                rows.splice(0, prevState.npiRows.length);
                return {
                    isValidNPITextField: false,
                    npiRows: rows,
                };
            });
        }
    };
    saveNPIFieldToLocalStorage = (value) => {
        window.localStorage.setItem('Programs.CreateEmails.npiTextarea', value);
    };
    render() {
        const {
            specialties,
            loadingSpecialties,
            openNewEmailModal,
            openNewNPIEmailModal,
            showModal,
            emails,
            loadingCampaigns,
            deleteCampaign,
            downloadTargetList,
            downloadingTargetList,
            campaignIdAction,
            deletingCampaign,
            creatingTargetList,
            errorCreatingCampaign,
            createCampaignErrorMessage,
            loadGeneralCampaigns,
            pageNumber,
            pageSize,
            totalEmails,
        } = this.props;
        const {
            checkedSpecialties,
            isValidNPITextField,
            isCheckedSpecialty,
            isSelectedAll,
            npiRows,
        } = this.state;
        const rows = specialties.map(({ id, name }) =>
            (
                <Form.Field key={id}>
                    <Checkbox
                        checked={checkedSpecialties.indexOf(id) !== -1}
                        label={<label>{name}</label>}
                        onChange={() => {
                            this.toggleSpecialty(id);
                        }}
                    />
                </Form.Field>
            ));
        return (
            <div className="page-container">
                <div className="inline-align">
                    <Header>{createEmails.createEmails}</Header>
                    <Navigation active="create_emails" />
                </div>
                <Divider className="expanded" />
                <NewEmailModal checkedSpecialties={checkedSpecialties} />
                <NewNPIEmailModal npiRows={npiRows} />
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <LoadingIndicator active={loadingSpecialties} />
                            <Form>
                                <Form.Group style={{ maxHeight: '450px', overflow: 'scroll', margin: 0 }} grouped>
                                    {rows}
                                </Form.Group>
                                <Form.Field>
                                    <Checkbox
                                        checked={isSelectedAll}
                                        onChange={() => {
                                            this.toggleAllSpecialties();
                                        }}
                                        label={<label>{createEmails.selectAll}</label>}
                                    />
                                </Form.Field>
                                <Form.Button
                                    positive
                                    disabled={!isCheckedSpecialty}
                                    icon
                                    onClick={() => openNewEmailModal()}
                                >{createEmails.createEmail}
                                </Form.Button>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <Message
                                size="tiny"
                                icon="info"
                                content={
                                    <div>
                                        <strong>{createEmails.formatTitle}</strong>
                                        {createEmails.formatDescription}
                                    </div>
                                }
                            />
                            <Form>
                                <Form.TextArea
                                    style={{ minHeight: '425px' }}
                                    placeholder={createEmails.npiPlaceholder}
                                    onChange={(e, { value }) => {
                                        this.validateNPITextField(value);
                                        this.saveNPIFieldToLocalStorage(value);
                                    }
                                    }
                                    value={(typeof window.localStorage.getItem('Programs.CreateEmails.npiTextarea') !== 'undefined') ?
                                        window.localStorage.getItem('Programs.CreateEmails.npiTextarea') :
                                        null
                                    }
                                />
                                <Form.Button
                                    disabled={!isValidNPITextField}
                                    positive
                                    icon
                                    onClick={() => openNewNPIEmailModal()}
                                >{createEmails.createEmail}
                                </Form.Button>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <Grid>
                    <Grid.Row>
                        {loadingCampaigns}
                        <Grid.Column>
                            <EmailList
                                emails={emails}
                                showModal={showModal}
                                downloadTargetList={downloadTargetList}
                                deleteCampaign={deleteCampaign}
                                creatingTargetList={creatingTargetList}
                                loadingEmailList={loadingCampaigns}
                                deletingCampaign={deletingCampaign}
                                downloadingTargetList={downloadingTargetList}
                                campaignIdAction={campaignIdAction}
                                errorCreatingCampaign={errorCreatingCampaign}
                                createCampaignErrorMessage={createCampaignErrorMessage}
                                specialties={specialties}
                                loadGeneralCampaigns={loadGeneralCampaigns}
                                pageNumber={pageNumber}
                                pageSize={pageSize}
                                total={totalEmails}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}


CreateEmails.propTypes = {
    specialties: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    emails: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        createdAt: PropTypes.string,
    })),
    loadingSpecialties: PropTypes.bool.isRequired,
    loadingCampaigns: PropTypes.bool.isRequired,
    downloadingTargetList: PropTypes.bool.isRequired,
    openNewEmailModal: PropTypes.func.isRequired,
    openNewNPIEmailModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    deleteCampaign: PropTypes.func.isRequired,
    downloadTargetList: PropTypes.func.isRequired,
    campaignIdAction: PropTypes.number,
    deletingCampaign: PropTypes.bool.isRequired,
    creatingTargetList: PropTypes.bool.isRequired,
    errorCreatingCampaign: PropTypes.bool.isRequired,
    createCampaignErrorMessage: PropTypes.string,
    loadGeneralCampaigns: PropTypes.func.isRequired,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    totalEmails: PropTypes.number,
};

CreateEmails.defaultProps = {
    emails: [],
    campaignIdAction: null,
    createCampaignErrorMessage: null,
    pageNumber: 1,
    pageSize: 1,
    totalEmails: 0,
};
export default CreateEmails;
