import client from '../../api';
import { comments } from '../../api/endpoints';

const {
    index,
    singleComment,
    ratings,
    createAnswersView: createAnswersViewCall,
} = comments;

export const loadAnswers = topicId => client.get(index, { params: { topicId } });

export const createAnswer = answer => client.post(index, { data: answer });

export const deleteAnswer = answerId => client.delete(singleComment(answerId));

export const updateAnswer = (answerId, answer) =>
    client.patch(singleComment(answerId), {
        data: answer,
    });

export const rateAnswer = (answerId, rating) =>
    client.post(ratings(answerId), { data: { rating } });

export const createAnswersView = data =>
    client.post(createAnswersViewCall, { data });
