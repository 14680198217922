import { parseTruthyString } from './utils/parseTruthyString';

const DTI_CACHE_TIME = 60 * 1000 * 60;

const {
    REACT_APP_HCMP_PROGRAMS_ENABLED,
    REACT_APP_DTI_CACHE_TIME,
    REACT_APP_SHOW_ANSWERS_INTERSTITIAL_ENABLED,
    REACT_APP_HIDDEN_ANSWERS_ENABLED,
    REACT_APP_TOPIC_PROGRAMS_ENABLED,
    REACT_APP_DISABLE_REWARDS,
    REACT_APP_MOCK_API,
} = process.env;

export const API_URL = '/api/v1';
export const hcmpEnabled = REACT_APP_HCMP_PROGRAMS_ENABLED
    ? REACT_APP_HCMP_PROGRAMS_ENABLED === 'true'
    : true;
export const dtiCacheTime = REACT_APP_DTI_CACHE_TIME
    ? parseInt(REACT_APP_DTI_CACHE_TIME, 10)
    : DTI_CACHE_TIME;
export const showAnswersInterstitialEnabled =
    REACT_APP_SHOW_ANSWERS_INTERSTITIAL_ENABLED
        ? REACT_APP_SHOW_ANSWERS_INTERSTITIAL_ENABLED === 'true'
        : true;
export const hiddenAnswersEnabled = REACT_APP_HIDDEN_ANSWERS_ENABLED
    ? REACT_APP_HIDDEN_ANSWERS_ENABLED === 'true'
    : true;
export const topicProgramsEnabled = REACT_APP_TOPIC_PROGRAMS_ENABLED
    ? REACT_APP_TOPIC_PROGRAMS_ENABLED === 'true'
    : false;
export const rewardsEnabled = !REACT_APP_DISABLE_REWARDS;
export const mockApiEnabled = parseTruthyString(REACT_APP_MOCK_API);
